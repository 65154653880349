/* eslint-disable react/forbid-prop-types */
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { usePromiseTracker } from 'react-promise-tracker';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Inner loading.
 * @param color of the Circular Progress.
 * @param text to show (optional).
 * @param style custom style for the container.
 * @param area which area will be rendered the loading on the screen.
 * */
export default function Loading({ color, text, style, area }) {
  const { promiseInProgress } = usePromiseTracker({ area, delay: 0 });

  return (
    promiseInProgress && (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={style}
    >
      <CircularProgress color={color} />
      <Typography style={{ marginTop: 10 }} component="p">{text}</Typography>
    </Grid>
    )
  );
}

Loading.defaultProps = {
  color: 'primary',
  text: '',
  style: {
    minHeight: '30vh',
    marginTop: 25,
  },
  area: '',
};

Loading.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.object,
  area: PropTypes.string,
};
