import { watchFindByIdentification, watchDependentsIdentification } from './find_by_identification';
import { watchRequestSaveIssuance } from './application';
import { watchRequestClientInformation } from './client_information';
import { watchRequestGetApplication, watchRequestGetApplicationPublic } from './get_application';

export const quoteIssuance = [
  watchFindByIdentification,
  watchRequestSaveIssuance,
  watchDependentsIdentification,
  watchRequestClientInformation,
  watchRequestGetApplication,
  watchRequestGetApplicationPublic,
];
