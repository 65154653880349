import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import SearchIcon from '@material-ui/icons/Search';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { requestEmissions } from '../actions/emissions';
import emissionsStyle from '../style/emissions-style';
import { requestPostReQuotation } from '../../Quotation/actions/reQuotation';
import {
  checkEmptyObject,
  dateTimeFormatOrEmptyAtEcuador,
  getUserRole,
  usePrevious,
} from '../../../utils/CommonUtils';
import { addSweetAlert, closeSweetAlert } from '../../common/SweetAlert/actions/sweet_alert';
import { resetWithReQuote } from '../../Quotation/actions/quote';
import { addApplicationInfo } from '../../Issuance/components/Application/actions/application';
import { goToStep } from '../../Issuance/utils/steps';
import Loading from '../../common/Loading';
import { requestGetApplication } from '../../Issuance/components/Application/actions/get_application';
import PaymentResume from './PaymentResume';
import { selectValidityDate } from '../../Issuance/components/Application/utils/selectors';
import ActionMenu from './ActionMenu';
import EmissionListItem from './EmissionListItem';
import { RolEnum } from '../../Issuance/components/Payment/Enums';
import {DebounceInput} from "react-debounce-input";

const getThirtyDaysBefore = () => {
  const now = new Date();
  const before = new Date();
  before.setDate(now.getDate() - 30);
  return before.toISOString();
};

const Emissions = () => {
  const [minDate, setMinDate] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [issuanceRow, setIssuanceRow] = React.useState(null);
  const [planList, setPlanList] = React.useState({});
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(20);
  const [value, setValueFilter] = useState('');
  const login = useSelector((state) => state.login);
  const isRolReporterUnified = getUserRole(login) === RolEnum.ReporterUnified;
  const history = useHistory();
  const { control, getValues } = useForm({
    defaultValues: {
      creationFrom: getThirtyDaysBefore(),
      creationTo: new Date().toISOString(),
    },
  });
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const emissionsProperties = !isDesktop
    && {
      detailPanel: (emission) => (
        <EmissionListItem
          emission={emission}
          planList={planList}
          statusColor={statusColor}
          requote={requote}
          isRolReporterUnified={isRolReporterUnified}
        />
      ),
    };
  const validityDate = useSelector(selectValidityDate);

  const classes = emissionsStyle();
  const dispatch = useDispatch();
  const emissions = useSelector((state) => state.emissions, shallowEqual);

  const { response: { catalogs = [],
    products = [] } } = useSelector((state) => state.catalogs, shallowEqual);
  const saleprocessStatus = catalogs && catalogs.find((c) => c.id === 'EstadoCotizacion')?.catalogue;

  const statusColor = {
    Cotizado: '#63a757',
    PendientePago: '#16c7c4',
    PendienteFirma: '#ffba00',
    GenerandoContrato: '#ffba00',
    Firmado: '#649568',
    FirmaRechazada: '#993129',
    FirmaExpirada: '#993129',
    FirmaFallida: '#993129',
    Caducado: '#993129',
    EnviadoaBMI: '#abcdef',
  };

  const reQuotation = useSelector((state) => state.reQuotation);
  const prevRequotation = usePrevious(reQuotation);
  const [quotationRequest, setQuotationRequest] = React.useState();
  const [step, setStep] = React.useState();

  const simpleModal = useSelector((state) => state.simpleModal);

  useEffect(() => {
    getPlanList();
    getStatusList();
    findIssuance();
  }, []);

  function getPlanList() {
    const planTemp = {};
    products.forEach((company) => {
      company.plans.forEach((plan) => {
        planTemp[plan.id] = plan.name;
      });
    });
    setPlanList(planTemp);
  }


  const [statusList, setStatusList] = React.useState({});

  function getStatusList() {
    const statusListTemp = {};
    if (saleprocessStatus.length > 0) {
      saleprocessStatus.forEach((status) => {
        statusListTemp[status.description] = status.description;
      });
      setStatusList(statusListTemp);
    }
  }

  useEffect(() => {
    if (prevRequotation && (reQuotation.response !== prevRequotation.response)) {
      if (!checkEmptyObject(reQuotation?.response) && !reQuotation.isError) {
        const { result, messages } = reQuotation.response.validationResult;

        if (result === 'SUCCESS') {
          dispatch(resetWithReQuote(quotationRequest, reQuotation.response.deductibleSelected));
          goToStep(history, step);
        } else {
          dispatch(addSweetAlert({
            type: 'error',
            title: 'Edades Incorrectas',
            content: (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                {
                  messages.map((item, index) => (
                    <Grid key={index} item md={12} xs={12}>
                      <Typography variant="h6">
                        {item}
                      </Typography>
                    </Grid>
                  ))
                }
              </Grid>),
            showCancel: result === 'CONTINUE_WITH_CLIENT_ACCEPTANCE',
            onCancelLabel: 'Cancelar',
            onConfirmLabel: 'Continuar',
            onCancel: () => {
              dispatch(closeSweetAlert());
              history.push('/quotation');
            },
            onConfirm: () => {
              dispatch(resetWithReQuote(quotationRequest, reQuotation.response.deductibleSelected));
              goToStep(history, step);
              dispatch(closeSweetAlert());
            },
          }));
        }
      }
    }
  }, [reQuotation.response]);

  const requote = (rowData) => {
    const quoteRequest = JSON.parse(rowData.quotationJson);
    const formatedQuote = {};

    // TODO: set data of the application form to application slice
    dispatch(addApplicationInfo(rowData?.billingData, rowData?.insuranceHolder));
    formatedQuote.name = quoteRequest.MainInsuredName;
    formatedQuote.age = rowData.insuranceMainInsured.ageAtValidityDate;
    formatedQuote.productId = quoteRequest.ProductId;
    formatedQuote.companyId = quoteRequest.CompanyId;
    formatedQuote.branchOfficeId = quoteRequest.BranchOfficeId;
    formatedQuote.ValidityDate = quoteRequest.ValidityDate;
    formatedQuote.deductibleId = rowData.deductibleSelectedId;
    formatedQuote.Identification = rowData.insuranceMainInsured.identification.trim();
    formatedQuote.TargetValidityDate = new Date(validityDate);
    formatedQuote.SaleProcessId = rowData.saleProcessId;
    formatedQuote.PaymentMethod = rowData.paymentMethod;
    formatedQuote.dependants = [];
    formatedQuote.planName = rowData.planName;
    formatedQuote.withDifferentHolder = quoteRequest.WithDifferentHolder;
    formatedQuote.riders = quoteRequest?.Riders;
    rowData.insuranceDependants.map((item) => {
      const dependant = {};
      dependant.name = item.completeName;
      dependant.age = item.ageAtValidityDate;
      dependant.rol = quoteRequest.Dependents
        .find((d) => d.Identification.trim() === item.identification.trim()).RolId;
      dependant.Identification = item.identification.trim();
      formatedQuote.dependants.push(dependant);
    });
    setQuotationRequest(formatedQuote);
    setStep(rowData.state);
    // FIXME: You shouldn't call two async calls
    dispatch(requestPostReQuotation(formatedQuote));
    dispatch(requestGetApplication(rowData.saleProcessId));
  };

  const handleSearch = () => {
    findIssuance();
  };

  const selectDate = (event) => {
    setMinDate(new Date(event));
    findIssuance(1, limit, new Date(event).toISOString(), undefined );
    return new Date(event).toISOString();
  };

  const selectDateMax = (event) => {
    findIssuance(1, limit, undefined , new Date(event).toISOString());
    return new Date(event).toISOString();
  };

  const handleValueChange = (event: any): void => {
    if (!value || !value.includes(event.target.value) || event.target.value === '') {
      setValueFilter(event.target.value)
      findIssuance(1, limit,undefined, undefined,event.target.value);
    }
  };

  const findIssuance = (pagina = 1, limit = 20, startDate = undefined, endDate = undefined, valueFilter = undefined) => {
    const values = getValues();
    dispatch(requestEmissions(startDate ? startDate : values.creationFrom,  endDate ? endDate : values.creationTo, pagina, limit, valueFilter || valueFilter === ''  ? valueFilter : value));
  };

  const handleOpenMenu = (event, data) => {
    setAnchorEl(event.currentTarget);
    setIssuanceRow(data);
  };

  const renderColumns = () => {
    const reporterUnifiedColumns = isRolReporterUnified
      ? [{ title: 'ICSId', field: 'brokerICSId' }, { title: 'Nombre Broker', field: 'brokerName' }]
      : [];
    if (isDesktop) {
      return (
        [
          { title: 'C\u00E9dula', field: 'insuranceHolder.identification' },
          { title: 'Cliente', field: 'insuranceHolder.completeName' },
          ...reporterUnifiedColumns,
          {
            title: 'Fecha',
            field: 'dateInsertion',
            type: 'datetime',
            render: (rowData) => (
              dateTimeFormatOrEmptyAtEcuador(rowData.dateInsertion)
            ),
          },
          { title: 'Contrato', field: 'officialPolicyNumber' },
          {
            title: 'Estado',
            field: 'state',
            type: 'string',
            lookup: statusList,
            render: (rowData) => (
              <Button
                size="small"
                style={{
                  backgroundColor: `${statusColor[rowData.state.replace(/\s/g, '')]}`,
                  color: '#2e2d2d',
                  textTransform: 'unset',
                  fontWeight: 'bold',
                }}
                onClick={() => requote(rowData)}
                disabled={rowData.noActions}
              >
                {rowData.state}
              </Button>
            ),
          },
          {
            title: 'Plan',
            field: 'quotationProductId',
            type: 'string',
            lookup: planList,
          },
          { title: 'Agente', field: 'agentName', type: 'string' },
          {
            title: 'Fecha de Vigencia',
            field: 'quotationSelectedValidityDateFormatted',
            type: 'date',
          },
          { title: 'Valor', field: 'quotationSelectedTotalValue', type: 'numeric' },
          { title: 'Forma de Pago', field: 'paymentMethodDescription', type: 'string' },
        ]
      );
    }
    return (
      [
        {
          title: 'C\u00E9dula',
          field: 'insuranceHolder.identification',
          cellStyle: { padding: 10 },
          headerStyle: { padding: 10 },
        },
        {
          title: 'Cliente',
          field: 'insuranceHolder.completeName',
          cellStyle: { padding: 10 },
          headerStyle: { padding: 10 },
        },
      ]
    );
  };

  if (reQuotation.loading) {
    return (
      <Loading text="Cargando" />
    );
  }

  return (
    <div className={classes.root}>
      {anchorEl && (
        <ActionMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          issuanceRow={issuanceRow}
          getEmissions={findIssuance}
        />
      )}
      <Typography
        variant="h2"
      >
        Listado de Emisiones
      </Typography>
      <form>
        {simpleModal?.isOpen && <PaymentResume />}
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} md={4} lg={4}>
            <div className=  "MuiFormControl-root MuiTextField-root jss29"   style={{width: "100%", marginTop: "22px"}}>
              <div
                  className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl MuiInputBase-adornedStart MuiOutlinedInput-adornedStart MuiInput-underline">
                <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
                  <SearchIcon />
                </div>
                <DebounceInput
                    className='MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedStart MuiOutlinedInput-inputAdornedStart'
                    placeholder='Buscar'
                    minLength={2}
                    debounceTimeout={500}
                    onChange={handleValueChange}
                   />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={(
                  <KeyboardDatePicker disableFuture />
                )}
                autoOk
                className={classes.formControl}
                name="creationFrom"
                fullWidth
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Desde"
                onChange={([event]) => selectDate(event)}// findIssuance(1, limit, new Date(event).toISOString(), undefined  );}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                InputProps={{ readOnly: true }}
                control={control}
                defaultValue={null}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={10} md={2} lg={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={(
                  <KeyboardDatePicker disableFuture />
                )}
                autoOk
                className={classes.formControl}
                name="creationTo"
                minDate={
                  minDate
                }
                fullWidth
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Hasta"
                onChange={([event]) => selectDateMax(event)}  //findIssuance(1, limit, undefined , new Date(event).toISOString()  );}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                InputProps={{ readOnly: true }}
                control={control}
                defaultValue={null}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={2} md={1} lg={1}>
            {/*<Tooltip title="Buscar" arrow>
              <IconButton
                className={classes.formControl}
                aria-label="search"
                onClick={handleSearch}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>*/}
          </Grid>
        </Grid>
      </form>

      <MaterialTable
        title=""
        columns={renderColumns()}
        data={emissions.response.items}
        page={page - 1}
        totalCount={emissions.response.count}
        options={{
          search: false,
          selection: false,
          actionsColumnIndex: -1,
          columnsButton: false,
          filtering: false,
          exportButton: true,
          exportAllData: true,
          pageSize: limit,
          pageSizeOptions: [20, 50],
          sorting: false
        }}
        isLoading={!emissions || emissions.loading}
        actions={[
          (rowData) => {
            if (rowData.state === 'Firmado' || rowData.changeP2pTokenAllowed || rowData.state === 'Pendiente Firma' || rowData.state === 'Pendiente Pago') {
              return {
                icon: () => <MoreVertIcon />,
                tooltip: 'Opciones',
                onClick: (event, data) => handleOpenMenu(event, data),
              };
            }
            return null;
          },
        ]}
        localization={{
          header: {
            actions: 'Acciones',
          },
        }}
        {...emissionsProperties}
        onChangeRowsPerPage={(value) => { if(value != limit) {findIssuance(1 , value); setLimit(value);   setPage(1); }}}
        onChangePage={(value) => { if(page != (value + 1)) {findIssuance(value + 1, limit); setPage(value + 1);}} }
      />
    </div>
  );
};

export default Emissions;
