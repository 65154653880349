/* eslint-disable import/prefer-default-export,max-len */
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { Logger, LogLevel } from 'msal';

const b2cPolicies = {
  authorities: {
    signIn: {
      authority: process.env.REACT_APP_AAD_AUTHORITY_URL_LOGIN,
    },
    forgotPassword: {
      authority: process.env.REACT_APP_AAD_AUTHORITY_URL_RESET_PASSWORD,
    },
  },
};

// Msal Configurations
const authProvider = new MsalAuthProvider({
  auth: {
    authority: b2cPolicies.authorities.signIn.authority,
    clientId: process.env.REACT_APP_AAD_AUTHORITY_CLIENTID,
    postLogoutRedirectUri: window.location.href,
    redirectUri: process.env.REACT_APP_AAD_REDIRECT_URL,
    validateAuthority: false,
  },
  system: {
    logger: new Logger(
      (logLevel, message, containsPii) => {
        console.log('[MSAL]', message);
      },
      {
        level: LogLevel.Verbose,
        piiLoggingEnabled: false,
      },
    ),
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
}, {
  scopes: [`${process.env.REACT_APP_AAD_AUTHORITY_URL_IMPERSONATION}`],
}, {
  loginType: LoginType.Popup,
  tokenRefreshUri: `${window.location.origin}/auth.html`,
});

const handleError = (error) => {
  // console.log('handleError: ', error);
  if (error?.errorMessage.indexOf('AADB2C90118') > -1) {
    authProvider.setAuthenticationParameters({ authority: b2cPolicies.authorities.forgotPassword.authority });
    authProvider.login();
  }
  // Renewal token exception is thrown so will be necessary to launch login flow again. It's error happen on forgot password user flow
  if (error?.errorCode === 'token_renewal_error') {
    authProvider.setAuthenticationParameters({ authority: b2cPolicies.authorities.signIn.authority });
    authProvider.login();
  }
};

authProvider.registerErrorHandler(handleError);

export { authProvider };
