import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Button, CircularProgress, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { useLocation } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import CustomPanel from '../../common/CustomPanel';
import { requestGetApplicationPublic } from '../../Issuance/components/Application/actions/get_application';
import * as Constants from '../../../utils/Constants';
import { areas } from '../../../utils/Constants';
import Loading from '../../common/Loading';
import { getSessionStart } from '../../Issuance/components/Payment/slices/payment_button';
import { WindowOpener } from '../../../utils/window-opener';
import { usePrevious } from '../../../utils/CommonUtils';
import { requestTokenUpdate } from '../reducers/updateTokenSlice';
import { addSweetAlert, closeSweetAlert } from '../../common/SweetAlert/actions/sweet_alert';
import { PaymentResult } from '../../Issuance/components/Payment/Enums';
import PlaceToPay from '../../../assets/images/payments/placetopay-logo.svg';
import PayPhone from '../../../assets/images/payments/payPhone.png';
import { openModal } from '../../common/modals/actions/simple_modal';
import CardDataForm from '../../Issuance/components/Payment/components/credit/CardDataForm';


const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '25%',
      paddingRight: '25%',
    },
  },
  backdrop: {
    display: 'flex',
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
  },
  backdropText: {
    padding: 10,
  },
  blueText: {
    fontSize: 14,
    color: '#518CBF',
  },
  confirmButton: {
    borderRadius: 20,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 30,
      marginRight: 30,
    },
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#00315C' },
  },
  pendingTitle: {
    backgroundColor: '#cccccc',
    paddingBottom: 13,
    paddingTop: 13,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 25,
  },
  successTitle: {
    backgroundColor: '#709933',
    paddingBottom: 13,
    paddingTop: 13,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 25,
  },
  whiteText: {
    color: '#ffffff',
    fontSize: 18,
    verticalAlign: 'middle',
  },
  contentContainer: {
    [theme.breakpoints.up('lg')]: {
      padding: 30,
    },
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
  },
}));

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SummaryData() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const { promiseInProgress } = usePromiseTracker({ area: areas.summary, delay: 3000 });
  const getApplication = useSelector((state) => state.getApplication.response);

  const person = getApplication?.InsuranceApplicationMainInsured?.Person;
  const billing = getApplication?.InsuranceApplicationBilling;
  const policy = getApplication?.SaleProcess?.Policy;

  const tokenUpdate = useSelector((state) => state.tokenUpdate);
  const prevTokenUpdate = usePrevious(tokenUpdate);

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [textBackdrop, setTextBackDrop] = React.useState('');

  const paymentButton = useSelector((state) => state.paymentButton);
  const previousPaymentButton = usePrevious(paymentButton);

  const updated = useRef(false);

  const cardDataResponse = useSelector((state) => state.simpleModal);
  const [payphoneProcessed, setPayphoneProcessed] = React.useState(false);

  useEffect(() => {
    dispatch(requestGetApplicationPublic(query.get('saleProcessId')));
  }, []);

  const togglePageBlock = () => {
    setOpenBackdrop(!openBackdrop);
  };

  useEffect(() => {
    if (previousPaymentButton && (
      previousPaymentButton.sessionData !== paymentButton.sessionData)) {
      if (paymentButton.sessionData?.status?.status === 'OK') {
        const popup = new WindowOpener(paymentWindowBridge,
          paymentButton.sessionData?.processURL);
        popup.onClickHandler();
      }
    }
  }, [paymentButton.sessionData, previousPaymentButton]);

  useEffect(() => {
    if (prevTokenUpdate && prevTokenUpdate !== tokenUpdate && !tokenUpdate.isLoading) {
      togglePageBlock();
      if (tokenUpdate.tokenUpdated) {
        const { response } = tokenUpdate;
        let title = 'Tarjeta actualizada';
        let type = 'info';
        const { message } = response;
        if (response.result === PaymentResult.PENDING) {
          updated.current = true;
          policy.MaskedCard = response.maskedCard;
        }
        if (response.result === PaymentResult.SUCCESS) {
          updated.current = true;
          policy.MaskedCard = response.maskedCard;
        }
        if (response.result === PaymentResult.FAILURE) {
          title = 'Tarjeta no actualizada';
          type = 'error';
          dispatch(addSweetAlert({
            type,
            title,
            content: message,
            showCancel: false,
            onConfirmLabel: 'Aceptar',
            onConfirm: () => {
              dispatch(closeSweetAlert());
            },
          }));
        }
      } else {
        dispatch(addSweetAlert({
          type: 'info',
          title: 'Tarjeta no actualizada',
          content: 'Por favor intente mas tarde',
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }));
      }
    }
  }, [tokenUpdate]);

  useEffect(() => {
    const result = cardDataResponse.result;
    if (result?.result !== undefined && !policy.isSuccess) {
      if (result.result === 1) {
        setOpenBackdrop(false);
        setPayphoneProcessed(true);
      } else if (result.result === 3) {
        dispatch(addSweetAlert({
          type: 'error',
          title: result.errorMessage,
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
            setOpenBackdrop(false);
            cardDataResponse.result = undefined;
          },
        }));
      }
    } else if (result?.canceled) {
      setOpenBackdrop(false);
    } else {
      setOpenBackdrop(false);
    }
  }, [cardDataResponse]);

  const paymentWindowBridge = (type, reference) => {
    if (type === 'success') {
      togglePageBlock();
      setTextBackDrop(Constants.REGISTERING_CARD);
      dispatch(requestTokenUpdate(reference));
    }
  };

  const handleUpdateCard = () => {
        //RZ: 1606223 tracker 44097 deshabilitar opción actualizar tarjeta
        dispatch(addSweetAlert({
          type: 'error',
          title: 'Opción deshabilitada- esta actualización debe realizarse en el Portal de Agentes BMI',
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }))
        /*
    if (policy.paymentButtonProvider === 1) {
      dispatch(getSessionStart({
        quotationPaymentMethodId: policy.paymentMethod,
        paymentConditionId: policy.PaymentConditionId,
        saleProcessId: getApplication.SaleProcessId,
        action: 'ACTION_TOKEN_UPDATE',
      }));
    } else if (policy.paymentButtonProvider === 2) {
      const cardData = {
        saleProcessId: policy.SaleProcessId,
        paymentConditionId: policy.PaymentConditionId,
        paymentMethod: policy.paymentMethod,
        creditCardName: policy.creditCardName,
        tokenUpdate: true,
      };
      dispatch(openModal(cardData));
    }*/
  };

  const renderPaymentBUtton = (payment) => ({
    1: PlaceToPay,
    2: PayPhone,
  })[payment];

  function renderItem(title, content) {
    if (promiseInProgress) {
      return (
        <Loading
          area={areas.summary}
          text="Cargando..."
          style={{ minHeight: '65vh', marginTop: 25 }}
        />
      );
    }
    return (
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography
            className={classes.blueText}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <p>{content}</p>
        </Grid>
      </Grid>
    );
  }

  function paymentData() {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item container direction="row">
          <Grid item xs={6}>
            {renderItem('CLIENTE TITULAR', person?.Name?.concat(' ', person?.LastName))}
          </Grid>
          <Grid item xs={6}>
            {renderItem('CONTRATO', policy?.OfficialPolicyNumber)}
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={6}>
            {renderItem('NOMBRE PAGADOR', billing?.Name.concat(' ', billing?.LastName))}
          </Grid>
          <Grid item xs={6}>
            {renderItem('CÉDULA DE IDENTIDAD PAGADOR', billing?.Identification)}
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={6}>
            {renderItem('TARJETA', policy?.creditCardName)}
          </Grid>
          <Grid item xs={6}>
            <img
              width="223"
              height="62"
              src={renderPaymentBUtton(policy?.paymentButtonProvider)}
              alt="payment button"
            />
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={6}>
            {renderItem('BANCO', policy?.PaymentBank)}
          </Grid>
          <Grid item xs={6}>
            {renderItem('TARJETA', `XXXX XXXX XXXX ${policy?.MaskedCard}`)}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderForm() {
    return (
      <>
        <CardDataForm />
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
          <p className={classes.backdropText}>{textBackdrop}</p>
        </Backdrop>
        <div className={classes.content}>
          <CustomPanel title="Actualizar Tarjeta">
            {paymentData()}
            <br />
            <Grid container direction="column" spacing={3}>
              <Grid item container justify="center">
                <Button
                  fullWidth
                  type="button"
                  color="secondary"
                  variant="contained"
                  className={classes.confirmButton}
                  onClick={handleUpdateCard}
                >
                  ACTUALIZAR TARJETA
                </Button>
              </Grid>
            </Grid>
          </CustomPanel>
        </div>
      </>
    );
  }

  function renderResult() {
    return (
      <>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
          <p className={classes.backdropText}>{textBackdrop}</p>
        </Backdrop>
        <div className={classes.content}>
          <CustomPanel title="Cambio de tarjeta" border={false}>
            <Grid container className={classes.contentContainer} spacing={0}>
              <Grid item xs={12} sm={12}>
                <Grid container direction="row" justify="center" spacing={1}>
                  <Grid item>
                    <div
                      className={tokenUpdate.response.result === PaymentResult.PENDING
                        ? classes.pendingTitle : classes.successTitle}
                    >
                      <Typography className={classes.whiteText}>
                        <CheckIcon fontSize="inherit" />
                        {tokenUpdate.response.result === PaymentResult.PENDING
                          ? 'Tarjeta pendiente de autorización'
                          : 'Nueva tarjeta guardada'}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {paymentData()}
            <br />
          </CustomPanel>
        </div>
      </>
    );
  }

  function renderPayphoneResult() {
    return (
      <>
        <div className={classes.content}>
          <CustomPanel title="Cambio de tarjeta" border={false}>
            <Grid container className={classes.contentContainer} spacing={0}>
              <Grid item xs={12} sm={12}>
                <Grid container direction="row" justify="center" spacing={1}>
                  <Grid item>
                    <div className={classes.successTitle}>
                      <Typography className={classes.whiteText}>
                        <CheckIcon fontSize="inherit" />
                        <span>Nueva tarjeta guardada</span>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {paymentData()}
            <br />
          </CustomPanel>
        </div>
      </>
    );
  }

  if (updated.current) {
    return renderResult();
  }

  if (payphoneProcessed) {
    return renderPayphoneResult();
  }

  return renderForm();
}
