/* eslint-disable max-len,global-require */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { selectDeductible } from '../../../../actions/quote';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 140,
    borderRadius: '10px',
    backgroundColor: '#518CBF',
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.down(450)]: {
      marginRight: '5px !important',
      width: 130,
    },
  },
  textColor: {
    color: theme.palette.primary.light,
  },
  listItem: {
    justifyContent: 'flex-end',
  },
  listItemSelect: {
    justifyContent: 'flex-end',
    '&:hover': {
      background: '#2B6B96',
    },
    '&.Mui-selected': {
      backgroundColor: '#06466E',
    },
  },
  selectedDeductible: {
    backgroundColor: '#17598E',
  },
}));

const getIcon = (typeDescription) => {

  switch (typeDescription) {
    case 'Contado':
      return (
        <Tooltip title="Contado">
          <img
            src={require('../../../../../../assets/images/deductible/ic_cotizacion_contado.svg')}
            alt="cloud error"
          />
        </Tooltip>
      );
    case 'Débito Bancario':
      return (
        <Tooltip title="Débito Bancario">
          <img
            src={require('../../../../../../assets/images/deductible/ic_cotizacion_debito.svg')}
            alt="cloud error"
          />
        </Tooltip>
      );
    case 'Diferido Especial':
      return (
        <Tooltip title="Diferido Especial">
          <img
            src={require('../../../../../../assets/images/deductible/ic_cotizacion_diferido.svg')}
            alt="cloud error"
          />
        </Tooltip>
      );
    case 'Tarjeta de Crédito':
      return (
        <Tooltip title="Tarjeta de Crédito">
          <img
            src={require('../../../../../../assets/images/deductible/ic_cotizacion_tarjeta.svg')}
            alt="cloud error"
          />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="Contado">
          <img
            src={require('../../../../../../assets/images/deductible/ic_cotizacion_contado.svg')}
            alt="cloud error"
          />
        </Tooltip>
      );
  }
};

export default function Deductible({ detail }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);

  const { deductibleSelected } = quote;

  const dispatchSelectDeductible = (paymentType) => {
    dispatch(selectDeductible(detail, paymentType));
  };

  return (
    <Card
      style={{ marginRight: 40 }}
      className={clsx(
        classes.root,
        detail.deductibleId === deductibleSelected?.deductible?.deductibleId && classes.selectedDeductible,
      )}
    >
      <List>
        <ListItem className={classes.listItem}>
          <Typography variant="h1" className={classes.textColor}>
            {detail?.productDescription}
          </Typography>
        </ListItem>
        {detail?.paymentType.map((payment) => (
          <ListItem
            key={payment?.typeId}
            button
            className={classes.listItemSelect}
            selected={deductibleSelected?.quotationId.toString().concat(`${deductibleSelected.paymentType}`)
            === payment?.quotationId.toString().concat(`${payment?.typeDescription}`)}
            onClick={() => dispatchSelectDeductible(payment)}
          >
            <ListItemIcon style={{ minWidth: '40px' }}>
              {getIcon(payment?.typeDescription)}
            </ListItemIcon>
            <ListItemText primary={
              (
                <Typography className={classes.textColor}>
                  {`$${payment?.value}`}
                </Typography>
              )
            }
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
}

Deductible.propTypes = {
  detail: PropTypes.shape({
    deductibleId: PropTypes.number.isRequired,
    productDescription: PropTypes.string.isRequired,
    paymentType: PropTypes.arrayOf(
      PropTypes.shape({
        typeId: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      }),
    ),
  }).isRequired,
};
