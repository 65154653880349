import { ADD_NOTIFICATION, CLOSE_NOTIFICATION } from '../actions/notification';

const INITIAL_STATE = {
  message: '',
  open: false,
  severity: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        message: action.payload.message,
        open: true,
        severity: action.payload.severity,
      };
    case CLOSE_NOTIFICATION:
      return { ...state, open: false };
    default:
      return state;
  }
};
