/* eslint-disable react/forbid-prop-types,react/no-array-index-key */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { checkEmptyObject, groupByKey } from '../../../../../../utils/CommonUtils';

const useStyles = makeStyles((theme) => ({
  containerStyle: {
    marginTop: 40,
  },
  boxStyle: {
    padding: 25,
    borderLeft: '15px solid #00315C',
    [theme.breakpoints.down(325)]: {
      padding: 15,
    },
  },
  valueStyle: {
    fontSize: 20,
  },
  contentContainer: {
    [theme.breakpoints.up('lg')]: {
      padding: 30,
    },
    [theme.breakpoints.down('md')]: {
      padding: 10,
      marginRight: 20,
    },
  },
}));

function MainBenefits({ mainBenefits = [] }) {
  const benefitsBoxProps = { bgcolor: 'white', borderRadius: 16, height: 220 };
  const grouped = groupByKey(mainBenefits, 'groupingId');
  const classes = useStyles();

  if (!checkEmptyObject(grouped)) {
    return (
      <Grid container className={classes.contentContainer} spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box {...benefitsBoxProps} className={classes.boxStyle}>
            <Grid container direction="row" spacing={1}>
              {grouped?.CARENCIA?.map((carencia, index) => (
                <Grid key={index} container item xs={12} direction="column">
                  <Typography color="primary">{carencia?.description}</Typography>
                  <p className={classes.valueStyle}>{carencia?.value}</p>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box {...benefitsBoxProps} className={classes.boxStyle}>
            <Grid container direction="row" spacing={1}>
              {grouped?.CUARTO?.map((cuarto, index) => (
                <Grid key={index} container item xs={12} direction="column">
                  <Typography color="primary">{cuarto?.description}</Typography>
                  <p className={classes.valueStyle}>{cuarto?.value}</p>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
  }
  return null;
}

MainBenefits.propTypes = {
  mainBenefits: PropTypes.array.isRequired,
};

export default memo(MainBenefits);
