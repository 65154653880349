export const isValidId = (id) => validateId(id);

export const isValidRuc = (id) => validateRuc(id);

export const isValidDate = (date) => {
  const validDate = new Date(date);
  return validDate.toString() !== 'Invalid Date';
};

function validateId(id) {
  if (id && id.length === 10) {
    const coeficientes = [2, 1, 2, 1, 2, 1, 2, 1, 2];
    return validarCedula(id, coeficientes);
  }

  return false;
}

function validateRuc(ruc) {
  if (ruc) {
    switch (ruc.charAt(2)) {
      case ('9'):
        if (ruc.length === 13) {
          return esRucEmpresaPrivadaValida(ruc);
        }
        break;
      case ('6'):
        if (ruc.length === 13) {
          return isValidPublicCompanyRuc(ruc);
        }
        break;
      default:
        if (ruc.length === 13) {
          return esRucPersonaNaturalValido(ruc);
        }
        break;
    }
  }
  return false;
}

function isValidPublicCompanyRuc(identificacion) {
  if (identificacion.length === 13 && identificacion.substring(10, 13) === '001') {
    const coeficientes = [3, 2, 7, 6, 5, 4, 3, 2];
    return validarCedula(identificacion, coeficientes);
  }
  return false;
}

function esRucEmpresaPrivadaValida(identificacion) {
  if (identificacion.length === 13 && identificacion.substring(10, 13) === '001') {
    const coeficientes = [4, 3, 2, 7, 6, 5, 4, 3, 2];
    return validarCedula(identificacion, coeficientes);
  }
  return false;
}

export function esRucPersonaNaturalValido(identificacion) {
  if (identificacion.length === 13 && identificacion.substring(10, 13) === '001') {
    const coeficientes = [2, 1, 2, 1, 2, 1, 2, 1, 2];
    return validarCedula(identificacion, coeficientes);
  }
  return false;
}

export function esCedulaPersonaNaturalValido(identificacion) {
  if (identificacion.length === 10) {
    const coeficientes = [2, 1, 2, 1, 2, 1, 2, 1, 2];
    return validarCedula(identificacion, coeficientes);
  }
  return false;
}

export function esCedulaORucPersonaNaturalValido(identificacion) {
  if (identificacion.length === 13 && identificacion.substring(10, 13) === '001') {
    const coeficientes = [2, 1, 2, 1, 2, 1, 2, 1, 2];
    return validarCedula(identificacion, coeficientes);
  }
  return validateId(identificacion);
}

function validarCedula(identificacion, coeficientes) {
  const id = identificacion;
  if (id !== '9999999999') {
    const sumaDigitosPorCoeficiente = calculateDigitsByCoefficients(id, coeficientes);
    const resultado = calculateVerifier(id, sumaDigitosPorCoeficiente);
    const verifierDigit = extractVerifierDigit(id);
    return resultado === verifierDigit;
  }
  return true;
}

function isPublicCompany(id) {
  return id.length === 13 && id.charAt(2) === '6';
}

function isPrivateCompany(id) {
  return id.charAt(2) === '9';
}

function isCompany(id) {
  return isPrivateCompany(id) || isPublicCompany(id);
}

function calculateVerifier(id, sumDigitsByCoefficients) {
  const tipoMod = isCompany(id) ? 11 : 10;
  const module = sumDigitsByCoefficients % tipoMod;
  return module === 0 ? module : tipoMod - module;
}

function calculateDigitsByCoefficients(id, coeficientes) {
  let sum = 0;
  for (let i = 0; i < coeficientes.length; i += 1) {
    const digito = id.charAt(i);
    let valor = coeficientes[i] * digito;
    if (valor > 9 && !isCompany(id)) {
      valor -= 9;
    }
    sum += valor;
  }
  return sum;
}

function extractVerifierDigit(id) {
  const verifierDigit = isPublicCompany(id) ? id.charAt(8) : id.charAt(9);
  return parseInt(verifierDigit, 0);
}

export function validateExpirationDate(expirationDate) {
  if (!expirationDate) {
    return false;
  }

  const today = new Date();
  const monthToday = today.getMonth() + 1;
  const yearToday = today
    .getFullYear()
    .toString()
    .substr(-2);

  const [expMonth, expYear] = expirationDate.split('/');

  if (Number(expYear) < Number(yearToday)) {
    return false;
  }
  if (Number(expMonth) < monthToday && Number(expYear) <= Number(yearToday)) {
    return false;
  }
  if (Number(expMonth) > 12) {
    return false;
  }
  return true;
}
