export const START_REQUEST_CATALOGS = 'START_REQUEST_CATALOGS';
export const REQUEST_CATALOGS_SUCCESS = 'REQUEST_CATALOGS_SUCCESS';
export const REQUEST_CATALOGS_FAILED = 'REQUEST_CATALOGS_FAILED';

/* Getting all catalogs */
export const requestCatalogs = () => ({
  type: START_REQUEST_CATALOGS,
});

export const successRequestCatalogs = (catalogs, products, parameters) => ({
  type: REQUEST_CATALOGS_SUCCESS,
  payload: { catalogs, products, parameters },
});

export const failedRequestCatalogs = (error) => ({
  type: REQUEST_CATALOGS_FAILED,
  payload: error,
});
