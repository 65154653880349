/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Controller, FormContext, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AccountRefunds, BillData, Holder, OwnerContact } from './components';
import applicationStyles from './style/application-style';
import { requestPostIssuance } from './actions/application';
import { addSweetAlert, closeSweetAlert } from '../../../common/SweetAlert/actions/sweet_alert';
import { resetRequestData, resetWithReQuote } from '../../../Quotation/actions/quote';
import {
  calculateAgeFromDate,
  checkEmptyObject,
  findParameter,
  isEmpty,
  usePrevious,
} from '../../../../utils/CommonUtils';
import { openModal, resetModal } from '../../../common/modals/actions/simple_modal';
import TermsConditionsForm from '../../../Quotation/components/TermsConditions/TermsConditionsForm';
import { requestPostReQuotation } from '../../../Quotation/actions/reQuotation';
import { requestGetApplication } from './actions/get_application';
import { selectValidityDate } from './utils/selectors';
import { applicationCovidSchema, applicationSchema } from './applicationSchema';

const Application = (props) => {
  const { handleNext } = props;
  const dispatch = useDispatch();

  const history = useHistory();

  const quote = useSelector((state) => state.quote);
  const getApplication = useSelector((state) => state.getApplication);
  const [dataRequest, setDataRequest] = React.useState({});

  const application = useSelector((state) => state.application);
  const previousApplication = usePrevious(application);

  const simpleModal = useSelector((state) => state.simpleModal);
  const classes = applicationStyles();
  const reQuotation = useSelector((state) => state.reQuotation);
  const prevRequotation = usePrevious(reQuotation);
  const prevSimpleModal = usePrevious(simpleModal);
  const prevGetApplication = usePrevious(getApplication);
  const [quoteRequest, setQuoteRequest] = React.useState({});
  const [updateQuoteRequest, setUpdatedQuoteRequest] = React.useState({});
  const [editionMode, setEditionMode] = React.useState(false);
  const [hasPolicy, setHasPolicy] = React.useState(false);
  const validityDate = useSelector(selectValidityDate);

  const { response: { parameters = [] } } = useSelector((state) => state.catalogs);
  const covid19QuestionParam = findParameter(parameters, 'Covid19Questions');
  const [isEnabledCovidParam, setIsEnabledCovidParam] = React.useState(JSON.parse(covid19QuestionParam.value.toLowerCase()));

  const formMethods = useForm({
    validationSchema: isEnabledCovidParam ? applicationCovidSchema : applicationSchema,
    defaultValues: {
      insuranceMainInsured: {
        identification: '',
      },
      insuranceHolder: {
        identification: '',
      },
      contact: {
        province: '',
      },
    },
  });

  const goToEmissions = () => {
    history.push('/emissions');
  };

  useEffect(() => {
    const { requestData: { SaleProcessId } } = quote;
    if (SaleProcessId) dispatch(requestGetApplication(SaleProcessId));
  }, []);

  useEffect(() => {
    if (prevGetApplication && (prevGetApplication.response !== getApplication.response)) {
      if (!isEmpty(getApplication.response)) {
        const applicationResponse = getApplication.response;
        if (applicationResponse?.InsuranceApplicationMainInsured?.QuestionCovid19TestPerformed === null) { // isEnabledCovidParam is false if the first covid question is null.
          setIsEnabledCovidParam(false);
        }
        setEditionMode(true);
        setHasPolicy(applicationResponse.SaleProcess?.Policy?.OfficialPolicyNumber?.length > 0);
      } else {
        // TODO: se deberia establecer en false el editMode cuando hubo un fallo al obtener los datos de la solicitud? Si es asi se estaria permitiendo ingresar datos.
        // TODO: show a error page if this scenario is present.
        setEditionMode(false);
      }
    }
  }, [getApplication.response]);

  useEffect(() => {
    if (prevRequotation && (reQuotation.response !== prevRequotation.response)) {
      if (!checkEmptyObject(reQuotation?.response) && !isEmpty(updateQuoteRequest) && !reQuotation.isError) {
        const { result, messages } = reQuotation.response.validationResult;
        if (result === 'SUCCESS') {
          dispatch(resetWithReQuote(updateQuoteRequest,
            reQuotation.response.deductibleSelected));
          showTermsAndConditions();
          history.push({ pathname: '/issuance', state: { step: 2 } });
        } else {
          dispatch(addSweetAlert({
            type: 'info',
            title: result === 'FINISH_SALE_PROCESS' || result === 'DUPLICATE_IDENTIFICATIONS' ? 'Advertencia' : 'Edades Incorrectas',
            style: { backgroundColor: 'blue' },
            content: (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                {
                  messages.map((message, index) => (
                    <Grid key={index} item md={12} xs={12}>
                      <Typography variant="h6">
                        {message}
                      </Typography>
                    </Grid>
                  ))
                }
              </Grid>),
            showCancel: result === 'CONTINUE_WITH_CLIENT_ACCEPTANCE',
            onCancelLabel: 'Cancelar',
            onConfirmLabel: result === 'FINISH_SALE_PROCESS' ? 'Ver listado de emisiones' :
              result === 'DUPLICATE_IDENTIFICATIONS' || result === 'AGE_NOT_ALLOWED' ? 'Aceptar' : 'Continuar',
            onCancel: () => {
              dispatch(resetRequestData(updateQuoteRequest));
              dispatch(closeSweetAlert());
              history.push('/quotation');
            },
            onConfirm: () => {
              if (result === 'FINISH_SALE_PROCESS') {
                dispatch(resetRequestData(updateQuoteRequest));
                dispatch(closeSweetAlert());
                history.push('/emissions');
              } else if (result === 'DUPLICATE_IDENTIFICATIONS' || result === 'AGE_NOT_ALLOWED') {
                dispatch(closeSweetAlert());
              } else {
                dispatch(resetWithReQuote(updateQuoteRequest,
                  reQuotation.response.deductibleSelected));
                dispatch(closeSweetAlert());
                showTermsAndConditions();
              }
            },
          }));
        }
      }
    }
  }, [reQuotation.response, updateQuoteRequest]);

  /**
   * When terms and conditions ara accepted the application form is saved.
   * */
  useEffect(() => {
    if (prevSimpleModal && (simpleModal.result !== prevSimpleModal.result)) {
      if (simpleModal.result && simpleModal.result.acceptedTerms === true) {
        dataRequest.termsConditionsAccepted = simpleModal.result.acceptedTerms;
        simpleModal.result.acceptedTerms = false;
        dispatch(requestPostIssuance(dataRequest));
        dispatch(resetModal());
      }
    }
  }, [simpleModal]);

  useEffect(() => {
    if (previousApplication && (previousApplication.response !== application.response)) {
      if (!application.isError) {
        handleNext();
      } else {
        dispatch(addSweetAlert({
          type: 'error',
          title: 'No se guardo la Solicitud',
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }));
      }
    }
  }, [application]);

  useEffect(() => {
    if (!isEmpty(dataRequest) && !isEmpty(quoteRequest)) {
      setCorrectQuoteRequest(dataRequest, quoteRequest);
    }
  }, [dataRequest, quoteRequest]);

  const onSubmit = (data) => {
    formQuotationRequest(data);
    setDataRequest(data);
  };

  const formQuotationRequest = (data) => {
    const { requestData, response, deductibleSelected } = quote;
    const cloneRequest = JSON.parse(JSON.stringify(requestData));
    let typeId = 0;
    if (deductibleSelected.paymentType) {
      const paymentMethod = deductibleSelected.deductible.paymentType.find(
        (p) => p.typeDescription === deductibleSelected.paymentType,
      );
      typeId = paymentMethod.typeId;
    } else {
      typeId = deductibleSelected.typeId;
    }

    cloneRequest.TargetValidityDate = new Date(validityDate);
    cloneRequest.Identification = data.insuranceMainInsured.identification.trim();
    cloneRequest.SaleProcessId = response.saleProcessId ? response.saleProcessId : requestData.SaleProcessId;
    cloneRequest.PaymentMethod = typeId;
    cloneRequest.deductibleId = deductibleSelected.deductibleId ? deductibleSelected.deductibleId : requestData.deductibleId;
    cloneRequest.name = `${data.insuranceMainInsured.name} ${data.insuranceMainInsured.lastName}`;
    cloneRequest.personalData = data;

    if (data.insuranceDependants) {
      data.insuranceDependants.map((item, index) => {
        cloneRequest.dependants[index].Identification = item.identification;
        cloneRequest.dependants[index].name = `${item.name} ${item.lastName}`;
      });
    }
    dispatch(requestPostReQuotation(cloneRequest));
    setQuoteRequest(cloneRequest);
  };

  const setCorrectQuoteRequest = (issuanceData, quoteData) => {
    const cloneQuoteData = JSON.parse(JSON.stringify(quoteData));
    if (!isEmpty(issuanceData) && !isEmpty(quoteData)) {
      const holderAge = calculateAgeFromDate(issuanceData.insuranceMainInsured.birthDate, validityDate);
      cloneQuoteData.age = holderAge;

      if (issuanceData?.insuranceDependants) {
        issuanceData.insuranceDependants.map((item, index) => {
          const dependantAge = calculateAgeFromDate(item.birthDate, validityDate);
          // eslint-disable-next-line no-param-reassign
          cloneQuoteData.dependants[index].age = dependantAge;
        });
      }
      setUpdatedQuoteRequest(cloneQuoteData);
    }
  };

  const showTermsAndConditions = () => {
    dispatch(openModal());
  };

  return (
    <FormContext {...formMethods}>
      {simpleModal.isOpen && <TermsConditionsForm />}
      <div className={classes.root}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          {
            !getApplication.loading
            && (
              <div className={classes.center}>
                {
                  hasPolicy ? (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.saveButton}
                        onClick={() => goToEmissions()}
                        hidden={!hasPolicy}
                      >
                        Ver Emisiones
                      </Button>
                  )
                    : (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.saveButton}
                        type="submit"
                        hidden={hasPolicy}
                      >
                        Guardar Solicitud y Continuar
                      </Button>
                    )
                }
              </div>
            )
          }
          <Holder
            editionMode={editionMode}
            hasPolicy={hasPolicy}
            isEnabledCovidParam={isEnabledCovidParam}
          />
          <OwnerContact
            readOnly={hasPolicy}
            withDifferentHolder={quote?.requestData?.withDifferentHolder}
            branchOfficeId={quote?.requestData?.branchOfficeId}
          />
          <BillData readOnly={hasPolicy} />
          <AccountRefunds
            readOnly={hasPolicy}
            withDifferentHolder={quote?.requestData?.withDifferentHolder}
          />

          {
            !getApplication.loading
            && (
              <div className={classes.center}>
                {
                  hasPolicy
                    ? (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.saveButton}
                        onClick={() => goToEmissions()}
                        hidden={!hasPolicy}
                      >
                        Ver Emisiones
                      </Button>
                    )
                    : (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.saveButton}
                        type="submit"
                        hidden={hasPolicy}
                      >
                        Guardar Solicitud y Continuar
                      </Button>
                    )
                }
              </div>
            )
          }
        </form>
      </div>
      <Controller
        as={TextField}
        name="termsConditionsAccepted"
        control={formMethods.control}
        value={false}
        type="hidden"
      />
    </FormContext>
  );
};

Application.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default Application;
