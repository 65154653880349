import React, { useEffect, useRef } from 'react';
import {
  Grid,
  Box,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField, FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Link from '@material-ui/core/Link';

import {
  checkEmptyObject,
  getPaymentConditionText,
  groupByKey,
} from '../../../../../../utils/CommonUtils';
import CardListGrid from './CardsListGrid';
import { searchPayments } from '../../slices/payment_info';
import RetryData from '../../../../../common/RetryData';
import Loading from '../../../../../common/Loading';
import { PaymentMethod } from '../../Enums';


const useStyles = makeStyles(() => ({
  box: {
    backgroundColor: '#e3e3e3',
    padding: 20,
  },
  faqLinkBox: {
    fontSize: '12px',
    color: '#a7a6a7',
    display: 'block',
    width: '100%',
    textAlign: 'right',
    paddingRight: '20px',
  },
}));

export default function CreditPayment() {
  const classes = useStyles();
  const boxProperties = { borderRadius: 16, className: classes.box };
  const dispatch = useDispatch();

  const [selectedCard, setSelectedCard] = React.useState({});
  const [conditions, setConditions] = React.useState({}); // grouping by specific name
  const [paymentMethod, setPaymentMethod] = React.useState('');

  const { control, watch, setValue, errors } = useFormContext();
  const paymentInfo = useSelector((state) => state.paymentInfo);
  const quote = useSelector((state) => state.quote);
  const { deductibleSelected = {} } = quote;
  const { data: payments } = paymentInfo;
  const [paymentConditionLabel, setPaymentConditionLabel] = React.useState('');

  const financialInstitutionWatcher = watch('creditPayment.financialInstitution');

  useEffect(() => {
    dispatch(searchPayments(deductibleSelected?.typeId === 4 ? true : undefined));
  }, [deductibleSelected, dispatch]);

  useEffect(() => {
    setSelectedCard({});
    setConditions({});
    setPaymentMethod('');
    setValue([
      { 'creditPayment.paymentCondition': '' },
      { 'creditPayment.selectedCard': {} },
    ]);
  }, [financialInstitutionWatcher, setValue]);

  useEffect(() => {
    if (!checkEmptyObject(selectedCard)) {
      setValue([
        { 'creditPayment.paymentCondition': '' },
        { 'creditPayment.selectedCard': selectedCard },
      ]);
      setPaymentMethod('');
      setConditions(groupByKey(selectedCard?.conditions, 'paymentMethod'));
    }
  }, [selectedCard, setValue]);

  const onChangePaymentMethod = (event) => {
    setValue('creditPayment.paymentCondition', '');
    setPaymentMethod(event.target.value);
    setPaymentConditionLabel(event.target.value);
    if (event.target.value === PaymentMethod.Corriente) {
      // set default value only for Corriente payment method
      setValue('creditPayment.paymentCondition', conditions[event.target.value][0]);
    }
  };

  const institucionRef = useRef();
  const corrienteRef = useRef();

  if (!paymentInfo.isSuccess) {
    return (
      <Grid container spacing={2}>
        <RetryData onClickHandler={() => dispatch(searchPayments())} />
      </Grid>
    );
  }
  if (paymentInfo.loading) {
    return (
      <Loading text="Cargando Instituciones" />
    );
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box {...boxProperties}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography component="p" color="textSecondary">
                Selecciona tu instituci&oacute;n financiera:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>INSTITUCIÓN FINANCIERA</InputLabel>
                <Controller
                  as={(
                    <Select inputRef={institucionRef} labelId="institution-select">
                      <MenuItem value=""><em>Seleccione..</em></MenuItem>
                      {payments.map((payment) => (
                        <MenuItem
                          key={payment?.id}
                          value={payment}
                        >
                          {payment?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  onFocus={() => institucionRef.current.focus()}
                  name="creditPayment.financialInstitution"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>
          <Controller
            as={TextField}
            name="creditPayment.selectedCard"
            control={control}
            type="hidden"
            defaultValue=""
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box {...boxProperties}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography component="p" color="textSecondary">
                Selecciona tu tarjeta:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {!checkEmptyObject(financialInstitutionWatcher) && (
                <CardListGrid
                  cards={financialInstitutionWatcher?.cards}
                  setSelectedCard={setSelectedCard}
                  selectedCard={selectedCard}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box {...boxProperties}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography component="p" color="textSecondary">
                Escoge tu forma de Pago:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>PAGO</InputLabel>
                <Select
                  labelId="payment-select"
                  value={paymentMethod}
                  onChange={onChangePaymentMethod}
                >
                  <MenuItem value=""><em>Seleccione...</em></MenuItem>
                  {Object.keys(conditions)?.map((payment, index) => (
                    <MenuItem
                      key={`payment${index}`}
                      value={payment}
                    >
                      {payment}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl
                fullWidth
                error={errors?.creditPayment?.hasOwnProperty('paymentCondition')}
              >
                <InputLabel>{paymentConditionLabel}</InputLabel>
                <Controller
                  as={(
                    <Select
                      inputRef={corrienteRef}
                      labelId="payment-select"
                    >
                      <MenuItem value=""><em>Seleccione...</em></MenuItem>
                      {conditions[paymentMethod]?.map(
                        (condition, index) => (
                          <MenuItem
                            key={`condition${index}`}
                            value={condition}
                          >
                            {getPaymentConditionText(condition)}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                  onFocus={() => corrienteRef.current.focus()}
                  name="creditPayment.paymentCondition"
                  control={control}
                  defaultValue=""
                />
                <FormHelperText>
                  {errors.creditPayment?.paymentCondition?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Box className={classes.faqLinkBox}>
        Si usted tiene dudas acerca del pago con tarjeta de cr&eacute;dito,
        puede consultar la secci&oacute;n de
        <Link href="/faq" target="blank">preguntas frecuentes</Link>
      </Box>
    </Grid>
  );
}
