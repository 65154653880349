import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loading: false,
  data: [],
  errorMessage: '',
  isSuccess: false,
};

const paymentInfoSlice = createSlice({
  name: 'paymentInfo',
  initialState: INITIAL_STATE,
  reducers: {
    searchPayments(state) {
      state.loading = true;
    },
    successPayments(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.errorMessage = '';
      state.isSuccess = true;
    },
    failedPayments(state, action) {
      state.loading = false;
      state.data = [];
      state.errorMessage = action.payload;
      state.isSuccess = false;
    },
  },
});

export const { searchPayments, successPayments, failedPayments } = paymentInfoSlice.actions;
export default paymentInfoSlice.reducer;
