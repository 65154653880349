export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const RESET_MODAL = 'RESET_MODAL';

export const openModal = (data = {}) => ({
  type: OPEN_MODAL,
  payload: {
    isOpen: true,
    data,
  },
});

export const closeModal = (result) => ({
  type: CLOSE_MODAL,
  payload: { isOpen: false },
  result,
});

export const resetModal = () => ({
  type: RESET_MODAL,
});
