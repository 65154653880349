import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../theme/palette';

const issuanceStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
  },
  content: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '25%',
      paddingRight: '25%',
    },
    backgroundColor: theme.palette.gray,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  header: {
    backgroundColor: theme.palette.gray,
    boxShadow: 'none',
    position: 'unset !important',
  },
  gridContainer: {
    paddingTop: theme.spacing(1),
    height: '100%',
    backgroundColor: 'red',
  },
  gridColumn: {
    height: '100%',
    overflowY: 'auto',
  },
  flexGrow: {
    flexGrow: 1,
  },
  cancelButton: {
    color: theme.palette.primary.dark,
    fontFamily: 'Roboto Regular',
    fontSize: '11px',
    textTransform: 'none',
    letterSpacing: 0,
    fontWeight: theme.typography.fontWeightMedium,
  },
  toolTip: {
    'min-width': '0px',
    borderRadius: 25,
    backgroundColor: palette.primary.darkLight,
    color: palette.primary.contrastText,
    height: '15px',
    padding: '11px 10px',
    '&:hover': {
      backgroundColor: '#2166ae',
    },
    '&::before': {
      width: '250px',
      backgroundColor: palette.primary.darkLight,
      border: 'solid 5px transparent',
      'border-radius': '10px',
      color: palette.primary.contrastText,
      content: 'attr(data-title)',
      display: 'none',
      padding: '2px',
      position: 'absolute',
      top: '-85px',
      left: '-125px',
      'z-index': 1,
      'font-size': '9px',
      'text-transform': 'none',
      'text-align': 'left',
      'margin-top': '-15px',
    },
    '&:hover::before': {
      display: 'block',
    },
  },
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textArea: {
    marginTop: 10,
  },
  refundAlert: {
    cursor: 'default',
    'min-width': '0px',
    borderRadius: 25,
    backgroundColor: palette.primary.redAlert,
    color: palette.primary.contrastText,
    height: '15px',
    padding: '11px 10px',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  stepperClass: {
    height: '144px',
    marginRight: '5%',
    marginLeft: '5%',
    paddingLeft: '25%',
    paddingRight: '25%',
    paddingTop: '4%',
    [theme.breakpoints.down('500')]: {
      paddingLeft: '0%',
      paddingRight: '0%',
    },
  },

}));

export default issuanceStyles;
