/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Controller, useFormContext } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';

import PropTypes from 'prop-types';
import { addSweetAlert, closeSweetAlert } from '../../../../common/SweetAlert/actions/sweet_alert';
import CustomPanel from '../../../../common/CustomPanel';
import { requestClientInformation } from '../actions/client_information';
import MedicalConditions from './MedicalConditions';
import Dependents from './Dependents';
import { addDependent } from '../actions/dependents_identification';
import InfinityHolder from './InfinityHolder';
import { isValidId } from '../../../../../utils/id-validator';
import * as Constants from '../../../../../utils/Constants';
import { ALERT_BUTTON_TEXT } from '../../../../legal.messages';
import issuanceStyles from '../../../style/issuance-style';
import {
  shouldBlockFields,
  isEmpty,
  resetFormApplicationValues,
  usePrevious, checkEmptyObject,
} from '../../../../../utils/CommonUtils';
import { PICKER_FORMAT_DATE } from '../../../../../utils/Constants';
import { selectCatalog, selectDivisionPolitica } from '../utils/selectors';
import { ResetApplicationForm } from '../../Payment/Enums';
import Covid19Questions from './Covid19Questions';

const setMainInsuredForm = (insuranceMainInsured, setValue) => {
  const { Person: person, QuestionCovid19TestPerformed} = insuranceMainInsured;
  setValue([
    { 'contact.identificationType': person?.IdentificationType.trim() || '' },
    { 'insuranceMainInsured.identification': person?.Identification.trim() || '' },
    { 'insuranceMainInsured.name': person?.Name.trim() || '' },
    { 'insuranceMainInsured.lastName': person?.LastName.trim() || '' },
    { 'insuranceMainInsured.birthDate': person?.BirthDate.trim() || '' },
    { 'insuranceMainInsured.birthPlace': person?.BirthPlace.trim() || '' },
    { 'insuranceMainInsured.gender': person?.Gender.trim() || '' },
    { 'insuranceMainInsured.nacionality': person?.Nacionality ?? Constants.DEFAULT_VALUE_NACIONALITY },
    { 'insuranceMainInsured.questionCovid19TestPerformed': QuestionCovid19TestPerformed },
    { 'insuranceMainInsured.height': Number(person?.PersonInsuranceHolders[0].Height) },
    { 'insuranceMainInsured.weight': Number(person.PersonInsuranceHolders[0].Weight) },   
           
  ]);
};

const Holder = ({ editionMode, isEnabledCovidParam }) => {
  const clientInfo = useSelector((state) => state.clientInfo);
  const prevCientInfo = usePrevious(clientInfo);
  const { requestData: quoteData } = useSelector((state) => state.quote);
  const getApplication = useSelector((state) => state.getApplication);
  const prevGetApplication = usePrevious(getApplication);

  const classes = issuanceStyles();
  const dispatch = useDispatch();

  const typeIdentification = useSelector(selectCatalog('TipoIdentificacion'));
  const gens = useSelector(selectCatalog('Generos'));
  const { control, errors, getValues, setValue, clearError, register, watch } = useFormContext();
  const [isDisableInput, setIsDisableInput] = React.useState(true);
  const [personWasFound, setPersonWasFound] = React.useState(true);
  const [mainInsured, setMainInsured] = React.useState({});
  const countries = useSelector(selectDivisionPolitica);
  const [enableCovidMedicalCondition, setEnableCovidMedicalCondition] = React.useState(false);

  const formValues = getValues({ nest: true });

  const identificationWatch = watch('insuranceMainInsured.identification');
 // const nacionalityWatch = watch('insuranceMainInsured.nacionality');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const hasCovid = (areAnswersRight) => {
    setEnableCovidMedicalCondition(areAnswersRight);
  };

  const positiveCovid = watch('insuranceMainInsured.questionCovid19ResultPositive');
  useEffect(() => {
    if (positiveCovid) {
      setEnableCovidMedicalCondition(positiveCovid);
    }
  }, [positiveCovid]);

  useEffect(() => {
    if (quoteData.dependants?.length > 0) {
      dispatch(addDependent(quoteData.dependants.length));
    }
  }, []);

  useEffect(() => {
    if (editionMode) {
      setIsDisableInput(mainInsured.Person.IsValidatedCivilRegistry);
    }
  }, [editionMode, mainInsured]);

  useEffect(() => {
    register({ name: 'insuranceMainInsured.identification' });
  }, [register]);

  const onChangeIdentification = (e) => {
    resetFormApplication();
    setValue('insuranceMainInsured.identification', e.target?.value);
  };

  useEffect(() => {
    if (prevGetApplication && (prevGetApplication.response !== getApplication.response)) {
      if (!isEmpty(getApplication.response)) {
        setMainInsured(getApplication?.response?.InsuranceApplicationMainInsured);
        setMainInsuredForm(getApplication.response?.InsuranceApplicationMainInsured, setValue);
      } else {
        setMainInsured({});
      }
    }
  }, [getApplication.response]);

  /**
   * Validation only for holder and Infinity kids beneficiary.
   * */
  useEffect(() => {
    const { validationResult } = clientInfo?.response;
    if (prevCientInfo && (prevCientInfo.response !== clientInfo.response) && validationResult) {
      if (validationResult?.result !== 'SUCCESS') {
        const { result } = validationResult;
        if (result === 'FINISH_SALE_PROCESS' || result === 'AGE_NOT_ALLOWED' || result === 'CONTINUE_WITH_CLIENT_WARNING') {
          const type = result === 'CONTINUE_WITH_CLIENT_WARNING' ? 'info' : 'warning';
          const title = result === 'CONTINUE_WITH_CLIENT_WARNING' ? 'Documento no válido' : '';
          dispatch(addSweetAlert({
            type,
            title,
            content: (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
                style={{ marginTop: 10 }}
              >
                <Typography
                  variant="h6"
                >
                  {`${clientInfo.response.validationResult.messages[0]}`}
                </Typography>
              </Grid>
            ),
            showCancel: false,
            onConfirmLabel: 'Aceptar',
            onConfirm: () => {
              resetFormApplication();
              dispatch(closeSweetAlert());
            },
          }));
        }
        if (validationResult?.result === 'CONTINUE_WITH_CLIENTE_ACCEPTANCE') {
          dispatch(addSweetAlert({
            type: 'warning',
            title: clientInfo.response.validationResult.messages[0],
            showCancel: false,
            onConfirmLabel: 'Aceptar',
            onConfirm: () => {
              dispatch(closeSweetAlert());
            },
          }));
        }
      } else if (clientInfo.isError) {
        dispatch(addSweetAlert({
          type: 'warning',
          title: 'No existe un registro con la identificaci\u00F3n ingresada',
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            resetFormApplication();
            dispatch(closeSweetAlert());
          },
        }));
      } else {
        const person = clientInfo.response.personDto;
        setIsDisableInput(shouldBlockFields(person));
        setPersonWasFound(!checkEmptyObject(person));
        if (!clientInfo.response.isHolder) {
          clearError([
            'insuranceMainInsured.name',
            'insuranceMainInsured.lastName',
            'insuranceMainInsured.birthDate',
            'insuranceMainInsured.gender',
            'insuranceMainInsured.birthPlace',
            'insuranceMainInsured.nacionality',
            'insuranceMainInsured.height',
            'insuranceMainInsured.weight',

          ]);
          setValue([
            { 'insuranceMainInsured.name': person?.name || '' },
            { 'insuranceMainInsured.lastName': person?.lastName || '' },
            { 'insuranceMainInsured.birthDate': person?.birthDate || null },
            { 'insuranceMainInsured.gender': person?.gender || '' },
            { 'insuranceMainInsured.birthPlace': person?.birthPlace || '' },
            { 'insuranceMainInsured.nacionality': person?.nacionality || '' },  
            { 'insuranceMainInsured.height': person?.height || '' },
            { 'insuranceMainInsured.weight': person?.weight || '' },              
          ]);
        }
        if (!person) {
          dispatch(addSweetAlert({
            type: 'info',
            title: 'Documento no encontrado',
            content: Constants.MESSAGE_REGISTRO_CIVIL,
            showCancel: false,
            onConfirmLabel: 'Continuar',
            onConfirm: () => {
              resetFormApplication();
              dispatch(closeSweetAlert());
              setValue('insuranceMainInsured.birthPlace', 'Ecuador');
            },
          }));
        }
      }
    }
  }, [clientInfo]);

  const resetFormApplication = () => {
    resetFormApplicationValues(setValue,
      clearError,
      quoteData?.withDifferentHolder
        ? ResetApplicationForm.InfinityFields
        : ResetApplicationForm.AllFields);
  };


  const handleSearchIdentification = () => {
    const identification = formValues.insuranceMainInsured?.identification;
    if (identification) {
      if (isValidId(identification)) {
        dispatch(requestClientInformation(identification, quoteData?.productId));
      } else {
        dispatch(addSweetAlert({
          type: 'warning',
          title: Constants.MESSAGE_INVALID_IDENTIFICATION,
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }));
      }
    }
  };

  const nombresRef = useRef();
  const apellidosRef = useRef();
  const sexoRef = useRef();
  const lugNacRef = useRef();
  const heightRef = useRef();
  const weightRef = useRef();

  return (
    <CustomPanel
      title={!quoteData?.withDifferentHolder ? 'Titular y Dependientes' : 'Titular y Beneficiario'}
      border={false}
    >
      {quoteData?.withDifferentHolder && (
        <InfinityHolder
          editionMode={editionMode}
        />
      )}
      <CustomPanel
        withoutTitle
        subtitle={!quoteData?.withDifferentHolder ? 'Información personal del titular' : 'Información del Afiliado (Menor de Edad)'}
        border={false}
      >
        <Grid container direction="row" spacing={3}>
          <Grid item md={6} xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth
              error={errors.insuranceMainInsured?.hasOwnProperty('identificationType')}
            >
              <InputLabel id="id-type-identification">TIPO DE IDENTIFICACIÓN</InputLabel>
              <Controller
                as={(
                  <Select>
                    <MenuItem value="">
                      <em>Seleccione..</em>
                    </MenuItem>
                    {
                      typeIdentification?.map((idType) => (
                        <MenuItem
                          key={idType.id}
                          value={idType.id}
                        >
                          {idType.description}
                        </MenuItem>
                      ))
                    }
                  </Select>
                )}
                labelId="id-type-identification"
                name="insuranceMainInsured.identificationType"
                control={control}
                readOnly={editionMode}
                defaultValue={Constants.DEFAULT_VALUE_TYPE_IDENTIFICATION}
              />
              <FormHelperText>
                {errors.insuranceMainInsured?.identificationType?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              autoFocus
              value={identificationWatch}
              onChange={onChangeIdentification}
              fullWidth
              type="number"
              className={classes.formControl}
              label="IDENTIFICACIÓN"
              autoComplete="off"
              error={errors.insuranceMainInsured?.hasOwnProperty('identification')}
              helperText={errors.insuranceMainInsured?.identification?.message}
              InputProps={{
                endAdornment: (
                  <Tooltip title="Buscar" arrow>
                    <IconButton
                      aria-label="search"
                      size="small"
                      disabled={editionMode}
                      onClick={handleSearchIdentification}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                ),
                readOnly: editionMode,
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item md={6} xs={12}>
            <Controller
              as={(
                <TextField inputRef={nombresRef} />
              )}
              onFocus={() => nombresRef.current.focus()}
              fullWidth
              className={classes.formControl}
              label="NOMBRES"
              autoComplete="off"
              name="insuranceMainInsured.name"
              control={control}
              InputProps={{ readOnly: isDisableInput }}
              error={errors.insuranceMainInsured?.hasOwnProperty('name')}
              helperText={errors.insuranceMainInsured?.name?.message}
              defaultValue=""
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              as={(
                <TextField inputRef={apellidosRef} />
              )}
              onFocus={() => apellidosRef.current.focus()}
              fullWidth
              className={classes.formControl}
              label="APELLIDOS"
              autoComplete="off"
              name="insuranceMainInsured.lastName"
              control={control}
              InputProps={{ readOnly: isDisableInput }}
              error={errors.insuranceMainInsured?.hasOwnProperty('lastName')}
              helperText={errors.insuranceMainInsured?.lastName?.message}
              defaultValue=""
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item md={6} xs={12}>
            <Controller
              as={(
                <TextField inputRef={heightRef} type="number"/>
              )}
              onFocus={() => heightRef.current.focus()}
              fullWidth
              className={classes.formControl}
              label="ALTURA mtr"
              autoComplete="off"
              name="insuranceMainInsured.height"
              control={control}
               type="number"
               inputProps={{ min: '0', step: '0.01', max: '3' }}
              rules={{ required: 'Campo requerido' }}
              error={errors.insuranceMainInsured?.hasOwnProperty('height')}
              helperText={errors.insuranceMainInsured?.height?.message}
              defaultValue=""
              placeholder="1.82"
            />
          </Grid> 
          <Grid item md={6} xs={12}>
            <Controller
              as={(
                <TextField inputRef={weightRef} type="number"/>
              )}
              onFocus={() => weightRef.current.focus()}
              fullWidth
              className={classes.formControl}
              label="PESO Kg"
              autoComplete="off"
              name="insuranceMainInsured.weight"
              control={control}
              type="number"
               inputProps={{ min: '0', step: '1', max: '200' }}
              rules={{ required: 'Campo requerido' }}
              error={errors.insuranceMainInsured?.hasOwnProperty('weight')}
              helperText={errors.insuranceMainInsured?.weight?.message}
              defaultValue=""
              placeholder="65"
            />
          </Grid>
        </Grid>
        <Grid container item direction="row" spacing={3}>
          <Grid item md={6} xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth
              error={errors.insuranceMainInsured?.hasOwnProperty('gender')}
            >
              <InputLabel id="id-type-identification">SEXO</InputLabel>
              <Controller
                as={(
                  <Select inputRef={sexoRef}>
                    {
                      gens?.map((gen) => (
                        <MenuItem
                          key={gen.id}
                          value={gen.id}
                        >
                          {gen.description}
                        </MenuItem>
                      ))
                    }
                  </Select>
                )}
                onFocus={() => sexoRef.current.focus()}
                fullWidth
                name="insuranceMainInsured.gender"
                label="SEXO"
                control={control}
                inputProps={{ readOnly: isDisableInput }}
                error={errors.insuranceMainInsured?.hasOwnProperty('gender')}
                defaultValue=""
              />
              <FormHelperText>
                {errors.insuranceMainInsured?.gender?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={(
                  <KeyboardDatePicker disableFuture />
                )}
                autoOk
                className={classes.formControl}
                name="insuranceMainInsured.birthDate"
                fullWidth
                disableToolbar={false}
                variant="inline"
                format={PICKER_FORMAT_DATE}
                margin="normal"
                label="FECHA NACIMIENTO"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                  size: 'small',
                }}
                readOnly={isDisableInput}
                InputProps={{ readOnly: isDisableInput }}
                control={control}
                error={errors.insuranceMainInsured?.hasOwnProperty('birthDate')}
                helperText={errors.insuranceMainInsured?.birthDate?.message}
                defaultValue={null}
              />
            </MuiPickersUtilsProvider>
          </Grid>        
          
        <Grid container item direction="row" spacing={3}>
        <Grid item md={6} xs={12}>
            {
               (
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                     error={errors.insuranceMainInsured?.hasOwnProperty('nacionality')}
                  >
                    <InputLabel id="id-country-select">NACIONALIDAD</InputLabel>
                    <Controller
                      as={(
                        <Select>
                          <MenuItem value="" >
                            <em>Seleccione...</em>
                          </MenuItem>
                          {countries?.map((country) => (
                              <MenuItem key={country.id} value={country.description}>
                                {country.description}
                              </MenuItem>
                          ))}
                        </Select>
                      )}
                      labelId="id-country-select"
                      name="insuranceMainInsured.nacionality"
                      control={control}                     
                      defaultValue={Constants.DEFAULT_VALUE_NACIONALITY}
                      //rules={{ required: 'Este campo es obligatorio' }} // Agregado: regla de requerido
                    />
                    <FormHelperText>
                      {errors.insuranceMainInsured?.nacionality?.message}
                    </FormHelperText>
                  </FormControl>
                )
            }
          </Grid> 
        <Grid item md={6} xs={12}>
            {
              personWasFound
                ? (
                  <Controller
                    as={(
                      <TextField inputRef={lugNacRef} />
                    )}
                    onFocus={() => lugNacRef.current.focus()}
                    fullWidth
                    className={classes.formControl}
                    name="insuranceMainInsured.birthPlace"
                    label="LUGAR NACIMIENTO"
                    autoComplete="off"
                    control={control}
                    error={errors.insuranceMainInsured?.hasOwnProperty('birthPlace')}
                    helperText={errors.insuranceMainInsured?.birthPlace?.message}
                    defaultValue=""
                  />
                )
                : (
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    error={errors.insuranceMainInsured?.hasOwnProperty('birthPlace')}
                  >
                    <InputLabel id="province-select">LUGAR NACIMIENTO</InputLabel>
                    <Controller
                      as={(
                        <Select>
                          <MenuItem value="">
                            <em>Seleccione...</em>
                          </MenuItem>
                          {countries?.map((b) => (
                            b.id !== '0' && (
                              <MenuItem key={b.id} value={b.description}>
                                {b.description}
                              </MenuItem>
                            )
                          ))}
                        </Select>
                      )}
                      labelId="province-select"
                      name="insuranceMainInsured.birthPlace"
                      control={control}
                    />
                    <FormHelperText>
                      {errors.insuranceMainInsured?.birthPlace?.message}
                    </FormHelperText>
                  </FormControl>
                )
            }
          </Grid>
        </Grid>
        <Grid container alignItems="flex-end" item xs={4} />
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <Typography className={classes.formControl}>
              Condiciones Médicas&nbsp;
              {isDesktop ? (
                <Button
                  data-title={ALERT_BUTTON_TEXT}
                  className={classes.toolTip}
                >
                  !
                </Button>
              ) : (
                <IconButton
                  edge="start"
                >
                  <InfoIcon style={{ color: '#518CBF' }} />
                </IconButton>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3}>
          {isEnabledCovidParam && (
            <Covid19Questions
              fieldName="insuranceMainInsured"
              hasCovid={hasCovid}
              hasResultPositive={mainInsured?.QuestionCovid19ResultPositive}
            />
          )}
        </Grid>
        <Grid container direction="row" spacing={3}>
          <MedicalConditions
            fieldArrayName="insuranceMainInsured.medicalConditions"
            medicalConditionsValues={mainInsured?.InsuranceMainInsuredMedicalConditions}
            hasCovid={enableCovidMedicalCondition}
          />
        </Grid>
      </CustomPanel>
      {(quoteData.dependants && quoteData.dependants.length > 0) && (
        <Grid container direction="row">
          <Dependents
            dependants={quoteData.dependants}
            isEnabledCovidParam={isEnabledCovidParam}
            editionMode={editionMode}
          />
        </Grid>
      )}
    </CustomPanel>
  );
};

Holder.propTypes = {
  editionMode: PropTypes.bool.isRequired,
  
  isEnabledCovidParam: PropTypes.bool.isRequired,  
};

export default Holder;
