import {
  START_REQUEST_EMPLOYEES,
  REQUEST_EMPLOYEES_SUCCESS,
  REQUEST_EMPLOYEES_FAILED,
  START_POST_EMPLOYEE,
  POST_EMPLOYEE_SUCCESS,
  POST_EMPLOYEE_FAILED,
  START_UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAILED,
} from '../actions/administrate_users';

const EMPLOYEES_INITIAL_STATE = {
  loading: false,
  data: [],
  error: false,
  errorData: '',
  isSuccess: false,
};

export default (state = EMPLOYEES_INITIAL_STATE, action) => {
  switch (action.type) {
    case START_REQUEST_EMPLOYEES:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        error: false,
      };
    case REQUEST_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorData: '',
        isSuccess: true,
        error: false,
      };
    case REQUEST_EMPLOYEES_FAILED:
      return {
        ...state,
        loading: false,
        errorData: action.payload,
        isSuccess: false,
        error: true,
      };
    case START_POST_EMPLOYEE:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        error: false,
      };
    case POST_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
        errorData: '',
        error: false,
      };
    case POST_EMPLOYEE_FAILED:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        errorData: action.payload,
        error: true,
      };
    case START_UPDATE_EMPLOYEE:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        error: false,
      };
    case UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorData: '',
        isSuccess: true,
        error: false,
      };
    case UPDATE_EMPLOYEE_FAILED:
      return {
        ...state,
        loading: false,
        errorData: action.payload,
        isSuccess: false,
        error: true,
      };
    default:
      return state;
  }
};
