/* eslint-disable indent */
export default class PolicyDTO {
  constructor(saleProcessId,
              paymentType,
              paymentFrequency = undefined,
              bankAccount,
              cardType,
              accountType,
              bankCode,
              corrienteDiferido,
              sinInteresesConInteres,
              numeroCuotasTarjeta,
              paymentButton,
              bankDescription,
              placeToPayReference,
              payPhoneReference,
              image = undefined,
              payedAmount = undefined,
              numeroQuotas = undefined) {
    this.saleProcessId = saleProcessId;
    this.paymentType = paymentType;
    this.paymentFrequency = paymentFrequency;
    this.bankAccount = bankAccount;
    this.cardType = cardType;
    this.accountType = accountType;
    this.bankCode = bankCode;
    this.corrienteDiferido = corrienteDiferido;
    this.sinInteresesConInteres = sinInteresesConInteres;
    this.numeroCuotasTarjeta = numeroCuotasTarjeta;
    this.paymentButton = paymentButton;
    this.bankDescription = bankDescription;
    this.placeToPayReference = placeToPayReference;
    this.payPhoneReference = payPhoneReference;
    this.image = image;
    this.payedAmount = payedAmount;
    this.numeroQuotas = numeroQuotas;
  }
}
