import React, { useEffect } from 'react';

const PaymentProcess = (props) => {
  useEffect(() => {
    const reference = props.location?.search.split('reference=');
    window.opener.onSuccess(reference[1]);
    window.close();
  }, []);

  return null;
};

export default PaymentProcess;
