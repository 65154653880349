import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { Main as MainLayout } from '../layouts';

import {
  Dashboard as DashboardView,
  Login as LoginView,
  AdministrateUsers as AdministrateUsersView,
  Quotation as QuotationView,
  Issuance as IssuanceView,
  Emissions as EmissionsView,
  TermsAndConditions as TermsAndConditionsView,
  FAQ as FAQView,
  ReallocateEmissions as ReallocateEmissionsView,
} from '../components';
import CustomRoute from '../components/RouteWithLayout/CustomRoute';
import PaymentProcess from '../components/Issuance/components/Payment/components/PaymentProcess';
import PrivacyPolicy from '../components/PrivacyPolicy';
import SummaryData from '../components/Emissions/screens/SummaryData';
import MaintenanceWindow from '../components/MaintenanceWindow';

const Routes = () => (
  <>
    <Switch>
      <Redirect
        exact
        from="/"
        to="/quotation"
      />
      <CustomRoute exact path="/login" component={LoginView} />
      <CustomRoute
        condition="private"
        exact
        path="/dashboard"
        layout={MainLayout}
        component={DashboardView}
      />
      <CustomRoute
        condition="public"
        exact
        path="/termsandconditions"
        layout={MainLayout}
        component={TermsAndConditionsView}
      />
      <CustomRoute
        condition="public"
        exact
        path="/faq"
        layout={MainLayout}
        component={FAQView}
      />
      <CustomRoute
        condition="private"
        exact
        path="/users"
        layout={MainLayout}
        component={AdministrateUsersView}
      />
      <CustomRoute
        condition="public"
        exact
        path="/quotation"
        layout={MainLayout}
        component={QuotationView}
      />
      <CustomRoute
        condition="private"
        exact
        path="/issuance"
        layout={MainLayout}
        component={IssuanceView}
      />
      <CustomRoute
        condition="private"
        exact
        path="/emissions"
        layout={MainLayout}
        component={EmissionsView}
      />
      <Route
        path="/payment/process"
        exact
        component={PaymentProcess}
      />
      <CustomRoute
        condition="public"
        exact
        path="/privacyPolicy"
        layout={MainLayout}
        component={PrivacyPolicy}
      />
      <CustomRoute
        condition="public"
        exact
        path="/summaryData"
        layout={MainLayout}
        component={SummaryData}
      />
      <CustomRoute
        condition="private"
        exact
        path="/reallocate-emissions"
        layout={MainLayout}
        component={ReallocateEmissionsView}
      />
      <Route
        exact
        path="/maintenance-window"
        component={MaintenanceWindow}
      />
    </Switch>
  </>
);

export default Routes;
