export const START_REQUEST_GET_APPLICATION = 'START_REQUEST_GET_APPLICATION';
export const START_REQUEST_GET_APPLICATION_PUBLIC = 'START_REQUEST_GET_APPLICATION_PUBLIC';
export const REQUEST_GET_APPLICATION_SUCCESS = 'REQUEST_GET_APPLICATION_SUCCESS';
export const REQUEST_GET_APPLICATION_FAILED = 'REQUEST_GET_APPLICATION_FAILED';
export const RESET_GET_APPLICATION = 'RESET_GET_APPLICATION';

/* Getting all users */
export const requestGetApplication = (data) => ({
  type: START_REQUEST_GET_APPLICATION,
  payload: data,
});

export const requestGetApplicationPublic = (data) => ({
  type: START_REQUEST_GET_APPLICATION_PUBLIC,
  payload: data,
});

export const successRequestGetApplication = (response) => ({
  type: REQUEST_GET_APPLICATION_SUCCESS,
  payload: response,
});

export const failedRequestGetApplication = (error) => ({
  type: REQUEST_GET_APPLICATION_FAILED,
  payload: error,
});

export const resetGetApplication = () => ({
  type: RESET_GET_APPLICATION,
});
