export const REQUEST_PDF = 'REQUEST_PDF';
export const SUCCESS_PDF = 'SUCCESS_PDF';
export const FAILURE_PDF = 'FAILURE_PDF';
export const RESET_PDF = 'RESET_PDF';

export const requestPDF = (quotationData, quotationHash, deductibleSelectedId) => ({
  type: REQUEST_PDF,
  payload: { quotationData, quotationHash, deductibleSelectedId },
});

export const successPDF = (response) => ({
  type: SUCCESS_PDF,
  payload: response,
});

export const failurePDF = (error) => ({
  type: FAILURE_PDF,
  payload: error,
});

export const resetPdf = () => ({
  type: RESET_PDF,
});
