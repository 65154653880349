/* eslint-disable dot-notation */
import { call, put, takeLatest } from 'redux-saga/effects';
import { AuthenticationState } from 'react-aad-msal';
import { AAD_LOGOUT_SUCCESS } from '../actions/aad_login';
import {
  failedSignOut,
  resetState,
  SIGN_IN_CALL_REQUEST,
  signInFailure,
  signInSuccess,
  successSignOut,
} from '../actions/login';

export function* watchStartSignIn() {
  yield takeLatest(SIGN_IN_CALL_REQUEST, startSignInSaga);
}

export function* startSignInSaga({ payload: authProvider }) {
  try {
    yield call(authProvider.login);
    if (authProvider.authenticationState === AuthenticationState.Authenticated) {
      yield put(signInSuccess(authProvider.getAccountInfo()));
    } else {
      yield put(signInFailure(authProvider.getError().errorMessage));
    }
  } catch (e) {
    console.log('Error startSignInSaga: ', e);
    yield put(signInFailure(e));
  }
}

export function* watchSignOut() {
  yield takeLatest(AAD_LOGOUT_SUCCESS, signOutSaga);
}

export function* signOutSaga() {
  try {
    yield put(successSignOut());
    yield put(resetState());
  } catch (e) {
    console.log('signOutSaga Error: ', e);
    yield put(failedSignOut(e));
  }
}
