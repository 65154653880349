import { makeStyles } from '@material-ui/core/styles';

const singingStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  contentContainerDark: {
    [theme.breakpoints.up('lg')]: {
      padding: 30,
    },
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
    backgroundColor: '#EFEFEF',
  },
  contentContainerLight: {
    [theme.breakpoints.up('lg')]: {
      padding: 30,
    },
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
    backgroundColor: '#ffffff',
  },
  contentContainer: {
    [theme.breakpoints.up('lg')]: {
      padding: 30,
    },
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
  },
  darkText: {
    color: '#000000',
    fontSize: 15,
  },
  blueText: {
    fontSize: 11,
    color: '#518CBF',
  },
  bottomText: {
    color: '#000000',
    marginTop: 20,
    marginBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 20,
    textAlign: 'center',
  },
  warning: {
    backgroundColor: '#FFC200',
    paddingBottom: 13,
    paddingTop: 13,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 25,
  },
  button: {
    textAlign: 'center',
    position: 'relative',
    margin: '0 auto',
    bottom: -22,
    backgroundColor: '#00517b',
    color: '#ffffff',
    borderRadius: 25,
    paddingRight: 40,
    paddingLeft: 40,
    '&:hover': {
      backgroundColor: '#00517b',
      color: '#ffffff',
    },
  },
}));

export default singingStyles;
