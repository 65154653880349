export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const showSnackBar = (message) => ({
  type: SHOW_SNACKBAR,
  payload: { message },
});

export const closeSnackBar = () => ({
  type: CLOSE_SNACKBAR,
});
