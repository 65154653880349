/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Backdrop, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.darkLight,
    backgroundColor: '#dbd6d6',
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 15,
  },
});

class LoginScreen extends React.Component {

  componentDidMount() {
    const { history } = this.props;
    history.push('/quotation');
  }

  render() {
    const { classes } = this.props;
    return (
      <Backdrop className={classes.backdrop} open>
        <Box
          className={classes.boxContainer}
          bgcolor="background.paper"
          p={5}
        >
          <CircularProgress style={{ alignSelf: 'center' }} color="inherit" />
          <p style={{ marginTop: 15 }}>Espere por favor espere...</p>
        </Box>
      </Backdrop>
    );
  }
}

LoginScreen.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginScreen);
