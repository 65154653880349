import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  tokenUpdated: false,
  isError: false,
  isLoading: true,
};

const tokenUpdateSlice = createSlice({
  name: 'tokenUpdateReducer',
  initialState: INITIAL_STATE,
  reducers: {
    requestTokenUpdate: {
      reducer(state) {
        state.tokenUpdated = false;
        state.isLoading = true;
      },
    },
    successTokenUpdate: {
      reducer(state, action) {
        state.tokenUpdated = true;
        state.isLoading = false;
        state.response = action.payload.data;
      },
    },
    failureTokenUpdate: {
      reducer(state) {
        state.isError = true;
        state.isLoading = false;
      },
    },
  },
});

// eslint-disable-next-line max-len
export const { requestTokenUpdate, successTokenUpdate, failureTokenUpdate } = tokenUpdateSlice.actions;

export default tokenUpdateSlice.reducer;
