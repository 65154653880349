import * as actions from '../actions/quote';

const INITIAL_STATE = {
  deductibleSelected: {
    deductible: {},
    deductibleId: 0,
    paymentType: 0,
    total: 0,
    quotationId: 0,
  },
  response: {},
  isError: false,
  requestData: {},
};

export function quote(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.REQUEST_QUOTE:
      return {
        ...state,
        response: {},
        isError: false,
      };
    case actions.SUCCESS_QUOTE:
      return {
        ...state,
        response: action.payload,
        requestData: action.payloadRequest,
        isError: false,
      };
    case actions.FAILURE_QUOTE:
      return {
        ...state,
        response: action.payload,
        isError: true,
      };
    case actions.SELECT_DEDUCTIBLE:
      return {
        ...state,
        deductibleSelected: {
          deductible: action.payload.deductible,
          deductibleId: action.payload.deductible.deductibleId,
          paymentType: action.payload.paymentType.typeDescription,
          typeId: action.payload.paymentType.typeId,
          total: action.payload.paymentType.value,
          quotationId: action.payload.paymentType.quotationId,
        },
      };
    case actions.RESET_REQUEST_DATA:
      return {
        ...state,
        deductibleSelected: {
          deductible: {},
          deductibleId: 0,
          paymentType: 0,
          typeId: 0,
          total: 0,
          quotationId: 0,
        },
        response: {},
        isError: false,
        requestData: action.payload,
      };
    case actions.RESET_WITH_RE_QUOTE:
      return {
        ...state,
        deductibleSelected: action.deductibleSelected,
        isError: false,
        requestData: action.payload,
      };
    case actions.RESET_QUOTE:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
}

// selectors

export const getQuoteData = (state) => state.quote;
