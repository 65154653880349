import { quote } from './quote';
import quotationUtils from './quotationUtils';
import sendEmailReducer from './emailSlice';
import reQuotation from './reQuotation';

export default {
  quote,
  quotationUtils,
  reQuotation,
  sendEmail: sendEmailReducer,
};
