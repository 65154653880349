/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from 'redux-saga/effects';
import { trackPromise } from 'react-promise-tracker';
import { failurePDF, REQUEST_PDF, successPDF } from '../actions/quotationUtils';
import api from '../../../utils/Api';
import { areas } from '../../../utils/Constants';

export function* watchRequestQuotationPdf() {
  yield takeLatest(REQUEST_PDF, requestQuotationPdfSaga);
}

function* requestQuotationPdfSaga({ payload }) {
  try {
    const response = yield call(fetchQuotationPdf, payload);
    yield put(successPDF(response.data));
  } catch (e) {
    yield put(failurePDF(e));
  }
}

const fetchQuotationPdf = ({ quotationData, quotationHash, deductibleSelectedId }) => {
  const data = JSON.stringify({
    quotationData,
    quotationHash,
    deductibleSelectedId,
  });
  return trackPromise(api.post(process.env.REACT_APP_GENERATE_PDF_URL, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  }), areas.global);
};
