import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import { Add, Edit } from '@material-ui/icons';

import NewUserScreen from './NewUserScreen';
import { requestEmployees } from '../actions/administrate_users';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  iframe: {
    width: '100%',
    minHeight: 640,
    border: 0,
  },
}));

const AdministrateUsersScreen = () => {
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.employees);

  useEffect(() => {
    dispatch(requestEmployees());
  }, []);

  const handleClickOpen = (employee = {}) => {
    setItem(employee);
    setOpen(true);
  };

  const handleClose = (shouldRefresh) => {
    if (shouldRefresh) {
      dispatch(requestEmployees());
    }
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
      >
        Listado de Agentes
      </Typography>
      <MaterialTable
        title=""
        columns={[
          { title: 'Nombre', field: 'displayName' },
          { title: 'Email', field: 'email' },
          { title: 'Cuenta Habilitada', field: 'accountEnabled', type: 'boolean' },
        ]}
        data={employees?.data}
        options={{ selection: false, actionsColumnIndex: -1, columnsButton: false }}
        isLoading={employees.loading}
        actions={[
          {
            icon: () => <Add />,
            tooltip: 'Add User',
            isFreeAction: true,
            onClick: () => handleClickOpen(),
          },
          {
            icon: () => <Edit />,
            tooltip: 'Editar',
            isFreeAction: false,
            onClick: (event, rowData) => handleClickOpen(rowData),
          },
        ]}

      />
      {open && <NewUserScreen open={open} handleClose={handleClose} data={item} />}
    </div>
  );
  // }
};

export default AdministrateUsersScreen;
