import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/Api';
import {
  failedRequestGetApplication,
  START_REQUEST_GET_APPLICATION,
  successRequestGetApplication,
  START_REQUEST_GET_APPLICATION_PUBLIC,
} from '../actions/get_application';
import { areas } from '../../../../../utils/Constants';

/* Getting all users saga */
const fetchGetApplication = (payload, isPublic, area) => {
  let url = `${process.env.REACT_APP_ISSUANCE_URL}/bySaleProcess`;
  if (isPublic) {
    url += '-public';
  }
  url += `?saleProcessId=${payload}`;
  return trackPromise(api.get(url), area);
};

export function* requestGetApplicationSaga({ payload }) {
  try {
    const response = yield call(fetchGetApplication, payload, false, areas.global);
    yield put(successRequestGetApplication(response.data));
  } catch (error) {
    yield put(failedRequestGetApplication(error));
  }
}

export function* requestGetApplicationSagaPublic({ payload }) {
  try {
    const response = yield call(fetchGetApplication, payload, true, areas.summary);
    yield put(successRequestGetApplication(response.data));
  } catch (error) {
    yield put(failedRequestGetApplication(error));
  }
}

export function* watchRequestGetApplication() {
  yield takeLatest(START_REQUEST_GET_APPLICATION, requestGetApplicationSaga);
}

export function* watchRequestGetApplicationPublic() {
  yield takeLatest(START_REQUEST_GET_APPLICATION_PUBLIC, requestGetApplicationSagaPublic);
}
