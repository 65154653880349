export const REQUEST_POST_ISSUANCE = 'REQUEST_POST_ISSUANCE';
export const SUCCESS_POST_ISSUANCE = 'SUCCESS_POST_ISSUANCE';
export const ADD_APPLICATION_INFO = 'ADD_APPLICATION_INFO';
export const FAILURE_POST_ISSUANCE = 'FAILURE_POST_ISSUANCE';
export const RESET_ISSUANCE = 'RESET_ISSUANCE';

export const requestPostIssuance = (data) => ({
  type: REQUEST_POST_ISSUANCE,
  payload: data,
});

export const successPostIssuance = (response, billingData, insuranceHolder) => ({
  type: SUCCESS_POST_ISSUANCE,
  payload: {
    response,
    billingData,
    insuranceHolder,
  },
});

export const failurePostIssuance = (error) => ({
  type: FAILURE_POST_ISSUANCE,
  payload: error,
});

export const resetIssuance = () => ({
  type: RESET_ISSUANCE,
});

export const addApplicationInfo = (billingData, insuranceHolder) => ({
  type: ADD_APPLICATION_INFO,
  payload: {
    billingData,
    insuranceHolder,
  },
});
