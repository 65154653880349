/* eslint-disable react/jsx-one-expression-per-line,react/forbid-prop-types */
/* eslint-disable no-shadow,object-curly-newline */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { closeModal } from '../../../../common/modals/actions/simple_modal';
import { resetReQuotation } from '../../../../Quotation/actions/reQuotation';
import HeaderTitleModal from './HeaderTitleModal';
import modalStyles from './style/modal-style';

/**
 * SuccessPayment.
 * */
const SuccessPayment = ({ classes, handleNext }) => {
  const simpleModal = useSelector((state) => state.simpleModal);
  const { titular = '', plan = '', totalPagado = '', metodoPago = '' } = simpleModal?.data;
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeModal({}));
    dispatch(resetReQuotation());
    handleNext();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={simpleModal.isOpen}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <HeaderTitleModal onClose={() => close()}>
        Confirmaci&oacute;n
      </HeaderTitleModal>
      <form>
        <DialogContent>
          <Grid container direction="row" spacing={2} style={{ padding: 20 }}>
            <Grid item md={6} xs={12}>
              <Typography className={classes.titleContent}>TITULAR</Typography>
              <Typography className={classes.valueContent}>{titular}</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography className={classes.titleContent}>PLAN</Typography>
              <Typography className={classes.valueContent}>{plan}</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography className={classes.titleContent}>TOTAL PAGADO</Typography>
              <Typography
                className={classes.valueContent}
              >
                USD ${totalPagado}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                className={classes.titleContent}
              >
                M&Eacute;TODO DE PAGO
              </Typography>
              <Typography className={classes.valueContent}>{metodoPago}</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <p className={classes.bigFont}>¡La tarjeta ha sido Registrada!</p>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography className={classes.message}>
                El documento de pago esta en proceso de confirmaci&oacute;n, el
                cliente recibir&aacute; una notificaci&oacute;n para
                la firma de contrato.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary" className={classes.roundButton}>
            Continuar
          </Button>
        </DialogActions>
        <Grid container direction="row"><Grid item xs={12}>&nbsp;</Grid></Grid>
      </form>
    </Dialog>
  );
};

SuccessPayment.propTypes = {
  classes: PropTypes.object.isRequired,
  handleNext: PropTypes.func,
};

export default withStyles(modalStyles)(SuccessPayment);
