/* eslint-disable no-shadow,object-curly-newline */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, DialogContentText, TextField, Backdrop, Box, CircularProgress, useTheme,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { closeModal } from '../../../common/modals/actions/simple_modal';
import { requestSendEmail } from '../../reducers/emailSlice';
import { usePrevious } from '../../../../utils/CommonUtils';
import { addSweetAlert, closeSweetAlert } from '../../../common/SweetAlert/actions/sweet_alert';

const schema = yup.object().shape({
  email: yup.string().email('email no valido').required('El campo email es requerido'),
});


/**
 * Send Email with quotation.
 * */
export default function EmailModal() {
  const sendEmail = useSelector((state) => state.sendEmail);
  const simpleModal = useSelector((state) => state.simpleModal);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema,
    mode: 'onSubmit',
  });
  const prevEmailWasSent = usePrevious(sendEmail);
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (prevEmailWasSent && prevEmailWasSent.emailWasSent !== sendEmail.emailWasSent) {
      if (sendEmail.emailWasSent) {
        setLoading(false);
        dispatch(addSweetAlert({
          type: 'success',
          title: 'El email fue enviado correctamente',
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }));
        handleClose();
      }
    }
  }, [sendEmail.emailWasSent]);

  useEffect(() => {
    if (prevEmailWasSent && prevEmailWasSent.isError !== sendEmail.isError) {
      if (sendEmail.isError) {
        setLoading(false);
        dispatch(addSweetAlert({
          type: 'error',
          title: 'Sucedio un error al enviar el correo',
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }));
        handleClose();
      }
    }
  }, [sendEmail.isError]);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const onSubmit = ({ email }) => {
    setLoading(true);
    dispatch(requestSendEmail(email));
  };

  return (
    <Dialog open={simpleModal.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Enviar Cotizacion por Email</DialogTitle>
      {loading ? (
        <Backdrop
          style={{
            zIndex: theme.zIndex.drawer + 1,
            color: theme.palette.primary.darkLight,
            backgroundColor: '#dbd6d6',
          }}
          open
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 15,
            }}
            bgcolor="background.paper"
            p={5}
          >
            <CircularProgress style={{ alignSelf: 'center' }} color="inherit" />
            <p style={{ marginTop: 15 }}>Enviando correo por favor espere...</p>
          </Box>
        </Backdrop>
      ) : (
        <form>
          <DialogContent>
            <DialogContentText>
              Ingrese el correo electronico al cual sera enviada la cotización.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Email"
              name="email"
              type="email"
              inputRef={register}
              fullWidth
            />
            {errors.email && <p>{errors.email.message}</p>}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
            >
              Enviar
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
}
