/* eslint-disable no-param-reassign,react/no-array-index-key,max-len */
import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import Remove from '@material-ui/icons/Remove';
import AddCircle from '@material-ui/icons/AddCircle';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import sideBarFormStyles from './style/sideBarForm-style';
import { requestQuote, resetQuote } from '../../../../actions/quote';
import { checkEmptyObject, findParameter } from '../../../../../../utils/CommonUtils';
import {
  addSweetAlert,
  closeSweetAlert,
} from '../../../../../common/SweetAlert/actions/sweet_alert';
import { MEDICINE_COVERAGE_ENABLED_PARAM } from '../../../../../../utils/Constants';

// validations
const schema = yup.object().shape({
  name: yup.string().required('Este campo es requerido').matches(/^[a-zA-Záéíóúüñç\s]*$/, 'Formato incorrecto del nombre'),
  age: yup.number().integer().typeError('Requerido').required('Requerido'),
  branchOfficeId: yup.number().typeError('Este campo es requerido').required('Este campo es requerido'),
  companyId: yup.string().required(),
  planName: yup.string().matches(/^[a-zA-Záéíóúüñç\s]*$/, 'Formato incorrecto del plan').required('Este campo es requerido'),
  productId: yup.number().required(),
  dependants: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Este campo es requerido').matches(/^[a-zA-Záéíóúüñç\s]*$/, 'Formato incorrecto del nombre'),
      age: yup.number().integer().typeError('Requerido').required('Requerido'),
      rol: yup.string().required('Este campo es requerido'),
    }),
  ),
  withDifferentHolder: yup.bool(),
  riders: yup.array(),
});

const SidebarForm = (props) => {
  const { className, isDesktop, onClose, ...rest } = props;
  const classes = sideBarFormStyles();

  const { register, control, errors, watch, setValue, handleSubmit, getValues } = useForm({
     validationSchema: schema,
  });

  const { fields, append, remove } = useFieldArray(
    {
      control,
      name: 'dependants',
    },
  );

  const dispatch = useDispatch();
  const { response: { catalogs = [], products = [], parameters = [] } } = useSelector((state) => state.catalogs);
  const medicineCoverageParam = findParameter(parameters, MEDICINE_COVERAGE_ENABLED_PARAM);
  const isMedicineCoverageEnable = JSON.parse(medicineCoverageParam.value?.toLowerCase() || 'false');
  const branch = catalogs?.find((c) => c.id === 'Sucursales')?.catalogue;

  const roles = catalogs && catalogs.find((c) => c.id === 'Roles')?.catalogue;
  const quote = useSelector((state) => state.quote, shallowEqual);
  const [plans, setPlans] = React.useState([]);
  const [riders, setRiders] = React.useState([]);
  const [checkedValues, setCheckedValues] = React.useState([]);
  //const [CheckName, setCheckName] = React.useState([]);
  const [planAutocomplete, setPlanAutocomplete] = React.useState({
    id: 0,
    companyId: '',
    name: '',
    // riders: [],
  });
  const [disableDependantsButton, setDisableDependantsButton] = React.useState(true);

  const  setCheckName = (value) => {
    console.log(value);
  }
  const validDependants = (dependants) => {
    let result = true;
    if (dependants !== undefined && dependants !== null) {
      dependants.forEach((d) => {
        if (d.rol === '1' && d.age < 18) {
          result = false;
        }
      });
    }
    return result;
  };

  const showAlert = (title, text) => {
    dispatch(addSweetAlert({
      type: 'warning',
      title,
      content: (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
          style={{ marginTop: 10 }}
        >
          <Typography variant="h6">{text}</Typography>
        </Grid>
      ),
      showCancel: false,
      onConfirmLabel: 'Aceptar',
      onConfirm: () => {
        dispatch(closeSweetAlert());
      },
    }));
  };

  const findSelectedPlanIgualas = (productId) => {
    const igualas = products.find((p) => p.name === 'IGUALAS');
    return igualas.plans.find((p) => p.id === productId);
  };

  const onSubmit = (data) => {
    data.SaleProcessId = quote.response.saleProcessId;
    // FIXME: Sometimes this value is undefined, maybe the components dont allow store values like arrays on Switch
    data.riders = checkedValues;

    const selectedPlan = findSelectedPlanIgualas(data.productId);

    const maxAge = selectedPlan.maxAge === 0 ? 100 : selectedPlan.maxAge;

    if (data.age >= selectedPlan.minAge && data.age <= maxAge) {
      if (validDependants(data.dependants)) {
        dispatch(requestQuote(data));
      } else {
        showAlert('Edad incorrecta', 'La edad de un conyugue no puede ser menor de 18 a\u00F1os');
      }
    } else {
      dispatch(addSweetAlert({
        type: 'warning',
        title: 'EDAD INCORRECTA',
        content: (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
            style={{ marginTop: 10 }}
          >
            {selectedPlan.name.includes('Kids') ? (
              <Typography variant="h6">
                {`La edad para este plan debe ser mayor a ${selectedPlan.minAge} y menor a ${selectedPlan.maxAge}`}
              </Typography>
            ) : (
              <Typography variant="h6">
                {`La edad para este plan debe ser mayor a ${selectedPlan.minAge}`}
              </Typography>
            )}
          </Grid>
        ),
        showCancel: false,
        onConfirmLabel: 'Aceptar',
        onConfirm: () => {
          setValue([
            { age: '' }]);
          dispatch(closeSweetAlert());
        },
      }));
    }
  };

  useEffect(() => {
    if (products && products.length > 0) {
      const igualas = products.find((p) => p.name === 'IGUALAS');
      const bmi = products.find((p) => p.name === 'BMI');
      let plansList = [];
      // eslint-disable-next-line max-len
      // TODO: refactor this
      if (igualas?.plans.length > 0 && bmi?.plans.length > 0) {
        plansList = igualas.plans.concat(bmi.plans);
      } else if (igualas.plans.length > 0 && !bmi?.plans) {
        plansList = igualas.plans;
      } else if (bmi.plans.length > 0 && !igualas?.plans) {
        plansList = bmi.plans;
      }
      const foundAutoComplete = plansList
      .find((plan) => plan.id === quote.requestData?.productId);
      setPlanAutocomplete(foundAutoComplete);
      setPlans(plansList);
    }
  }, [products]);

  useEffect(() => {
    if (plans.length > 0) {
      if (quote.requestData?.productId) {
        const plan = plans.find((plan) => plan.id === quote.requestData?.productId);
        setDisableDependantsButton(!plan.withDependants);
      }
    }
  }, [plans]);

  useEffect(() => {
    const initializeDependants = quote?.requestData?.dependants
      ? quote?.requestData?.dependants : [];
    if (initializeDependants) {
      const dependents = [];
      initializeDependants.map((item) => dependents
        .push({ name: item.name, age: item.age, rol: item.rol }));
      append(dependents);
    }
    if (!checkEmptyObject(quote.requestData)) {
      const selectedPlan = findSelectedPlanIgualas(quote.requestData?.productId);
      // set all riders of the current selected plan
      setRiders(selectedPlan.riders);
      // set selected riders on local state
      if (quote.requestData?.riders && selectedPlan.name !== 'Hospicare') {
        setCheckedValues(quote.requestData?.riders);
      }
      setValue([
        { productId: quote.requestData?.productId },
        { planName: quote.requestData?.planName },
        { age: quote.requestData?.age },
        { name: quote.requestData?.name },
        { branchOfficeId: quote.requestData?.branchOfficeId },
        { companyId: quote.requestData?.companyId },
        { withDifferentHolder: quote.requestData?.withDifferentHolder },
        { riders: quote.requestData?.riders },
      ]);
    }
  }, []);

  const dependantNameWatcher = watch('dependants');

  const handlePlan = (value) => {
    dispatch(resetQuote());
    if (value && value?.riders.length > 0) {
      setCheckedValues([]);
      setRiders(value.riders);
    } else {
      setRiders([]);
      setValue('riders', []);
      setCheckedValues([]);
    }
    if (value) {
      if (!value.withDependants) {
        dependantNameWatcher?.length > 0 && remove();
      }
      setDisableDependantsButton(!value.withDependants);
      setValue([
        { productId: value.id },
        { companyId: value.companyId.trim() },
        { planName: value.name },
        { withDifferentHolder: value.withDifferentHolder },
      ]);
    }
  };

  /**
   * Solution from the next link:
   * @see https://github.com/react-hook-form/react-hook-form/issues/1517#issuecomment-624526174
   * */
  const handleSwitches = (checkedName) => {
    const newNames = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newNames);
    setValue('riders', newNames);
    return newNames;
  };

  useEffect(() => {
    const checkMedicineCoverageRiderInHospicare = () => {
      const selectedPlanName = getValues({ nest: true }).planName;
      const plan = plans.find((p) => p.name === 'Hospicare');
      if (selectedPlanName === 'Hospicare' && isMedicineCoverageEnable && plan !== undefined) {
        const rider = plan.riders.find((p) => p.description === 'Cobertura Medicinas');
        handleSwitches(rider.coverageCode);
      }
    };
    checkMedicineCoverageRiderInHospicare();
  }, [riders, plans, isMedicineCoverageEnable]);

  /*const NAME_REGEX = new RegExp(/^[a-zA-Z\s]*$/);

  const handleValidateName = (name) => {
    debugger
    if (NAME_REGEX.test(name)) {
      errors["name"] = null;
    } else {
      errors["name"] = "Invalid name. Please try again."
    }
    return NAME_REGEX.test(name);
  }*/

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
          className={classes.topForm}
        >
          {
            isDesktop
            && (
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography variant="h4" className={classes.title}>Datos para Cotizar</Typography>
              </Grid>
            )
          }
          <Grid
            item
            md={12}
            xs={12}
          >
            {plans.length > 0 && (
              <Autocomplete
                defaultValue={planAutocomplete}
                onChange={(event, newValue) => {
                  handlePlan(newValue);
                }}
                autoSelect
                options={[...plans]}
                filterSelectedOptions
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => {
                  if (value) {
                    return option.id === value.id;
                  }
                  return false;
                }}
                style={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    style={{ backgroundColor: '#F2F2F2' }}
                    {...params}
                    label="PLANES"
                    error={errors.planName && true}
                    helperText={errors.planName && errors.planName.message}
                  />
                )}
                renderOption={(option) => (<span>{option.name}</span>)}
              />
            )}
            <Controller
              as={TextField}
              name="planName"
              control={control}
              type="hidden"
              defaultValue=""
            />
            <Controller
              as={Checkbox}
              name="withDifferentHolder"
              control={control}
              style={{ display: 'none' }}
              defaultValue={false}
            />
          </Grid>
          {
            riders.length > 0 && (
              <Grid
                item
                md={12}
                xs={12}
              >
                {
                  riders.map((item, index) => (
                    <div
                      key={index}
                      style={(item.description === 'Cobertura Medicinas' && isMedicineCoverageEnable) ? { display: 'none' } : null}
                    >
                      <FormControlLabel
                        control={(
                          <Controller
                            as={<Switch />}
                            control={control}
                            name="riders"
                            onChange={() => handleSwitches(item.coverageCode)}
                            checked={checkedValues.includes(item.coverageCode)}
                          />
                        )}
                        label={item.description}
                        labelPlacement="start"
                        style={{ marginTop: '-25px', marginBottom: '15px' }}
                      />
                    </div>
                  ))
                }
              </Grid>
            )
          }
          <Grid
            item
            md={12}
            xs={12}
          >
            <Controller
              as={(
                <TextField  />
              )}
              autoFocus
              margin="dense"
              label="NOMBRE"
              name="name"
              type="text"
              fullWidth
              style={{ backgroundColor: '#F2F2F2', marginTop: '-25px' }}
              control={control}

              error={errors.name && true}
              helperText={errors.name && errors.name.message}
              defaultValue=""
            />
          </Grid>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginLeft: 0, marginRight: 5 }}
            spacing={3}
          >
            <Grid item md={3} xs={3}>
              <Controller
                as={(
                  <TextField />
                )}
                id="age"
                style={{ backgroundColor: '#F2F2F2' }}
                autoFocus
                margin="dense"
                label="EDAD"
                type="number"
                name="age"
                fullWidth
                control={control}
                error={errors.age && true}
                helperText={errors.age && errors.age.message}
                inputProps={{ min: '0', step: '1' }}
                defaultValue=""
              />
            </Grid>
            <Grid item md={9} xs={9}>
              <FormControl
                className={classes.formControl}
                error={errors.branchOfficeId && true}
              >
                <InputLabel id="region-select">REGIÓN</InputLabel>
                <Controller
                  as={(
                    <Select>
                      {branch?.map((b) => (
                        <MenuItem key={b.id} value={b.id}>
                          {b.description}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  name="branchOfficeId"
                  control={control}
                  defaultValue=""
                />
                {errors.branchOfficeId
                && <FormHelperText>{errors.branchOfficeId.message}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {fields.map((item, index) => (
          <ExpansionPanel
            key={item.id}
            expanded
            square={false}
          >
            <ExpansionPanelSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className={classes.expansionPanelSummary}
            >

            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ width: '100%', padding: 0, backgroundColor: '#F2F2F2' }}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid
                  item
                  md={11}
                  xs={11}
                >
                  <TextField
                    className={classes.textPanelSummary}
                    autoFocus
                    margin="dense"
                    label="NOMBRE"
                    name={`dependants[${index}].name`}
                    type="text"
                    fullWidth
                    defaultValue={`${item.name}`}
                    inputRef={register()}
                    error={
                      errors.dependants
                      && errors.dependants[index]
                      && errors.dependants[index].name
                      && true
                    }
                    helperText={
                      errors.dependants
                      && errors.dependants[index]
                      && errors.dependants[index].name
                      && errors.dependants[index].name.message
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={1}
                  xs={1}>
                  <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={(
                      <IconButton
                        aria-label="delete"
                        className={classes.remove}
                        onClick={() => remove(index)}
                        size="small"
                      >
                        <Remove className={classes.icon} fontSize="inherit" />
                      </IconButton>
                    )}
                  />

                </Grid>
                <Grid
                  item
                  md={3}
                  xs={12}
                >
                  <TextField
                    className={classes.textPanelSummary}
                    autoFocus
                    margin="dense"
                    label="EDAD"
                    name={`dependants[${index}].age`}
                    type="number"
                    fullWidth
                    defaultValue={`${item.age}`}
                    inputRef={register({})}
                    error={
                      errors.dependants
                      && errors.dependants[index]
                      && errors.dependants[index].age
                      && true
                    }
                    helperText={
                      errors.dependants
                      && errors.dependants[index]
                      && errors.dependants[index].age
                      && errors.dependants[index].age.message
                    }
                    inputProps={{ min: 0, step: 0 }}
                  />
                </Grid>

                <Grid
                  item
                  md={9}
                  xs={12}
                >
                  <FormControl
                    style={{ backgroundColor: '#F2F2F2' }}
                    className={classes.select}
                    error={
                      errors.dependants
                      && errors.dependants[index]
                      && errors.dependants[index].rol
                      && errors.dependants[index].rol.message
                      && true
                    }
                  >
                    <InputLabel id="demo-simple-select-label">
                      RELACIÓN
                    </InputLabel>
                    <Controller
                      as={(
                        <Select>
                          {
                            roles?.map((item, index) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                              >
                                {item.description}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      )}
                      name={`dependants[${index}].rol`}
                      control={control}
                      defaultValue={`${item.rol}`}
                    />
                    {
                      errors.dependants
                      && errors.dependants[index]
                      && errors.dependants[index].rol
                      && errors.dependants[index].rol.message
                      && <FormHelperText>{errors.dependants[index].rol.message}</FormHelperText>
                    }
                  </FormControl>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>

          </ExpansionPanel>
        ))}
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid
            item
            md={12}
            xs={12}
          >
            <Button
              color="primary"
              className={classes.addDependantButton}
              disableElevation
              startIcon={<AddCircle
                className={disableDependantsButton ? classes.disabledAddIcon : classes.addIcon} />}
              disabled={disableDependantsButton}
              onClick={() => {
                dispatch(resetQuote());
                append({ name: '', age: '', rol: '' });
              }}
            >
              Agregar Dependiente
            </Button>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.buttons}
              size="large"
              disableElevation
              type="submit"
            >
              Cotizar
            </Button>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <Controller
              as={TextField}
              name="productId"
              control={control}
              type="hidden"
              defaultValue=""
            />
            <Controller
              as={TextField}
              name="companyId"
              control={control}
              type="hidden"
              defaultValue=""
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

SidebarForm.propTypes = {
  className: PropTypes.node.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default SidebarForm;
