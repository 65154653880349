import {
  AppBar,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect } from 'react';
import { AzureAD } from 'react-aad-msal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';

import logo from '../../../../assets/images/logo.png';
import logoMovil from '../../../../assets/images/logo_movil.png';
import { startSignIn } from '../../../../components/Login/actions/login';
import { authProvider } from '../../../../components/Login/auth/auth_provider';
import { resetQuote } from '../../../../components/Quotation/actions/quote';
import { store } from '../../../../store/Store';
import topbarStyles from './style/topbar-style';
import { resetGetApplication } from '../../../../components/Issuance/components/Application/actions/get_application';

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const Topbar = (props) => {
  const { className, menus, ...rest } = props;

  const dispatch = useDispatch();
  const [subMenus, setSubMenus] = React.useState([]);
  const login = useSelector((state) => state.login);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const [stateMenu, setStateMenu] = React.useState(false);

  useEffect(() => {
    if (menus.length > 0) {
      setSubMenus(menus[0].menus);
    }
  }, [menus]);

  const user = useSelector((state) => state.login, shallowEqual);
  const classes = topbarStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (action) => {
    action();
    handleClose();
  };

  const handleLogin = () => {
    dispatch(startSignIn(authProvider));
    handleClose();
  };


  const handleSubmenuSelected = (event) => {
    setStateMenu(false);
    if (event.currentTarget.href.endsWith('quotation')) {
      dispatch(resetQuote());
      dispatch(resetGetApplication());
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setStateMenu(open);
  };

  return (
    <AzureAD provider={authProvider} reduxStore={store}>
      {({ logout }) => (
        <AppBar {...rest} className={clsx(classes.root, className)}>
          <Toolbar>
            {/* Hamburger Menu */}
            {
              !isDesktop && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer(true)}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
              )
            }
            {/* Logo */}
            <div className={classes.logo}>
              <RouterLink to="/">
                <img
                  alt="Logo"
                  src={isDesktop ? logo : logoMovil}
                />
              </RouterLink>
            </div>
            {isDesktop
            && (
              <div className={classes.headerContent}>
                {/* First Menu */}
                {menus.map((catalog) => (
                  <div key={catalog.description}>
                    <Button
                      className={classes.button}
                      style={{ fontFamily: 'Roboto-Light' }}
                      onClick={() => setSubMenus(catalog.menus)}
                    >
                      {catalog.description}
                    </Button>
                  </div>
                ))}
              </div>
            )}
            <div className={classes.flexGrow} />
            {/* Name of Current User */}
            <Typography
              variant="h5"
              className={classes.username}
            >
              {user.response?.account?.name}
            </Typography>
            <IconButton
              className={classes.signOutButton}
              variant="contained"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <AccountCircle style={{ color: '#518CBF' }} />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {login.signedIn ? (
                <MenuItem onClick={() => handleLogout(logout)}>
                  Logout
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => handleLogin()}
                >
                  Login
                </MenuItem>
              )}
            </Menu>
          </Toolbar>
          {/* Second Toolbar */}
          {
            isDesktop && (
              <Toolbar className={classes.secondToolbar}>
                <div className={classes.secondHeaderContent}>
                  {subMenus.map((subMenu) => (
                    <div key={subMenu.description}>
                      <Button
                        className={classes.buttonSecondHeader}
                        activeClassName={classes.activeButtonSecondHeader}
                        variant="text"
                        size="small"
                        component={CustomRouterLink}
                        to={subMenu.url}
                        onClick={handleSubmenuSelected}
                      >
                        {subMenu.description}
                      </Button>
                    </div>
                  ))}
                </div>
              </Toolbar>
            )
          }
          {/* Menu and Submenus for Mobile */}
          {!isDesktop && (
            <Drawer anchor="left" open={stateMenu} onClose={toggleDrawer(false)}>
              <List>
                {menus.map((catalog) => (
                  <div key={catalog.description}>
                    <ListItem key={catalog.description}>
                      <Button
                        style={{ fontFamily: 'Roboto-Light' }}
                        onClick={() => setSubMenus(catalog.menus)}
                      >
                        {catalog.description}
                      </Button>
                    </ListItem>
                    {subMenus.map((subMenu) => (
                      <ListItem key={subMenu.description}>
                        <Button
                          className={classes.buttonSecondHeader}
                          activeClassName={classes
                            .activeButtonSecondHeader}
                          variant="text"
                          size="small"
                          component={CustomRouterLink}
                          to={subMenu.url}
                          onClick={handleSubmenuSelected}
                        >
                          {subMenu.description}
                        </Button>
                      </ListItem>
                    ))}
                  </div>
                ))}
              </List>
            </Drawer>
          )}
        </AppBar>
      )}
    </AzureAD>
  );
};

Topbar.propTypes = {
  className: PropTypes.string.isRequired,
  menus: PropTypes.array.isRequired,
};

export default Topbar;
