import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../../../../../theme/palette';

const sideBarFormStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  expansionPanelSummary: {
    backgroundColor: '#F2F2F2',
    minHeight: '10px !important',
    height: '10px !important',
  },
  textPanelSummary: {
    backgroundColor: '#F2F2F2',
  },
  expansionPanelDetail: {
    backgroundColor: '#F2F2F2',
  },
  remove: {
    margin: theme.spacing(1),
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: '#ef5350',
      borderColor: '#ef5350',
    },
    flexBasis: '10%',
    fontSize: '10px !important',
  },
  icon: {
    color: 'white',
  },
  addIcon: {
    color: '#90B648',
    fontSize: '30px !important',
  },
  disabledAddIcon: {
    color: '#e6e6e6',
    fontSize: '30px !important',
  },
  select: {
    margin: theme.spacing(1),
    minWidth: '95%',
  },
  column: {
    flexBasis: '90%',
    alignSelf: 'center',
  },
  topForm: {
    marginBottom: '10%',
  },
  buttons: {
    width: '100%',
    borderRadius: 25,
  },
  checkBoxes: {
    marginTop: 20,
  },
  dependantHeader: {
    color: palette.text.primary,
    fontSize: '10px',
  },
  dependantName: {
    color: '#000000',
    fontSize: '15px',
  },
  formControl: {
    minWidth: '100%',
  },
  title: {
    fontStyle: 'italic',
    color: theme.palette.primary.dark,
  },
  addDependantButton: {
    width: '100%',
    textTransform: 'unset',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default sideBarFormStyles;
