import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import Routes from './routes/routes';
import TagManager from 'react-gtm-module';

const browserHistory = createBrowserHistory();
ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID, { testMode: process.env.NODE_ENV === 'test' });

browserHistory.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TRACKING_TAG_MANAGER_ID
}
TagManager.initialize(tagManagerArgs)

export default class App extends Component {

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </ThemeProvider>
    );
  }
}
