import { CLOSE_MODAL, OPEN_MODAL, RESET_MODAL } from '../actions/simple_modal';

const INITIAL_STATE = {
  isOpen: false,
  data: {}, // used for set data to show  on Modal
  result: {}, // used TermsAndConditions component
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        isOpen: action.payload.isOpen,
        data: action.payload.data,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isOpen: action.payload.isOpen,
        result: action.result,
      };
    case RESET_MODAL:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
