/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';

import { Topbar } from './components';
import SimpleSnackbar from '../../components/common/messages/SimpleSnackBar';
import SweetAlert from '../../components/common/SweetAlert/CustomSweetAlert';
import { requestCatalogs } from '../../components/Catalogs/actions/catalogs';
import BackdropLoading from '../../components/common/BackdropLoading';
import RetryData from '../../components/common/RetryData';
import { areas } from '../../utils/Constants';

import api from '../../utils/Api';
import { setMenus } from '../../components/Login/actions/login';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 100,
    height: '100%',
    [theme.breakpoints.down(1280)]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up(1280)]: {
      paddingTop: 60,
    },
    [theme.breakpoints.down(600)]: {
      paddingTop: 40,
    },
  },
  content: {
    overflowY: 'scroll',
    [`${theme.breakpoints.up('xs')}`]: {
      marginTop: '25px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '65px',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '65px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '65px',
    },
  },
  nav: {
    marginBottom: theme.spacing(1),
  },
  nav2: {
    marginTop: theme.spacing(7),
  },
}));

export const fetchMenus = async () => {
  const url = `${process.env.REACT_APP_MENUS}`;
  return api.get(url);
};


const Main = (props) => {
  const { children } = props;
  const classes = useStyles();
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const catalogs = useSelector((state) => state.catalogs, shallowEqual);
  const [isErrorMenus, setIsErrorMenus] = React.useState(false);

  useEffect(() => {
    if (!catalogs.hasData) {
      dispatch(requestCatalogs());
    }
  }, []);

  const gettingMenus = () => {
    setTimeout(() => {
      trackPromise(fetchMenus(), areas.global).then(({ data }) => {
        setIsErrorMenus(false);
        dispatch(setMenus(data));
      }).catch((error) => {
        setIsErrorMenus(true);
      });
    }, 500);
  };

  useEffect(() => {
    if (login.signedIn) {
      gettingMenus();
    }
  }, [login.signedIn]);

  const renderMainContent = () => {
    if (catalogs.error) {
      return <RetryData onClickHandler={() => dispatch(requestCatalogs())} />;
    }
    if (isErrorMenus) {
      return <RetryData onClickHandler={() => gettingMenus()} />;
    }
    if (!catalogs.error && catalogs.response) {
      return (
        <main className={classes.content}>
          {children}
        </main>
      );
    }
    return null;
  };

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <SimpleSnackbar />
      <SweetAlert />
      <Topbar className={classes.nav} menus={login.menus} />
      <BackdropLoading area={areas.global} />
      {renderMainContent()}
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
