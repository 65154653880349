import { makeStyles } from '@material-ui/core/styles';

const applicationStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  iframe: {
    width: '100%',
    minHeight: 640,
    border: 0,
  },
  center: {
    alignSelf: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  saveButton: {
    borderRadius: 20,
  },
}));

export default applicationStyles;
