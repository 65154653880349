import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

import CustomPanel from '../../../../common/CustomPanel';
import * as Constants from '../../../../../utils/Constants';
import { PICKER_FORMAT_DATE } from '../../../../../utils/Constants';
import { findByIdentification } from '../actions/find_by_identification';
import useFindByIdentification from '../hooks/useFindByIdentification';
import { isValidId } from '../../../../../utils/id-validator';
import {
  selectDivisionPolitica,
  selectGender,
  selectRelationship,
  selectTypeIdentification,
} from '../utils/selectors';
import { addSweetAlert, closeSweetAlert } from '../../../../common/SweetAlert/actions/sweet_alert';
import { resetFormApplicationValues } from '../../../../../utils/CommonUtils';
import { ResetApplicationForm } from '../../Payment/Enums';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  radioGroup: {
    marginTop: 10,
  },
}));

const setInsuranceHolderForm = (holder, setValue) => {
  const { Person: person } = holder;
  setValue([
    { 'insuranceHolder.relationshipBeneficiary': holder.RelationshipBeneficiary.trim() || '' },
    { 'insuranceHolder.identificationType': person?.IdentificationType.trim() || '' },
    { 'insuranceHolder.identification': person?.Identification.trim() || '' },
    { 'insuranceHolder.name': person?.Name.trim() || '' },
    { 'insuranceHolder.lastName': person?.LastName.trim() || '' },
    { 'insuranceHolder.birthDate': person?.BirthDate.trim() || '' },
    { 'insuranceHolder.birthPlace': person?.BirthPlace.trim() || '' },
    { 'insuranceHolder.gender': person?.Gender.trim() || '' },
  ]);
};

const InfinityHolder = ({ editionMode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const relationship = useSelector(selectRelationship);
  const typeIdentification = useSelector(selectTypeIdentification);
  const gens = useSelector(selectGender);
  const { requestData: quoteData } = useSelector((state) => state.quote);
  const { register, control, errors, getValues, setValue, clearError, watch } = useFormContext();
  const countries = useSelector(selectDivisionPolitica);

  const getApplication = useSelector((state) => state.getApplication);

  const [isDisableInput, setIsDisableInput] = React.useState(true);
  const [personWasFound, setPersonWasFound] = React.useState(true);

  const identificationWatch = watch('insuranceHolder.identification');


  useFindByIdentification(clearError, setValue, setIsDisableInput, setPersonWasFound);

  const handleSearchIdentification = () => {
    const formValues = getValues({ nest: true });
    const identification = formValues.insuranceHolder?.identification;
    if (identification) {
      if (isValidId(identification)) {
        dispatch(findByIdentification(identification, quoteData?.productId));
      } else {
        dispatch(addSweetAlert({
          type: 'warning',
          title: Constants.MESSAGE_INVALID_IDENTIFICATION,
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }));
      }
    }
  };

  useEffect(() => {
    register({ name: 'insuranceHolder.identification' });
  }, [register]);

  useEffect(() => {
    if (editionMode) {
      const applicationHolder = getApplication.response.InsuranceApplicationHolder;
      setIsDisableInput(applicationHolder.Person.IsValidatedCivilRegistry);
      setInsuranceHolderForm(applicationHolder, setValue);
    }
  }, [editionMode, getApplication.response.InsuranceApplicationHolder]);

  const onChangeIdentification = (e) => {
    resetFormApplicationValues(setValue, clearError, ResetApplicationForm.HolderFields);
    setValue('insuranceHolder.identification', e.target?.value);
  };

  const relacionRef = useRef();
  const tipoIdRef = useRef();
  const nombresRef = useRef();
  const apellidosRef = useRef();
  const sexoRef = useRef();
  const lugarRef = useRef();

  return (
    <CustomPanel withoutTitle subtitle="Datos Titular">
      <Grid container direction="row" spacing={3}>
        <Grid item md={4} xs={12}>
          <FormControl
            className={classes.formControl}
            fullWidth
            error={errors.insuranceHolder?.hasOwnProperty('relationshipBeneficiary')}
          >
            <InputLabel id="id-relationshipBeneficiary" style={{ fontSize: 11 }}>
              RELACIÓN CON BENEFICIARIO
            </InputLabel>
            <Controller
              as={(
                <Select autoFocus inputRef={relacionRef}>
                  <MenuItem value="">
                    <em>Seleccione..</em>
                  </MenuItem>
                  {
                    relationship?.map((relation) => (
                      <MenuItem
                        key={relation.id}
                        value={relation.id}
                      >
                        {relation.description}
                      </MenuItem>
                    ))
                  }
                </Select>
              )}
              onFocus={() => relacionRef.current.focus()}
              labelId="id-relationshipBeneficiary"
              name="insuranceHolder.relationshipBeneficiary"
              control={control}
              defaultValue=""
            />
            <FormHelperText>
              {errors.insuranceHolder?.relationshipBeneficiary?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl
            className={classes.formControl}
            fullWidth
            error={errors.insuranceHolder?.hasOwnProperty('identificationType')}
          >
            <InputLabel id="id-type-identification">TIPO DE IDENTIFICACIÓN</InputLabel>
            <Controller
              as={(
                <Select inputRef={tipoIdRef}>
                  <MenuItem value="">
                    <em>Seleccione...</em>
                  </MenuItem>
                  {
                    typeIdentification?.map((t) => (
                      <MenuItem
                        key={t.id}
                        value={t.id}
                      >
                        {t.description}
                      </MenuItem>
                    ))
                  }
                </Select>
              )}
              onFocus={() => tipoIdRef.current.focus()}
              labelId="id-type-identification"
              name="insuranceHolder.identificationType"
              control={control}
              defaultValue={Constants.DEFAULT_VALUE_TYPE_IDENTIFICATION}
            />
            <FormHelperText>
              {errors.insuranceHolder?.identificationType?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            fullWidth
            type="number"
            className={classes.formControl}
            value={identificationWatch}
            onChange={onChangeIdentification}
            label="IDENTIFICACIÓN"
            autoComplete="off"
            error={errors.insuranceHolder?.hasOwnProperty('identification')}
            helperText={errors.insuranceHolder?.identification?.message}
            InputProps={{
              endAdornment: (
                <Tooltip title="Buscar" arrow>
                  <IconButton
                    aria-label="search"
                    size="small"
                    onClick={handleSearchIdentification}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={3}>
        <Grid item md={4} xs={12}>
          <Controller
            as={(
              <TextField inputRef={nombresRef} />
            )}
            onFocus={() => nombresRef.current.focus()}
            fullWidth
            className={classes.formControl}
            label="NOMBRES"
            autoComplete="off"
            name="insuranceHolder.name"
            control={control}
            InputProps={{ readOnly: isDisableInput }}
            error={errors.insuranceHolder?.hasOwnProperty('name')}
            helperText={errors.insuranceHolder?.name?.message}
            defaultValue=""
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            as={(
              <TextField inputRef={apellidosRef} />
            )}
            onFocus={() => apellidosRef.current.focus()}
            fullWidth
            className={classes.formControl}
            label="APELLIDOS"
            autoComplete="off"
            name="insuranceHolder.lastName"
            control={control}
            InputProps={{ readOnly: isDisableInput }}
            error={errors.insuranceHolder?.hasOwnProperty('lastName')}
            helperText={errors.insuranceHolder?.name?.message}
            defaultValue=""
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={(
                <KeyboardDatePicker
                  readOnly={isDisableInput}
                  disableFuture
                />
              )}
              autoOk
              className={classes.formControl}
              name="insuranceHolder.birthDate"
              fullWidth
              disableToolbar={false}
              variant="inline"
              format={PICKER_FORMAT_DATE}
              margin="normal"
              label="FECHA NACIMIENTO"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{ readOnly: isDisableInput }}
              error={errors.insuranceHolder?.hasOwnProperty('birthDate')}
              helperText={errors.insuranceHolder?.birthDate?.message}
              control={control}
              defaultValue={null}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid container item direction="row" spacing={3}>
        <Grid item md={4} xs={12}>
          <FormControl
            className={classes.formControl}
            fullWidth
            error={errors.insuranceHolder?.hasOwnProperty('gender')}
          >
            <InputLabel id="id-gender">SEXO</InputLabel>
            <Controller
              as={(
                <Select inputRef={sexoRef}>
                  {
                    gens?.map((gen) => (
                      <MenuItem
                        key={gen.id}
                        value={gen.id}
                      >
                        {gen.description}
                      </MenuItem>
                    ))
                  }
                </Select>
              )}
              onFocus={() => sexoRef.current.focus()}
              fullWidth
              name="insuranceHolder.gender"
              label="SEXO"
              control={control}
              inputProps={{ readOnly: isDisableInput }}
              error={errors.insuranceHolder?.hasOwnProperty('gender')}
              defaultValue=""
            />
            <FormHelperText>
              {errors.insuranceHolder?.gender?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          {
            personWasFound
              ? (
                <Controller
                  as={(
                    <TextField inputRef={lugarRef} />
                  )}
                  onFocus={() => lugarRef.current.focus()}
                  fullWidth
                  className={classes.formControl}
                  name="insuranceHolder.birthPlace"
                  autoComplete="off"
                  label="LUGAR NACIMIENTO"
                  error={errors.insuranceHolder?.hasOwnProperty('birthPlace')}
                  helperText={errors.insuranceHolder?.birthPlace?.message}
                  control={control}
                  defaultValue=""
                />
              )
              : (
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  error={errors.insuranceHolder?.birthPlace && true}
                >
                  <InputLabel id="province-select">LUGAR NACIMIENTO</InputLabel>
                  <Controller
                    as={(
                      <Select labelId="province-select">
                        <MenuItem value="">
                          <em>Seleccione...</em>
                        </MenuItem>
                        {countries?.map((b) => (
                          b.id !== '0' && (
                            <MenuItem key={b.id} value={b.description}>
                              {b.description}
                            </MenuItem>
                          )
                        ))}
                      </Select>
                    )}
                    name="insuranceHolder.birthPlace"
                    control={control}
                    defaultValue="Ecuador"
                  />
                  <FormHelperText>
                    {errors.insuranceHolder?.birthPlace.message}
                  </FormHelperText>
                </FormControl>
              )
          }
        </Grid>
      </Grid>
    </CustomPanel>
  );
};

InfinityHolder.propTypes = {
  editionMode: PropTypes.bool.isRequired,
};

export default InfinityHolder;
