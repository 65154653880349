import * as actions from '../actions/application';

const INITIAL_STATE = {
  loading: false,
  response: {},
  requestData: {},
  isError: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.REQUEST_POST_ISSUANCE:
      return {
        ...state,
        loading: true,
        requestData: {},
        isError: false,
      };
    case actions.SUCCESS_POST_ISSUANCE:
      return {
        ...state,
        loading: false,
        response: action.payload.response,
        requestData: {
          billingData: action.payload.billingData,
          insuranceHolder: action.payload.insuranceHolder,
        },
        isError: false,
      };
    case actions.ADD_APPLICATION_INFO:
      return {
        ...state,
        requestData: {
          billingData: action.payload.billingData,
          insuranceHolder: action.payload.insuranceHolder,
        },
      };
    case actions.FAILURE_POST_ISSUANCE:
      return {
        ...state,
        loading: false,
        response: action.payload,
        requestData: {},
        isError: true,
      };
    case actions.RESET_ISSUANCE:
      return {
        ...state,
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

// selectors
export const getIssuanceData = (state) => state.quote;
