/* eslint-disable react-hooks/exhaustive-deps,react/forbid-prop-types,react/destructuring-assignment,max-len */
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import RouteWithLayout from './RouteWithLayout';
import { getUserRole } from '../../utils/CommonUtils';
import { RolEnum } from '../Issuance/components/Payment/Enums';

export const foundPathInMenus = (path, menus) => {
  const joinedMenus = menus.map((menu) => menu.menus).flat();
  return joinedMenus.some((menu) => menu.url === path);
};

/**
 * Allow retrieving the correct route to users with specific roles.
 * */
const CustomRoute = (props) => {
  const [returnedRoute, setReturnedRoute] = useState('');
  const login = useSelector((state) => state.login);
  const role = getUserRole(login);

  useEffect(() => {
    switch (props.condition) {
      case 'private':
        // exception only for this inner path
        if (login.signedIn && props.path === '/issuance' && role !== RolEnum.ReporterUnified) {
          return setReturnedRoute(<RouteWithLayout {...props} />);
        }
        if (login.signedIn && foundPathInMenus(props.path, login.menus)) {
          return setReturnedRoute(<RouteWithLayout {...props} />);
        }
        return setReturnedRoute(<Redirect to="/quotation" />);
      case 'public':
        return setReturnedRoute(
          (
            <RouteWithLayout {...props} />
          ),
        );
      default:
        return setReturnedRoute(<Route {...props} />);
    }
  }, [role, props]);
  return <>{returnedRoute}</>;
};

const mapStateToProps = ({ login }) => ({ login });

CustomRoute.defaultProps = {
  condition: '',
};

CustomRoute.propTypes = {
  condition: PropTypes.string,
  login: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  null,
)(CustomRoute);
