/* eslint-disable max-len,no-shadow */
import React from 'react';
import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ExpandMore } from '@material-ui/icons';

import Deductible from './Deductible';

const useStyles = makeStyles((theme) => ({
  paymentTypeStyle: {
    fontSize: 10,
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
  },
  mainContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    paddingTop: 30,
    marginLeft: '2%',
  },
  container: {
    padding: 0,
  },
  deductiblesContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  listItem: {
    justifyContent: 'flex-end',
  },
  borderGrid: {
    borderLeftStyle: 'dashed',
    borderWidth: '4px',
    borderColor: theme.palette.primary.main,
  },
  mainTitle: {
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '15px',
    paddingLeft: 5,
  },
  secondaryTitle: {
    marginBottom: 20,
    fontSize: '12px',
    color: '#000000',
    paddingLeft: 5,
  },
  paymentTypeElevator: {
    height: '8px',
    backgroundColor: 'transparent',
    borderWidth: '0px',
  },
  headerExpansion: {
    backgroundColor: '#E6E6E6 !important',
  },
}));

export default function Deductibles({ quote, parentWidth }) {
  const classes = useStyles();

  const { mainGroup = [] } = quote;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });


  return (
    <div style={{ width: '100%' }}>
      {!isDesktop
        ? (
          <div style={{ width: '100%' }}>
            {mainGroup?.map((main, index) => (
              <div style={{ width: '100%' }} key={index}>
                {main?.secondaryGroup?.map((secondary, index) => (
                  <ExpansionPanel key={index}>
                    <ExpansionPanelSummary
                      className={classes.headerExpansion}
                      expandIcon={(
                        <IconButton
                          variant="contained"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                        >
                          <ExpandMore style={{ color: '#518CBF' }} />
                        </IconButton>
                      )}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.secondaryTitle} align="left">
                        {main.title}
                        <br />
                        {secondary?.title}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.headerExpansion}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={2}
                      >
                        {secondary?.quotationDetail?.map((deductible, index) => (
                          <Grid item key={index} xs={6} sm={7}>
                            <Deductible
                              key={deductible?.deductibleId}
                              detail={deductible}
                            />
                          </Grid>
                        ))}

                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
              </div>
            ))}
          </div>
        )
        : (
          <div className={classes.mainContainer}>
            <div style={{ overflowX: 'auto', width: parentWidth }}>
              <div className={classes.deductiblesContainer}>
                {mainGroup?.map((main, index) => (
                  <Box key={main?.title} className={index === 1 ? classes.borderGrid : null}>
                    <Typography className={classes.mainTitle}>{main.title}</Typography>
                    <Box key={index} display="inline-flex" flexDirection="row">
                      {main?.secondaryGroup?.map((secondary, index) => (
                        <Box key={index}>
                          <Typography className={classes.secondaryTitle} align="center">
                            {secondary?.title}
                          </Typography>
                          <Box key={index} display="inline-flex" flexDirection="row">
                            {secondary?.quotationDetail?.map((deductible) => (
                              <Deductible
                                key={deductible?.deductibleId}
                                detail={deductible}
                              />
                            ))}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </div>
            </div>
          </div>
        )}
    </div>


  );
}

Deductibles.propTypes = {
  quote: PropTypes.shape({
    mainGroup: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        secondaryGroup: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            quotationDetail: PropTypes.array.isRequired,
          }),
        ),
      }),
    ),
  }).isRequired,
  parentWidth: PropTypes.number.isRequired,
};
