/* eslint-disable max-len,react/forbid-prop-types */
import React, { memo, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { get } from 'lodash';
import { PICKER_FORMAT_DATE } from '../../../../../utils/Constants';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
  },
  addIcon: {
    color: '#90B648',
  },
  expansionPanelSummary: {
    backgroundColor: '#F4F4F4',
  },
  remove: {
    padding: 0,
    color: '#ef5350',
  },
}));

function MedicalConditions({ fieldArrayName, medicalConditionsValues, hasCovid }) {
  const { register, control, errors, getValues, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray(
    {
      control,
      name: fieldArrayName,
    },
  );
  const classes = useStyles();
  const formValues = getValues();
  const conditionsWatcher = watch(fieldArrayName);

  useEffect(() => {
    if (medicalConditionsValues?.length > 0) {
      setMainMedicalConditions(medicalConditionsValues);
    }
  }, [medicalConditionsValues]);


  const setMainMedicalConditions = (medicalConditions) => {
    const mainMedicalConditions = medicalConditions.map((item) => ({
      diagnosis: item.Diagnosis,
      diagnosisFrom: item.DiagnosisFrom,
      diagnosisIsToThePresent: item.DiagnosisIsToThePresent,
      diagnosisTo: item.DiagnosisTo,
    }));
    append(mainMedicalConditions);
  };

  const checkErrorProperty = (index, property) => {
    if (get(errors, fieldArrayName) && get(errors, fieldArrayName)[index]) {
      return get(errors, fieldArrayName)[index]?.hasOwnProperty(`${property}`);
    }
    return false;
  };

  const getMessage = (index, property) => {
    if (get(errors, fieldArrayName)
      && get(errors, fieldArrayName)[index]) {
      return get(errors, fieldArrayName)[index][property]?.message;
    }
    return '';
  };

  const nestedForm = getValues({ nest: true });

  useEffect(() => {
    const checkCovidOnArray = fields.some((f) => f.diagnosis === 'SARS-CoV-2/COVID-19');
    if (hasCovid && !checkCovidOnArray) {
      append({
        diagnosis: 'SARS-CoV-2/COVID-19',
        diagnosisFrom: null,
        diagnosisTo: null,
        diagnosisIsToThePresent: false,
      });
    } if (!hasCovid && fields.length > 0) {
      fields.map((item, index) => {
        if (item?.diagnosis === 'SARS-CoV-2/COVID-19') {
          remove(index);
        }
      });
    }
  }, [hasCovid]);


  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={5}>
          <Typography className={classes.formControl}>DIAGNÓSTICO</Typography>
        </Grid>
      </Grid>
      {fields.map((item, index) => (
        <ExpansionPanel
          key={item.id}
          expanded
        >
          <ExpansionPanelSummary
            className={classes.expansionPanelSummary}
            aria-controls="panel-content"
            id="panel-header"
          >
            <Grid container direction="row" spacing={2}>
              <Grid item xs={10}>
                <Typography>{formValues[`${fieldArrayName}[${index}].diagnosis`] || 'Condición'}</Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label="delete"
                  className={classes.remove}
                  onClick={() => remove(index)}
                  disabled={fields[index].diagnosis === 'SARS-CoV-2/COVID-19'}
                >
                  <RemoveCircleIcon className={classes.icon} />
                </IconButton>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container direction="row" spacing={2}>
              <Grid
                item
                md={conditionsWatcher
                && !conditionsWatcher[index]?.diagnosisIsToThePresent ? 3 : 5}
                xs={12}
              >
                <TextField
                  id={`text${index}`}
                  className={classes.formControl}
                  fullWidth
                  inputRef={register()}
                  name={`${fieldArrayName}[${index}].diagnosis`}
                  label="Diagnóstico"
                  autoComplete="off"
                  error={checkErrorProperty(index, 'diagnosis')}
                  helperText={getMessage(index, 'diagnosis')}
                  defaultValue={fields[index].diagnosis}
                  disabled={fields[index].diagnosis === 'SARS-CoV-2/COVID-19'}
                />
              </Grid>
              <Grid
                item
                md={conditionsWatcher
                && !conditionsWatcher[index]?.diagnosisIsToThePresent ? 3 : 4}
                xs={12}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    as={(
                      <KeyboardDatePicker
                        disableFuture
                        value={fields[index].diagnosisFrom}
                      />
                    )}
                    autoOk
                    id={`pickerFromDate${index}`}
                    name={`${fieldArrayName}[${index}].diagnosisFrom`}
                    className={classes.formControl}
                    fullWidth
                    variant="inline"
                    format={PICKER_FORMAT_DATE}
                    margin="normal"
                    label="Desde"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                      size: 'small',
                    }}
                    onChange={(date) => {
                      if (date && date[0]?.toString() !== 'Invalid Date') {
                        return new Date(date[0]).toISOString();
                      }
                      return 'Invalid';
                    }}
                    control={control}
                    error={checkErrorProperty(index, 'diagnosisFrom')}
                    helperText={getMessage(index, 'diagnosisFrom')}
                    defaultValue={fields[index].diagnosisFrom}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={3} xs={12} container alignItems="flex-end">
                <FormControlLabel
                  control={(
                    <Switch
                      color="primary"
                      inputRef={register()}
                      name={`${fieldArrayName}[${index}].diagnosisIsToThePresent`}
                      defaultChecked={fields[index]?.diagnosisIsToThePresent}
                    />
                  )}
                  label={(
                    <Typography style={{ fontSize: 12 }}>
                      Actualidad
                    </Typography>
                  )}
                  labelPlacement="start"
                />
              </Grid>
              {conditionsWatcher
              && !conditionsWatcher[index]?.diagnosisIsToThePresent && (
                <Grid item md={3} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      as={(
                        <KeyboardDatePicker
                          disableFuture
                          value={fields[index].diagnosisTo}
                        />
                      )}
                      autoOk
                      id={`pickerToDate${index}`}
                      name={`${fieldArrayName}[${index}].diagnosisTo`}
                      className={classes.formControl}
                      fullWidth
                      variant="inline"
                      format={PICKER_FORMAT_DATE}
                      margin="normal"
                      label="Hasta"
                      minDate={
                        get(nestedForm, fieldArrayName)[index]?.diagnosisFrom
                      }
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                        size: 'small',
                      }}
                      onChange={(date) => {
                        if (date && date[0]?.toString() !== 'Invalid Date') {
                          return new Date(date[0]).toISOString();
                        }
                        return 'Invalid';
                      }}
                      control={control}
                      error={checkErrorProperty(index, 'diagnosisTo')}
                      helperText={getMessage(index, 'diagnosisTo')}
                      defaultValue={fields[index].diagnosisTo}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item md={12} xs={12}>
          <Button
            color="primary"
            disableElevation
            startIcon={<AddCircleIcon className={classes.addIcon} />}
            onClick={() => append({
              diagnosis: '', diagnosisFrom: null, diagnosisTo: null, diagnosisIsToThePresent: false,
            })}
          >
            Agregar Preexistencia
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

MedicalConditions.defaultProps = {
  medicalConditionsValues: [],
};

MedicalConditions.propTypes = {
  fieldArrayName: PropTypes.string.isRequired,
  medicalConditionsValues: PropTypes.array,
  hasCovid: PropTypes.bool.isRequired,
};

export default memo(MedicalConditions);
