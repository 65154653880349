/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  IconButton,
  TextField,
  Typography,
  LinearProgress,
  FormControlLabel,
  Switch,
  Grid, Tooltip,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import * as yup from 'yup';
import moment from 'moment';
import useAxios from 'axios-hooks';

import { postEmployee, updateEmployee } from '../actions/administrate_users';
import { checkEmptyObject, usePrevious } from '../../../utils/CommonUtils';
import { addSweetAlert, closeSweetAlert } from '../../common/SweetAlert/actions/sweet_alert';
import { isValidId } from '../../../utils/id-validator';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const schema = yup.object().shape({
  id: yup.string(),
  displayName: yup.string(),
  givenName: yup.string().required('El campo Nombre es requerido'),
  surName: yup.string().required('El campo Apellido es requerido'),
  email: yup.string().email('email no valido').required('El campo email es requerido'),
  agentIdentification: yup.string().test('id-validation', 'C\u00E9dula no es v\u00e1lida.', isValidId),
  birthday: yup.string(),
  accountEnabled: yup.boolean(),
});

const NewUserScreen = (props) => {
  const { open, handleClose, data } = props;
  const [readOnly, setReadOnly] = React.useState(true);
  const employees = useSelector((state) => state.employees);
  const dispatch = useDispatch();
  const [{ data: userData, error: userError }, fetchUser] = useAxios(
    null, { manual: true },
  );
  const previousEmployess = usePrevious(employees);

  const { register, handleSubmit, errors, control, setValue, getValues, setError } = useForm({
    mode: 'onChange',
    validationSchema: schema,
  });

  const defaultBirthday = data.birthday ? moment(data.birthday, 'DD/MM/yyyy') : null;
  const [birthdayForm, setBirthdayForm] = useState(defaultBirthday);

  // HANDLE SUCCESS
  useEffect(() => {
    if (previousEmployess && (previousEmployess.isSuccess !== employees.isSuccess)) {
      if (employees.isSuccess) {
        handleClose(true);
      }
    }
  }, [employees.isSuccess]);

  // HANDLE ERROR
  useEffect(() => {
    if (previousEmployess && (previousEmployess.error !== employees.error)) {
      if (employees.error) {
        dispatch(addSweetAlert({
          type: 'warning',
          title: 'No se pudo crear o Actualizar el Agente.',
          content: employees.errorData,
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }));
        handleClose(false);
      }
    }
  }, [employees.error]);

  const getUser = () => {
    const formValues = getValues({ nest: true });
    if (formValues.agentIdentification && !errors?.hasOwnProperty('agentIdentification')) {
      fetchUser({ url: `${process.env.REACT_APP_FIND_BY_IDENTIFICATION_URL}/${formValues.agentIdentification}` });
    }
  };

  useEffect(() => {
    if (!checkEmptyObject(userError)) {
      if (userError.response.status === 404) {
        setError('agentIdentification', 'notMatch', userError.response.data?.Message
          .concat('. Ingrese Manualmente.'));
        setReadOnly(false);
      } else {
        setReadOnly(true);
        setError('agentIdentification', 'notMatch', userError.response.data?.Detail || 'Error al obtener los datos');
      }
    }
  }, [userError, setError]);

  useEffect(() => {
    if (userData) {
      setValue([
        { givenName: userData.name },
        { surName: userData.lastName },
      ]);
      setBirthdayForm(userData.birthDate);
      setReadOnly(userData.isValidatedCivilRegistry);
    }
  }, [userData, setValue]);

  const onSubmit = (values) => {
    if (!checkEmptyObject(data)) {
      dispatch(updateEmployee(values));
    } else {
      dispatch(postEmployee(values));
    }
  };

  function onChangeIdentification(event) {
    setValue([
      { givenName: '' },
      { surName: '' },
    ]);
    setBirthdayForm(null);
    setReadOnly(true);
    return event.target?.value;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="customized-dialog-title"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={() => handleClose(false)}>
          {data.id ? 'Editar' : 'Agregar'} Usuario
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Controller
                as={TextField}
                name="id"
                control={control}
                type="hidden"
                defaultValue={data.id}
              />
              <Grid container item xs={12} sm={6} md={6} xl={6} lg={6}>
                <Controller
                  as={(<TextField />)}
                  margin="dense"
                  label="Cédula"
                  name="agentIdentification"
                  type="text"
                  fullWidth
                  control={control}
                  onChange={([event]) => onChangeIdentification(event)}
                  error={errors?.hasOwnProperty('agentIdentification')}
                  helperText={errors.agentIdentification?.message}
                  defaultValue={data.agentIdentification || ''}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Buscar" arrow>
                        <IconButton
                          aria-label="search"
                          size="small"
                          onClick={getUser}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                    inputProps: { maxLength: 10 },
                  }}
                />
              </Grid>
              <Grid container item alignItems="flex-end" xs={12} sm={6} md={6} xl={6} lg={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture
                    margin="dense"
                    label="Fecha de Nacimiento"
                    name="birthday"
                    fullWidth
                    inputRef={register}
                    autoOk
                    format="dd/MM/yyyy"
                    value={birthdayForm}
                    onChange={(value) => setBirthdayForm(value)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{ readOnly }}
                    readOnly={readOnly}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid container item xs={12} sm={6} md={6} xl={6} lg={6}>
                <Controller
                  as={(<TextField />)}
                  control={control}
                  autoFocus
                  margin="dense"
                  label="Nombres"
                  name="givenName"
                  type="text"
                  fullWidth
                  defaultValue={data.givenName || ''}
                  inputRef={register}
                  error={errors?.hasOwnProperty('givenName')}
                  helperText={errors.givenName?.message}
                  InputProps={{ readOnly }}
                />
              </Grid>
              <Grid container item xs={12} sm={6} md={6} xl={6} lg={6}>
                <Controller
                  as={(<TextField />)}
                  control={control}
                  margin="dense"
                  label="Apellidos"
                  name="surName"
                  type="text"
                  fullWidth
                  defaultValue={data.surName || ''}
                  inputRef={register}
                  error={errors?.hasOwnProperty('surName')}
                  helperText={errors.surName?.message}
                  InputProps={{ readOnly }}
                />
              </Grid>
              <Grid container item xs={12} sm={6} md={6} xl={6} lg={6}>
                <TextField
                  margin="dense"
                  label="Email"
                  name="email"
                  type="email"
                  fullWidth
                  defaultValue={data.email}
                  inputRef={register}
                  error={errors?.hasOwnProperty('email')}
                  helperText={errors.email?.message}
                />
              </Grid>
              <Grid container item xs={12} sm={6} md={6} xl={6} lg={6}>
                <FormControlLabel
                  control={(
                    <Switch
                      margin="dense"
                      defaultChecked={data.hasOwnProperty('accountEnabled') ? data.accountEnabled : true}
                      inputRef={register}
                      name="accountEnabled"
                      color="primary"
                    />
                  )}
                  label="Cuenta Habilitada"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Guardar
            </Button>
          </DialogActions>
        </form>
        {employees.loading && <LinearProgress color="primary" />}
      </Dialog>
    </div>
  );
};

NewUserScreen.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default NewUserScreen;
