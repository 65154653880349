/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, List, ListItem, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import PlaceToPay from '../../../../../../assets/images/payments/placetopay-logo.svg';
import PayPhone from '../../../../../../assets/images/payments/payPhone.png';
import { checkEmptyObject, renderCreditCardImage } from '../../../../../../utils/CommonUtils';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    padding: 0,
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(5),
  },
  itemContainer: {
    overflowX: 'auto',
  },
  item: {
    padding: 8,
  },
  active: {
    backgroundColor: '#ffe759',
  },
}));

const renderPaymentMethod = (payment) => ({
  PLACETOPAY: PlaceToPay,
  PAYPHONE: PayPhone,
})[payment];

export default function CardListGrid({ cards = [], setSelectedCard, selectedCard }) {
  const classes = useStyles();

  const handleListItemClick = (card) => {
    setSelectedCard(card);
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12} className={classes.itemContainer}>
        <List className={classes.listContainer}>
          {cards.map((card) => (
            <ListItem
              key={card.id}
              button
              disableGutters
              selected={selectedCard?.id === card?.id}
              onClick={() => handleListItemClick(card)}
              className={classes.item}
            >
              <Tooltip title={card.name} aria-label={card.name}>
                <Avatar
                  className={classes.avatar}
                  alt="card"
                  src={renderCreditCardImage(card.name.replace(' ', '')
                    || renderCreditCardImage('VISA'))}
                  variant="square"
                />
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12}>
        {!checkEmptyObject(selectedCard) && (
          <img
            width="223"
            height="62"
            src={renderPaymentMethod(selectedCard?.provider)}
            alt="payment button"
          />
        )}
      </Grid>
    </Grid>
  );
}

CardListGrid.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setSelectedCard: PropTypes.func.isRequired,
  selectedCard: PropTypes.object.isRequired,
};
