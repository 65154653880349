// eslint-disable-next-line max-classes-per-file
export const PaymentType = Object.freeze(
  {
    Credito: 2,
    Contado: 3,
    DebitoBancario: 1,
    DiferidoEspecial: 4,
  },
);

export const PaymentMethod = Object.freeze(
  {
    Corriente: 'Corriente',
    Diferido: 'Diferido',
    Recurrente: 'Recurrente',
  },
);

export const PaymentResult = Object.freeze(
  {
    SUCCESS: 1,

    PENDING: 2,

    FAILURE: 3,
  },
);

export const ResetApplicationForm = Object.freeze(
  {
    InfinityFields: 'InfinityFields',
    HolderFields: 'HolderFields',
    DependantFields: 'DependantFields',
    AllFields: 'AllFields',
  },
);

export const RolEnum = Object.freeze(
  {
    Broker: 'broker',
    Vendedor: 'vendedor',
    ReporterUnified: 'reporterunified',
    Admin: 'administrator',
  },
);
