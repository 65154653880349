import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { Grid, IconButton, Typography } from '@material-ui/core';
import logo from '../../../../../assets/images/logo.png';
import modalStyles from './style/modal-style';

const HeaderTitleModal = withStyles(modalStyles)((props) => {
  const { children: title, classes, onClose } = props;
  return (
    <div>
      <Grid container direction="column">
        <Grid item className={classes.dialogHeader} md={12} xs={12}>
          <Grid container className={classes.dialogHeader} direction="row">
            <Grid item md={2} xs={2}>
              <img
                alt="Logo"
                src={logo}
                className={classes.imageHeader}
              />
            </Grid>
            <Grid item md={9} xs={8}>
              <Typography className={classes.titleText}>{title}</Typography>
            </Grid>
            <Grid className={classes.closeButtonContainer} item md={1} xs={2}>
              {onClose ? (
                <IconButton size="small" className={classes.iconClose} aria-label="close" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.titleHeader} direction="row">
          <Grid item md={11} xs={10}>
                        &nbsp;
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

export default HeaderTitleModal;
