import React, { memo } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/core/SvgIcon/SvgIcon';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '85%',
    flexShrink: 0,
    color: theme.palette.white,
  },
  headingTable2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '55%',
    flexShrink: 0,
    color: theme.palette.white,
  },
  secondaryHeadingTable2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '15%',
    color: theme.palette.white,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.white,
  },
  expansionPanel: {
    borderRadius: '15px',
    borderTopLeftRadius: '15px !important',
    borderTopRightRadius: '15px !important',
    borderBottomLeftRadius: '15px !important',
    borderBottomRightRadius: '15px !important',
    margin: '1.5px 0 0 0'
  },
  expansionPanelSummary: {
    backgroundColor: '#518CBF',
    borderRadius: '15px',
    height: '40px',
  },
  expansionPanelDetails: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
  iconColor: {
    color: 'white',
  },
  horizontalList: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
}));

function Benefits({ benefitsGrouped = [] }) {
  const classes = useStyles();
  return (
    <Grid item xs={12} style={{ paddingTop: '1px', paddingBottom: '0px' }}>
      <div className={classes.root}>
        {benefitsGrouped?.map((benefit) => (
          <div> {benefit.benefits[0] && benefit.benefits[0].tipoTabla === '1' && <ExpansionPanel className={classes.expansionPanel} key={benefit.title}>
            <ExpansionPanelSummary
              className={classes.expansionPanelSummary}
              expandIcon={<ExpandMoreIcon className={classes.iconColor} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                {benefit.title.toUpperCase()}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Coberturas
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
               <div style={{ flex: 1, maxWidth: '100%' }}>
                 <MaterialTable
                    columns={[
                      {
                        title: 'Column1',
                        field: 'description',
                        cellStyle: {
                          width: '80%',
                        },
                      },
                      {
                        title: 'Column2',
                        field: 'value',
                        cellStyle: {
                          width: '20%',
                        },
                      },
                    ]}
                    data={benefit.benefits}
                    options={{
                      paging: false,
                      search: false,
                      sorting: false,
                      draggable: false,
                      showTitle: false,
                      toolbar: false,
                      header: false,
                      rowStyle: (x) => {
                        if (x.tableData.id % 2) {
                          return { backgroundColor: '#c4d9f2', color: '#518CBF' };
                        }
                        return { color: '#518CBF' };
                      },
                    }}
                    components={{
                      Container: (props) => (
                          <Paper
                              {...props}
                              elevation={0}
                          />
                      ),
                    }}
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>}  {benefit.benefits[0] && benefit.benefits[0].tipoTabla === '2' && <ExpansionPanel className={classes.expansionPanel} key={benefit.title}>
            <ExpansionPanelSummary
                className={classes.expansionPanelSummary}
                expandIcon={<ExpandMoreIcon className={classes.iconColor} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
              <Typography className={classes.headingTable2}>
                {benefit.title.toUpperCase()}
              </Typography>
              <Typography className={classes.secondaryHeadingTable2}>
                Copago
              </Typography>
              <Typography className={classes.secondaryHeadingTable2}>
                Monto Coberturas
              </Typography>
              <Typography className={classes.secondaryHeadingTable2}>
                Carencia
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              <div style={{ flex: 1, maxWidth: '100%' }}>
                <MaterialTable
                    columns={[
                      {
                        title: 'Column1',
                        field: 'description',
                        cellStyle: {
                          width: '55%',
                        },
                      },
                      {
                        title: 'Column2',
                        field: 'copago',
                        cellStyle: {
                          width: '15%',
                        },
                      },
                      {
                        title: 'Column3',
                        field: 'sublimite',
                        cellStyle: {
                          width: '15%',
                        },
                      },
                      {
                        title: 'Column4',
                        field: 'carencia',
                        cellStyle: {
                          width: '15%',
                        },
                      },
                    ]}
                    data={benefit.benefits}
                    options={{
                      paging: false,
                      search: false,
                      sorting: false,
                      draggable: false,
                      showTitle: false,
                      toolbar: false,
                      header: false,
                      rowStyle: (x) => {
                        if (x.tableData.id % 2) {
                          return { backgroundColor: '#c4d9f2', color: '#518CBF' };
                        }
                        return { color: '#518CBF' };
                      },
                    }}
                    components={{
                      Container: (props) => (
                          <Paper
                              {...props}
                              elevation={0}
                          />
                      ),
                    }}
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>}
          </div>
        ))}
      </div>
    </Grid>
  );
}

Benefits.propTypes = {
  benefitsGrouped: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      benefits: PropTypes.array.isRequired,
    }),
  ).isRequired,
};

export default memo(Benefits);
