import { CLOSE_SNACKBAR, SHOW_SNACKBAR } from '../actions/snackbar';

export const INITIAL_STATE = {
  message: '',
  open: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        message: action.payload.message,
        open: true,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};
