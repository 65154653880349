/* eslint-disable import/prefer-default-export */
import { put, takeLatest } from 'redux-saga/effects';
import api from '../../../../../utils/Api';
import { getSessionSuccess, getSessionFailed, getSessionStart } from '../slices/payment_button';

export function* watchCallPaymentButton() {
  yield takeLatest(getSessionStart.type, requestPaymentButtonSaga);
}

function* requestPaymentButtonSaga({ payload: values }) {
  try {
    const body = {
      quotationPaymentMethodId: values.quotationPaymentMethodId,
      paymentConditionId: values.paymentConditionId,
      saleProcessId: values.saleProcessId,
      redirectUrl: buildRediectUrl(),
    };
    let targetUrl = process.env.REACT_APP_PAYMENT_SESSION;
    if (values.action === 'ACTION_TOKEN_UPDATE') {
      targetUrl = process.env.REACT_APP_PAYMENT_SESSION_UPDATE_TOKEN;
    }
    const { data } = yield api.post(targetUrl, body);
    yield put(getSessionSuccess(data));
  } catch (e) {
    yield put(getSessionFailed(e?.message));
  }
}

function buildRediectUrl() {
  const { href } = window.location;
  const firstSlash = href.lastIndexOf('/');
  return `${href.substr(0, firstSlash)}/payment/process`;
}
