import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../theme/palette';

const quotationStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  shiftContent: {
    paddingLeft: 318,
  },
  content: {
    height: '100%',
  },
  grayText: {
    color: palette.black,
    'font-size': '10px',
    padding: '18px !important',
  },
}));

export default quotationStyles;
