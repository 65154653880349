/* eslint-disable object-curly-newline,no-nested-ternary,no-underscore-dangle */
import React, { useEffect, memo, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl, FormHelperText, IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField, Tooltip,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CustomPanel from '../../../../common/CustomPanel';
import MedicalConditions from './MedicalConditions';
import { requestDependentIdentification } from '../actions/dependents_identification';
import { isValidId } from '../../../../../utils/id-validator';
import {
  selectGender,
  selectCatalog,
  selectRelationship,
  selectTypeIdentification, selectDivisionPolitica,
} from '../utils/selectors';
import * as Constants from '../../../../../utils/Constants';
import { addSweetAlert, closeSweetAlert } from '../../../../common/SweetAlert/actions/sweet_alert';
import {
  isEmpty,
  resetFormApplicationValues,
  shouldBlockFields,
  usePrevious,
} from '../../../../../utils/CommonUtils';
import { PICKER_FORMAT_DATE } from '../../../../../utils/Constants';
import Covid19Questions from './Covid19Questions';
import { ResetApplicationForm } from '../../Payment/Enums';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  radioGroup: {
    marginTop: 10,
  },
}));

function Dependents({ dependants = [], isEnabledCovidParam, editionMode }) {
  const getApplication = useSelector((state) => state.getApplication);
  const prevGetApplication = usePrevious(getApplication);
  const classes = useStyles();
  const dependentsIdentification = useSelector((state) => state.dependentsIdentification);
  const roleSelector = useSelector(selectCatalog('Roles'));
  const typeIdentificationSelector = useSelector(selectTypeIdentification);
  const gens = useSelector(selectGender);
  const relationshipSelector = useSelector(selectRelationship);
  const dispatch = useDispatch();
  const actualIndex = useRef(-1);
  const [dependantsValues, setDependantsValues] = React.useState([]);
  const countries = useSelector(selectDivisionPolitica);
  const [enableCovidMedicalCondition, setEnableCovidMedicalCondition] = React.useState([]);
  const [isDisableInputs, setIsDisableInputs] = React.useState([]);

  const formMethods = useFormContext();
  const { fields, append } = useFieldArray(
    {
      control: formMethods.control,
      name: 'insuranceDependants',
    },
  );

  // Update array
  const hasCovid = (areAnswersRight, itemIndex) => {
    setEnableCovidMedicalCondition(
      enableCovidMedicalCondition.map((item, index) => (index === itemIndex
        ? { ...item, hasCovidEnabled: areAnswersRight }
        : item)),
    );
  };

  // Create empty array
  useEffect(() => {
    // Default Initializer
    if (dependants.length > 0) {
      dependants.map((item) => {
        setEnableCovidMedicalCondition((prevArray) => [...prevArray, { hasCovidEnabled: false }]);
      });
    }
  }, [dependants]);

  useEffect(() => {
    if (prevGetApplication && (prevGetApplication.response !== getApplication.response)) {
      if (!isEmpty(getApplication.response)) {
        setDependantsValues(getApplication.response.InsuranceApplicationDependants);
      }
    }
  }, [getApplication.response]);

  useEffect(() => {
    if (dependantsValues?.length > 0) {
      setValuesOnDependantsForm(dependantsValues);
      // Initializer on EditMode
      setEnableCovidMedicalCondition(
        enableCovidMedicalCondition
          .map((item, index) => (dependantsValues[index]?.QuestionCovid19ResultPositive
            ? { ...item, hasCovidEnabled: dependantsValues[index]?.QuestionCovid19ResultPositive }
            : item)),
      );
    }
  }, [dependantsValues]);

  const setValuesOnDependantsForm = (insuranceApplicationDependants) => {
    insuranceApplicationDependants.forEach((item, index) => {
      formMethods.setValue([
        { [`insuranceDependants[${index}].identificationType`]: item.Person.IdentificationType.trim() || '' },
        { [`insuranceDependants[${index}].identification`]: item.Person.Identification.trim() },
        { [`insuranceDependants[${index}].name`]: item.Person.Name.trim() || '' },
        { [`insuranceDependants[${index}].lastName`]: item.Person.LastName.trim() || '' },
        { [`insuranceDependants[${index}].gender`]: item.Person.Gender.trim() || '' },
        { [`insuranceDependants[${index}].birthDate`]: item.Person.BirthDate.trim() || '' },
        { [`insuranceDependants[${index}].birthPlace`]: item.Person.BirthPlace.trim() || '' },
        { [`insuranceDependants[${index}].nacionality`]: item.Person.Nacionality },
        { [`insuranceDependants[${index}].questionCovid19TestPerformed`]: item?.QuestionCovid19TestPerformed },
        { [`insuranceDependants[${index}].height`]: item?.Height },
        { [`insuranceDependants[${index}].weight`]: item?.Weight },
      ]);
    });
  };

  useEffect(() => {
    const fieldsArray = {
      identificationType: '',
      relationshipBeneficiary: '',
      medicalConditions: {},
      identification: '',
      name: '',
      birthDate: null,
      birthPlace: '',
      gender: '',
      height: '',
      weight: ''
    };
    append(Array.from({ length: dependants.length }, () => fieldsArray));
    setIsDisableInputs(Array.from({ length: dependants.length }, () => ({ isDisable: true })));
  }, [append, dependants.length]);


  useEffect(() => {
    if (editionMode) {
      setIsDisableInputs(dependantsValues.map((_, index) => ({ isDisable:
        dependantsValues[index].Person.IsValidatedCivilRegistry })));
    }
  }, [editionMode, dependantsValues]);

  function onChangeIsDisableInputs(dependents) {
    setIsDisableInputs(isDisableInputs.map((item, index) => actualIndex.current === index
      ? { isDisable:
          shouldBlockFields(dependents[actualIndex.current].response) }
      : item));
  }

  useEffect(() => {
    const { dependents } = dependentsIdentification;
    if (actualIndex.current >= 0) {
      onChangeIsDisableInputs(dependents);
      if (dependents[actualIndex.current].found) {
        formMethods.clearError([
          `insuranceDependants[${actualIndex.current}].identification`,
          `insuranceDependants[${actualIndex.current}].name`,
          `insuranceDependants[${actualIndex.current}].lastName`,
          `insuranceDependants[${actualIndex.current}].birthDate`,
          `insuranceDependants[${actualIndex.current}].gender`,
          `insuranceDependants[${actualIndex.current}].birthPlace`,
          `insuranceDependants[${actualIndex.current}].nacionality`,
          `insuranceDependants[${actualIndex.current}].height`,
          `insuranceDependants[${actualIndex.current}].weight`,
        ]);
        formMethods.setValue([
          { [`insuranceDependants[${actualIndex.current}].name`]: dependents[actualIndex.current].response?.name || '' },
          { [`insuranceDependants[${actualIndex.current}].lastName`]: dependents[actualIndex.current].response?.lastName || '' },
          { [`insuranceDependants[${actualIndex.current}].birthDate`]: dependents[actualIndex.current].response?.birthDate || null },
          { [`insuranceDependants[${actualIndex.current}].gender`]: dependents[actualIndex.current].response?.gender || '' },
          { [`insuranceDependants[${actualIndex.current}].birthPlace`]: dependents[actualIndex.current].response?.birthPlace || '' },
          { [`insuranceDependants[${actualIndex.current}].nacionality`]: dependents[actualIndex.current].response?.nacionality },
          { [`insuranceDependants[${actualIndex.current}].height`]: dependents[actualIndex.current].response?.height },
          { [`insuranceDependants[${actualIndex.current}].weight`]: dependents[actualIndex.current].response?.weight },
        ]);
      } else if (dependents[actualIndex.current]?.isError) {
        if (dependents[actualIndex.current].response?.Message) {
          dispatch(addSweetAlert({
            type: 'info',
            title: dependents[actualIndex.current].response?.Message,
            content: dependents[actualIndex.current].response?.Detail,
            showCancel: false,
            onConfirmLabel: 'Continuar',
            onConfirm: () => {
              dispatch(closeSweetAlert());
            },
          }));
        }
        formMethods.setValue([
          { [`insuranceDependants[${actualIndex.current}].name`]: '' },
          { [`insuranceDependants[${actualIndex.current}].lastName`]: '' },
          { [`insuranceDependants[${actualIndex.current}].birthDate`]: null },
          { [`insuranceDependants[${actualIndex.current}].gender`]: '' },
          { [`insuranceDependants[${actualIndex.current}].nacionality`]: '' },
          { [`insuranceDependants[${actualIndex.current}].height`]: '' },
          { [`insuranceDependants[${actualIndex.current}].weight`]: '' },
        ]);
      }
    }
  }, [dependentsIdentification]);

  const handleSearchIdentification = (index) => {
    const formValues = formMethods.getValues({ nest: true });
    const identification = formValues?.insuranceDependants[index]?.identification;
    if (identification) {
      if (isValidId(identification)) {
        actualIndex.current = index;
        dispatch(requestDependentIdentification(identification, index));
      } else {
        dispatch(addSweetAlert({
          type: 'warning',
          title: Constants.MESSAGE_INVALID_IDENTIFICATION,
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }));
      }
    }
  };

  const checkErrorProperty = (index, property) => {
    if (formMethods.errors?.insuranceDependants
      && formMethods.errors?.insuranceDependants[index]) {
      return formMethods.errors.insuranceDependants[index]?.hasOwnProperty(`${property}`);
    }
    return false;
  };

  const getMessage = (index, property) => {
    if (formMethods.errors?.insuranceDependants
      && formMethods.errors?.insuranceDependants[index]) {
      return formMethods.errors?.insuranceDependants[index][property]?.message;
    }
    return '';
  };

  const findRolName = (index) => {
    const currentRol = roleSelector?.find((rol) => rol.id === dependants[index]?.rol);
    return currentRol?.description;
  };

  const getRoles = (isOther) => {
    if (isOther) {
      return relationshipSelector;
    }
    return roleSelector;
  };

  // set values.
  useEffect(() => {
    if (relationshipSelector && roleSelector) {
      if (fields?.length > 0) {
        fields.map((item, index) => formMethods.setValue(
          `insuranceDependants[${index}].relationshipBeneficiary`,
          dependants[index]?.rol === '3' ? '' : dependants[index].rol,
        ));
      }
    }
    if (typeIdentificationSelector) {
      fields.map((item, index) => formMethods.setValue(
        `insuranceDependants[${index}].identificationType`,
        Constants.DEFAULT_VALUE_TYPE_IDENTIFICATION,
      ));
    }
  }, [dependants, fields, relationshipSelector,
    roleSelector, formMethods.setValue, typeIdentificationSelector]);

  const onChangeIdentification = (event, position) => {
    resetFormApplicationValues(formMethods.setValue, formMethods.clearError,
      ResetApplicationForm.DependantFields, position);
    return event.target?.value;
  };

  return (
    <>
      {fields?.map((field, index) => {
        const relationship = getRoles(dependants[index]?.rol === '3');
        return (
          <CustomPanel key={field.id} withoutTitle subtitle={findRolName(index)}>
            <Grid container direction="row" spacing={3}>
              <Grid item md={4} xs={12}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  error={checkErrorProperty(index, 'relationshipBeneficiary')}
                >
                  <InputLabel id="id-relationship">RELACIÓN CON TITULAR</InputLabel>
                  <Controller
                    as={(
                      <Select>
                        <MenuItem value="">
                          <em>Seleccione..</em>
                        </MenuItem>
                        {
                          relationship?.map((relation) => (
                            <MenuItem
                              key={relation.id}
                              value={relation.id}
                            >
                              {relation.description}

                            </MenuItem>
                          ))
                        }
                      </Select>
                    )}
                    inputProps={{ readOnly: dependants[index]?.rol !== '3' }}
                    name={`insuranceDependants[${index}].relationshipBeneficiary`}
                    control={formMethods.control}
                    defaultValue=""
                  />
                  <FormHelperText>{getMessage(index, 'relationshipBeneficiary')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  error={checkErrorProperty(index, 'identificationType')}
                >
                  <InputLabel id="id-type-identification">TIPO DE IDENTIFICACIÓN</InputLabel>
                  <Controller
                    as={(
                      <Select>
                        <MenuItem value="">
                          <em>Seleccione...</em>
                        </MenuItem>
                        {
                          typeIdentificationSelector?.map((rol) => (
                            <MenuItem
                              key={rol.id}
                              value={rol.id}
                            >
                              {rol.description}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    )}
                    name={`insuranceDependants[${index}].identificationType`}
                    control={formMethods.control}
                    readOnly={editionMode}
                    defaultValue=""
                  />
                  <FormHelperText>{getMessage(index, 'identificationType')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <Controller
                  as={(
                    <TextField />
                  )}
                  fullWidth
                  className={classes.formControl}
                  name={`insuranceDependants[${index}].identification`}
                  onChange={([event]) => onChangeIdentification(event, index)}
                  label="IDENTIFICACIÓN"
                  autoComplete="off"
                  error={checkErrorProperty(index, 'identification')}
                  helperText={getMessage(index, 'identification')}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Buscar" arrow>
                        <IconButton
                          aria-label="search"
                          size="small"
                          onClick={() => handleSearchIdentification(index)}
                          disabled={editionMode}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                    readOnly: editionMode,
                  }}
                  defaultValue={fields[index].identification}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={3}>
              <Grid item md={6} xs={12}>
                <Controller
                  as={(
                    <TextField />
                  )}
                  fullWidth
                  className={classes.formControl}
                  name={`insuranceDependants[${index}].name`}
                  label="NOMBRES"
                  autoComplete="off"
                  control={formMethods.control}
                  InputProps={{ readOnly: isDisableInputs[index].isDisable }}
                  error={checkErrorProperty(index, 'name')}
                  helperText={getMessage(index, 'name')}
                  defaultValue={fields[index].name}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  as={(
                    <TextField />
                  )}
                  fullWidth
                  className={classes.formControl}
                  name={`insuranceDependants[${index}].lastName`}
                  label="APELLIDOS"
                  autoComplete="off"
                  control={formMethods.control}
                  InputProps={{ readOnly: isDisableInputs[index].isDisable }}
                  error={checkErrorProperty(index, 'lastName')}
                  helperText={getMessage(index, 'lastName')}
                  defaultValue={fields[index].name}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={3}>
            <Grid item md={6} xs={12}>
                <Controller
                  as={(
                    <TextField type="number"/>
                  )}
                  fullWidth
                  className={classes.formControl}
                  name={`insuranceDependants[${index}].height`}
                  label="ALTURA mtr"
                  autoComplete="off"
                  control={formMethods.control}       
                  type="number"
                  inputProps={{ min: '0', step: '0.01', max: '3' }}        
                  error={checkErrorProperty(index, 'height')}
                  helperText={getMessage(index, 'height')}
                  defaultValue={fields[index].height}
                  placeholder="1.82"
                />
            </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  as={(
                    <TextField type="number"/>
                  )}
                  fullWidth
                  className={classes.formControl}
                  name={`insuranceDependants[${index}].weight`}
                  label="PESO Kg"
                  autoComplete="off"
                  control={formMethods.control}   
                  type="number"
                  inputProps={{ min: '0', step: '1', max: '200' }}              
                  error={checkErrorProperty(index, 'weight')}
                  helperText={getMessage(index, 'weight')}
                  defaultValue={fields[index].weight}
                  placeholder="65"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={3}>
              <Grid item md={6} xs={12}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  error={checkErrorProperty(index, 'gender')}
                >
                  <InputLabel id="id-type-identification">SEXO</InputLabel>
                  <Controller
                    as={(
                      <Select>
                        {
                          gens?.map((gen) => (
                            <MenuItem
                              key={gen.id}
                              value={gen.id}
                            >
                              {gen.description}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    )}
                    fullWidth
                    label="SEXO"
                    name={`insuranceDependants[${index}].gender`}
                    control={formMethods.control}
                    inputProps={{ readOnly: isDisableInputs[index].isDisable }}
                    defaultValue=""
                  />
                  <FormHelperText>{getMessage(index, 'gender')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    as={(
                      <KeyboardDatePicker
                        readOnly={isDisableInputs[index].isDisable}
                        disableFuture
                      />
                    )}
                    autoOk
                    name={`insuranceDependants[${index}].birthDate`}
                    className={classes.formControl}
                    fullWidth
                    disableToolbar={false}
                    variant="inline"
                    format={PICKER_FORMAT_DATE}
                    margin="normal"
                    label="FECHA NACIMIENTO"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    control={formMethods.control}
                    InputProps={{ readOnly: isDisableInputs[index].isDisable }}
                    error={checkErrorProperty(index, 'birthDate')}
                    helperText={getMessage(index, 'birthDate')}
                    defaultValue={fields[index].birthDate}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              
            </Grid>
            <Grid container direction="row" spacing={3}>
            <Grid item md={6} xs={12}>
                {
                  (
                      <FormControl
                        className={classes.formControl}
                        fullWidth
                        error={checkErrorProperty(index, 'nacionality')}
                      >
                        <InputLabel id="country-select">NACIONALIDAD</InputLabel>
                        <Controller
                          as={(
                            <Select labelId="country-select">
                              <MenuItem value="">
                                <em>Seleccione...</em>
                              </MenuItem>
                              {countries?.map((b) => (
                                b.id !== '0' && (
                                  <MenuItem
                                    key={b.id}
                                    value={b.description}
                                  >
                                    {b.description}
                                  </MenuItem>
                                )
                              ))}
                            </Select>
                          )}
                          name={`insuranceDependants[${index}].nacionality`}
                          control={formMethods.control}
                          defaultValue={Constants.DEFAULT_VALUE_NACIONALITY}
                        />
                        <FormHelperText>{getMessage(index, 'nacionality')}</FormHelperText>
                      </FormControl>
                    )
                }
              </Grid>
            <Grid item md={6} xs={12}>
                {
                  dependentsIdentification.dependents[index].found
                    ? (
                      <Controller
                        as={(
                          <TextField />
                        )}
                        fullWidth
                        className={classes.formControl}
                        name={`insuranceDependants[${index}].birthPlace`}
                        label="LUGAR NACIMIENTO"
                        autoComplete="off"
                        control={formMethods.control}
                        error={checkErrorProperty(index, 'birthPlace')}
                        helperText={getMessage(index, 'birthPlace')}
                        defaultValue={fields[index].birthPlace}
                      />
                    )
                    : (
                      <FormControl
                        className={classes.formControl}
                        fullWidth
                        error={checkErrorProperty(index, 'nationality')}
                      >
                        <InputLabel id="province-select">LUGAR NACIMIENTO</InputLabel>
                        <Controller
                          as={(
                            <Select labelId="province-select">
                              <MenuItem value="">
                                <em>Seleccione...</em>
                              </MenuItem>
                              {countries?.map((b) => (
                                b.id !== '0' && (
                                  <MenuItem
                                    key={b.id}
                                    value={b.description}
                                  >
                                    {b.description}
                                  </MenuItem>
                                )
                              ))}
                            </Select>
                          )}
                          name={`insuranceDependants[${index}].nationality`}
                          control={formMethods.control}
                          defaultValue="Ecuador"
                        />
                        <FormHelperText>{getMessage(index, 'nationality')}</FormHelperText>
                      </FormControl>
                    )
                }
              </Grid>
            </Grid> 
            <Grid container direction="row" spacing={3}>
              <Grid item xs={12}>
                <Typography className={classes.formControl}>
                  Condiciones Médicas
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={3}>
              {isEnabledCovidParam && (
                <Covid19Questions
                  fieldName={`insuranceDependants[${index}]`}
                  hasCovid={hasCovid}
                  dependantIndex={index}
                  hasResultPositive={dependantsValues[index]?.QuestionCovid19ResultPositive}
                />
              )}
            </Grid>
            <Grid container direction="row" spacing={3}>
              <MedicalConditions
                fieldArrayName={`insuranceDependants[${index}].medicalConditions`}
                medicalConditionsValues={dependantsValues?.length > 0
                  ? dependantsValues[index].InsuranceDependantMedicalConditions
                  : []}
                hasCovid={enableCovidMedicalCondition[index].hasCovidEnabled}
              />
            </Grid>
          </CustomPanel>
        );
      })}
    </>
  );
}

Dependents.propTypes = {
  dependants: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEnabledCovidParam: PropTypes.bool.isRequired,
  editionMode: PropTypes.bool.isRequired,
};

export default memo(Dependents);
