import { makeStyles } from '@material-ui/core/styles';

const reallocateEmissionsStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  statusCell: {
    width: '80px',
    height: '14px',
    borderRadius: '6px',
    backgroundColor: '#abc123',
  },
  select: {
    margin: theme.spacing(1),
    minWidth: '95%',
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default reallocateEmissionsStyles;
