export const START_REQUEST_REALLOCATE_EMISSIONS = 'START_REQUEST_REALLOCATE_EMISSIONS';
export const REQUEST_REALLOCATE_EMISSIONS_SUCCESS = 'REQUEST_REALLOCATE_EMISSIONS_SUCCESS';
export const REQUEST_REALLOCATE_EMISSIONS_FAILED = 'REQUEST_REALLOCATE_EMISSIONS_FAILED';


export const requestReallocateEmissions = (agent, status, creationFrom, creationTo) => ({
  type: START_REQUEST_REALLOCATE_EMISSIONS,
  payload: {
    agent,
    status,
    creationFrom,
    creationTo,
  },
});

export const successRequestReallocateEmissions = (emissions) => ({
  type: REQUEST_REALLOCATE_EMISSIONS_SUCCESS,
  payload: emissions,
});

export const failedRequestReallocateEmissions = (error) => ({
  type: REQUEST_REALLOCATE_EMISSIONS_FAILED,
  payload: error,
});
