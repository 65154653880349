import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loading: false,
  response: {},
  error: null,
  isSuccess: false,
};

const policySlice = createSlice({
  name: 'policy',
  initialState: INITIAL_STATE,
  reducers: {
    startCreatePolicy(state) {
      state.loading = true;
      state.isSuccess = false;
    },
    successPolicy(state, action) {
      state.loading = false;
      state.response = action.payload;
      state.error = null;
      state.isSuccess = true;
    },
    failedPolicy(state, action) {
      state.loading = false;
      state.response = {};
      state.error = action.payload;
      state.isSuccess = false;
    },
    resetPolicy(state, action) {
      state.loading = false;
      state.response = {};
      state.error = null;
      state.isSuccess = false;
    },
  },
});

export const { startCreatePolicy, successPolicy, failedPolicy, resetPolicy } = policySlice.actions;
export default policySlice.reducer;
