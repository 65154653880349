export const REQUEST_QUOTE = 'REQUEST_QUOTE';
export const SUCCESS_QUOTE = 'SUCCESS_QUOTE';
export const FAILURE_QUOTE = 'FAILURE_QUOTE';
export const RESET_QUOTE = 'RESET_QUOTE';
export const SELECT_DEDUCTIBLE = 'SELECT_DEDUCTIBLE';
export const RESET_REQUEST_DATA = 'RESET_REQUEST_DATA';
export const RESET_WITH_RE_QUOTE = 'RESET_WITH_RE_QUOTE';

export const requestQuote = (data) => ({
  type: REQUEST_QUOTE,
  payload: data,
});

export const successQuote = (response, requestData) => ({
  type: SUCCESS_QUOTE,
  payload: response,
  payloadRequest: requestData,
});

export const failureQuote = (error) => ({
  type: FAILURE_QUOTE,
  payload: error,
});

export const selectDeductible = (deductible, paymentType) => ({
  type: SELECT_DEDUCTIBLE,
  payload: {
    deductible,
    paymentType,
  },
});

export const resetRequestData = (data) => ({
  type: RESET_REQUEST_DATA,
  payload: data,
});

export const resetWithReQuote = (reQuote, deductibleSelected) => ({
  type: RESET_WITH_RE_QUOTE,
  payload: reQuote,
  deductibleSelected,
});

export const resetQuote = () => ({
  type: RESET_QUOTE,
});
