export const ADD_SWEET_ALERT = 'ADD_SWEET_ALERT';
export const SUCCESS_ADD_SWEET_ALERT = 'SUCCESS_ADD_SWEET_ALERT';
export const CLOSE_SWEET_ALERT = 'CLOSE_SWEET_ALERT';

export const addSweetAlert = (alert) => (
  {
    type: ADD_SWEET_ALERT,
    payload: alert,
  }
);

export const successAddSweetAlert = (alert) => (
  {
    type: SUCCESS_ADD_SWEET_ALERT,
    payload: alert,
  }
);

export const closeSweetAlert = () => ({
  type: CLOSE_SWEET_ALERT,
});
