export const START_REQUEST_EMPLOYEES = 'START_REQUEST_EMPLOYEES';
export const REQUEST_EMPLOYEES_SUCCESS = 'REQUEST_EMPLOYEES_SUCCESS';
export const REQUEST_EMPLOYEES_FAILED = 'REQUEST_EMPLOYEES_FAILED';

export const START_POST_EMPLOYEE = 'START_POST_EMPLOYEE';
export const POST_EMPLOYEE_SUCCESS = 'POST_EMPLOYEE_SUCCESS';
export const POST_EMPLOYEE_FAILED = 'POST_EMPLOYEE_FAILED';

export const START_UPDATE_EMPLOYEE = 'START_UPDATE_EMPLOYEE';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAILED = 'UPDATE_EMPLOYEE_FAILED';

/* Getting all users */
export const requestEmployees = () => ({
  type: START_REQUEST_EMPLOYEES,
});

export const successRequestEmployees = (employees) => ({
  type: REQUEST_EMPLOYEES_SUCCESS,
  payload: employees,
});

export const failedRequestEmployees = (error) => ({
  type: REQUEST_EMPLOYEES_FAILED,
  payload: error,
});

/* Posting user */
export const postEmployee = (values) => ({
  type: START_POST_EMPLOYEE,
  payload: {
    values,
  },
});

export const successPostEmployee = (response) => ({
  type: POST_EMPLOYEE_SUCCESS,
  payload: response,
});

export const failedPostEmployee = (error) => ({
  type: POST_EMPLOYEE_FAILED,
  payload: error,
});

/* Updating user */
export const updateEmployee = (values) => ({
  type: START_UPDATE_EMPLOYEE,
  payload: values,
});

export const successUpdateEmployee = (response) => ({
  type: UPDATE_EMPLOYEE_SUCCESS,
  payload: response,
});

export const failedUpdateEmployee = (error) => ({
  type: UPDATE_EMPLOYEE_FAILED,
  payload: error,
});
