import React from 'react';
import ImageUploading from 'react-images-uploading';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const maxMbFileSize = 3 * 1024 * 1024; // 3Mb

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 10,
  },
  imageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  imageItem: {
    display: 'flex',
    margin: '10px 0',
  },
  errorText: {
    color: '#e53935',
    fontSize: 11,
  },
}));

export default function UploadImage({ onChangeImage, onRemoveImage }) {
  const classes = useStyles();

  const onChange = (imageList) => {
    onChangeImage(imageList[0]);
  };

  return (
    <ImageUploading
      onChange={onChange}
      maxFileSize={maxMbFileSize}
      acceptType={['jpg', 'png', 'JPG', 'PNG']}
    >
      {({ imageList, onImageUpload, errors }) => (
        <div className={classes.container}>
          <Button size="small" onClick={onImageUpload} variant="outlined" color="primary">
            { imageList.length > 0
              ? (<Typography>Cambiar Imagen</Typography>)
              : (<Typography>Subir Imagen</Typography>) }
          </Button>
          {imageList.map((image) => (
            <div key={image.key} className={classes.imageItem}>
              <img src={image.dataURL} alt="Preview" width="100" />
            </div>
          ))}
          {(Object.values(errors).includes(true)
                        && imageList[0]) && onRemoveImage(imageList[0].onRemove)}
          {errors.acceptType && (
            <p className={classes.errorText}>
              EL tipo de archivo seleccionado no es correcto
            </p>
          )}
          {errors.maxFileSize && (
            <p className={classes.errorText}>
              EL tamaño máximo de archivos a subir es de 3MB
            </p>
          )}
        </div>
      )}
    </ImageUploading>
  );
}

UploadImage.propTypes = {
  onChangeImage: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
};
