import palette from '../../../../../../theme/palette';

const modalStyles = (theme) => ({
  root: {
    margin: 0,
    padding: '0px 0px 0px 6px',
    'background-color': palette.primary.main,
    color: palette.primary.contrastText,
    height: '55px',
    lineHeight: '55px',
  },
  darkline: {
    height: '40px',
    'background-color': palette.primary.dark,
    'border-top-width': '0px',
    'border-top-style': 'solid',
    'border-bottom-width': '0px',
    'border-bottom-style': 'solid',
    'border-right-width': '0px',
    'border-right-style': 'solid',
    'border-left-width': '0px',
    'border-left-style': 'solid',
    '-moz-border-radius': '4px 4px 0 0',
    '-webkit-border-radius': '4px 4px 0 0',
    'border-radius': '4px 4px 0 0',
  },
  closeButtonContainer: {
    display: 'flex',
    padding: '12px',
  },
  midFont: {
    fontSize: 16,
    color: palette.black,
    whiteSpace: 'nowrap',
  },
  bigFont: {
    fontSize: 30,
    color: palette.primary.dark,
    textAlign: 'center',
    fontFamily: 'Roboto-Light',
    marginTop: 20,

  },
  midFontCenter: {
    fontSize: 16,
    color: palette.black,
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  roundButton: {
    backgroundColor: palette.primary.darkLight,
    border: 'none',
    color: 'white',
    padding: '2px 20px 2px 20px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: palette.primary.dark,
      color: 'white',
    },
  },
  imageHeader: {
    width: '90px',
    height: 'auto',
    padding: 15,
    display: 'block',
    margin: 'auto',
  },
  titleHeader: {
    backgroundColor: theme.palette.primary.main,
    padding: 0,
  },
  dialogHeader: {
    backgroundColor: theme.palette.primary.dark,

  },
  titleText: {
    color: '#ffffff',
    paddingLeft: 15,
    fontFamily: 'Roboto-Light',
    fontSize: 16,
    paddingTop: '15px',
  },
  titleContent: {
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  valueContent: {
    fontSize: 15,
    color: '#000000',
  },
  message: {
    textAlign: 'center',
    color: '#000000',
    fontFamily: 'Roboto-Light',
    fontSize: 17,
  },
  iconClose: {
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '25px',
    height: '22px',
    width: '22px',
  },

});

export default modalStyles;
