/* eslint-disable max-len,no-param-reassign */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  checkEmptyObject,
  resetFormApplicationValues,
  shouldBlockFields,
  usePrevious,
} from '../../../../../utils/CommonUtils';
import { addSweetAlert, closeSweetAlert } from '../../../../common/SweetAlert/actions/sweet_alert';
import * as Constants from '../../../../../utils/Constants';
import { ResetApplicationForm } from '../../Payment/Enums';

/**
 * Hook which can set data of user from civil registration on react hook forms.
 * Now is only used for InfinityHolder component only when you create the application.
 * @param clearError clean error of form
 * @param setValue
 * @param setIsDisableInput
 * */
export default function useFindByIdentification(clearError, setValue, setIsDisableInput, setPersonWasFound) {
  const dispatch = useDispatch();
  const findByIdentificationResult = useSelector((state) => state.findByIdentificationResult);
  const previousFindByIdentification = usePrevious(findByIdentificationResult);

  const setHolderValues = (holder) => {
    setValue([
      { 'insuranceHolder.name': holder?.name || '' },
      { 'insuranceHolder.lastName': holder?.lastName || '' },
      { 'insuranceHolder.birthDate': holder?.birthDate || null },
      { 'insuranceHolder.gender': holder?.gender || '' },
      { 'insuranceHolder.birthPlace': holder?.birthPlace || '' },
    ]);
  };

  const showIdentificationNotFoundMessage = (title, message) => {
    dispatch(addSweetAlert({
      type: 'info',
      title,
      content: message,
      showCancel: false,
      onConfirmLabel: 'Continuar',
      onConfirm: () => {
        dispatch(closeSweetAlert());
      },
    }));
  };

  useEffect(() => {
    if (previousFindByIdentification
      && previousFindByIdentification !== findByIdentificationResult) {
      if (findByIdentificationResult.found) {
        const { response } = findByIdentificationResult;
        if (response.validationResult.result === 'CONTINUE_WITH_CLIENT_WARNING') {
          showIdentificationNotFoundMessage('Documento no válido', response.validationResult.messages[0]);
        } else {
          const person = findByIdentificationResult.response.personDto;
          setIsDisableInput(shouldBlockFields(person));
          setPersonWasFound(!checkEmptyObject(person));
          if (person) {
            setHolderValues(person);
          } else {
            showIdentificationNotFoundMessage('Documento no encontrado', Constants.MESSAGE_REGISTRO_CIVIL);
            resetFormApplicationValues(setValue, clearError, ResetApplicationForm.HolderFields);
          }
        }
      } else if (findByIdentificationResult?.isError) {
        setIsDisableInput(false);
        setPersonWasFound(false);
        showIdentificationNotFoundMessage('Documento no encontrado', Constants.MESSAGE_REGISTRO_CIVIL);
        resetFormApplicationValues(setValue, clearError, ResetApplicationForm.HolderFields);
      }
    }
  }, [clearError, dispatch, findByIdentificationResult, previousFindByIdentification, setValue]);
}
