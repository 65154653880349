/* eslint-disable global-require */
import React from 'react';
import {
  Toolbar,
  useMediaQuery,
  useTheme,
  AppBar, Typography,
} from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import logo from '../../assets/images/logo.png';
import logoMovil from '../../assets/images/logo_movil.png';
import topbarStyles from '../../layouts/Main/components/Topbar/style/topbar-style';

export default function MaintenanceWindow() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const classes = topbarStyles();
  const url = new URL(window.location.href);

  return (
    <>
      <AppBar className={classes.root}>
        <Toolbar>
          {/* Logo */}
          <div className={classes.logo}>
            <RouterLink to="/">
              <img
                alt="Logo"
                src={isDesktop ? logo : logoMovil}
              />
            </RouterLink>
          </div>
        </Toolbar>
      </AppBar>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '65vh' }}
      >
        <Grid item>
          <Typography variant="h4" style={{ textAlign: 'center', color: '#282727' }}>
            Recuerde que el sistema se encuentra inhabilitado
          </Typography>
          <Typography variant="h4" style={{ textAlign: 'center', color: '#282727' }}>
            {`desde las ${url.searchParams.get('start')} hasta ${url.searchParams.get('end')}`}
          </Typography>
        </Grid>
        <Grid item>
          <img
            src={require('../../assets/images/technical-support.svg')}
            alt="maintenance window"
            height={150}
            width={150}
          />
        </Grid>
      </Grid>
    </>
  );
}
