import { useEffect, useRef } from 'react';
import { groupBy } from 'lodash';
import moment from 'moment-timezone';
import { INTEGER_EMPTY_VALUE, oneEffectiveDateConfiguration, regions } from './Constants';
import Visa from '../assets/images/cards/ic_visa.svg';
import Dinners from '../assets/images/cards/ic_diners.svg';
import MasterCard from '../assets/images/cards/ic_mastercard.svg';
import Discover from '../assets/images/cards/ic_discover.svg';
import Amex from '../assets/images/cards/ic_amex.svg';

/**
 * Read payload from token and return it.
 * @param token from login service.
 * */
export const getTokenCatalogs = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('No parsable token, returning empty: ');
    return e;
  }
};

/**
 * Group object with a specific key.
 * */
export const groupByKey = (array, key) => groupBy(array, (obj) => obj[key].trim());

/**
 * Check if the object contain objects or arrays inside it. If the obj passed
 * is null or undefined also is considered like empty object.
 * */
export const checkEmptyObject = (object) => {
  if (Object.is(object, undefined) || Object.is(object, null)) {
    return true;
  }
  return Object.keys(object).length === 0;
};

/**
 * Use previous for compare old with current values.
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * */
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}


export function isEmpty(obj) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export function calculateAgeFromDate(birthDate, dateFrom) {
  const date = new Date(birthDate);
  date.setHours(0, 0, 0, 0);
  const currentDate = new Date(dateFrom);
  currentDate.setHours(0, 0, 0, 0);
  const diffMs = currentDate.getTime() - date.getTime();
  const ageDt = new Date(diffMs);
  return Math.abs(ageDt.getUTCFullYear() - 1970);
}

export const getLocalDateTime = () => {
  const now = new Date();
  now.setHours(now.getHours() - now.getTimezoneOffset() / 60);
  return now;
};

export const getAlwaysString = (date) => (date instanceof Date ? moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date);

export const getEffectiveDate = (date) => {
  let effectiveDate = new Date();
  const analizedDate = moment(date, 'YYYY-MM-DD');
  const nextMonthDate = analizedDate.clone().add(1, 'M');
  const dayToAnalize = Number(analizedDate.format('DD'));

  if (oneEffectiveDateConfiguration.enabled) {
    // iterate every range
    oneEffectiveDateConfiguration.Ranges.every((range) => {
      let { endDay } = range;
      // get end month day
      if (range.endOfMonth) {
        const endMonthAnalizedDate = analizedDate.endOf('month');
        endDay = Number(endMonthAnalizedDate.format('DD'));
      }
      if (dayToAnalize >= range.initialDay && dayToAnalize <= endDay) {
        const newDayDate = range.proposedDay;
        const newDateObject = range.nextMonth
          ? nextMonthDate.toObject()
          : analizedDate.toObject();
        newDateObject.day = newDayDate;
        effectiveDate = moment(newDateObject).toDate();

        return false;
      }
      return true;// continue iterate
    });
  }

  return moment(effectiveDate, 'YYYY-MM-DD').toDate();
};

export const locationUrl = () => {
  const { href } = window.location;
  const firstSlash = href.lastIndexOf('/');
  return `${href.substr(0, firstSlash)}`;
};

export const formatOrEmty = (date) => {
  const fechaMoment = moment(date);
  if (fechaMoment.year() <= 1900) return '';

  return fechaMoment.format('DD-MM-YYYY');
};

export const dateTimeFormatOrEmptyAtEcuador = (date) => {
  const fechaMoment = moment(date);

  if (fechaMoment.year() <= 1900) return '';

  return fechaMoment.tz('America/Bogota').format('DD/MM/YYYY, HH:mm:ss');
};

export function generatePdf(data) {
  generateFile(data, 'application/pdf');
}

export function generateZip(data) {
  generateFile(data, 'application/zip');
}

function generateFile(data, contentType) {
  const binaryString = window.atob(data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([bytes], { type: contentType });
  const url = URL.createObjectURL(blob);
  window.open(url);
}

/**
 * Reset Form application values. SetValue and clearError are functions of react hook forms library.
 * position param is only useful for dependants array, it indicates the position in the array that
 * will be deleted the form.
 * */
export function resetFormApplicationValues(setValue, clearError,
  resetApplicationFormEnum, position) {
  const infinityFields = [
    { 'insuranceMainInsured.name': '' },
    { 'insuranceMainInsured.lastName': '' },
    { 'insuranceMainInsured.birthDate': null },
    { 'insuranceMainInsured.gender': '' },
    { 'insuranceMainInsured.birthPlace': '' },
    { 'insuranceMainInsured.nacionality': '' },
    { 'insuranceMainInsured.height': 0},
    { 'insuranceMainInsured.weight': 0},
  ];
  const dependantFields = [
    { [`insuranceDependants[${position}].name`]: '' },
    { [`insuranceDependants[${position}].lastName`]: '' },
    { [`insuranceDependants[${position}].birthDate`]: null },
    { [`insuranceDependants[${position}].gender`]: '' },
    { [`insuranceDependants[${position}].birthPlace`]: '' },
    { [`insuranceDependants[${position}].nacionality`]: '' },
    { [`insuranceDependants[${position}].height`]: 0 },
    { [`insuranceDependants[${position}].weight`]: 0 },
  ];
  const commonFields = [
    { 'insuranceHolder.name': '' },
    { 'insuranceHolder.lastName': '' },
    { 'insuranceHolder.birthDate': null },
    { 'insuranceHolder.gender': '' },
    { 'insuranceHolder.birthPlace': '' },
    { 'billingData.anotherPerson': false },
    { 'billingData.name': '' },
    { 'billingData.lastName': '' },
    { 'billingData.identification': '' },
    { 'billingData.address': '' },
    { 'billingData.phone': '' },
    { 'billingData.email': '' },
    { 'billingData.relationshipBeneficiary': '' },
    { 'billingData.nacionality': '' },
    { 'contact.place': '' },
    { 'contact.province': '' },
    { 'contact.city': '' },
    { 'contact.cityDescription': '' },
    { 'contact.address': '' },
    { 'contact.conventionalPhone': '' },
    { 'contact.cellularPhone': '' },
    { 'contact.email': '' },
    { 'refund.accountNumber': '' },
    { 'refund.financialInstitution': '' },
    { 'refund.accountType': '' },
  ];
  clearError();

  const setFields = (applicationFormEnum) => ({
    InfinityFields: [...infinityFields],
    HolderFields: [...commonFields],
    DependantFields: [...dependantFields],
    AllFields: [...commonFields, ...infinityFields],
  })[applicationFormEnum];

  setValue(setFields(resetApplicationFormEnum));
}

export const renderCreditCardImage = (imageName) => ({
  VISA: Visa,
  VISATITANIUM: Visa,
  DINERS: Dinners,
  MASTERCARD: MasterCard,
  DISCOVER: Discover,
  AMEX: Amex,
})[imageName];

export const getRegionName = (branchOfficeId) => {
  switch (branchOfficeId) {
    case 1:
      return 'SIERRA';
    case 2:
      return 'COSTA';
    case 3:
      return 'AUSTRO';
    default:
      return '';
  }
};

export function groupProvinceByRegion(branchOfficeId, provinces) {
  if (branchOfficeId) {
    const selectedRegionName = getRegionName(branchOfficeId);
    const currentRegion = regions[selectedRegionName];
    return currentRegion.map((province) => provinces
      .find((p) => p.description === province.name));
  }
  return [];
}

export function getUserRole(login) {
  return login.response?.account?.idToken?.extension_Role;
}

export const getPaymentConditionText = (condition) => ({
  Corriente: `${condition.dues} PAGO`,
  Recurrente: `${condition.dues} PAGOS`,
  Diferido: `${condition.monthsToDefer} MESES ${condition.applyInterest ? 'CON INTERESES' : 'SIN INTERESES'}`,
})[condition.paymentMethod];

export function truncateToDate(myDate) {
  return myDate.setHours(0, 0, 0, 0);
}

/**
 * Sometimes when a value is empty, VT return -32768, for this case
 * it's necessary return a empty string otherwise the current value.
 * */
export function checkEmptyVTValue(value) {
  if (value && value !== INTEGER_EMPTY_VALUE) {
    return value;
  }
  return '';
}

export function findParameter(parameters, searchedValue) {
  return parameters.find((param) => param.name === searchedValue) || {};
}

export function shouldBlockFields(person) {
  const personWasFound = !checkEmptyObject(person);
  if (personWasFound) {
    return person.isValidatedCivilRegistry;
  }
  return false;
}
