import { all, fork } from 'redux-saga/effects';
import { loginSagas } from '../components/Login/sagas';
import { employeesSaga } from '../components/AdministrateUsers/sagas';
import { catalogsSaga } from '../components/Catalogs/sagas';
import { quoteSagas } from '../components/Quotation/sagas';
import { quoteIssuance } from '../components/Issuance/components/Application/sagas';
import { emissionsSaga } from '../components/Emissions/sagas';
import { sweetAlertSaga } from '../components/common/SweetAlert/sagas';
import { payments } from '../components/Issuance/components/Payment/sagas';
import { signing } from '../components/Issuance/components/Signing/sagas';
import {reallocateEmissionsSaga} from '../components/ReallocateEmissions/sagas';

function* rootSaga() {
  yield all(
    [
      ...Object.values(loginSagas),
      ...Object.values(employeesSaga),
      ...Object.values(catalogsSaga),
      ...Object.values(quoteSagas),
      ...Object.values(quoteIssuance),
      ...Object.values(sweetAlertSaga),
      ...Object.values(emissionsSaga),
      ...Object.values(payments),
      ...Object.values(signing),
      ...Object.values(reallocateEmissionsSaga),
    ].map(fork),
  );
}

export default rootSaga;
