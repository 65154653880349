export const ADD_DEPENDENT = 'ADD_DEPENDENT';
export const REQUEST_DEPENDENT_IDENTIFICATION = 'REQUEST_DEPENDENT_IDENTIFICATION';
export const SUCCESS_DEPENDENT_IDENTIFICATION = 'SUCCESS_DEPENDENT_IDENTIFICATION';
export const FAILED_DEPENDENT_IDENTIFICATION = 'FAILED_DEPENDENT_IDENTIFICATION';

export const addDependent = (dependentsSize) => ({
  type: ADD_DEPENDENT,
  payload: dependentsSize,
});

export const requestDependentIdentification = (identification, index) => ({
  type: REQUEST_DEPENDENT_IDENTIFICATION,
  payload: { identification, index },
});

export const successDependentIdentification = (citizen, index) => ({
  type: SUCCESS_DEPENDENT_IDENTIFICATION,
  payload: { citizen, index },
});

export const failedDependentIdentification = (error, index) => ({
  type: FAILED_DEPENDENT_IDENTIFICATION,
  payload: { error, index },
});
