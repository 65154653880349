import {
    START_REQUEST_REALLOCATE_EMISSIONS,
    REQUEST_REALLOCATE_EMISSIONS_SUCCESS,
    REQUEST_REALLOCATE_EMISSIONS_FAILED,
  } from '../actions/reallocateEmissions';
  
  const EMISSIONS_INITIAL_STATE = {
    loading: false,
    response: [],
    error: false,
  };
  
  export default (state = EMISSIONS_INITIAL_STATE, action) => {
    switch (action.type) {
      case START_REQUEST_REALLOCATE_EMISSIONS:
        return {
          ...state,
          loading: true,
          response: [],
          error: false,
        };
      case REQUEST_REALLOCATE_EMISSIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          response: action.payload,
          error: false,
        };
      case REQUEST_REALLOCATE_EMISSIONS_FAILED:
        return {
          ...state,
          loading: false,
          response: [],
          error: true,
        };
      default:
        return state;
    }
  };
  