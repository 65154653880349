import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormContext, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  iframe: {
    width: '100%',
    minHeight: 640,
    border: 0,
  },
  center: {
    alignSelf: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  saveButton: {
    borderRadius: 20,
  },
}));

const Documents = (props) => {
  const { handleNext } = props;
  const classes = useStyles();
  const methods = useForm({});

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <FormContext {...methods}>
      <div className={classes.root}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={classes.center}>
            <Button variant="contained" color="primary" size="large" className={classes.saveButton} onClick={handleNext} type="submit">
              Guardar Solicitud y Continuar
            </Button>
          </div>
        </form>
      </div>
    </FormContext>
  );
};

Documents.propTypes = {
  handleNext: PropTypes.any.isRequired,
};

export default Documents;
