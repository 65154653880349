import { makeStyles } from '@material-ui/core/styles';

const contentStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  nav: {
    marginBottom: theme.spacing(1),
  },
  tabs: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
  },
  tabBMI: {
    backgroundColor: theme.palette.primary.dark,
  },
  tabIgualas: {
    backgroundColor: '#275075',
  },
}));

export default contentStyles;
