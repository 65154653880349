/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import employeesReducer from '../components/AdministrateUsers/reducers/administrate_users';
import usersReAssignReducer from '../components/AdministrateUsers/reducers/users-re-assign';
import loginReducers from '../components/Login/reducers';
import messagesReducers from '../components/common/messages/reducers';
import quoteReducers from '../components/Quotation/reducers';
import modalReducers from '../components/common/modals/reducers';
import paymentsReducers from '../components/Issuance/components/Payment/slices';
import catalogsReducers from '../components/Catalogs/reducers/catalogs';
import { RESET_STATE } from '../components/Login/actions/login';
import issuanceReducers from '../components/Issuance/components/Application/reducers';
import alertsReducers from '../components/common/SweetAlert/reducers/sweet_alert';
import signingReducer from '../components/Issuance/components/Signing/reducers';
import emissionsReducers from '../components/Emissions/reducers';
import reallocateEmissionsReducers from '../components/ReallocateEmissions/reducers';

const appReducers = combineReducers({
  ...loginReducers,
  ...messagesReducers,
  ...quoteReducers,
  ...modalReducers,
  ...issuanceReducers,
  ...paymentsReducers,
  ...signingReducer,
  employees: employeesReducer,
  usersReAssign: usersReAssignReducer,
  catalogs: catalogsReducers,
  alerts: alertsReducers,
  ...emissionsReducers,
  ...reallocateEmissionsReducers,
});

/**
 * Reset all state of all reducers.
 * */
const rootReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    state = undefined;
  }
  return appReducers(state, action);
};

export default rootReducer;
