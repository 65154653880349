import {
  RESET_STATE,
  SIGN_IN_CALL_FAILURE,
  SIGN_IN_CALL_REQUEST,
  SIGN_IN_CALL_SUCCESS,
  START_SIGN_OUT,
  SUCCESS_SIGN_OUT,
  FAILED_SIGN_OUT, SET_MENUS,
} from '../actions/login';
import { navMenu } from '../../../layouts/Main/components/Topbar/navMenu';

const SIGN_IN_INITIAL_STATE = {
  isSigningInProgress: false,
  isSigningOutProgress: false,
  signedIn: false,
  response: {},
  isError: false,
  menus: navMenu.menus,
};

export default (state = SIGN_IN_INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN_CALL_REQUEST:
      return {
        ...state,
        isSigningInProgress: true,
        response: {},
        signedIn: false,
        isError: false,
      };
    case SIGN_IN_CALL_SUCCESS:
      return {
        ...state,
        isSigningInProgress: false,
        response: action.payload,
        signedIn: true,
        isError: false,
      };
    case SIGN_IN_CALL_FAILURE:
      return {
        ...state,
        isSigningInProgress: false,
        signedIn: false,
        response: action.payload,
        isError: true,
      };
    case START_SIGN_OUT:
      return {
        ...state,
        isSigningOutProgress: true,
        isError: false,
      };
    case SUCCESS_SIGN_OUT:
      return {
        ...state,
        ...SIGN_IN_INITIAL_STATE,
      };
    case FAILED_SIGN_OUT:
      return {
        ...state,
        isSigningOutProgress: false,
        response: action.payload,
        isError: true,
      };
    case RESET_STATE:
      return { ...SIGN_IN_INITIAL_STATE };
    case SET_MENUS:
      return {
        ...state,
        menus: action.payload,
      };
    default:
      return state;
  }
};

// selectors
export const getUser = (state) => state.login.response;
