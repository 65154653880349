import {
  call, put, takeLatest, select,
} from 'redux-saga/effects';
import { requestSendEmail, successSendEmail, failureSendEmail } from '../reducers/emailSlice';
import api from '../../../utils/Api';
import { getQuoteData } from '../reducers/quote';

export function* watchRequestSendEmail() {
  yield takeLatest(requestSendEmail.type, requestSendEmailSaga);
}

export function* requestSendEmailSaga({ payload: email }) {
  try {
    const quotation = yield select(getQuoteData);
    const { quotationHash } = quotation.response;
    const { deductibleId } = quotation.deductibleSelected;
    const { requestData: quotationData } = quotation;
    quotationData.deductibleId = deductibleId;
    quotationData.DeductibleQuotationData = quotation.response;
    const body = { quotationData, quotationHash, deductibleSelectedId: deductibleId };
    yield call(fetchEmail, body, email, quotationData.name);
    yield put(successSendEmail());
  } catch (e) {
    yield put(failureSendEmail(e));
  }
}

export const fetchEmail = (body, emailAddressTo, nameTo) => api
  .post(process.env.REACT_APP_SEND_EMAIL_URL, body,
    { params: { emailAddressTo, nameTo } });
