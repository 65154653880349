export const REFERENTIAL_INFORMATION = `Este es un cotizador; por lo tanto, la informaci\u00F3n es referencial. Los datos ingresados estar\u00E1n sujetos a una verificaci\u00F3n posterior de parte de BMI.
    La cotizaci\u00F3n constituye un acto preparatorio al contrato. Por lo tanto, no se genera ninguna obligaci\u00F3n entre las partes. Recuerde que debe culminar el proceso de contrataci\u00F3n.
    Toda cotizaci\u00F3n es temporal. Se advierte que las tarifas son referenciales y est\u00E1n sujetas a modificaci\u00F3n de conformidad con los par\u00E1metros legalmente establecidos. Diferido Especial Tarjeta de Cr\u00E9dito: Aplica para pagos diferidos sin intereses con tarjetas de cr\u00E9dito:
    A 3, 6 y 12 meses: Diners, Discover, Visa y Mastercard Banco Pichincha.
    A 3, 6, 9 y 12 meses: Visa y Mastercard Produbanco. `;

export const ALERT_BUTTON_TEXT = `El broker reconoce que asesor\u00F3 al cliente sobre su responsabilidad de declarar a la compa\u00F1\u00ECa la condici\u00F3n de salud y toda condici\u00F3n m\u00E9dica preexistente.
La falta de informaci\u00F3n adecuada y veraz limita los beneficios del contrato y puede ser causa de su terminaci\u00F3n.`;

export const PERSONAL_ACCOUNT_MESSAGE = 'La cuenta debe ser exclusivamente del titular, no puede pertenecer a terceros';

export const ADDRESS_ALERT_MESSAGE = 'El asesor productor de seguros reconoce que asesoró al cliente sobre la obligación de ingresar su verdadera dirección de residencia y región a la cual corresponde. La falta de información adecuada y veraz limita los beneficios del contrato y puede ser causa de su terminación.';
