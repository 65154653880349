// SIGN IN TYPES
export const SIGN_IN_CALL_REQUEST = 'SIGN_IN_CALL_REQUEST';
export const SIGN_IN_CALL_SUCCESS = 'SIGN_IN_CALL_SUCCESS';
export const SIGN_IN_CALL_FAILURE = 'SIGN_IN_CALL_FAILURE';

// SIGN OUT TYPES
export const START_SIGN_OUT = 'START_SIGN_OUT';
export const SUCCESS_SIGN_OUT = 'SUCCESS_SIGN_OUT';
export const FAILED_SIGN_OUT = 'FAILED_SIGN_OUT';

// RESET WHOLE APP STATE
export const RESET_STATE = 'RESET_STATE';
export const SET_MENUS = 'SET_MENUS';


// actions creator
export const startSignIn = (authProvider) => ({
  type: SIGN_IN_CALL_REQUEST,
  payload: authProvider,
});

export const signInSuccess = (aadInfo) => ({
  type: SIGN_IN_CALL_SUCCESS,
  payload: aadInfo,
});

export const signInFailure = (error) => ({
  type: SIGN_IN_CALL_FAILURE,
  payload: error,
});

export const startSignOut = () => ({
  type: START_SIGN_OUT,
});

export const successSignOut = () => ({
  type: SUCCESS_SIGN_OUT,
});

export const failedSignOut = (error) => ({
  type: FAILED_SIGN_OUT,
  payload: error,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const setMenus = (menus) => ({
  type: SET_MENUS,
  payload: menus,
});
