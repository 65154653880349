import React from 'react';
import PropTypes from 'prop-types';

let browser = window;
let popup = null;
let timer = null;

function watcher() {
  if (popup === null) {
    clearInterval(timer);
    timer = null;
  } else if (popup !== null && !popup.closed) {
    popup.focus();
  } else if (popup !== null && popup.closed) {
    clearInterval(timer);
    browser.focus();
    browser.onClose('child was closed');
    timer = null;
    popup = null;
  }
}

export class WindowOpener {
  constructor(bridge, url) {
    this.url = url;

    browser = window.self;

    browser.onSuccess = (res) => {
      bridge('success', res);
    };

    browser.onError = (error) => {
      bridge('error', error);
    };

    browser.onOpen = (message) => {
      bridge('', message);
    };

    browser.onClose = (message) => {
      bridge('closed', message);
    };
  }

  onClickHandler() {
    // console.log('onClickHandler', this.props);
    const opts = `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, alwaysRaised=${1}, width=${800}, height=${500}`;

    // const { url, name, opts } = this.props;
    if (popup && !popup.closed) {
      popup.focus();
      return;
    }

    popup = browser.open(this.url, 'sonScrenn', opts);

    setTimeout(() => {
      popup.opener.onOpen('child was opened');
    }, 0);

    if (timer === null) {
      timer = setInterval(watcher, 2000);
    }
    return;
  }
}

WindowOpener.propTypes = {
  url: PropTypes.string.isRequired,
  bridge: PropTypes.func.isRequired,
  name: PropTypes.string,
  opts: PropTypes.string,
};

WindowOpener.defaultProps = {
  name: 'Cool popup',
  opts: `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, alwaysRaised=${1}, width=${300}, height=${300}`,
};
