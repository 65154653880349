/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  iframe: {
    width: '100%',
    minHeight: 640,
    border: 0,
  },
}));

const TermsAndConditions = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2>TERMINOS Y CONDICIONES BMI CONNECTED</h2>
      <br />
      Los presentes Términos y Condiciones establecen las normas aplicables al acceso y uso del Sitio Web BMI CONNECTED (en adelante Sitio Web), así como a las Aplicaciones a las que como Agente/Broker (<b>Usuario</b>) con relación contractual con BMI IGUALAS MÉDICAS DEL ECUADOR S.A. (BMI) tiene acceso, los mismos que al momento de su uso se entienden aceptados salvo manifestación expresa en contrario.
      <br />
      <br />
      <h4>ASPECTOS LEGALES</h4>
      <br />
      <ul>
        <li>DEL SITIO WEB BMI CONNECTED</li>
        BMI pone a disposición del <b>Usuario</b> el Sitio Web, el cual permite generar cotizaciones automáticamente con base en la información y datos proporcionados por el <b>Usuario</b> con respecto al Cliente, incluyendo Datos Personales con su debida autorización, permite completar información, llenar la solicitud y finalmente contratar productos de BMI a nombre y representación del cliente y con su autorización.
        <br />
        <br />
        Se entenderá por Datos Personales aquella información que afecta la intimidad de las personas o cuyo uso indebido puede generar discriminación por condiciones de etnia, orientación política, religión, datos de salud, vida sexual, biométricos.
        <br />
        <br />
        <li>LIMITACIONES DEL SITIO WEB BMI CONNECTED</li>
        El uso del Sitio Web se realizará únicamente dentro del TERRITORIO ECUATORIANO, se entiende por tal el espacio geográfico que pertenece a la República del Ecuador de conformidad con su Constitución Política. Es condición indispensable que el Cliente beneficiario de los productos que se contraten sea ciudadano ecuatoriano o tenga la condición migratoria de residente en la República del Ecuador. Para poder utilizar los servicios del Sitio Web será estrictamente necesario que el <b>Usuario</b> acceda con su <b>Usuario</b> y contraseña entregados por BMI, desde un dispositivo móvil con cobertura de Internet prestada por una operadora celular o con acceso a WiFi. BMI no será responsable por la interrupción o restricción del uso del Sitio Web por parte del prestador de servicios de telefonía móvil ni será tampoco responsable de los cambios o actualizaciones del equipo o las Aplicaciones que puedan llegar a ser necesarias, ni de ningún costo relacionado con ello, lo anterior en tanto que se entiende y acepta que dichas circunstancias son ajenas a su control. El acceso al Sitio Web podría no estar disponible en cualquier momento, debido a factores diversos, incluyendo pero no limitado a condiciones meteorológicas, fallas en la red, condiciones topográficas, ubicación geográfica, ubicación del dispositivo móvil en construcciones con supresores de señal; en consecuencia, el acceso y uso del Sitio Web podría ser interrumpido por causas fuera del control de BMI, de modo que podrían presentarse fallas o errores tales como que el proceso de contratación no culmine, los datos ingresados para las cotizaciones no se hayan guardado, se deba reiniciar el proceso. En todo caso, la responsabilidad por cualquier afectación en el acceso o uso al Sitio Web por causas ajenas a BMI no será asumida en ningún grado.
        <br />
        <br />
        <li>MODIFICACIONES</li>
        BMI por así convenir a sus intereses, a sus necesidades de negocio o por cumplimiento de disposición legal o judicial aplicable, se reserva el derecho a introducir cambios en cualquier momento tanto en el acceso y uso del Sitio Web como en los servicios que a través de éste se prestan, los presentes términos, la información requerida para el proceso de contratación, para lo cual será suficiente la publicación de la modificación realizada en la PÁGINA DE INTERNET DE BMI.
        <br />
        <br />
        Es responsabilidad del <b>Usuario</b> revisar periódicamente la página Web en mención para informarse acerca de las actualizaciones de estos Términos y Condiciones, dado que entrarán en vigor una vez aparezcan en la misma.
        <br />
        <br />
        <li>PAGINA WEB Y APLICACIONES</li>
        El uso de nuestro Sitio Web así como la descarga de Aplicaciones (en caso de ser pertinente), implica la aceptación de estos Términos y Condiciones de uso especificado en este aviso legal y la afirmación implícita de tener la capacidad legal para aceptarlos y sujetarse a ellos en nombre propio y en nombre y representación del cliente luego de haberle asesorado e informado al respecto.
        <br />
        <br />
        El uso del Sitio Web y Aplicaciones requiere de dispositivos compatibles y acceso a Internet, pueden requerirse actualizaciones periódicas y puede ser afectado por el desempeño de estos factores. Se acepta que estos requisitos son una responsabilidad a cargo del <b>Usuario</b>.
        <br />
        <br />
        <li>USO DE COOKIES</li>
        BMI le informa que durante la navegación por el Sitio Web no se utilizan&quot;cookies&quot;; sin embargo, en caso de que se llegaren a utilizar este uso está autorizado.
        <br />
        <br />
        <li>REGLAS DE USO</li>
        (i) El <b>Usuario</b> de BMI CONNECTED se encuentra autorizado para utilizar el Sitio Web para uso comercial, con el objetivo de generar nuevos negocios con mutuos clientes de BMI y el <b>Usuario</b>.
        <br />
        <br />
        (ii) El <b>Usuario</b> podrá acceder al portal de forma ilimitada a través de dispositivos móviles que cuenten con sistema operativo Android e Ios.
        <br />
        <br />
        (iii) El <b>Usuario</b> no se encuentra autorizado para copiar en forma o grado el contenido del Sitio Web.
        <br />
        <br />
        iv) El <b>Usuario</b> no podrá utilizar el Sitio Web con los siguientes fines:
        <br />
        <br />
        <ul>
          <li>Propósitos impropios, ilegales o inmorales provocando molestias en los Servicios o haciendo que el funcionamiento de los Servicios se vea comprometido o imposibilitado;</li>
          <li>Para crear, almacenar o transmitir contenido difamatorio, ofensivo u obsceno, o para realizar actividades que pudieran ofender a otros por motivos de raza, religión, credo o sexo;</li>
          <li>Para dañar, o intentar dañar, a menores de cualquier forma;</li>
          <li>Para crear, almacenar o transmitir cualquier material que suponga una amenaza, que propicie daños materiales o la destrucción de la propiedad o constituya un delito penal o genere responsabilidades civiles;</li>
          <li>Para crear, almacenar o transmitir material que infrinja el copyright, la marca comercial, patente, secreto industrial, la privacidad, publicidad o cualquier otro tipo de derecho de propiedad intelectual o derecho como propietario;</li>
          <li>Para crear, almacenar o transmitir material publicitario no solicitado a otros <b>Usuario</b>s;</li>
          <li>Para crear, almacenar o transmitir cualquier material que moleste a otros;</li>
          <li>Para realizar ofertas fraudulentas, falsas, incorrectas, o para vender o comprar productos, artículos o servicios no autorizados por BMI, o para enviar cadenas de cartas, esquemas piramidales o similares;</li>
          <li>Para recopilar o intentar recopilar información personal acerca de terceros sin su consentimiento previo, o para recopilar información acerca de su comportamiento en pantalla, o para crear bases de datos o cualquier otra actividad cuya finalidad sea obtener listados de <b>Usuario</b>s u otros datos;</li>
          <li>Para cualquier actividad que afecte adversamente la capacidad de otras personas, o sistemas para utilizar los servicios o Internet en general, incluidos, entre otros, saturación de la red y piratería;</li>
          <li>Para revender, modificar o redistribuir cualquiera de los servicios o bien hacerse pasar por cualquier persona o entidad, o utilizar un nombre falso;</li>
          <li>Para crear falsos negocios o convenciones, como tampoco para generar o inducir a engaño.</li>
          <li>Para divulgar cualquier información que identifique personalmente a terceros (p. ej. número de teléfono, dirección geográfica o cualquier otra información con la que pueda conseguirse la identidad de una persona o los datos de contacto).</li>
        </ul>
        <br />
        <br />
        La enumeración antes hecha es ejemplificativa y no se pretende proporcionar una lista exhaustiva de los tipos de usos no aceptables que pueden dar como resultado la restricción, suspensión o cancelación de su permiso para usar el Sitio Web. Teniendo en cuenta la naturaleza global de Internet, los <b>Usuario</b>s deben comprometerse a respetar las normativas locales con respecto al comportamiento en línea y al contenido aceptable. Los <b>Usuario</b>s también se comprometen a acatar cualquier norma aplicable relacionada con la exportación de datos desde cualquier país.
        <br />
        <br />
        <li>PROPIEDAD INTELECTUAL</li>
        Al usar este Sitio Web y/o Aplicaciones BMI CONNECTED, se reconoce que contiene información, comunicaciones, software, fotos, vídeos, gráficos, música, sonido, imágenes y otros materiales y servicios (en adelante todo denominado "el Contenido"), y que este Contenido es suministrado por BMI o bajo licencia de proveedores de contenido independientes, por tanto, se hace conocer y aceptar que, sin perjuicio que BMI permite acceder al Contenido, el mismo está protegido por las normas relativas a la propiedad industrial e intelectual, protección que se extiende tanto a los medios de transmisión existentes hoy o que en el futuro se desarrollen, por lo que el uso del Contenido estará regido por las referidas normas de propiedad industrial e intelectual, incluidos los tratados internacionales vigentes aplicables en Ecuador, salvo que se indique expresamente lo contrario en cada caso. Nada de lo contenido en estos Términos y Condiciones o cualquier otro documento deberán interpretarse como una licencia, concesión, autorización, cesión, sub-licencia o transferencia de derechos a favor del <b>Usuario</b> o cualquier tercero y el <b>Usuario</b> expresamente reconoce que no tiene derecho alguno sobre dichos derechos de propiedad intelectual o industrial.
        <br />
        Todo el Contenido del Sitio Web y Aplicaciones BMI CONNECTED está protegido por las leyes de propiedad intelectual nacionales e internacionales, en consecuencia, como <b>Usuario</b> no puede modificar, publicar, transmitir, participar en la transferencia o venta, reproducir, crear trabajos derivados, distribuir, ejecutar, exhibir o en cualquier manera explotar parte alguna del Contenido.
        <br />
        El Contenido solo puede usarse en el modo dispuesto en el presente documento. La infracción de estos Términos de uso puede constituir una infracción de nuestros derechos de propiedad intelectual o los de nuestros proveedores de contenido, quienes se reservan el derecho de emprender acciones legales para proteger estos derechos. El Contenido puede visualizarse e imprimirse y los documentos pueden distribuirse siempre y cuando se utilicen como información y solo para fines personales no comerciales. No está permitido intercambiar, modificar, vender, ni transmitir nada de lo que haya en el Sitio.
        <br />
        BMI se compromete a conducirse en estricto cumplimiento a la normativa vigente sobre Protección de Datos Personales. Adicionalmente, a lo expresamente determinado dentro del correspondiente Aviso de Privacidad Integral, el <b>Usuario</b> del Sitio Web y Aplicaciones acepta: (a) proporcionar información verdadera, precisa, actual y completa sobre sí mismos de acuerdo con lo que se solicite en el formulario de registro de <b>Usuario</b>; (b) mantener y actualizar de inmediato los datos de registro para que sigan siendo verdaderos, precisos, actuales y completos; (c) contar con la debida autorización de parte del cliente para actuar en su nombre y representación; (d) autorizar que BMI valide la información, para este efecto obtenga información de terceros incluyendo Datos Personales que son regularmente protegidos; (e) proporcionar en todo momento información veraz, completa, actualizada sobre el cliente, debido para esto informarle sobre las implicaciones legales y contractuales que el incumplimiento podría acarrear. Si se proporciona cualquier información falsa, imprecisa, no actual o incompleta, o BMI tiene motivos fundados para sospechar que la información es falsa, imprecisa, no actual o incompleta, BMI se reserva el derecho de suspender o finalizar su acceso al servicio o uso de él.
        <br />
        Las transmisiones a través de Internet podrían ser vulneradas, por lo que se entiende que cualquier información o mensaje que se envíe en el Sitio Web o a través de las Aplicaciones podría ser interceptado o leído por terceros, BMI no será responsable en ningún caso de los actos no autorizados de terceros que se realicen con la intención de eludir los protocolos de seguridad del Sitio Web.
        <br />
        Las contraseñas y nombres de <b>Usuario</b> serán exclusivamente para uso personal y no pueden ser transferidos, siendo el <b>Usuario</b> responsable de mantener la confidencialidad de la contraseña así como de todas las actividades que se lleven a cabo bajo su contraseña o cuenta. Como <b>Usuario</b> se compromete a notificar inmediatamente a BMI cualquier uso no autorizado de la contraseña o cuenta o cualquier otra infracción relativa a la seguridad. BMI no pueden responsabilizarse ni se responsabilizará de cualquier pérdida o daño ocasionado por el incumplimiento de estos Términos de Uso.
        <br />
        <br />
        <li>SOFTWARE DISPONIBLE EN EL SITIO</li>
        Cualquier software que se ofrezca para su descarga desde el Sitio (el “Software”) es propiedad intelectual de BMI o sus proveedores de contenido. Cualquier reproducción o redistribución del Software puede conllevar sanciones.
        <br />
        <br />
        <li>TERMINACIÓN</li>
        Ante el incumplimiento a los presentes Términos y Condiciones BMI tendrá facultad discrecional para (i) terminar el contrato con el Agente sin que concluya la obligación de pagar los montos adeudados y/o (ii) impedir el acceso al portal y uso de aplicaciones.
        <br />
        <br />
        <li>EXCLUSIÓN DE GARANTÍAS; LIMITACIÓN DE RESPONSABILIDAD</li>
        BMI no garantiza ni declara que el uso que haga de BMI CONNECTED será ininterrumpido o libre de error, por lo que acepta expresamente que el uso es bajo su exclusiva responsabilidad. BMI CONNECTED puesto a su disposición se brinda "como son" y "de acuerdo con su disponibilidad" para su uso, sin garantías de ninguna clase, ya sean expresas o implícitas.
        <br />
        En ningún caso BMI, sus directores, funcionarios, empleados, afiliados, agentes, representantes legales, proveedores o licenciantes serán responsables por ningún daño o perjuicio, directo, indirecto, incidental, punitivo, especial o emergente derivado del uso de BMI CONNECTED o de cualquier otro reclamo relacionado de cualquier modo con el uso que haga, inclusive y sin carácter restrictivo cualquier error u omisión de cualquier contenido, o cualquier pérdida, perjuicio o daño de cualquier clase en que se incurra como resultado del uso de cualquier contenido o aplicación.
        <br />
        La información relacionada con el uso de BMI CONNECTED, es de su exclusivo riesgo, y por lo tanto, por medio del presente el <b>Usuario</b> renuncia a cualquier reclamo  a BMI por cualquier pérdida o responsabilidad relacionada con el uso del Sitio Web.
        <br />
        <br />
        <li>JURISDICCION</li>
        Para la interpretación y aplicación de los presentes Términos y Condiciones se estará a lo dispuesto por las leyes ecuatorianas y se somete a la jurisdicción de los tribunales competentes en la Ciudad de Quito desde este momento, tanto los <b>Usuario</b>s de este Sitio, como BMI.
        <br />
        <br />
        <li>OBLIGACIONES DEL AGENTE/BROKER</li>
        El agente/broker reconoce que sus obligaciones son:
        <ul>
          <li>Cumplir con las obligaciones establecidas en su Contrato de Agenciamiento y en la normativa vigente para el Ejercicio de las Actividades de los Asesores Productores de Seguros.</li>
          <li>Actuar por cuenta y a nombre del cliente estando debidamente autorizado para ello.</li>
          <li>Cumplir con los principios de ética profesional.</li>
          <li>Asesorar al cliente en forma veraz, suficiente, detallada y permanente acerca de las condiciones del contrato, alcance de las coberturas, beneficios, exclusiones, cuotas, forma de pago, requisitos, plazos y procedimientos para reclamar el pago de reembolsos.</li>
          <li>Advertir al cliente sobre su obligación de declarar con exactitud y veracidad su condición de salud así como todas sus condiciones médicas preexistentes, haciéndole conocer las implicaciones contractuales y legales que la falta de declaración podría acarrear. Esto incluye información sobre el lugar de residencia del Titular y cada Cliente.</li>
          <li>Asesorar al cliente sobre la validez de la formación y perfeccionamiento del consentimiento a través del uso de medios electrónicos. </li>
          <li>Gestionar adecuadamente con el cliente la información del medio de pago, sea esto la información de su tarjeta de crédito, débito automático, y generar para sí (agente/broker) la autorización del uso de dicha información para el pago respectivo.</li>
          <li>Gestionar que el cliente confirme que los fondos provienen de actividades licitas.</li>
          <li>Asesorar al cliente sobre el uso adecuado de medios electrónicos, así como explicarle que el consentimiento electrónico de conformidad con la legislación vigente genera obligaciones. De igual forma que la autorización de uso de medio de pago en forma electrónica le obliga a respetar tal autorización y por tanto a que se ejecute el pago.</li>
        </ul>
      </ul>


    </div>
  );
};

export default TermsAndConditions;
