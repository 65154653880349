import * as yup from 'yup';
import {
  esCedulaORucPersonaNaturalValido,
  isValidDate,
  isValidId,
  esCedulaPersonaNaturalValido,
  esRucPersonaNaturalValido,
} from '../../../../utils/id-validator';

const medicalConditionsSchema = yup.array().of(
  yup.object().shape({
    diagnosis: yup.string().required('Este campo es requerido'),
    diagnosisFrom: yup.string().typeError('Este campo es requerido').required('Este campo es requerido')
      .test('date-from-validation', 'Fecha no es v\u00e1lida.', isValidDate),
    diagnosisIsToThePresent: yup.boolean(),
    diagnosisTo: yup.string().nullable().when('diagnosisIsToThePresent',
      {
        is: false,
        then: yup.string().required('Este campo es requerido')
          .test('date-from-validation', 'Fecha no es v\u00e1lida.', isValidDate),
      }),
  }),
);

const covid19QuestionsSchema = yup.object({
  questionCovid19TestPerformed: yup.boolean().nullable().required('Seleccione una opcion.'),
  questionCovid19ResultPositive: yup.boolean().nullable().when('questionCovid19TestPerformed', {
    is: true,
    then: yup.boolean().required('Seleccione una opcion.'),
  }),
});

const insuranceMainInsuredSchema = yup.object({
  identificationType: yup.string().required('Este campo es requerido'),
  identification: yup.string()
    .test('id-validation', 'C\u00E9dula no es v\u00e1lida.', isValidId),
  name: yup.string().trim().required('Este campo es requerido'),
  lastName: yup.string().trim().required('Este campo es requerido'),
  birthDate: yup.date().required().typeError('Este campo es requerido').required('Este campo es requerido')
    .max(new Date(), 'Por favor ingrese una fecha anterior'),
  gender: yup.string().required('Este campo es requerido'),
  birthPlace: yup.string().required('Este campo es requerido'),
  medicalConditions: medicalConditionsSchema,
  height: yup.number().positive('El valor debe ser mayor que cero').typeError('Por favor, introduzca un número válido').required('Campo requerido'),
  weight: yup.number().positive('El valor debe ser mayor que cero').typeError('Por favor, introduzca un número válido').required('Campo requerido'),
  nacionality: yup.string().required('Este campo es requerido'),
});

const insuranceHolderSchema = yup.object({ // Infinity Kids Holder
  relationshipBeneficiary: yup.string().required('Este campo es requerido'),
  identificationType: yup.string().required('Este campo es requerido'),
  identification: yup.string()
    .test('id-validation', 'C\u00E9dula no es v\u00e1lida.', isValidId),
  name: yup.string().trim().required('Este campo es requerido'),
  lastName: yup.string().trim().required('Este campo es requerido'),
  birthDate: yup.date().typeError('Este campo es requerido').required('Este campo es requerido')
    .max(new Date(), 'Por favor ingrese una fecha anterior'),
  gender: yup.string().required('Este campo es requerido'),
  birthPlace: yup.string().required('Este campo es requerido'),
  height: yup.number().positive('El valor debe ser mayor que cero').typeError('Por favor, introduzca un número válido').required('Campo requerido'),
  weight: yup.number().positive('El valor debe ser mayor que cero').typeError('Por favor, introduzca un número válido').required('Campo requerido'),
  nacionality: yup.string().required('Este campo es requerido'),
}).notRequired().default(undefined);

const insuranceDependantsSchema = yup.object({
  relationshipBeneficiary: yup.string().required('Este campo es requerido'),
  identificationType: yup.string().required('Este campo es requerido'),
  medicalConditions: medicalConditionsSchema,
  identification: yup.string().required('Este campo es requerido')
    .test('id-validation', 'C\u00E9dula no es v\u00e1lida.', isValidId),
  name: yup.string().trim().required('Este campo es requerido'),
  lastName: yup.string().trim().required('Este campo es requerido'),
  birthDate: yup.date().typeError('Este campo es requerido').required('Este campo es requerido')
    .max(new Date(), 'Por favor ingrese una fecha anterior'),
  birthPlace: yup.string().required('Este campo es requerido'),
  gender: yup.string().required('Este campo es requerido'),
  found: yup.boolean().default(true),
  // ...(isEnabledCovidParam && { ...covid19QuestionsSchema }),
  height: yup.number().positive('El valor debe ser mayor que cero').typeError('Por favor, introduzca un número válido').required('Campo requerido'),
  weight: yup.number().positive('El valor debe ser mayor que cero').typeError('Por favor, introduzca un número válido').required('Campo requerido'),
  nacionality: yup.string().required('Este campo es requerido'),
});

const contactSchema = yup.object({
  place: yup.string().required('Este campo es requerido'),
  province: yup.string().required('Este campo es requerido'),
  city: yup.string().required('Este campo es requerido'),
  cityDescription: yup.string(),
  address: yup.string().required('Este campo es requerido').matches(/^[\u00a0a-zA-Z0-9ñÑáéíóú.,\- ]+$/, 'Direcci\u00F3n incorrecta'),
  conventionalPhone: yup.lazy((value) => (!value ? yup.string() : yup.string().matches('[0]{1}[0-9]{8}', 'Tel\u00E9fono incorrecto').max(10, 'Hasta 10 caracteres'))),
  cellularPhone: yup.string().matches('0[1-9]{1}[0-9]{8}', 'Tel\u00E9fono incorrecto').min(10, 'Minimo 10 caracteres').max(10, 'Hasta 10 caracteres'),
  email: yup.string().email('Email no valido').required('El campo email es requerido').matches(/^[^ñáéíóúÑÁÉÍÓÚ]*$/, 'Mail contiene carateres no permitidos'),
});

const billingDataSchema = yup.object({
  anotherPerson: yup.boolean(),
  name: yup.string().trim().required('Este campo es requerido').matches(/^[\u00a0a-zA-ZñÑáéíóú ]+$/, 'Nombre incorrecto'),
  lastName: yup.string().trim().matches(/^[\u00a0a-zA-ZñÑáéíóú ]+$/, 'Apellido incorrecto'),
  isIdentification: yup.bool(),
  identification: yup.string()
    .when('isIdentification', { is: true, then: yup.string().required('Este campo es requerido').test('ruc-natural-person', 'El CI ingresado no corresponde a una persona natural o No es una cédula válida', esCedulaPersonaNaturalValido) })
    .when('isIdentification', { is: false, then: yup.string().required('Este campo es requerido').test('ruc-natural-person', 'El RUC ingresado no corresponde a una persona natural o No es una cédula válida', esRucPersonaNaturalValido) }),
  address: yup.string().required('Este campo es requerido').matches(/^[\u00a0a-zA-Z0-9ñÑáéíóú.,\- ]+$/, 'Direcci\u00F3n incorrecta'),
  phone: yup.lazy((value) => (!value ? yup.string() : yup.string().matches('0[1-9]{1}[0-9]{8}', 'Tel\u00E9fono incorrecto').min(10, 'Minimo 10 caracteres').max(10, 'Hasta 10 caracteres'))),
  email: yup.string().email('Email no valido').required('El campo email es requerido').matches(/^[^ñáéíóúÑÁÉÍÓÚ]*$/, 'Mail contiene carateres no permitidos'),
  relationshipBeneficiary: yup.string().nullable().when('anotherPerson',
    { is: true, then: yup.string().required('Debe especificar la relacion') }),
  nacionality: yup.string().required('Este campo es requerido'),  
});

const refundSchema = yup.object({
  accountNumber: yup.string().typeError('Requerido').required('Requerido'),
  financialInstitution: yup.string().required('Este campo es requerido'),
  accountType: yup.string().required('Este campo es requerido'),
});

export const applicationSchema = yup.object().shape({
  insuranceMainInsured: insuranceMainInsuredSchema,
  insuranceHolder: insuranceHolderSchema,
  insuranceDependants: yup.array().of(
    insuranceDependantsSchema,
  ),
  contact: contactSchema,
  billingData: billingDataSchema,
  refund: refundSchema,
  termsConditionsAccepted: yup.boolean(),
});

export const applicationCovidSchema = yup.object().shape({
  insuranceMainInsured: insuranceMainInsuredSchema.concat(covid19QuestionsSchema),
  insuranceHolder: insuranceHolderSchema,
  insuranceDependants: yup.array().of(
    insuranceDependantsSchema.concat(covid19QuestionsSchema),
  ),
  contact: contactSchema,
  billingData: billingDataSchema,
  refund: refundSchema,
  termsConditionsAccepted: yup.boolean(),
});
