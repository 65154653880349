/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  iframe: {
    width: '100%',
    minHeight: 640,
    border: 0,
  },
  faqList: {
    paddingLeft: '25px',
  },
  faqItem: {
    marginBottom: '20px',
  },
  faqQuestion: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  faqAnswer: {
    fontWeight: 'inherit',
    marginBottom: '5px',
  },
}));

const FAQ = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2>PREGUNTAS FRECUENTES SOBRE PLACE TO PAY</h2>
      <br />
      <p>En este documento, usted encontrará una sección de preguntas y respuestas que le ayudará a aclarar sus
        dudas sobre los pagos electrónicos procesados a través de PlaceToPay.
      </p>
      <br />
      <ol className={classes.faqList}>
        <li className={classes.faqItem}>
          <p className={classes.faqQuestion}>¿Qué es PlacetoPay?</p>
          <p className={classes.faqAnswer}>PlacetoPay es la plataforma de pagos electrónicos que usa BMI
            IGUALAS MÉDICAS DEL ECUADOR S.A. para procesar en línea las transacciones generadas en la tienda
            virtual con las formas de pago habilitadas para tal fin.
          </p>
        </li>
        <li className={classes.faqItem}>
          <p className={classes.faqQuestion}>¿Cómo puedo pagar?</p>
          <p className={classes.faqAnswer}>En la plataforma BMI-Connected de BMI IGUALAS MÉDICAS DEL ECUADOR
            S.A. usted podrá realizar su
            pago
            con los medios habilitados para tal fin. Usted, de acuerdo a las opciones de pago escogidas por
            el
            comercio, podrá pagar a través Diners, Discover, Visa y MasterCard; de todos los bancos con pago
            corriente y en los diferido, únicamente las tarjetas emitidas por Banco Pichincha, Diners, Loja,
            BGR
            y Manabí.
          </p>
        </li>
        <li className={classes.faqItem}>
          <p className={classes.faqQuestion}>¿Es seguro ingresar mis datos bancarios en este sitio web?</p>
          <p className={classes.faqAnswer}>Para proteger tus datos BMI IGUALAS MÉDICAS DEL ECUADOR S.A. delega
            en PlacetoPay la captura de
            la información sensible. Nuestra plataforma de pagos cumple con los más altos estándares
            exigidos por la norma internacional PCI DSS de seguridad en transacciones con tarjeta de
            crédito. Además tiene certificado de seguridad SSL expedido por GeoTrust una compañía Verisign,
            el cual garantiza comunicaciones seguras mediante la encriptación de todos los datos hacia y
            desde el sitio; de esta manera, te podrás sentir seguro a la hora de ingresar la información de
            su tarjeta.
          </p>
          <p className={classes.faqAnswer}>
            Durante el proceso de pago, en el navegador se muestra el nombre de la organización autenticada,
            la autoridad que lo certifica y la barra de dirección cambia a color verde. Estas
            características son visibles de inmediato y dan garantía y confianza para completar la
            transacción en PlacetoPay.
          </p>
          <p className={classes.faqAnswer}>PlacetoPay también cuenta con el monitoreo constante de McAfee
            Secure y la firma de mensajes electrónicos con Certicámara.
          </p>
          <p className={classes.faqAnswer}>PlacetoPay es una marca de la empresa colombiana EGM Ingeniería Sin
            Fronteras S.A.S.
          </p>
        </li>
        <li className={classes.faqItem}>
          <p className={classes.faqQuestion}>¿Puedo realizar el pago cualquier día y a cualquier hora?</p>
          <p className={classes.faqAnswer}>Sí, en BMI IGUALAS MÉDICAS DEL ECUADOR S.A. podrás realizar tus
            compras en línea los 7 días de la semana, de 6:00 AM a 21:00 PM a sólo un clic de distancia.
          </p>
        </li>
        <li className={classes.faqItem}>
          <p className={classes.faqQuestion}>¿Puedo cambiar la forma de pago?</p>
          <p className={classes.faqAnswer}>Si aún no has finalizado tu pago, podrás volver al paso inicial y
            elegir la forma de pago que prefieras. Una vez finalizada la compra (Emitido el contrato) no es
            posible cambiar la forma de pago.
          </p>
        </li>
        <li className={classes.faqItem}>
          <p className={classes.faqQuestion}>¿Pagar electrónicamente tiene algún valor para mí como
            comprador?
          </p>
          <p className={classes.faqAnswer}>No, los pagos electrónicos realizados a través de PlacetoPay no
            generan costos adicionales para el comprador.
          </p>
        </li>
        <li className={classes.faqItem}>
          <p className={classes.faqQuestion}>¿Qué debo hacer si mi transacción no concluyó?</p>
          <p className={classes.faqAnswer}>En primera instancia deberás revisar si llegó un mail de
            confirmación del pago en tu cuenta de correo electrónico (la inscrita en el momento de realizar
            el pago), en caso de no haberlo recibido, deberás contactar a BMI IGUALAS MÉDICAS DEL ECUADOR
            S.A. al 1800BMIBMI o enviar un mail a postventa@bmi.com.ec para confirmar el estado de la
            transacción.
          </p>
          <p className={classes.faqAnswer}>En caso que tu transacción haya declinado, debes verificar si la
            información de la cuenta es válida, está habilitada para compras no presenciales y si tienes
            cupo o saldo disponible. Si después de esto continua con la declinación debes comunicarte con
            BMI IGUALAS MÉDICAS DEL ECUADOR S.A. al 1800BMIBMI o enviar un mail a postventa@bmi.com.ec. En
            última instancia, puedes remitir tu solicitud a <a href="mailto:servicioposventa@placetopay.ec">servicioposventa@placetopay.ec</a>.
          </p>
        </li>
        <li className={classes.faqItem}>
          <p className={classes.faqQuestion}>¿Qué debo hacer si no recibí el comprobante de pago?</p>
          <p className={classes.faqAnswer}>Por cada transacción aprobada a través de PlacetoPay, recibirás
            un comprobante del pago con la
            referencia de compra en la dirección de correo electrónico que indicaste al momento de pagar. Si
            no lo recibes, podrás contactar a la línea 1800BMIBMI o al correo electrónico
            postventa@bmi.com.ec , para solicitar el reenvío del comprobante a la misma dirección de correo
            electrónico registrada al momento de pagar. En última instancia, puedes remitir tu solicitud a <a href="mailto:servicioposventa@placetopay.ec">servicioposventa@placetopay.ec</a>.
          </p>
        </li>
      </ol>

    </div>
  );
};

export default FAQ;
