export const REQUEST_POST_RE_QUOTATION = 'REQUEST_POST_RE_QUOTATION';
export const SUCCESS_POST_RE_QUOTATION = 'SUCCESS_POST_RE_QUOTATION';
export const FAILURE_POST_RE_QUOTATION = 'FAILURE_POST_RE_QUOTATION';
export const RESET_RE_QUOTATION = 'RESET_RE_QUOTATION';

export const requestPostReQuotation = (data) => ({
  type: REQUEST_POST_RE_QUOTATION,
  payload: data,
});

export const successPostReQuotation = (response) => ({
  type: SUCCESS_POST_RE_QUOTATION,
  payload: response,
});

export const failurePostReQuotation = (error) => ({
  type: FAILURE_POST_RE_QUOTATION,
  payload: error,
});

export const resetReQuotation = () => ({
  type: RESET_RE_QUOTATION,
});
