import * as actions from '../actions/client_information';

const INITIAL_STATE = {
  loading: false,
  response: {},
  isError: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.REQUEST_CLIENT_INFORMATION:
      return {
        ...state,
        loading: true,
        response: {},
        isError: false,
      };
    case actions.SUCCESS_CLIENT_INFORMATION:
      return {
        ...state,
        loading: false,
        response: action.payload,
        isError: false,
      };
    case actions.FAILURE_CLIENT_INFORMATION:
      return {
        ...state,
        loading: false,
        response: action.payload,
        isError: true,
      };
    case actions.RESET_CLIENT_INFORMATION:
      return {
        ...state,
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

// selectors
export const getClientInformation = (state) => state.clientInformation;
