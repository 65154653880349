import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';

import CustomPanel from '../../../../common/CustomPanel';
import { checkEmptyVTValue, isEmpty, usePrevious } from '../../../../../utils/CommonUtils';
import { PERSONAL_ACCOUNT_MESSAGE } from '../../../../legal.messages';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  iframe: {
    width: '100%',
    minHeight: 640,
    border: 0,
  },
  select: {
    margin: theme.spacing(1),
    minWidth: '95%',
  },
}));

const setRefundForm = (insuranceApplicationRefund, setValue) => {
  setValue([
    { 'refund.accountNumber': insuranceApplicationRefund?.AccountNumber.trim() || '' },
    { 'refund.financialInstitution': insuranceApplicationRefund?.FinancialInstitution.trim() || '' },
    { 'refund.accountType': insuranceApplicationRefund?.AccountType.trim() || '' },
  ]);
};


const AccountRefunds = (props) => {
  const { readOnly, withDifferentHolder } = props;
  const classes = useStyles();
  const { control, errors, setValue } = useFormContext();
  const { response: { catalogs = [] } } = useSelector((state) => state.catalogs, shallowEqual);
  const getApplication = useSelector((state) => state.getApplication);
  const prevGetApplication = usePrevious(getApplication);

  const financialInstitution = catalogs && catalogs.find((c) => c.id === 'InstitucionFinancieraReembolso')?.catalogue;
  const clientInfo = useSelector((state) => state.clientInfo);

  const prevClientInfo = usePrevious(clientInfo);
  const findByIdentificationResult = useSelector((state) => state.findByIdentificationResult);

  useEffect(() => {
    if (prevClientInfo && (clientInfo.response !== prevClientInfo.response)) {
      if (!withDifferentHolder) {
        setClientInformation(clientInfo.response.clientDto);
      }
    }
  }, [clientInfo.response]);

  useEffect(() => {
    if (prevGetApplication && (prevGetApplication.response !== getApplication.response)) {
      if (!isEmpty(getApplication.response)) {
        setRefundForm(getApplication.response.InsuranceApplicationRefund, setValue);
      }
    }
  }, [getApplication, prevGetApplication]);

  const setClientInformation = (client) => {
    setValue('refund.accountType', client?.nAccType?.toString() || '');
    setValue('refund.accountNumber', client?.sAccount || '');
    setValue('refund.financialInstitution', checkEmptyVTValue(client?.nBankCode));
  };

  useEffect(() => {
    if (findByIdentificationResult.found) {
      setClientInformation(findByIdentificationResult.response.clientDto);
    }
  }, [findByIdentificationResult.response]);

  const numeroCuentaRef = useRef();
  const bancoRef = useRef();

  return (
    <CustomPanel title="Cuenta Reembolsos" border={false}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item md={6} xs={12}>
          <Controller
            as={(
              <TextField
                inputRef={numeroCuentaRef}
                onKeyPress={(e) => {
                  const key = e.which;
                  if (!(key >= 48 && key <= 57)) {
                    e.preventDefault();
                  }
                }}
              />
            )}
            onFocus={() => numeroCuentaRef.current.focus()}
            fullWidth
            label="NÚMERO DE CUENTA"
            autoComplete="off"
            name="refund.accountNumber"
            control={control}
            error={errors.refund?.hasOwnProperty('accountNumber')}
            helperText={
              errors.refund?.accountNumber && errors.refund?.accountNumber.message
            }
            defaultValue=""
            InputProps={{ readOnly }}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <FormControl
            className={classes.select}
            error={errors.refund?.financialInstitution && true}
          >
            <InputLabel id="region-select">INSTITUCIÓN FINANCIERA</InputLabel>
            <Controller
              as={(
                <Select inputRef={bancoRef}>
                  <MenuItem value="">
                    <em>Seleccione..</em>
                  </MenuItem>
                  {financialInstitution?.map((b) => (
                    <MenuItem key={b.id} value={b.id}>
                      {b.description}
                    </MenuItem>
                  ))}
                </Select>
              )}
              onFocus={() => bancoRef.current.focus()}
              name="refund.financialInstitution"
              control={control}
              defaultValue=""
              inputProps={{ readOnly }}
            />
            {errors.refund?.financialInstitution
            && (
              <FormHelperText>
                {errors.refund?.financialInstitution.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControl component="fieldset" error={errors.refund?.accountType && true}>
            <Controller
              as={(
                <RadioGroup row className={classes.checkBoxes}>
                  <FormControlLabel
                    value="1"
                    control={<Radio disabled={readOnly} />}
                    label="Ahorros"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio disabled={readOnly} />}
                    label="Corriente"
                  />
                </RadioGroup>
              )}
              name="refund.accountType"
              control={control}
              defaultValue="1"
            />
            {errors.refund?.accountType
            && (
              <FormHelperText>
                {errors.refund?.accountType.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>

          <IconButton
            edge="start"
          >
            <InfoIcon style={{ color: '#e53935' }} />
          </IconButton>
          &nbsp;
          {PERSONAL_ACCOUNT_MESSAGE}
        </Grid>
      </Grid>
    </CustomPanel>
  );
};

AccountRefunds.defaultProps = {
  withDifferentHolder: false,
};

AccountRefunds.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  withDifferentHolder: PropTypes.bool,
};

export default AccountRefunds;
