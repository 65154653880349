/* eslint-disable no-use-before-define */
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../../utils/Api';

import {
  failedRequestPaymentData,
  START_REQUEST_PAYMENT_DATA,
  successRequestPaymentData,
} from '../actions/signing';

export function* watchRequestPaymentData() {
  yield takeLatest(START_REQUEST_PAYMENT_DATA, requestPaymentDataSaga);
}

export function* requestPaymentDataSaga({ payload: saleProcessId }) {
  try {
    const paymentData = yield call(fetchPaymentData, saleProcessId);
    yield put(successRequestPaymentData(paymentData.data));
  } catch (error) {
    yield put(failedRequestPaymentData(error));
    console.log('Error requestCatalogsSaga: ', error);
  }
}

export const fetchPaymentData = (saleProcessId) => api.get(`${process.env.REACT_APP_PAYMENT_DATA}?saleProcessId=${saleProcessId}`);
