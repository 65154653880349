import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loading: false,
  sessionData: {},
  errorMessage: '',
  isSuccess: false,
};

const paymentButtonSlice = createSlice({
  name: 'payment',
  initialState: INITIAL_STATE,
  reducers: {
    getSessionStart(state) {
      state.loading = true;
    },
    getSessionSuccess(state, action) {
      state.loading = false;
      state.sessionData = action.payload;
      state.isSuccess = true;
    },
    getSessionFailed(state, action) {
      state.loading = false;
      state.errorMessage = action.payload;
      state.isSuccess = false;
    },
  },
});

export const { getSessionStart, getSessionSuccess, getSessionFailed } = paymentButtonSlice.actions;
export default paymentButtonSlice.reducer;
