import { makeStyles } from '@material-ui/core/styles';

const headerStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '80%',
  },
  buttonStyle: {
    color: theme.palette.white,
    [theme.breakpoints.down(500)]: {
      width: '30px !important',
      height: '30px !important',
    },
  },
  input: {
    height: 5,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  shareButtons: {
    [theme.breakpoints.down('lg')]: {
      marginTop: '0px !important',
    },
    [theme.breakpoints.down(1280)]: {
      marginLeft: '60% !important',
      marginRight: '5% !important',
    },
    [theme.breakpoints.down(500)]: {
      marginLeft: '60% !important',
      marginRight: '5% !important',
    },
    [theme.breakpoints.down(400)]: {
      marginLeft: '60% !important',
      marginRight: '5% !important',
    },
    [theme.breakpoints.down(350)]: {
      marginLeft: '60% !important',
      marginRight: '15% !important',
    },
  },
  pdfButtons: {
    [theme.breakpoints.down('lg')]: {
      marginTop: '0px !important',
    },
    [theme.breakpoints.down(1280)]: {
      marginLeft: '-5% !important',
      marginRight: '5% !important',
    },
    [theme.breakpoints.down(550)]: {
      marginLeft: '-10% !important',
    },
    [theme.breakpoints.down(480)]: {
      marginLeft: '-15% !important',
    },
    [theme.breakpoints.down(427)]: {
      marginLeft: '-45% !important',
    },
    [theme.breakpoints.down(350)]: {
      marginLeft: '-59% !important',
    },
  },
  sildebarButtons: {
    marginLeft: '-100% !important',
    [theme.breakpoints.down(500)]: {
      width: '30px !important',
      height: '30px !important',
      marginTop: 10,
      marginLeft: '-79% !important',
    },
    [theme.breakpoints.down(400)]: {
      width: '30px !important',
      height: '30px !important',
      marginTop: 10,
      marginLeft: '-84% !important',
    },
    [theme.breakpoints.down(350)]: {
      width: '30px !important',
      height: '30px !important',
      marginTop: 10,
      marginLeft: '-89% !important',
    },
  },
  acceptQuote: {
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      marginLeft: theme.spacing(40),
      width: '30%',
      borderRadius: 25,
      marginTop: 45,
    },
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      marginLeft: '20%',
      width: '50%',
      borderRadius: 25,
      marginTop: 10,
    },
    [theme.breakpoints.down(500)]: {
      position: 'absolute',
      marginLeft: '20%',
      width: '50%',
      borderRadius: 25,
      marginTop: 10,
      fontSize: '10px',
    },
    [theme.breakpoints.down(400)]: {
      position: 'absolute',
      marginLeft: '20%',
      width: '52%',
      borderRadius: 25,
      marginTop: 10,
      fontSize: '10px',
    },
    [theme.breakpoints.down(370)]: {
      position: 'absolute',
      marginLeft: '20%',
      width: '55%',
      borderRadius: 25,
      marginTop: 10,
      fontSize: '10px',
    },
    backgroundColor: '#00315C',
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#518CBF', color: '#ffffff' },
  },
  containerShareButton: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: '100%',
    },
    [theme.breakpoints.down(500)]: {
      marginLeft: '80%',
    },
    [theme.breakpoints.down(400)]: {
      marginLeft: '85%',
    },
    [theme.breakpoints.down(350)]: {
      marginLeft: '90%',
    },
  },
  nav: {
    width: '100%',
  },
  iconColor: {
    color: 'black',
  },
}));

export default headerStyles;
