import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import { SidebarForm } from './components';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('lg')]: {
      marginTop: 130,
      height: 'calc(100% - 130px)',
      width: 318,
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100%)',
      width: 300,
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc(100% - 130px)',
      width: 318,
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(0),
    borderRight: '5px solid #e6e6e6',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, isDesktop, ...rest } = props;
  const classes = useStyles({ props });

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <SidebarForm
          className={classes.nav}
          isDesktop={isDesktop}
          onClose={onClose}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

export default Sidebar;
