/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from 'redux-saga/effects';
import { trackPromise } from 'react-promise-tracker';

import { failureQuote, REQUEST_QUOTE, successQuote } from '../actions/quote';
import api from '../../../utils/Api';
import { getLocalDateTime } from '../../../utils/CommonUtils';
import { areas } from '../../../utils/Constants';

export function* watchRequestQuote() {
  yield takeLatest(REQUEST_QUOTE, requestQuoteSaga);
}

function* requestQuoteSaga({ payload: quoteInfo }) {
  try {
    // eslint-disable-next-line no-param-reassign
    quoteInfo.ValidityDate = getLocalDateTime();
    // eslint-disable-next-line no-param-reassign
    delete quoteInfo.SaleProcessId;
    const response = yield call(fetchQuote, quoteInfo);
    yield put(successQuote(response.data, quoteInfo));
  } catch (e) {
    yield put(failureQuote(e));
  }
}

const fetchQuote = (quoteInfo) => trackPromise(api.post(
  process.env.REACT_APP_QUOTE_URL, quoteInfo,
), areas.quotation);
