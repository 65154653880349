import { call, put, takeLatest } from 'redux-saga/effects';
import { trackPromise } from 'react-promise-tracker';
import { requestUpdateTokenSendEmail, successUpdateTokenSendEmail, failureUpdateTokenSendEmail } from '../reducers/updateTokenSendEmailSlice';
import api from '../../../utils/Api';

export function* watchRequestUpdateTokenSendEmail() {
  yield takeLatest(requestUpdateTokenSendEmail.type, requestSendUpdateTokenEmailSaga);
}

export function* requestSendUpdateTokenEmailSaga({ payload }) {
  try {
    yield call(fetchEmail, payload);
    yield put(successUpdateTokenSendEmail());
  } catch (e) {
    yield put(failureUpdateTokenSendEmail(e));
  }
}

export const fetchEmail = (payload) => trackPromise(api
  .post(process.env.REACT_APP_SEND_UPDATE_TOKEN_EMAIL_URL, payload));
