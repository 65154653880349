import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const gray = '#E6E6E6';

export default {
  black,
  white,
  gray,
  primary: {
    contrastText: white,
    dark: '#00315C',
    darkLight: '#00517b',
    main: '#518CBF',
    light: '#ffffff',
    redAlert: '#e53935',
  },
  secondary: {
    contrastText: white,
    dark: '#ffffff',
    main: '#00315C',
    light: '#ffffff',
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: '#518CBF',
    secondary: '#303838',
    link: colors.blue[600],
    grayMessage: '#aaaaaa',
  },
  background: {
    default: '#F4F6F8',
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
};
