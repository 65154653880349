export const START_REQUEST_USERS_RE_ASSIGN = 'START_REQUEST_USERS_RE_ASSIGN';
export const REQUEST_USERS_RE_ASSIGN_SUCCESS = 'REQUEST_USERS_RE_ASSIGN_SUCCESS';
export const REQUEST_USERS_RE_ASSIGN_FAILED = 'REQUEST_USERS_RE_ASSIGN_FAILED';

/* Getting all users */
export const requestUsersReAssign = () => ({
  type: START_REQUEST_USERS_RE_ASSIGN,
});

export const successRequestUsersReAssign = (users) => ({
  type: REQUEST_USERS_RE_ASSIGN_SUCCESS,
  payload: users,
});

export const failedRequestUsersReAssigns = (error) => ({
  type: REQUEST_USERS_RE_ASSIGN_FAILED,
  payload: error,
});
