import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Content, Sidebar } from './components';
import quotationStyles from './style/quotation-style';
import { startSignIn } from '../Login/actions/login';
import { authProvider } from '../Login/auth/auth_provider';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Quotation = () => {
  const query = useQuery();
  const classes = quotationStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const dispatch = useDispatch();

  const [openSidebar, setOpenSidebar] = useState(true);

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const shouldOpenSidebar = !!isDesktop;

  useEffect(() => {
    const isNew = query.get('new');
    if (isNew) {
      dispatch(startSignIn(authProvider));
    }
  }, []);

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <div>
        <Sidebar
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          variant={isDesktop ? 'persistent' : 'temporary'}
          isDesktop={isDesktop}
        />
        <Content
          className={classes.content}
          onOpen={handleSidebarOpen}
          isDesktop={isDesktop}
        />
      </div>
    </div>
  );
};

export default Quotation;
