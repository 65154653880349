/* eslint-disable import/prefer-default-export */
import { watchRequestQuote } from './quote';
import { watchRequestQuotationPdf } from './quotatationUtils';
import { watchRequestSendEmail } from './send_email';
import { watchRequestReQuotation } from './reQuotation';

export const quoteSagas = [
  watchRequestQuote,
  watchRequestQuotationPdf,
  watchRequestSendEmail,
  watchRequestReQuotation,
];
