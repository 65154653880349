export const START_REQUEST_PAYMENT_DATA = 'START_REQUEST_PAYMENT_DATA';
export const REQUEST_PAYMENT_DATA_SUCCESS = 'REQUEST_PAYMENT_DATA_SUCCESS';
export const REQUEST_PAYMENT_DATA_FAILED = 'REQUEST_PAYMENT_DATA_FAILED';

export const requestPaymentData = (data) => ({
  type: START_REQUEST_PAYMENT_DATA,
  payload: data,
});

export const successRequestPaymentData = (response) => ({
  type: REQUEST_PAYMENT_DATA_SUCCESS,
  payload: response,
});

export const failedRequestPaymentData = (error) => ({
  type: REQUEST_PAYMENT_DATA_FAILED,
  payload: error,
});
