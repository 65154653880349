/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { trackPromise } from 'react-promise-tracker';
import {
  failureClientInformation,
  REQUEST_CLIENT_INFORMATION,
  successClientInformation,
} from '../actions/client_information';
import api from '../../../../../utils/Api';
import { areas } from '../../../../../utils/Constants';

export function* watchRequestClientInformation() {
  yield takeLatest(REQUEST_CLIENT_INFORMATION, requestClientInformationSaga);
}

function* requestClientInformationSaga({ payload }) {
  try {
    const response = yield call(getClientInformation, payload);
    yield put(successClientInformation(response.data));
  } catch (e) {
    yield put(failureClientInformation(e));
  }
}

const getClientInformation = (parameters) => trackPromise(api
  .get(process.env.REACT_APP_CLIENT_INFORMATION_VALIDATION
    .concat('/', parameters.identification, '/', parameters.productId, '/', parameters.isHolder)), areas.global);
