/* eslint-disable react/no-array-index-key,max-len */
import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, FormContext, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import { get } from 'lodash';

import * as Constants from '../../../../../utils/Constants';
import { PICKER_FORMAT_DATE } from '../../../../../utils/Constants';
import CustomPanel from '../../../../common/CustomPanel';
import CashPayment from './cash/CashPayment';
import CreditPayment from './credit/CreditPayment';
import BankDebitPayment from './BankDebitPayment';
import {
  resetRequestData,
  resetWithReQuote,
  selectDeductible,
} from '../../../../Quotation/actions/quote';
import {
  checkEmptyObject,
  getAlwaysString,
  getPaymentConditionText,
  usePrevious,
} from '../../../../../utils/CommonUtils';
import { getSessionStart } from '../slices/payment_button';
import { WindowOpener } from '../../../../../utils/window-opener';
import paymentStyles from './style/payment-style';
import { resetPolicy, startCreatePolicy, successPolicy, failedPolicy } from '../slices/policy';
import PolicyDTO from '../PolicyDTO';
import { requestPostReQuotation } from '../../../../Quotation/actions/reQuotation';
import { addSweetAlert, closeSweetAlert } from '../../../../common/SweetAlert/actions/sweet_alert';
import SuccessPayment from './SuccessPayment';
import { openModal } from '../../../../common/modals/actions/simple_modal';
import { PaymentMethod, PaymentResult, PaymentType } from '../Enums';
import CashPaymentModal from './cash/CashPaymentModal';
import { selectValidityDate, selectValidityDateEnabled } from '../../Application/utils/selectors';
import CardDataForm from './credit/CardDataForm';

const schema = yup.object().shape({
  paymentType: yup.object().nullable().required('Se debe seleccionar Forma de Pago'),
  effectiveDate: yup.string().nullable().required('Este campo es requerido'),
  creditPayment: yup.object().shape({
    paymentCondition: yup.object().nullable().required('Este campo es requerido'),
  }).notRequired().default(undefined),
  debitPayment: yup.object().shape({
    paymentFrequency: yup.string().required('Este campo es requerido'),
    accountHolder: yup.string().required('Este campo es requerido'),
    bankAccount: yup.string().required('Debe ingresar un numero de cuenta'),
    bankCode: yup.string().required('Este campo es requerido'),
    accountType: yup.string().required('Este campo es requerido'),
    bankDescription: yup.string(),
  }).notRequired().default(undefined),
  cashPayment: yup.object().shape({
    dues: yup.object().nullable().required('Debe seleccionar una cuota'),
    image: yup.string(),
    imageName: yup.string().required('Proporcione una imagen de la transferencia'),
  }).notRequired().default(undefined),
});

const corrienteDiferidoCode = (paymentMethod) => ({
  Corriente: 1,
  Diferido: 2,
  Recurrente: 3,
})[paymentMethod];

const Payment = (props) => {
  const history = useHistory();
  const { handleNext } = props;
  const classes = paymentStyles();
  const dispatch = useDispatch();
  const formMethods = useForm({ validationSchema: schema });
  const formValues = formMethods.getValues({ nest: true });
  const formWatcher = formMethods.watch('creditPayment.selectedCard');
  const paymentTypeWatcher = formMethods.watch('paymentType');
  const effectiveDateWatcher = formMethods.watch('effectiveDate');

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [textBackdrop, setTextBackDrop] = React.useState('');
  const [paymentRequest, setPaymentRequest] = React.useState({});

  const quote = useSelector((state) => state.quote);
  const { deductibleSelected = {}, requestData = {} } = quote;
  const typeId = deductibleSelected?.typeId;

  const application = useSelector((state) => state.application);

  const reQuotation = useSelector((state) => state.reQuotation);
  const prevRequotation = usePrevious(reQuotation);
  const [quoteRequest, setQuoteRequest] = React.useState({});

  const policy = useSelector((state) => state.policy);
  const previousPolicy = usePrevious(policy);

  const paymentButton = useSelector((state) => state.paymentButton);
  const previousPaymentButton = usePrevious(paymentButton);
  const [responsabilityAssumed, setResponsabilityAssumed] = React.useState(false);
  const validityDate = useSelector(selectValidityDate);
  const validityDateEnabled = useSelector(selectValidityDateEnabled);
  const [tempPaymentType, setTempPaymentType] = React.useState();

  const cardDataResponse = useSelector((state) => state.simpleModal);

  // totalToPay for BankDebitPayment screen
  const [totalToPay, setTotalToPay] = React.useState(deductibleSelected?.total);
  /**
   * Open or close backdrop component.
   * */
  const handleToggle = () => {
    setOpenBackdrop(!openBackdrop);
  };

  const createDebitPolicy = (data) => new PolicyDTO(
    requestData?.SaleProcessId,
    data.paymentType.typeId,
    Number(data.debitPayment.paymentFrequency),
    data.debitPayment.bankAccount,
    undefined,
    Number(data.debitPayment.accountType),
    data.debitPayment.bankCode,
    undefined,
    undefined,
    undefined,
    undefined,
    data.debitPayment.bankDescription,
    undefined,
  );

  const createCashPolicy = (data) => new PolicyDTO(
    requestData?.SaleProcessId,
    data.paymentType.typeId,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    data.cashPayment.image,
    data.cashPayment.dues.amount,
    data.cashPayment.dues.quota,
  );

  const createPolicyPayload = (saleProcessId, reference, paymentRequestData) => {

    let numeroCuotasTarjeta = paymentRequestData?.creditPayment?.paymentCondition?.monthsToDefer;
    if (corrienteDiferidoCode(paymentRequestData?.creditPayment?.paymentCondition?.paymentMethod) === 3) {
      numeroCuotasTarjeta = paymentRequestData?.creditPayment?.paymentCondition?.dues;
    }

    const isPayPhone = paymentRequestData?.creditPayment?.selectedCard?.provider === 'PAYPHONE';
    const isPlaceToPay = paymentRequestData?.creditPayment?.selectedCard?.provider === 'PLACETOPAY';

    return new PolicyDTO(
      saleProcessId,
      paymentRequestData.paymentType?.typeId,
      undefined,
      undefined,
      paymentRequestData?.creditPayment?.selectedCard?.bmiCode,
      3,
      paymentRequestData?.creditPayment?.financialInstitution?.bmiCode.toString(),
      corrienteDiferidoCode(paymentRequestData?.creditPayment?.paymentCondition?.paymentMethod),
      paymentRequestData?.creditPayment?.paymentCondition?.applyInterest ? 2 : 1,
      numeroCuotasTarjeta,
      isPlaceToPay ? 1 : 2,
      undefined,
      isPlaceToPay ? reference : null,
      isPayPhone ? reference : null,
    )

  };

  const onSubmit = (data) => {
    handleToggle();
    const paymentTypeId = data.paymentType.typeId;
    setPaymentRequest(data);
    if (paymentTypeId === PaymentType.DebitoBancario) {
      setTextBackDrop(Constants.PROCESSING_PAYMENT);
      dispatch(startCreatePolicy(createDebitPolicy(data)));
    } else if (paymentTypeId === PaymentType.Contado) {
      setTextBackDrop(Constants.PROCESSING_PAYMENT);
      dispatch(startCreatePolicy(createCashPolicy(data)));
    } else if (paymentTypeId === PaymentType.Credito || paymentTypeId === PaymentType.DiferidoEspecial) {
      if (data.creditPayment.selectedCard.provider === 'PAYPHONE') {
        const { paymentMethod } = get(formMethods.getValues(), 'creditPayment.paymentCondition');
        const cardData = {
          saleProcessId: quote.requestData?.SaleProcessId,
          paymentConditionId: data.creditPayment.paymentCondition.id,
          paymentMethod: paymentTypeWatcher.typeId,
          creditCardName: formValues.creditPayment.selectedCard.name,
          isDeferred: paymentMethod === PaymentMethod.Diferido,
          interests: paymentMethod === PaymentMethod.Diferido
            && getPaymentConditionText(get(formMethods.getValues(), 'creditPayment.paymentCondition')),
          policyPayload: createPolicyPayload(quote.requestData?.SaleProcessId, null, data)
        };
        dispatch(openModal(cardData));
      } else if (data.creditPayment.selectedCard.provider === 'PLACETOPAY') {
        setTextBackDrop('Procesando su pago con PlaceToPay');
        dispatch(getSessionStart({
          quotationPaymentMethodId: data.paymentType?.typeId,
          paymentConditionId: data.creditPayment?.paymentCondition?.id,
          saleProcessId: quote.requestData?.SaleProcessId,
        }));
      }
    }
  };

  useEffect(() => {
    setEffectiveDate();
    setPaymentType();
    dispatch(resetPolicy());
  }, []);

  const setEffectiveDate = () => {
    let defaultDate = new Date(validityDate);
    if (deductibleSelected?.typeId === PaymentType.DebitoBancario) {
      defaultDate = '';
    }
    formMethods.setValue('effectiveDate', defaultDate);
  };

  /**
   * Found the paymentType object inside deductibleSelected and
   * set this on react hook form state.
   * * */
  const setPaymentType = () => {
    const paymentTypeMatch = deductibleSelected?.deductible?.paymentType
      .find((d) => d.typeId === deductibleSelected?.typeId);
     // .find((d) => d.typeId === 3);  //todo quitar en abril
    formMethods.setValue('paymentType', paymentTypeMatch);
    setTempPaymentType(paymentTypeMatch);
  };

  /**
   * Dispatch an action than update selectDeductible object
   * inside quote slice reducer.
   * @param payment is paymentType object selected of the current deductible.
   * */
  useEffect(() => {
    if (!checkEmptyObject(paymentTypeWatcher)) {
      dispatch(selectDeductible(deductibleSelected?.deductible, paymentTypeWatcher));
      setTotalToPay(paymentTypeWatcher?.value);
      setTempPaymentType(paymentTypeWatcher);
      // This tempPaymentType is to avoid bucle on paymentTypeWatcher
      if (tempPaymentType?.typeId !== paymentTypeWatcher?.typeId) {
        setEffectiveDate();
      }
    }
  }, [paymentTypeWatcher]);

  useEffect(() => {
    // FIXME: should be called only when datepicker value change, not on first render.
    reQuote(effectiveDateWatcher);
  }, [effectiveDateWatcher]);

  useEffect(() => {
    if (previousPaymentButton && previousPaymentButton.sessionData !== paymentButton.sessionData) {
      if (paymentButton.sessionData?.status?.status === 'OK') {
        const popup = new WindowOpener(sonBridge, paymentButton.sessionData?.processURL);
        popup.onClickHandler();
      }
    }
  }, [paymentButton.sessionData, previousPaymentButton]);

  useEffect(() => {
    const { titular, plan, totalAmount, metodoPago } = policy.response;
    if (previousPolicy && previousPolicy.response !== policy.response) {
      flying.current = false;
      if (policy.isSuccess) {
        if (policy.response?.successful) {
          handleToggle();
          if (isCash(paymentRequest?.paymentType?.typeId)) {
            dispatch(openModal());
          } else if (isCredit(paymentRequest?.paymentType?.typeId)) {
            dispatch(openModal({ titular, plan, totalPagado: totalAmount, metodoPago }));
          } else {
            handleNext();
          }
        }
      } else if (policy.error) {
        let title = 'No se ha podido realizar la emisión, por favor consulte con el administrador.';
        let targetPage = '/emissions';
        let errorType = 'info';
        let content = '';
        if (policy?.error?.code && policy?.error?.code === 'ECONNABORTED') {
          title = 'El contrato se encuentra en proceso de creaci\u00F3n, por favor revise en unos minutos el estado en el reporte de emisiones.';
          errorType = 'info';
        }
        if (policy?.error?.response?.data?.paymentResponse?.result) {
          const { paymentResponse } = policy.error.response.data;
          title = paymentResponse.result === PaymentResult.PENDING ? 'Tarjeta pendiente' : 'Poliza no creada';
          content = paymentResponse.message;
          errorType = paymentResponse.result === PaymentResult.PENDING ? 'info' : 'error';
          targetPage = '/quotation';
        }
        dispatch(addSweetAlert({
          type: errorType,
          title,
          showCancel: false,
          content,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
            history.push(targetPage);
          },
        }));
      }
    }
  }, [handleToggle, policy, previousPolicy]);

  useEffect(() => {
    const result = cardDataResponse.result;
    if (result?.result !== undefined && !policy.isSuccess) {
      if (result.result === 1) {
        setOpenBackdrop(true);
        sonBridge('success', result.paymentReference, result?.policyResponse);
      } else if (result.result === 3) {
        dispatch(addSweetAlert({
          type: 'warning',
          title: 'Aviso',
          content: result.errorMessage,
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
            setOpenBackdrop(false);
            cardDataResponse.result = undefined;
          },
        }));
      }
    } else if (result?.canceled) {
      setOpenBackdrop(false);
    } else {
      setOpenBackdrop(false);
    }
  }, [cardDataResponse]);

  /**
   * This function receives messages from the son window.
   * */
  const flying = useRef(false);
  const sonBridge = (type, reference, policyResponse = {}) => {
    if (type === 'success') {
      flying.current = true;
      setTextBackDrop(Constants.PROCESSING_PAYMENT);
      switch (paymentRequest?.creditPayment?.selectedCard?.provider) {
        case 'PLACETOPAY':
          const policyDTO = createPolicyPayload(requestData?.SaleProcessId, reference, paymentRequest);
          dispatch(startCreatePolicy(policyDTO));
          break;
        case 'PAYPHONE':
          if (policyResponse?.successful)
            dispatch(successPolicy(policyResponse));
          if (!policyResponse?.successful)
            dispatch(failedPolicy(policyResponse));
          break;
        default:
          setOpenBackdrop(false);
          break;
        }
    }
    if (type === 'closed' && !flying.current) {
      handleToggle();
    }
  };

  const reQuote = (effectiveDate) => {
    if (effectiveDate) {
      let paymentMethodId = 0;

      if (deductibleSelected.paymentType) {
        const paymentMethod = deductibleSelected.deductible.paymentType.find(
          (p) => p.typeDescription === deductibleSelected.paymentType,
        );
        paymentMethodId = paymentMethod.typeId;
      } else {
        paymentMethodId = deductibleSelected.typeId;
      }
      requestData.TargetValidityDate = getAlwaysString(effectiveDate);
      requestData.PaymentMethod = paymentMethodId;
      setQuoteRequest(requestData);

      dispatch(requestPostReQuotation(requestData));
    }
  };

  const renderDatePicker = () => {
    
   const maxDate = moment().add(15, 'day');   
   /*const maxDate = (deductibleSelected.typeId === 4 || deductibleSelected.typeId === 2 ) ? moment().endOf('month').add(1, 'day'):moment().add(15, 'day'); /*quitar condicion descomentar solo deja al fin de mes*/
  const endDateYear = moment().endOf('year').add(1, 'day');
  return (<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        as={(
          <KeyboardDatePicker />
        )}
        autoOk
        disabled={validityDateEnabled || deductibleSelected.typeId === 3}
        className={classes.effectiveDateCredit}
        minDate={moment().add(1, 'day').format('YYYY-MM-DD')}
        maxDate={maxDate.diff(endDateYear, 'minutes') > 0 ? endDateYear.format('YYYY-MM-DD') : maxDate.format('YYYY-MM-DD')}  
        /*maxDate={(deductibleSelected.typeId === 4 || deductibleSelected.typeId === 2 ) ? maxDate.format('YYYY-MM-DD'):(maxDate.diff(endDateYear, 'minutes') > 0 ? endDateYear.format('YYYY-MM-DD') : maxDate.format('YYYY-MM-DD'))}/*quitar condicion descomentar solo deja al fin de mes*/
        disableToolbar
        fullWidth
        variant="inline"
        format={PICKER_FORMAT_DATE}
        margin="normal"
        name="effectiveDate"
        control={formMethods.control}
        label="FECHA DE INICIO DE VIGENCIA"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        InputProps={{ readOnly: true }}
        error={formMethods.errors?.hasOwnProperty('effectiveDate')}
        helperText={formMethods?.errors.effectiveDate?.message}
      />
    </MuiPickersUtilsProvider>
  )};

  useEffect(() => {
    if (prevRequotation && (reQuotation.response !== prevRequotation.response)) {
      if (!checkEmptyObject(reQuotation?.response) && !reQuotation.isError) {
        const { result, messages } = reQuotation.response.validationResult;
        const { typeId, deductible: { paymentType } } = reQuotation.response.deductibleSelected;
        const payment = paymentType.find((p) => p.typeId === typeId);
        if (result === 'SUCCESS') {
          reQuotation.response.deductibleSelected.paymentType = payment.typeDescription;
          dispatch(resetWithReQuote(quoteRequest, reQuotation.response.deductibleSelected));
          formMethods.setValue('paymentType', payment);
        } else {
          dispatch(addSweetAlert({
            type: 'error',
            title: 'Edades Incorrectas',
            content: (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                {
                  messages.map((item, index) => (
                    <Grid key={index} item md={12} xs={12}>
                      <Typography variant="h6">{item}</Typography>
                    </Grid>
                  ))
                }
              </Grid>),
            showCancel: result === 'CONTINUE_WITH_CLIENT_ACCEPTANCE',
            onCancelLabel: 'Cancelar',
            onConfirmLabel: 'Continuar',
            onCancel: () => {
              dispatch(resetRequestData(quoteRequest));
              dispatch(closeSweetAlert());
            },
            onConfirm: () => {
              formMethods.setValue('paymentType', payment);
              reQuotation.response.deductibleSelected.paymentType = payment.typeDescription; // ???
              dispatch(resetWithReQuote(quoteRequest, reQuotation.response.deductibleSelected));
              dispatch(closeSweetAlert());
            },
          }));
        }
      }
    }
  }, [reQuotation.response]);

  const handleChange = (event) => {
    setResponsabilityAssumed(event.target.checked);
  };

  /**
   * Show a select date with 1 and 16 day of current month
   * or the next month.
   * */
  const renderDatePickerBankDebit = () => {
    const availableDates = calcDebitAvailableDates();
    return (
      <FormControl
        fullWidth
        error={formMethods.errors?.hasOwnProperty('effectiveDate')}
      >
        <InputLabel>FECHA DE INICIO DE VIGENCIA</InputLabel>
        <Controller
          as={(
            <Select>
              <MenuItem value=""><em>Seleccione...</em></MenuItem>
              {availableDates.map((date, index) => (
                <MenuItem
                  key={index}
                  value={date.format('YYYY-MM-DD')}
                >
                  {date.format('DD-MM-YYYY')}
                </MenuItem>
              ))}
            </Select>
          )}
          name="effectiveDate"
          control={formMethods.control}
          defaultValue=""
        />
        <FormHelperText>
          {formMethods?.errors.effectiveDate?.message}
        </FormHelperText>
      </FormControl>
    );
  };

  const calcDebitAvailableDates = () => {
    
    const sixteenDayOfMonth = truncMoment().set('date', 16);
    const fifteenDayOfMonth = truncMoment().set('date', 15);
    const currentDate = truncMoment();
    const lastDayOfMonth = moment().endOf('month');
    const availableDates = [];
    if (currentDate.isSameOrBefore(fifteenDayOfMonth)) {
      let addDate16 =  truncMoment().set('date', 16);
      if(moment().month() === 11 && moment().date() > 15) {
        addDate16 = truncMoment().set('date', 30); 
        
      }
      if(moment().year() === addDate16.year() ){
        availableDates.push(addDate16);
      }
      /*const addDateMonth =  truncMoment().add(1, 'month').set('date', 1);
      if(moment().year() === addDateMonth.year() ){
        availableDates.push(addDateMonth);
      }*/
    } 
    else if (currentDate.isSameOrAfter(sixteenDayOfMonth)) {
      let addDate16 =  truncMoment().add(1, 'month').set('date', 1);
      if(moment().month() === 11 && moment().date() > 15) {
        addDate16 = truncMoment().set('date', 30); 
        
      }
      if(moment().year() === addDate16.year() ){
        availableDates.push(addDate16);
      }
      const addDateMonth =  truncMoment().add(1, 'month').set('date', 16);
      if(moment().year() === addDateMonth.year() ){
        if(lastDayOfMonth.isSame(currentDate,  'day')) {
          availableDates.push(addDateMonth);
        }
      }
    }

    return availableDates;
  };

  const truncMoment = () => moment().startOf('day');

  const renderModal = () => {
    if (policy.isSuccess) {
      if (paymentRequest?.paymentType?.typeId === PaymentType.Contado) {
        return <CashPaymentModal />;
      }
      return <SuccessPayment handleNext={handleNext} />;
    } if (formWatcher?.provider !== undefined && formWatcher?.provider === 'PAYPHONE') {
      return <CardDataForm />;
    }
  };

  return (
    <>
      {renderModal()}
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
        <p className={classes.backdropText}>{textBackdrop}</p>
      </Backdrop>
      <FormContext {...formMethods}>
        <div className={classes.root}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <CustomPanel title="Pago" border={false}>
              <Grid container>
                <Grid item xs={6} className={classes.inlineContainer}>
                  <Typography
                    className={classes.textStyleSubtitle}
                    variant="subtitle2"
                  >
                    Titular
                  </Typography>
                  <Typography
                    className={clsx(classes.contentSubtitle1, classes.textStyleContent)}
                    variant="subtitle1"
                  >
                    {!quote?.requestData?.withDifferentHolder
                      ? quote?.requestData?.name
                      : application?.requestData?.insuranceHolder?.name?.concat(' ', application?.requestData?.insuranceHolder?.lastName)}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.inlineContainer}>
                  <Typography
                    className={classes.textStyleSubtitle}
                    variant="subtitle2"
                  >
                    Plan
                  </Typography>
                  <Typography
                    className={clsx(classes.contentSubtitle1, classes.textStyleContent)}
                    variant="subtitle1"
                  >
                    {quote?.requestData?.planName}
                  </Typography>
                </Grid>
                <Grid component="div" item xs={12} className={classes.line} />
                <Grid
                  container
                  direction="row"
                  style={{ paddingRight: 20, paddingTop: 10 }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    justify="flex-end"
                    className={classes.inlineContainer}
                  >
                    <Typography
                      variant="subtitle2"
                      className={classes.totalLabel}
                    >
                      TOTAL A PAGAR
                    </Typography>
                    <div>&nbsp;</div>
                    <Typography
                      variant="subtitle2"
                      className={classes.totalValue}
                    >
                      {`USD ${isDebit(typeId) ? Math.round(totalToPay * 12 * 100) / 100 : totalToPay}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Data */}
              <Grid
                container
                className={classes.contentContainer}
                direction="row"
                spacing={2}
              >
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={formMethods.errors?.hasOwnProperty('paymentType')}
                  >
                    <InputLabel>FORMA DE PAGO</InputLabel>
                    <Controller
                      as={(
                        <Select autoFocus>
                          <MenuItem value="">
                            <em>
                              Seleccione una
                              opción
                            </em>
                          </MenuItem>
                          {deductibleSelected?.deductible?.paymentType
                          ?.map((payment, index) => (
                            //payment?.typeDescription != 'Débito Bancario' && //quitar abril
                            <MenuItem    
                              key={index}
                              value={payment}
                            >
                              {payment?.typeDescription}
                              {' '}
                              {payment?.value}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      name="paymentType"
                      control={formMethods.control}
                      defaultValue=""
                      IconComponent={(props) => (
                        <div
                          {...props}
                          className={`material-icons ${props.className}`}
                        >
                          <Typography style={{
                            backgroundColor: '#C44D4D',
                            color: '#ffffff',
                            fontSize: 13,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 5,
                          }}
                          >
                            CAMBIAR
                          </Typography>
                        </div>
                      )}
                    />
                    <FormHelperText>
                      {formMethods?.errors.paymentType?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {(deductibleSelected?.typeId !== 1 || validityDateEnabled) ? (
                    renderDatePicker()
                  ) : renderDatePickerBankDebit()}
                </Grid>
                {{
                  3: <CashPayment total={totalToPay} />,
                  2: <CreditPayment />,
                  1: <BankDebitPayment
                    effectiveDate={getAlwaysString(effectiveDateWatcher)}
                    deductibleTotal={deductibleSelected?.total}
                  />,
                  4: <CreditPayment />,
                }[typeId]}
              </Grid>
              <Grid container className={classes.buttonsContainer}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.saveButton}
                        type="submit"
                        disabled={!responsabilityAssumed}
                      >
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Grid container direction="row">
                        <Grid item xs={12}>&nbsp;</Grid>
                        <Grid
                          item
                          xs={2}
                          sm={1}
                          md={1}
                          lg={1}
                          xl={1}
                          className={classes.centered}
                        >
                          <Checkbox
                            color="secondary"
                            checked={responsabilityAssumed}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={10} sm={11} md={11} lg={11} xl={11}>
                          <Grid container direction="column">
                            <Grid item>
                              <div
                                className={classes.responsabilityAssumed}
                              >
                                Declaro que he cumplido los
                                t&eacute;rminos y condiciones de uso
                                de esta herramienta, he asesorado al
                                cliente a fin de que se complete la
                                información en forma adecuada, veraz
                                y que la información de pago que se
                                ha procesado es con la
                                autorizaci&oacute;n del cliente o
                                hecha por el mismo. Por tanto, me
                                responsabilizo de cualquier reclamo
                                que se podr&iacute;a generar por los
                                hechos antes descritos.
                              </div>
                            </Grid>
                            <Grid item>
                              <div
                                className={classes.responsabilityAssumed}
                              >
                                El agente declara que el cliente le
                                ha proporcionado la
                                informaci&oacute;n bancaria y le ha
                                autorizado para transaccionar con
                                sus datos.
                              </div>
                            </Grid>
                            <Grid item>
                              <div
                                className={classes.responsabilityAssumed}
                              >
                                De igual forma, el agente declara
                                que el cliente conifrm&oacute; que
                                los fondos provienen de actividades
                                l&iacute;citas.
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>&nbsp;</Grid>
                        <Grid item xs={12}>
                          <div className={classes.responsabilityAssumed}>
                            Recuerde que la oferta enviada es temporal y
                            que se debe culminar el proceso para la
                            contratación.
                            <br />
                            Puede realizar el pago durante los próximos
                            30 d&iacute;as. Advertimos que las
                            condiciones pueden variar, hasta que no se
                            ejecute el contrato.
                          </div>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CustomPanel>
          </form>
        </div>
      </FormContext>
    </>
  );
};

Payment.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default Payment;

function isCash(paymentType) {
  return paymentType === PaymentType.Contado;
}

function isCredit(paymentType) {
  return paymentType === PaymentType.Credito
    || paymentType === PaymentType.DiferidoEspecial;
}

function isDebit(paymentType) {
  return paymentType === PaymentType.DebitoBancario;
}
