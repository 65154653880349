import { call, put, takeLatest } from 'redux-saga/effects';
import { requestTokenUpdate, successTokenUpdate, failureTokenUpdate } from '../reducers/updateTokenSlice';
import api from '../../../utils/Api';

export function* watchRequestTokenUpdate() {
  yield takeLatest(requestTokenUpdate.type, requestSendTokenUpdateSaga);
}

export function* requestSendTokenUpdateSaga({ payload }) {
  try {
    const response = yield call(updateToken, payload);
    yield put(successTokenUpdate(response));
  } catch (e) {
    console.log(e);
    yield put(failureTokenUpdate(e));
  }
}

export const updateToken = (reference) => api
  .get(process.env.REACT_APP_SEND_TOKEN_UPDATE_URL, { params: { reference } });
