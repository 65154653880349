import {
  AAD_LOGIN_ERROR,
  // AAD_AUTHENTICATED_STATE_CHANGE,
  // AAD_LOGIN_SUCCESS,
} from '../actions/aad_login';

const initialState = {
  aadResponse: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AAD_LOGIN_ERROR:
      return { ...state, aadResponse: action.payload };
      // case AAD_AUTHENTICATED_STATE_CHANGE:
      //     return { ...state, loading: false, isError: false };
      // case AAD_LOGIN_SUCCESS:
      //     return { ...state, loading: false, response: action.payload, isError: false };
    default:
      return state;
  }
};
