import React, { useEffect } from 'react';
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  Radio,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { get } from 'lodash';

import CustomPanel from '../../../../common/CustomPanel';

/**
 * Covid19 Questions available temporarily.
 * @param fieldName the name of the field on the objet schema.
 * @param hasCovid this function allow to added a new medical condition on MainInsured or Dependent.
 * @param dependantIndex only passed for Dependent component indicate the position of the dependant
 * on the array.
 * @param hasResultPositive when the first covid question is true so the second question is showed
 * for this case is necessary pass explicitly the value and not wait the watch value of the first
 * question. Only passed on EditMode.
 * */
export default function Covid19Questions({ fieldName,
  hasCovid, dependantIndex, hasResultPositive }) {
  const { setValue, errors, watch, control, clearError } = useFormContext();
  const [testPerformed, setTestPerformed] = React.useState('');
  const [resultPositive, setResultPositive] = React.useState('');

  // watched multiple values. One MainInsured object, Two Dependent object.
  const questionTestPerformedWatch = watch([`${fieldName}.questionCovid19TestPerformed`]);

  useEffect(() => {
    if (questionTestPerformedWatch) {
      setTestPerformed(get(questionTestPerformedWatch,
        `${fieldName}.questionCovid19TestPerformed`)?.toString() || '');
    }
  }, [questionTestPerformedWatch, fieldName]);

  useEffect(() => {
    if (typeof hasResultPositive === 'boolean') {
      setResultPositive(hasResultPositive.toString() || '');
      setValue(`${fieldName}.questionCovid19ResultPositive`, hasResultPositive);
    }
  }, [hasResultPositive, fieldName]);

  const handleChangeTestPerformed = (event) => {
    if (event.target.value === 'false') {
      setValue(`${fieldName}.questionCovid19ResultPositive`, undefined);
      setResultPositive('');
      hasCovid(false, dependantIndex);
    }
    setValue(`${fieldName}.questionCovid19TestPerformed`, JSON.parse(event.target.value));
    clearError(`${fieldName}.questionCovid19TestPerformed`);
  };

  const handleChangeResultPositive = (event) => {
    setValue(`${fieldName}.questionCovid19ResultPositive`, JSON.parse(event.target.value));
    setResultPositive(event.target.value);
    clearError(`${fieldName}.questionCovid19ResultPositive`);

    if (event.target.value === 'true') {
      hasCovid(true, dependantIndex);
    } else {
      hasCovid(false, dependantIndex);
    }
  };

  return (
    <CustomPanel withoutTitle border>
      <Grid item>
        <FormControl
          component="fieldset"
          error={get(errors, fieldName)?.hasOwnProperty('questionCovid19TestPerformed')}
        >
          <FormLabel component="p">
            Se ha realizado alguna prueba relacionada a COVID19?
            (Prueba PCR, Prueba Rápida , Prueba Cuantitativa y Cualitativa y/o cualquier otra).
          </FormLabel>
          <RadioGroup
            row
            aria-label="test performed"
            name="covid19TestPerformed"
            value={testPerformed}
            onChange={handleChangeTestPerformed}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Si"
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
          <FormHelperText>
            {get(errors, fieldName)?.questionCovid19TestPerformed?.message}
          </FormHelperText>
        </FormControl>
        <Controller
          as={TextField}
          name={`${fieldName}.questionCovid19TestPerformed`}
          control={control}
          type="hidden"
          defaultValue={undefined}
        />
      </Grid>
      {get(questionTestPerformedWatch, `${fieldName}.questionCovid19TestPerformed`) && (
        <Grid item>
          <FormControl
            component="fieldset"
            error={get(errors, fieldName)?.hasOwnProperty('questionCovid19ResultPositive')}
          >
            <FormLabel component="p">
              El resultado de esta prueba fue:
            </FormLabel>
            <RadioGroup
              row
              aria-label="test result"
              name="covid19ResultPositive"
              value={resultPositive}
              onChange={handleChangeResultPositive}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Positivo"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Negativo"
              />
            </RadioGroup>
            <FormHelperText>
              {get(errors, fieldName)?.questionCovid19ResultPositive?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
      )}
      <Grid item>
        <Controller
          as={TextField}
          name={`${fieldName}.questionCovid19ResultPositive`}
          control={control}
          type="hidden"
          defaultValue={undefined}
        />
      </Grid>
    </CustomPanel>
  );
}

Covid19Questions.defaultProps = {
  hasResultPositive: undefined,
  dependantIndex: undefined,
};

Covid19Questions.propTypes = {
  fieldName: PropTypes.string.isRequired,
  hasCovid: PropTypes.func.isRequired,
  dependantIndex: PropTypes.number,
  hasResultPositive: PropTypes.bool,
};
