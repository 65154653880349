/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { trackPromise } from 'react-promise-tracker';
import { failurePostReQuotation, REQUEST_POST_RE_QUOTATION, successPostReQuotation } from '../actions/reQuotation';
import api from '../../../utils/Api';
import { areas } from '../../../utils/Constants';


export function* watchRequestReQuotation() {
  yield takeLatest(REQUEST_POST_RE_QUOTATION, requestPostReQuotationSaga);
}

function* requestPostReQuotationSaga({ payload: reQuotation }) {
  console.log('Payload antes de la solicitud POST:', reQuotation);

  try {
    if (reQuotation.personalData) {
      const pd = reQuotation.personalData;
      if (pd.insuranceMainInsured) {
        const date = new Date(pd.insuranceMainInsured.birthDate);
        pd.insuranceMainInsured.birthDate = new Date(date.setHours(0, 0, 0, 0));
      }
      if (pd.insuranceHolder) {
        let date = '';
        if (reQuotation.withDifferentHolder) {
          date = new Date(pd.insuranceHolder.birthDate);
        } else {
          date = new Date(pd.insuranceMainInsured.birthDate);
        }
        pd.insuranceHolder.birthDate = new Date(date.setHours(0, 0, 0, 0));
      }
      
      if (pd.insuranceDependants) {
        pd.insuranceDependants.forEach((dep) => {
          const date = new Date(dep.birthDate);
          dep.birthDate = new Date(date.setHours(0, 0, 0, 0));
          dep.height=Number(dep.height);
          dep.weight=Number(dep.weight);
        });
      }
      reQuotation.personalData.insuranceMainInsured.height=Number(reQuotation.personalData.insuranceMainInsured.height);
      reQuotation.personalData.insuranceMainInsured.weight=Number(reQuotation.personalData.insuranceMainInsured.weight);
    }
    
    
    const response = yield call(fetchPostReQuotation, reQuotation);
    yield put(successPostReQuotation(response.data));
  } catch (e) {
    console.log(e);
    console.log('URL de la solicitud POST:', process.env.REACT_APP_RE_QUOTE);
    yield put(failurePostReQuotation(e));
  }
}

const fetchPostReQuotation = (issuance) => trackPromise(api.post(process.env.REACT_APP_RE_QUOTE, issuance), areas.global);
