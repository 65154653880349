/* eslint-disable import/prefer-default-export */
import {
  watchRequestEmployees,
} from './administrate_users';

import {
  watchRequestUsersReAssign,
} from './users-re-assign';

export const employeesSaga = [
  watchRequestEmployees,
  watchRequestUsersReAssign,
];
