/* eslint-disable object-curly-newline,consistent-return,global-require */
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { debounce } from 'lodash';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { usePromiseTracker } from 'react-promise-tracker';

import { checkEmptyObject } from '../../../../../../utils/CommonUtils';
import RetryData from '../../../../../common/RetryData';
import { REFERENTIAL_INFORMATION } from '../../../../../legal.messages';
import quotationStyles from '../../../../style/quotation-style';
import AdditionalBenefits from './AdditionalBenefits';
import Benefits from './Benefits';
import Deductibles from './Deductibles';
import MainBenefits from './MainBenefits';
import Loading from '../../../../../common/Loading';
import { areas } from '../../../../../../utils/Constants';


/**
 * Show the result of quotation.
 * */
export default function ContentQuote() {
  const fxedPadding = 50;
  const classes = quotationStyles();
  const quote = useSelector((state) => state.quote);
  const [width, setWidth] = React.useState(window.innerWidth);
  const { promiseInProgress } = usePromiseTracker({ area: areas.quotation, delay: 0 });

  const parentRef = useRef();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  useEffect(() => {
    const handleResize = debounce(() => setWidth(parentRef.current.offsetWidth
      - fxedPadding), 100);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    if (parentRef.current) {
      setWidth(parentRef.current.offsetWidth - fxedPadding);
    }
  }, []);

  const renderDeductible = () => (
    <>
      <Deductibles quote={quote.response} parentWidth={width} />
      {/*<MainBenefits
        mainBenefits={quote?.deductibleSelected?.deductible?.mainBenefits || []}
      />*/}
      <Benefits
        benefitsGrouped={quote?.deductibleSelected?.deductible?.benefitsGrouped || []}
      />
      {!checkEmptyObject(quote.deductibleSelected?.deductible)
      && <AdditionalBenefits benefits={quote.response?.additionalBenefits} />}
    </>
  );

  /**
   * Show empty content when there is not data to show.
   * */
  const renderEmptyContent = () => (
    <Grid
      container
      alignItems="center"
      justify="center"
      style={{ marginTop: 25 }}
    >
      <div style={{ textAlign: 'center' }}>
        
       
      </div>

      
    </Grid>
  );

  const renderContent = () => {
    if (promiseInProgress) {
      return (
        <Loading
          area={areas.quotation}
          text="Cargando Cotizacion."
          style={{ minHeight: '65vh', marginTop: 25 }}
        />
      );
    }
    if (quote.isError) {
      return (
        <Grid container style={{ marginTop: 25 }}>
          <RetryData />
        </Grid>
      );
    }
    return (
      <Grid
        container
        spacing={2}
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        style={{ minHeight: '65vh', marginTop: 25, paddingLeft: 20, paddingRight: 20 }}
      >
        {Object.keys(quote.response).length === 0
          ? renderEmptyContent()
          : renderDeductible()}
      </Grid>
    );
  };

  const getStyle=() => {
    if(Object.keys(quote.response).length === 0) {
      if(!isDesktop)
        return { backgroundColor: '#E6E6E6',  backgroundImage: `url(${require('../../../../../../assets/images/background_inicio_ebmi_movil.png')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', backgroundAttachment: 'fixed'};
      return { backgroundColor: '#E6E6E6',  backgroundImage: `url(${require('../../../../../../assets/images/background_inicio_ebmi.png')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center'}
    }
    return { backgroundColor: '#E6E6E6'}
    
  }


  return (
    <Grid
      container
      spacing={2}
      direction="column"
      ref={parentRef}
      style={getStyle()}
    >
      <Grid item>
        {renderContent()}
      </Grid>
      <Grid item className={classes.grayText}>{REFERENTIAL_INFORMATION}</Grid>
    </Grid>
  );
}
