/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, List, ListItem, ListItemSecondaryAction, ListItemText,
} from '@material-ui/core';
import { dateTimeFormatOrEmptyAtEcuador } from '../../../utils/CommonUtils';

const EmissionListItem = ({ emission, planList, statusColor, requote, isRolReporterUnified }) => (
  <List component="nav" aria-label="secondary mailbox folder">
    <ListItem>
      <ListItemText primary="Fecha" />
      <ListItemSecondaryAction>
        <ListItemText
          primary={dateTimeFormatOrEmptyAtEcuador(emission.dateInsertion)}
        />
      </ListItemSecondaryAction>
    </ListItem>
    <ListItem>
      <ListItemText primary="Contrato" />
      <ListItemSecondaryAction>
        <ListItemText primary={emission.officialPolicyNumber} />
      </ListItemSecondaryAction>
    </ListItem>
    {isRolReporterUnified && (
      <>
        <ListItem>
          <ListItemText primary="ICSId" />
          <ListItemSecondaryAction>
            <ListItemText primary={emission.brokerICSId} />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText primary="Nombre Broker" />
          <ListItemSecondaryAction>
            <ListItemText primary={emission.brokerName} />
          </ListItemSecondaryAction>
        </ListItem>
      </>
    )}
    <ListItem>
      <ListItemText primary="Plan" />
      <ListItemSecondaryAction>
        <ListItemText primary={planList[emission.quotationProductId]} />
      </ListItemSecondaryAction>
    </ListItem>
    <ListItem>
      <ListItemText primary="Agente" />
      <ListItemSecondaryAction>
        <ListItemText primary={emission.agentName} />
      </ListItemSecondaryAction>
    </ListItem>
    <ListItem>
      <ListItemText primary="Fecha de Vigencia" />
      <ListItemSecondaryAction>
        <ListItemText primary={emission.quotationSelectedValidityDateFormatted} />
      </ListItemSecondaryAction>
    </ListItem>
    <ListItem>
      <ListItemText primary="Valor" />
      <ListItemSecondaryAction>
        <ListItemText primary={emission.quotationSelectedTotalValue} />
      </ListItemSecondaryAction>
    </ListItem>
    <ListItem>
      <ListItemText primary="Forma de Pago" />
      <ListItemSecondaryAction>
        <ListItemText primary={emission.paymentMethodDescription} />
      </ListItemSecondaryAction>
    </ListItem>
    <ListItem>
      <ListItemText primary="Estado" />
      <ListItemSecondaryAction>
        <Button
          size="small"
          style={{
            backgroundColor: `${statusColor[emission.state.replace(/\s/g, '')]}`, color: '#2e2d2d', textTransform: 'unset', fontWeight: 'bold',
          }}
          onClick={() => requote(emission)}
          disabled={emission.noActions}
        >
          {emission.state}
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  </List>
);

EmissionListItem.propTypes = {
  emission: PropTypes.shape({
    dateInsertion: PropTypes.string,
    officialPolicyNumber: PropTypes.string,
    quotationProductId: PropTypes.number,
    agentName: PropTypes.string,
    quotationSelectedValidityDateFormatted: PropTypes.string,
    quotationSelectedTotalValue: PropTypes.number,
    paymentMethodDescription: PropTypes.string,
    state: PropTypes.string,
    noActions: PropTypes.bool,
    brokerICSId: PropTypes.number,
    brokerName: PropTypes.string,
  }).isRequired,
  planList: PropTypes.object.isRequired,
  statusColor: PropTypes.object.isRequired,
  requote: PropTypes.func.isRequired,
  isRolReporterUnified: PropTypes.bool.isRequired,
};

export default EmissionListItem;
