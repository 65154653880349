import { call, put, select, takeLatest } from 'redux-saga/effects';
import api from '../../../utils/Api';

import {
  failedRequestUsersReAssigns,
  START_REQUEST_USERS_RE_ASSIGN,
  successRequestUsersReAssign,
} from '../actions/users-re-assign';

export function* watchRequestUsersReAssign() {
  yield takeLatest(START_REQUEST_USERS_RE_ASSIGN, requestUsersReAssignSaga);
}

/* Getting all users saga */
const fetchUsersReAssign = () => api.get(`${process.env.REACT_APP_USERS_URL}/re-assign`);

export function* requestUsersReAssignSaga() {
  try {
    const response = yield call(fetchUsersReAssign);
    yield put(successRequestUsersReAssign(response?.data));
  } catch (error) {
    yield put(failedRequestUsersReAssigns(error.response?.data?.Message));
  }
}
