// Types Actions
export const REQUEST_FIND_BY_IDENTIFICATION = 'REQUEST_FIND_BY_IDENTIFICATION';
export const SUCCESS_FIND_BY_IDENTIFICATION = 'SUCCESS_FIND_BY_IDENTIFICATION';
export const FAILURE_FIND_BY_IDENTIFICATION = 'FAILURE_FIND_BY_IDENTIFICATION';

export const findByIdentification = (identification, productId) => ({
  type: REQUEST_FIND_BY_IDENTIFICATION,
  payload: { identification, productId, isHolder: true },
});

export const successFindByIdentification = (payload) => ({
  type: SUCCESS_FIND_BY_IDENTIFICATION,
  payload,
});

export const failureFindByIdentification = (error) => ({
  type: FAILURE_FIND_BY_IDENTIFICATION,
  payload: error,
});
