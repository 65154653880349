import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { closeSweetAlert } from './actions/sweet_alert';

const useStyles = makeStyles((theme) => ({
    sweetAlertConfirmButton: {
        margin: theme.spacing(2),
    },
    sweetAlertCancelButton: {
        margin: theme.spacing(2),
    },
}));

/**
 * @return {null}
 */
export default function CustomSweetAlert() {
    const { alert, showAlert } = useSelector((state) => state.alerts);
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(closeSweetAlert());
    }, []);

    return (
        showAlert && alert?.onConfirm ? (
            <SweetAlert
                type={alert.type}
                title={alert.title}
                onConfirm={alert?.onConfirm}
                onCancel={alert.onCancel}
                showCancel={alert.showCancel}
                closeOnClickOutside={false}
                customButtons={(
                    <>
                        {alert.showCancel && (
                            <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                className={classes.sweetAlertCancelButton}
                                onClick={alert.onCancel}
                            >
                                {alert.onCancelLabel}
                            </Button>
                        )}
                        <Button variant="contained" color="primary" size="large" className={classes.sweetAlertConfirmButton} onClick={alert?.onConfirm}>
                            {alert.onConfirmLabel}
                        </Button>
                    </>
                )}
            >
                {alert.content}
            </SweetAlert>
        ) : null
    );
}
