/* eslint-disable react/forbid-prop-types,object-curly-newline */
import React from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import palette from '../../theme/palette';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: 20,
  },
  titleContainer: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
  },
  gridContainer: {
    padding: 10,
  },
  title: {
    color: theme.palette.white,
    fontFamily: 'Roboto-Light',
    paddingLeft: 10,
  },
  subTitle: {
    padding: 10,
    color: theme.palette.black,
  },
  toolTip: {
    'min-width': '0px',
    borderRadius: 25,
    backgroundColor: palette.primary.darkLight,
    color: palette.primary.contrastText,
    height: '15px',
    padding: '11px 10px',
    '&:hover': {
      backgroundColor: '#2166ae',
    },
    '&::before': {
      width: '250px',
      backgroundColor: palette.primary.darkLight,
      border: 'solid 5px transparent',
      'border-radius': '10px',
      color: palette.primary.contrastText,
      content: 'attr(data-title)',
      display: 'none',
      padding: '2px',
      position: 'absolute',
      top: '-85px',
      left: '-125px',
      'z-index': 1,
      'font-size': '9px',
      'text-transform': 'none',
      'text-align': 'left',
      'margin-top': '-15px',
    },
    '&:hover::before': {
      display: 'block',
    },
  },
});

const CustomPanel = ({ title, withoutTitle, subtitle, classes, children, border, tooltipMessage }) => (
  <div className={classes.root}>
    {(withoutTitle && subtitle) && (
      <Typography variant="h5" className={classes.subTitle}>{subtitle}</Typography>
    )}
    <Box
      display="flex"
      border={border ? 1 : 0}
      borderColor={border ? 'primary.main' : 'transparent'}
      bgcolor="background.paper"
      borderRadius={15}
    >
      <Grid container item xs={12} direction="column">
        {!withoutTitle && (
          <Grid
            container
            direction="row"
            className={clsx(classes.titleContainer, classes.gridContainer)}
          >
            <Typography variant="h4" className={classes.title}>
              {title}
              &nbsp;&nbsp;
              {tooltipMessage.length > 0 ? (
                <Button
                  data-title={tooltipMessage}
                  className={classes.toolTip}
                >
                  !
                </Button>
              ) : (<div />)}
            </Typography>
          </Grid>
        )}
        <Grid container direction="column" className={classes.gridContainer}>
          {children}
        </Grid>
      </Grid>
    </Box>
  </div>
);

CustomPanel.defaultProps = {
  title: '',
  subtitle: '',
  withoutTitle: false,
  border: true,
};

CustomPanel.propTypes = {
  title: PropTypes.string,
  withoutTitle: PropTypes.bool,
  subtitle: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  border: PropTypes.bool,
  tooltipMessage: PropTypes.string,
};

CustomPanel.defaultProps = {
  tooltipMessage: '',
};

export default withStyles(styles)(CustomPanel);
