import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiStepIcon from './MuiStepIcon';
import MuiStepConnector from './MuiStepConnector';
import MuiInputBase from './MuiInputBase';

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiStepIcon,
  MuiStepConnector,
  MuiInputBase,
};
