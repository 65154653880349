/* eslint-disable import/prefer-default-export */
import { watchRequestEmissions } from './emissions';
import { watchRequestUpdateTokenSendEmail } from './token_update_send_email';
import { watchRequestTokenUpdate } from './token_update';

export const emissionsSaga = [
  watchRequestEmissions,
  watchRequestUpdateTokenSendEmail,
  watchRequestTokenUpdate,
];
