export const REQUEST_CLIENT_INFORMATION = 'REQUEST_CLIENT_INFORMATION';
export const SUCCESS_CLIENT_INFORMATION = 'SUCCESS_CLIENT_INFORMATION';
export const FAILURE_CLIENT_INFORMATION = 'FAILURE_CLIENT_INFORMATION';
export const RESET_CLIENT_INFORMATION = 'RESET_CLIENT_INFORMATION';

export const requestClientInformation = (identification, productId) => ({
  type: REQUEST_CLIENT_INFORMATION,
  payload: { identification, productId, isHolder: false },
});

export const requestHolderInformation = (identification, productId) => ({
  type: REQUEST_CLIENT_INFORMATION,
  payload: { identification, productId, isHolder: true },
});

export const successClientInformation = (response) => ({
  type: SUCCESS_CLIENT_INFORMATION,
  payload: response,
});

export const failureClientInformation = (error) => ({
  type: FAILURE_CLIENT_INFORMATION,
  payload: error,
});

export const resetClientInformation = () => ({
  type: RESET_CLIENT_INFORMATION,
});
