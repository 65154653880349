import {
  FAILURE_FIND_BY_IDENTIFICATION,
  REQUEST_FIND_BY_IDENTIFICATION,
  SUCCESS_FIND_BY_IDENTIFICATION,
} from '../actions/find_by_identification';

export default (state = {}, action) => {
  switch (action.type) {
    case REQUEST_FIND_BY_IDENTIFICATION:
      return {
        ...state,
        found: false,
        isError: false,
      };
    case SUCCESS_FIND_BY_IDENTIFICATION:
      return {
        ...state,
        found: true,
        isError: false,
        response: action.payload,
      };
    case FAILURE_FIND_BY_IDENTIFICATION:
      return {
        ...state,
        found: false,
        isError: true,
        response: action.payload,
      };
    default:
      return state;
  }
};
