import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MaterialTable from 'material-table';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  checkEmptyObject,
  dateTimeFormatOrEmptyAtEcuador,
  getUserRole,
  usePrevious,
} from '../../../utils/CommonUtils';
import Loading from '../../common/Loading';
import { addSweetAlert, closeSweetAlert } from '../../common/SweetAlert/actions/sweet_alert';
import EmissionListItem from '../../Emissions/screens/EmissionListItem';
import { addApplicationInfo } from '../../Issuance/components/Application/actions/application';
import { requestGetApplication } from '../../Issuance/components/Application/actions/get_application';
import { selectValidityDate } from '../../Issuance/components/Application/utils/selectors';
import { RolEnum } from '../../Issuance/components/Payment/Enums';
import { goToStep } from '../../Issuance/utils/steps';
import { resetWithReQuote } from '../../Quotation/actions/quote';
import { requestPostReQuotation } from '../../Quotation/actions/reQuotation';
import { requestReallocateEmissions } from '../actions/reallocateEmissions';
import reallocateEmissionsStyles from '../style/reallocateEmissions-style';
import PaymentResume from '../../Emissions/screens/PaymentResume';
import { requestUsersReAssign } from '../../AdministrateUsers/actions/users-re-assign';
import api from '../../../utils/Api';

const getThirtyDaysBefore = () => {
  const now = new Date();
  const before = new Date();
  before.setDate(now.getDate() - 30);
  return before.toISOString();
};

const ReallocateEmissions = () => {
  const insuranceApplicationTableRef = React.useRef(null);
  const [planList, setPlanList] = React.useState({});
  const login = useSelector((state) => state.login);
  const isRolReporterUnified = getUserRole(login) === RolEnum.ReporterUnified;
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const { control, getValues } = useForm({
    defaultValues: {
      agent: 'all',
      status: 'Pendiente Pago',
      creationFrom: getThirtyDaysBefore(),
      creationTo: new Date().toISOString(),
    },
  });
  const [minDate, setMinDate] = React.useState();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const emissionsProperties = !isDesktop
    && {
      detailPanel: (emission) => (
        <EmissionListItem
          emission={emission}
          planList={planList}
          statusColor={statusColor}
          requote={requote}
          isRolReporterUnified={isRolReporterUnified}
        />
      ),
    };
  const validityDate = useSelector(selectValidityDate);

  const classes = reallocateEmissionsStyles();
  const dispatch = useDispatch();
  const emissions = useSelector((state) => state.reallocateEmissions, shallowEqual);
  const { response: { catalogs = [],
    products = [] } } = useSelector((state) => state.catalogs, shallowEqual);
  const saleprocessStatus = catalogs && catalogs.find((c) => c.id === 'EstadoCotizacion')?.catalogue;

  const statusColor = {
    Cotizado: '#63a757',
    PendientePago: '#16c7c4',
    PendienteFirma: '#ffba00',
    GenerandoContrato: '#ffba00',
    Firmado: '#649568',
    FirmaRechazada: '#993129',
    FirmaExpirada: '#993129',
    FirmaFallida: '#993129',
    Caducado: '#993129',
    EnviadoaBMI: '#abcdef',
  };

  const reQuotation = useSelector((state) => state.reQuotation);
  const prevRequotation = usePrevious(reQuotation);
  const [quotationRequest, setQuotationRequest] = React.useState();
  const [step, setStep] = React.useState();

  const simpleModal = useSelector((state) => state.simpleModal);
  const [insuranceApplications, setInsuranceApplications] = React.useState([]);
  const usersReAssign = useSelector((state) => state.usersReAssign);
  const [selectedAgent, setSelectedAgent] = React.useState({});

  const selectDate = (event) => {
    setMinDate(new Date(event));
    return new Date(event).toISOString();
  };

  useEffect(() => {
    getPlanList();
    getStatusList();
    findIssuance();
    dispatch(requestUsersReAssign());
  }, []);

  function getPlanList() {
    const planTemp = {};
    products.forEach((company) => {
      company.plans.forEach((plan) => {
        planTemp[plan.id] = plan.name;
      });
    });
    setPlanList(planTemp);
  }


  const [statusList, setStatusList] = React.useState({});
  const [stateList, setStateList] = React.useState([]);

  function getStatusList() {
    const statusListTemp = {};
    if (saleprocessStatus.length > 0) {
      saleprocessStatus.forEach((status) => {
        statusListTemp[status.description] = status.description;
        setStateList((prevArray) => [...prevArray, status]);
      });
      setStatusList(statusListTemp);
    }
  }

  useEffect(() => {
    if (prevRequotation && (reQuotation.response !== prevRequotation.response)) {
      if (!checkEmptyObject(reQuotation?.response) && !reQuotation.isError) {
        const { result, messages } = reQuotation.response.validationResult;
        if (result === 'SUCCESS') {
          dispatch(resetWithReQuote(quotationRequest,
            reQuotation.response.deductibleSelected));
          goToStep(history, step);
        } else {
          dispatch(addSweetAlert({
            type: 'error',
            title: 'Edades Incorrectas',
            content: (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                {
                  messages.map((item, index) => (
                    <Grid key={index} item md={12} xs={12}>
                      <Typography variant="h6">
                        {item}
                      </Typography>
                    </Grid>
                  ))
                }
              </Grid>),
            showCancel: result === 'CONTINUE_WITH_CLIENT_ACCEPTANCE',
            onCancelLabel: 'Cancelar',
            onConfirmLabel: 'Continuar',
            onCancel: () => {
              dispatch(closeSweetAlert());
              history.push('/quotation');
            },
            onConfirm: () => {
              dispatch(resetWithReQuote(quotationRequest,
                reQuotation.response.deductibleSelected));
              goToStep(history, step);
              dispatch(closeSweetAlert());
            },
          }));
        }
      }
    }
  }, [reQuotation.response]);

  const requote = (rowData) => {
    const quoteRequest = JSON.parse(rowData.quotationJson);
    const formatedQuote = {};

    // TODO: set data of the application form to application slice
    dispatch(addApplicationInfo(rowData?.billingData, rowData?.insuranceHolder));
    formatedQuote.name = quoteRequest.MainInsuredName;
    formatedQuote.age = rowData.insuranceMainInsured.ageAtValidityDate;
    formatedQuote.productId = quoteRequest.ProductId;
    formatedQuote.companyId = quoteRequest.CompanyId;
    formatedQuote.branchOfficeId = quoteRequest.BranchOfficeId;
    formatedQuote.ValidityDate = quoteRequest.ValidityDate;
    formatedQuote.deductibleId = rowData.deductibleSelectedId;
    formatedQuote.Identification = rowData.insuranceMainInsured.identification.trim();
    formatedQuote.TargetValidityDate = new Date(validityDate);
    formatedQuote.SaleProcessId = rowData.saleProcessId;
    formatedQuote.PaymentMethod = rowData.paymentMethod;
    formatedQuote.dependants = [];
    formatedQuote.planName = rowData.planName;
    formatedQuote.withDifferentHolder = quoteRequest.WithDifferentHolder;
    formatedQuote.riders = quoteRequest?.Riders;
    rowData.insuranceDependants.map((item) => {
      const dependant = {};
      dependant.name = item.completeName;
      dependant.age = item.ageAtValidityDate;
      dependant.rol = quoteRequest.Dependents
        .find((d) => d.Identification.trim() === item.identification.trim()).RolId;
      dependant.Identification = item.identification.trim();
      formatedQuote.dependants.push(dependant);
    });
    setQuotationRequest(formatedQuote);
    setStep(rowData.state);
    dispatch(requestPostReQuotation(formatedQuote));
    dispatch(requestGetApplication(rowData.saleProcessId));
  };

  const handleSearch = () => {
    findIssuance();
  };

  const findIssuance = () => {
    const values = getValues();
    dispatch(requestReallocateEmissions(values.agent, values.status, values.creationFrom, values.creationTo));
  };
  
  const renderColumns = () => {
    const reporterUnifiedColumns = [];
    if (isDesktop) {
      return (
        [
          { title: 'C\u00E9dula', field: 'insuranceHolder.identification' },
          { title: 'Cliente', field: 'insuranceHolder.completeName' },
          ...reporterUnifiedColumns,
          {
            title: 'Fecha',
            field: 'dateInsertion',
            type: 'datetime',
            render: (rowData) => (
              dateTimeFormatOrEmptyAtEcuador(rowData.dateInsertion)
            ),
          },
          { title: 'Contrato', field: 'officialPolicyNumber' },
          {
            title: 'Estado',
            field: 'state',
            type: 'string',
            lookup: statusList,
            render: (rowData) => (
              <Button
                size="small"
                style={{
                  backgroundColor: `${statusColor[rowData.state.replace(/\s/g, '')]}`,
                  color: '#2e2d2d',
                  textTransform: 'unset',
                  fontWeight: 'bold',
                }}
                disabled={rowData.noActions}
              >
                {rowData.state}
              </Button>
            ),
          },
          {
            title: 'Plan',
            field: 'quotationProductId',
            type: 'string',
            lookup: planList,
          },
          { title: 'Agente', field: 'agentName', type: 'string' },
          {
            title: 'Fecha de Vigencia',
            field: 'quotationSelectedValidityDateFormatted',
            type: 'date',
          },
          { title: 'Valor', field: 'quotationSelectedTotalValue', type: 'numeric' },
        ]
      );
    }
    return (
      [
        {
          title: 'C\u00E9dula',
          field: 'insuranceHolder.identification',
          cellStyle: { padding: 10 },
          headerStyle: { padding: 10 },
        },
        {
          title: 'Cliente',
          field: 'insuranceHolder.completeName',
          cellStyle: { padding: 10 },
          headerStyle: { padding: 10 },
        },
      ]
    );
  };

  if (reQuotation.loading) {
    return (
      <Loading text="Cargando" />
    );
  }

  const handleReassign = () => {
    
    var insuranceApplicationSelectedList = 
      insuranceApplicationTableRef?.current.dataManager?.data?.filter(item => item.tableData.checked);
    if(insuranceApplicationSelectedList.length > 0)
    {
      setInsuranceApplications(insuranceApplicationSelectedList);
      setOpen(true);
      return true;
    } 
      
    dispatch(addSweetAlert({
        type: 'error',
        title: 'No ha seleccionado ningun elemento',
        showCancel: false,
        onConfirmLabel: 'Aceptar',
        onConfirm: async () => {
          findIssuance();
          dispatch(closeSweetAlert());
        },
      }));
    return false;
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleReasignDialog = () => {
    const reAsignDtos = [];

    insuranceApplications.map((item) => {
      const reAssignItem = {
        InsuranceApplicationId: item.id,
        AgentName: selectedAgent.displayName,
        AgentEmail: selectedAgent.email,
        ICSCode: selectedAgent.brokerICSId,
        SaleProcessId: item.saleProcessId,
        Broker: selectedAgent.id
      };
      reAsignDtos.push(reAssignItem);
    });

    api.post(`${process.env.REACT_APP_REASSIGN}`, reAsignDtos).then(
      (response) => {
        setOpen(false);
        dispatch(addSweetAlert({
          type: 'success',
          title: 'La reasignación fue realizada con éxito',
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            findIssuance();
            dispatch(closeSweetAlert());
            setInsuranceApplications([]);
          },
        }));
      },
    ).catch((err) => {
      console.log(err);
      setOpen(false);
      dispatch(addSweetAlert({
        type: 'error',
        title: 'Error al realizar la reasignación',
        showCancel: false,
        onConfirmLabel: 'Aceptar',
        onConfirm: async () => {
          findIssuance();
          dispatch(closeSweetAlert());
        },
      }));
    });
  };

  const handleCloseReasignDialog = () => {
    setOpen(false);
  };

  const handleChangeAgent = (event) => {
    setSelectedAgent(event.target.value);
  };

  return (
    <div className={classes.root}>

      <Typography
        variant="h2"
      >
        Reasignacion de negocios a agente
      </Typography>
      <form>
        {simpleModal?.isOpen && <PaymentResume />}
        <Grid
          container
          direction="row"
          justify="flex-start"
          spacing={2}
        >
          <Grid item xs={12} md={2} lg={2}>
            <FormControl
              className={classes.select}
            >
              <InputLabel id="demo-simple-select-label">Agente</InputLabel>
              <Controller
                as={(
                  <Select>
                    <MenuItem value="all">Todos</MenuItem>
                    {
                      usersReAssign?.data?.filter((item) => item.accountEnabled)
                      .map((item, index) => (
                        <MenuItem key={index} value={item.displayName}>
                          {item.displayName}
                        </MenuItem>
                      ))
                    }
                  </Select>
                )}
                className={classes.formControl}
                name="agent"
                control={control}
                defaultValue="all"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <FormControl
              className={classes.select}
            >
              <InputLabel id="demo-simple-select-label">Estado</InputLabel>
              <Controller
                as={(
                  <Select>
                    {stateList.map((s) => (
                      <MenuItem key={s.description} value={s.description}>
                        {s.description}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                className={classes.formControl}
                name="status"
                label="Estado"
                control={control}
                defaultValue="Pendiente Pago"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={(
                  <KeyboardDatePicker disableFuture />
                )}
                autoOk
                className={classes.formControl}
                name="creationFrom"
                fullWidth
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Desde"
                onChange={([event]) => selectDate(event)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                InputProps={{ readOnly: true }}
                control={control}
                defaultValue={null}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={10} md={2} lg={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={(
                  <KeyboardDatePicker disableFuture />
                )}
                autoOk
                className={classes.formControl}
                name="creationTo"
                minDate={
                  minDate
                }
                fullWidth
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Hasta"
                onChange={([event]) => new Date(event).toISOString()}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                InputProps={{ readOnly: true }}
                control={control}
                defaultValue={null}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container alignContent="flex-end" item xs={2} md={1} lg={1}>
            <Tooltip title="Buscar" arrow>
              <IconButton
                className={classes.formControl}
                aria-label="search"
                onClick={handleSearch}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid container alignContent="center" item xs={12} md={3} lg={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleReassign()}
              fullWidth
              className={classes.formControl}
            >
              Reasignar
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid
        container
        direction="column"
        spacing={3}
      >
        <MaterialTable
          title=""
          tableRef={insuranceApplicationTableRef}
          columns={renderColumns()}
          data={emissions.response}
          options={{
            search: false,
            selection: true,
            actionsColumnIndex: -1,
            columnsButton: false,
            filtering: true,
            exportButton: false,
            exportAllData: false,
            pageSize: 20,
            pageSizeOptions: [20, 50],
            toolbar: false,
          }}
          isLoading={!emissions || emissions.loading}
          {...emissionsProperties}
        />
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Re asignación</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            <FormControl className={classes.formControl} style={{ minWidth: '150px' }}>
              <InputLabel id="demo-simple-select-label">Agente</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedAgent}
                style={{ width: '150px' }}
                onChange={handleChangeAgent}
              >
                {
                  usersReAssign?.data?.filter((item) => item.accountEnabled)
                  .map((item, index) => (
                    <MenuItem key={index} value={item}>{item.displayName}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReasignDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleReasignDialog} color="primary">
          Reasignar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReallocateEmissions;
