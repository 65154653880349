import {
  REQUEST_GET_APPLICATION_FAILED,
  REQUEST_GET_APPLICATION_SUCCESS,
  RESET_GET_APPLICATION,
  START_REQUEST_GET_APPLICATION,
} from '../actions/get_application';

const GET_APPLICATION_INITIAL_STATE = {
  loading: false,
  response: {},
  error: false,
};

export default (state = GET_APPLICATION_INITIAL_STATE, action) => {
  switch (action.type) {
    case START_REQUEST_GET_APPLICATION:
      return {
        ...state,
        loading: true,
        response: {},
        error: false,
      };
    case REQUEST_GET_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        error: false,
      };
    case REQUEST_GET_APPLICATION_FAILED:
      return {
        ...state,
        loading: false,
        response: action.payload,
        error: true,
      };
    case RESET_GET_APPLICATION:
      return {
        ...state,
        ...GET_APPLICATION_INITIAL_STATE,
      };
    default:
      return state;
  }
};
