import React, { useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { requestPaymentData } from './actions/signing';
import signingStyles from './style/signing-style';
import CustomPanelWithoutPadding from '../../../common/CustomPanelWithotPadding';
import { selectDivisionPolitica } from '../Application/utils/selectors';
import { usePrevious, isEmpty } from '../../../../utils/CommonUtils';

const Signing = () => {
  const classes = signingStyles();
  const dispatch = useDispatch();
  const { requestData } = useSelector((state) => state.quote);
  const { response } = useSelector((state) => state.signing);
  const prevResponse = usePrevious(response);
  const countries = useSelector(selectDivisionPolitica);
  const states = countries && countries.find((c) => c.id === '55')?.children;
  const [cityDescription, setCityDescription] = React.useState('');
  const history = useHistory();

  useEffect(() => {
    const { SaleProcessId } = requestData;
    dispatch(requestPaymentData(SaleProcessId));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (prevResponse && (response !== prevResponse)) {
      if (!isEmpty(response) && states) {
        const cities = states.find((p) => p.id === response.province).children;
        const city = cities.find((c) => c.id === response.city);
        setCityDescription(city.description);
      }
    }
  }, [response, states]);

  const onClick = () => {
    history.push('/emissions');
  };

  return (
    <div className={classes.root}>
      <CustomPanelWithoutPadding title="Resumen" border={false}>
        <Grid container className={classes.contentContainer} spacing={0}>
          <Grid item xs={12} sm={12}>
            <Grid container direction="row" justify="center" spacing={1}>
              <Grid item>
                <div className={classes.warning}>
                  <Typography className={classes.darkText}>{response.status}</Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.contentContainerDark} spacing={0}>
          <Grid item xs={12} sm={4}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography className={classes.blueText}>NÚMERO DE CONTRATO</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.darkText}>{response.policyNumber}</Typography>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography className={classes.blueText}>TITULAR</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.darkText}>{response.holder}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography className={classes.blueText}>PLAN</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.darkText}>{response.product}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.contentContainerLight} spacing={0}>
          <Grid item xs={12} sm={4}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography className={classes.blueText}>CORREO ELECTRONICO</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.darkText}>{response.email}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography className={classes.blueText}>TELÉFONO CELULAR</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.darkText}>{response.phone}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography className={classes.blueText}>CIUDAD</Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.darkText}
                >
                  {cityDescription && cityDescription}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.contentContainerDark} spacing={0}>
          <Grid item xs={12} sm={4}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography className={classes.blueText}>TOTAL A PAGAR</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.darkText}>{response.totalValue}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography className={classes.blueText}>FORMA DE PAGO</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.darkText}>{response.paymentMethod}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography className={classes.blueText}>MÉTODO DE PAGO</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.darkText}>
                  {response.creditCardName ? response.creditCardName : response.paymentBank}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.contentContainer} spacing={0}>
          <Grid item xs={12} sm={12}>
            <Grid container direction="row" justify="center" spacing={1}>
              <Grid item lg={10} sm={10}>
                <Typography className={classes.bottomText}>
                  Gracias por confiar en BMI,
                  {response.status === 'Pendiente Firma' ? ' el contrato ha sido enviado al e-mail del cliente para su firma'
                    : ' en unos minutos se enviará el contrato al e-mail del cliente para su firma'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Button
          size="large"
          className={classes.button}
          onClick={() => onClick()}
        >
          VER ESTADO DE EMISIONES
        </Button>
      </CustomPanelWithoutPadding>

    </div>
  );
};

export default Signing;
