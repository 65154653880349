import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import contentStyles from './style/content-style';
import HeaderQuote from './components/PlanDetail/HeaderQuote';
import ContentQuote from './components/PlanDetail/ContentQuote';

const Content = (props) => {
  const { onOpen, isDesktop } = props;

  const classes = contentStyles();

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
      style={{ overflow: 'hidden' }}
    >
      <HeaderQuote onOpen={onOpen} isDesktop={isDesktop} />
      <ContentQuote />
    </div>
  );
};

Content.propTypes = {
  onOpen: PropTypes.func,
  isDesktop: PropTypes.bool.isRequired,
};

export default Content;
