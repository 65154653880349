/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import {
  call, put, takeLatest, select,
} from 'redux-saga/effects';
import { trackPromise } from 'react-promise-tracker';

import {
  failurePostIssuance,
  REQUEST_POST_ISSUANCE,
  successPostIssuance,
} from '../actions/application';
import api from '../../../../../utils/Api';
import { getQuoteData } from '../../../../Quotation/reducers/quote';
import { areas } from '../../../../../utils/Constants';

export function* watchRequestSaveIssuance() {
  yield takeLatest(REQUEST_POST_ISSUANCE, requestPostIssuanceSaga);
}

function* requestPostIssuanceSaga({ payload: issuance }) {
  try {
    const quote = yield select(getQuoteData);
    const quotation = {};
    quotation.request = quote.requestData;
    let typeId = 0;
    if (quote?.deductibleSelected?.paymentType) {
      const payment = quote.deductibleSelected.deductible.paymentType.find(
        (p) => p.typeDescription === quote.deductibleSelected.paymentType);
      typeId = payment.typeId;
    } else {
      typeId = quote.deductibleSelected.typeId;
    }
    issuance.quotationJson = JSON.stringify(quotation);
    issuance.state = 'SOLICITUD';
    issuance.lastStep = '#DOC';
    issuance.QuotationProductId = quote.requestData.productId;
    issuance.DeductibleSelectedId = quote.deductibleSelected.deductibleId;
    issuance.QuotationSelectedTotalValue = quote.deductibleSelected.total;
    issuance.SelectedBranchOfficeId = quote.requestData.branchOfficeId;
    issuance.SaleProcessId = quote.requestData.SaleProcessId;
    issuance.PaymentMethod = typeId;
    const response = yield call(fetchPostIssuance, issuance);
    // TODO: set data of the application form to application slice
    yield put(successPostIssuance(response.data, issuance.billingData,
      issuance.insuranceHolder));
  } catch (e) {
    yield put(failurePostIssuance(e?.message));
  }
}

const fetchPostIssuance = (issuance) => trackPromise(api
  .post(process.env.REACT_APP_ISSUANCE_URL, issuance), areas.global);
