import {
  ADD_DEPENDENT, FAILED_DEPENDENT_IDENTIFICATION,
  SUCCESS_DEPENDENT_IDENTIFICATION,
} from '../actions/dependents_identification';

const dependentsArray = {
  found: true,
  isError: false,
  response: {},
};

export default (state = [], action) => {
  switch (action.type) {
    case ADD_DEPENDENT:
      return {
        ...state,
        dependents: Array
          .from({ length: action.payload }, () => dependentsArray),
      };
    case SUCCESS_DEPENDENT_IDENTIFICATION:
      return {
        ...state,
        dependents: {
          ...state.dependents,
          [action.payload.index]: { found: true, isError: false, response: action.payload.citizen },
        },
      };
    case FAILED_DEPENDENT_IDENTIFICATION:
      return {
        ...state,
        dependents: {
          ...state.dependents,
          [action.payload.index]: { found: false, isError: true, response: action.payload.error },
        },
      };
    default:
      return state;
  }
};
