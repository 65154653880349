// eslint-disable-next-line import/prefer-default-export
export const navMenu = {
  role: 'anonymous',
  menus: [
    {
      description: 'NUEVOS NEGOCIOS',
      menus: [
        {
          description: 'Cotizar',
          url: '/quotation',
        },
      ],
    },
  ],
};
