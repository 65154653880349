import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  emailWasSent: false,
  isError: false,
};

const emailSlice = createSlice({
  name: 'sendEmail',
  initialState: INITIAL_STATE,
  reducers: {
    requestSendEmail: {
      reducer(state) {
        state.emailWasSent = false;
      },
    },
    successSendEmail: {
      reducer(state) {
        state.emailWasSent = true;
      },
    },
    failureSendEmail: {
      reducer(state) {
        state.isError = true;
      },
    },
  },
});

// ducks pattern
export const { requestSendEmail, successSendEmail, failureSendEmail } = emailSlice.actions;

export default emailSlice.reducer;
