import { makeStyles } from '@material-ui/core/styles';

const emissionsStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  statusCell: {
    width: '80px',
    height: '14px',
    borderRadius: '6px',
    backgroundColor: '#abc123',
  },
}));

export default emissionsStyles;
