import { makeStyles } from '@material-ui/core/styles';

const topbarStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.dark,
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down(450)]: {
      padding: '0px !important',
    },
  },
  active: {
    color: theme.palette.primary.light,
    fontWeight: theme.typography.fontWeightMedium,
  },
  button: {
    color: theme.palette.primary.light,
    padding: '10px 8px',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down(385)]: {
      width: '75%',
    },
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& nav': {
      alignItems: 'center',
      display: 'flex',
    },
    marginLeft: theme.spacing(4),
  },
  secondHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& nav': {
      alignItems: 'center',
      display: 'flex',
    },
    fontFamily: 'Roboto-Light',
    marginLeft: theme.spacing(16),
    [theme.breakpoints.down(768)]: {
      marginLeft: '0px',
    },
  },
  logo: {
    '& img': {
      transition: 'all 0.3s ease-out',
      height: 34,
      marginRight: theme.spacing(),
      [theme.breakpoints.only('md')]: {
        marginLeft: theme.spacing(2),
      },
    },
  },
  secondToolbar: {
    backgroundColor: theme.palette.primary.light,
    borderBottom: '5px solid #00315C',
  },
  buttonSecondHeader: {
    textTransform: 'unset',
    marginRight: theme.spacing(10),
    width: '90%',
    color: theme.palette.primary.dark,
    fontFamily: 'Roboto-Light',
    fontSize: '14px',
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#518CBF', color: '#ffffff' },
    [theme.breakpoints.down(768)]: {
      marginRight: '0px',
    },
  },
  activeButtonSecondHeader: {
    textTransform: 'unset',
    marginRight: theme.spacing(10),
    width: '90%',
    color: '#ffffff',
    backgroundColor: '#518CBF',
  },
  username: {
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '-0.05px',
    fontFamily: 'Roboto-Light',
    [theme.breakpoints.down(450)]: {
      fontSize: '10px',
    },
  },
}));

export default topbarStyles;
