import { call, put, takeLatest } from 'redux-saga/effects';
import { startCreatePolicy, successPolicy, failedPolicy } from '../slices/policy';
import api from '../../../../../utils/Api';

export function* watchPolicySaga() {
  yield takeLatest(startCreatePolicy.type, policySaga);
}

export function* policySaga({ payload: values }) {
  try {
    const { data } = yield call(fetchPolicy, values);
    yield put(successPolicy(data));
  } catch (e) {
    yield put(failedPolicy(e));
  }
}

const fetchPolicy = (values) => api.post(process.env.REACT_APP_POLICY, values);
