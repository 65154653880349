import { createSelector } from 'reselect';

export const selectRelationship = createSelector(
  (state) => state.catalogs.response,
  (response) => response?.catalogs?.find((c) => c.id === 'Parentescos')?.catalogue,
);

export const selectRelationshipBillingData = createSelector(
  (state) => state.catalogs.response,
  (response) => response?.catalogs?.find((c) => c.id === 'Relacion')?.catalogue,
);

export const selectTypeIdentification = createSelector(
  (state) => state.catalogs.response,
  (response) => response?.catalogs?.find((c) => c.id === 'TipoIdentificacion')?.catalogue,
);

export const selectGender = createSelector(
  (state) => state.catalogs.response,
  (response) => response?.catalogs?.find((c) => c.id === 'Generos')?.catalogue,
);

export const selectDivisionPolitica = createSelector(
  (state) => state.catalogs.response,
  (response) => response?.catalogs?.find((c) => c.id === 'DivisionPolitica')?.catalogue,
);

export const selectPlace = createSelector(
  (state) => state.catalogs.response,
  (response) => response?.catalogs?.find((c) => c.id === 'Lugar')?.catalogue,
);

export const selectCashRanges = createSelector(
  (state) => state.catalogs.response,
  (response) => response?.catalogs?.find((c) => c.id === 'CashRanges')?.cashRanges,
);

export const selectCatalog = (typeCatalog) => createSelector(
  (state) => state.catalogs.response,
  (response) => response?.catalogs?.find((c) => c.id === `${typeCatalog}`)?.catalogue,
);

export const selectValidityDate = createSelector(
  (state) => state.catalogs.response,
  (response) => response?.parameters?.find((c) => c.name === 'VALIDITY_DATE')?.value,
);

export const selectValidityDateEnabled = createSelector(
  (state) => state.catalogs.response,
  (response) => response?.parameters?.
  find((c) => c.name === 'VALIDITY_DATE_NOW')?.value.toLowerCase() === 'false',
);
