/* eslint-disable react/forbid-prop-types,object-curly-newline */
import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: 20,
  },
  titleContainer: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
  },
  gridContainer: {
    padding: 10,
  },
  gridContainerContent: {
    padding: 0,
  },
  title: {
    color: theme.palette.white,
    fontFamily: 'Roboto-Light',
    paddingLeft: 10,
  },
  subTitle: {
    padding: 10,
    color: theme.palette.black,
  },
});

const CustomPanelWithoutPadding = ({ title, withoutTitle, subtitle, classes, children, border }) => (
  <div className={classes.root}>
    {(withoutTitle && subtitle) && (
    <Typography variant="h5" className={classes.subTitle}>{subtitle}</Typography>
    )}
    <Box
      display="flex"
      border={border ? 1 : 0}
      borderColor={border ? 'primary.main' : 'transparent'}
      bgcolor="background.paper"
      borderRadius={15}
    >
      <Grid container item xs={12} direction="column">
        {!withoutTitle && (
        <Grid
          container
          direction="row"
          className={clsx(classes.titleContainer, classes.gridContainer)}
        >
          <Typography variant="h4" className={classes.title}>{title}</Typography>
        </Grid>
        )}
        <Grid container direction="column" className={classes.gridContainerContent}>
          {children}
        </Grid>
      </Grid>
    </Box>
  </div>
);

CustomPanelWithoutPadding.defaultProps = {
  title: '',
  subtitle: '',
  withoutTitle: false,
  border: true,
};

CustomPanelWithoutPadding.propTypes = {
  title: PropTypes.string,
  withoutTitle: PropTypes.bool,
  subtitle: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  border: PropTypes.bool,
};

export default withStyles(styles)(CustomPanelWithoutPadding);
