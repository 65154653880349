import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import api from '../../../utils/Api';
import {
  failedRequestEmissions,
  START_REQUEST_EMISSIONS,
  successRequestEmissions,
} from '../actions/emissions';

/* Getting all users saga */
const fetchEmissions = (payload) => {
  const from = payload.creationFrom;
  const to = payload.creationTo;
  const page = payload.page;
  const limit = payload.limit;
  const valueText = payload.valueText;
  const url = `${process.env.REACT_APP_ISSUANCE_URL}?creationFrom=${from}&creationTo=${to}&page=${page}&limit=${limit}&filter=${valueText}`;
  return api.get(url);
};

export function* requestEmissionsSaga(params) {
  try {
    const response = yield call(fetchEmissions, params.payload);
    yield put(successRequestEmissions(response.data));
  } catch (error) {
    yield put(failedRequestEmissions(error));
  }
}

export function* watchRequestEmissions() {
  yield takeLatest(START_REQUEST_EMISSIONS, requestEmissionsSaga);
}
