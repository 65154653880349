import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  updateTokenEmailSent: false,
  isError: false,
};

const tokenUpdateSlice = createSlice({
  name: 'updateTokenEmailSliceReducer',
  initialState: INITIAL_STATE,
  reducers: {
    requestUpdateTokenSendEmail: {
      reducer(state) {
        state.updateTokenEmailSent = false;
      },
    },
    successUpdateTokenSendEmail: {
      reducer(state) {
        state.updateTokenEmailSent = true;
      },
    },
    failureUpdateTokenSendEmail: {
      reducer(state) {
        state.isError = true;
      },
    },
  },
});

// eslint-disable-next-line max-len
export const { requestUpdateTokenSendEmail, successUpdateTokenSendEmail, failureUpdateTokenSendEmail } = tokenUpdateSlice.actions;

export default tokenUpdateSlice.reducer;
