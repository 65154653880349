export const START_REQUEST_EMISSIONS = 'START_REQUEST_EMISSIONS';
export const REQUEST_EMISSIONS_SUCCESS = 'REQUEST_EMISSIONS_SUCCESS';
export const REQUEST_EMISSIONS_FAILED = 'REQUEST_EMISSIONS_FAILED';

/* Getting all users */
export const requestEmissions = (creationFrom, creationTo, page, limit, valueText) => ({
  type: START_REQUEST_EMISSIONS,
  payload: {
    creationFrom,
    creationTo,
    page,
    limit,
    valueText
  },
});

export const successRequestEmissions = (emissions) => ({
  type: REQUEST_EMISSIONS_SUCCESS,
  payload: emissions,
});

export const failedRequestEmissions = (error) => ({
  type: REQUEST_EMISSIONS_FAILED,
  payload: error,
});
