/* eslint-disable no-param-reassign */
import axios from 'axios';
import { checkEmptyObject } from './CommonUtils';
import { configure } from 'axios-hooks';

/**
 * Global Configurations for Axios, setting BaseURL, Timeout
 * and Interceptors.
 * */
const api = axios.create({
  timeout: 120000,
});

api.interceptors.request.use((config) => {
  // Do something before request is sent
  const applicationState = JSON.parse(sessionStorage.getItem('persist:applicationState'));
  const login = applicationState ? JSON.parse(applicationState?.login) : {};
  config.headers['Ocp-Apim-Subscription-Key'] = `${process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY}`;
  if (!checkEmptyObject(login) && !config.url.endsWith('-public')) {
    config.headers.Authorization = `Bearer ${login?.response?.jwtIdToken}`;
  }
  return config;
}, (error) => {
  console.log('Catched Request Error:', error);
  return Promise.reject(error);
});

api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  // Do something with response error
  if (error.response?.status === 503) {
    const response = JSON.parse(error.response.data.Message);
    window.location.replace(`/maintenance-window?start=${response.Start}&end=${response.End}`);
  }
  return Promise.reject(error);
});

configure({ axios: api, cache: false });

export default api;
