/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Box, CircularProgress } from '@material-ui/core';
import { usePromiseTracker } from 'react-promise-tracker';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.darkLight,
    backgroundColor: '#dbd6d6',
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 15,
  },
  circularProgress: {
    alignSelf: 'center',
  },
  backdropText: {
    marginTop: 15,
  },
});

/**
 * Show a full screen loading.
 * @param classes styles
 * @param text to show (optional)
 * @param area which area will be rendered the loading on the screen.
 * */
function BackdropLoading({ classes, text, area }) {
  const { promiseInProgress } = usePromiseTracker({ area, delay: 0 });

  return (
    promiseInProgress && (
    <Backdrop className={classes.backdrop} open>
      <Box
        className={classes.boxContainer}
        bgcolor="background.paper"
        p={5}
      >
        <CircularProgress className={classes.circularProgress} color="inherit" />
        <p className={classes.backdropText}>{text}</p>
      </Box>
    </Backdrop>
    )
  );
}

BackdropLoading.defaultProps = {
  text: 'Cargando espere por favor.',
};

BackdropLoading.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
  area: PropTypes.string.isRequired,
};

export default withStyles(styles)(BackdropLoading);
