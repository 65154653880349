import findByIdentificationResult from './find_by_identification';
import application from './application';
import dependentsIdentification from './dependents_identification';
import clientInfo from './client_information';
import getApplication from './get_application';

export default {
  findByIdentificationResult,
  application,
  dependentsIdentification,
  clientInfo,
  getApplication,
};
