import {
  START_REQUEST_EMISSIONS,
  REQUEST_EMISSIONS_SUCCESS,
  REQUEST_EMISSIONS_FAILED,
} from '../actions/emissions';

const EMISSIONS_INITIAL_STATE = {
  loading: false,
  response: [],
  error: false,
};

export default (state = EMISSIONS_INITIAL_STATE, action) => {
  switch (action.type) {
    case START_REQUEST_EMISSIONS:
      return {
        ...state,
        loading: true,
        response: [],
        error: false,
      };
    case REQUEST_EMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        error: false,
      };
    case REQUEST_EMISSIONS_FAILED:
      return {
        ...state,
        loading: false,
        response: [],
        error: true,
      };
    default:
      return state;
  }
};
