/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { closeModal } from '../../../common/modals/actions/simple_modal';
import HeaderTitleModal from '../../../Issuance/components/Payment/components/HeaderTitleModal';

const styles = (theme) => ({
  cancelButton: {
    borderRadius: 20,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 30,
      marginRight: 30,
    },
  },
  confirmButton: {
    borderRadius: 20,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 30,
      marginRight: 30,
    },
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#00315C' },
  },
  link: {
    borderBottom: '1.5px solid #518CBF',
    color: '#518CBF',
  },
});


/**
 * TermsConditionsForm.
 * */
const TermsConditionsForm = withStyles(styles)((props) => {
  const { classes } = props;
  const simpleModal = useSelector((state) => state.simpleModal);
  const dispatch = useDispatch();
  const [acceptedTermsConditions, setAcceptedTermsConditions] = React.useState(false);

  const close = () => {
    dispatch(closeModal({ acceptedTerms: true }));
  };

  const cancel = () => {
    dispatch(closeModal({ acceptedTerms: false }));
  };

  const handleChange = (event) => {
    setAcceptedTermsConditions(event.target.checked);
  };

  return (
    <Dialog open={simpleModal.isOpen} onClose={close} aria-labelledby="form-dialog-title">
      <HeaderTitleModal onClose={() => cancel()}>
        T&Eacute;RMINOS Y CONDICIONES DE USO Y POL&Iacute;TICA DE PRIVACIDAD
      </HeaderTitleModal>
      <form>
        <DialogContent dividers>
          <Grid container direction="column">
            <Grid container direction="row">
              <Grid item xs={2} md={1}></Grid>
              <Grid item xs={10} md={11}>
                <DialogContentText>
                  El agente declara que asesor&oacute; al cliente a fin de que
                  la solicitud y toda la documentaci&oacute;n contenga
                  informaci&oacute;n veraz.
                  La falta de veracidad puede afectar la validez del
                  contrato o el reembolso de los reclamos.
                </DialogContentText>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={2} md={1}>
                <Checkbox
                  disableRipple
                  color="secondary"
                  checked={acceptedTermsConditions}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={10} md={11}>
                <DialogContentText>
                  El agente reconoce que el cliente acept&oacute; que
                  act&uacute;e por su cuenta y representaci&oacute;n
                  en esta transacci&oacute;n. As&iacute; como
                  tambi&eacute;n reconoce que el cliente
                  ha le&iacute;do y aceptado los
                  <a
                    className={classes.link}
                    target="blank"
                    href="termsandconditions"
                  >
                    T&eacute;rminos y condiciones de Uso
                  </a> y <a className={classes.link} target="blank" href="privacyPolicy">Pol&iacute;tica de Privacidad</a> de este servicio digital.
                </DialogContentText>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 20 }}>
          <Button
            fullWidth
            onClick={cancel}
            color="secondary"
            className={classes.cancelButton}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            fullWidth
            type="button"
            color="secondary"
            disabled={!acceptedTermsConditions}
            onClick={close}
            variant="contained"
            className={classes.confirmButton}
          >
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

export default TermsConditionsForm;
