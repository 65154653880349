import { makeStyles } from '@material-ui/core/styles';

const paymentStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  iframe: {
    width: '100%',
    minHeight: 640,
    border: 0,
  },
  center: {
    alignSelf: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  saveButton: {
    borderRadius: 20,
  },
  inlineContainer: {
    display: 'inline-flex',
  },
  textStyleSubtitle: {
    padding: 10,
    fontFamily: 'Roboto-Light',
    fontSize: 15,
    paddingLeft: 20,
    paddingTop: 11,
  },
  textStyleContent: {
    padding: 10,
  },
  line: {
    borderBottom: '1px solid',
    color: theme.palette.primary.main,
    marginLeft: 20,
    marginRight: 20,
  },
  contentSubtitle1: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
    },
  },
  contentContainer: {
    [theme.breakpoints.up('lg')]: {
      padding: 30,
    },
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
  },
  buttonsContainer: {
    paddingTop: 25,
  },
  effectiveDate: {
    marginBottom: 0,
  },
  effectiveDateCredit: {
    marginBottom: 0,
    marginTop: 0,
  },
  backdrop: {
    display: 'flex',
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
  },
  backdropText: {
    padding: 10,
  },
  responsabilityAssumed: {
    color: '#a7a6a7',
    fontSize: 12,
  },
  centered: {
    textAlign: 'center',
  },
  totalValue: {
    fontWeight: 'bold',
    fontSize: 15,
    paddingLeft: 10,
  },
  totalLabel: {
    fontFamily: 'Roboto-Light',
    fontSize: 15,
  },
}));

export default paymentStyles;
