import React, { useEffect, useRef } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel, FormHelperText,
  Grid, InputLabel, MenuItem, Select,
  Switch,
  TextField,
  Typography,
  ButtonGroup, Tooltip, IconButton,
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, FormContext, useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';

import CustomPanel from '../../../../common/CustomPanel';
import { selectRelationshipBillingData, selectDivisionPolitica } from '../utils/selectors';
import SearchIcon from '@material-ui/icons/Search';
import { isValidId } from '../../../../../utils/id-validator';
import { requestClientInformation } from '../actions/client_information';
import { addSweetAlert, closeSweetAlert } from '../../../../common/SweetAlert/actions/sweet_alert';
import * as Constants from '../../../../../utils/Constants';

import useAxios from 'axios-hooks';

const BillData = (props) => {
  const { readOnly } = props;
  const { register, control, errors, watch, setValue, clearError, getValues } = useFormContext();
  const relationship = useSelector(selectRelationshipBillingData);
  const dispatch = useDispatch();
  const { requestData: quoteData } = useSelector((state) => state.quote);

  const quote = useSelector((state) => state.quote);
  const getApplication = useSelector((state) => state.getApplication);
  const watchContact = watch('contact');
  const anotherPersonWatch = watch('billingData.anotherPerson');
  const identificationWatch = watch('billingData.identification');
  const { name: nameHolder = '', lastName: lastNameHolder = '', identification: identificationHolder = '',nacionality: nacionalityHolder = '' } = watch('insuranceHolder') || {};
  const { name: nameMain = '', lastName: lastNameMain = '', identification: identificationMain = '',nacionality: nacionalityMain = '' } = watch('insuranceMainInsured') || {};
  const [isIdentification, setIsIdentification] = React.useState(null);
  const identification = watch('billingData.identification');
  const [foundIdentification, setFoundIdentificacion] = React.useState(null);
  const countries = useSelector(selectDivisionPolitica);
  const nacionalityWatch = watch('billingData.nacionality');

  const [
    { loading, error, response }, getIdentificationInfo,
  ] = useAxios(
    {
      url: `${process.env.REACT_APP_CLIENT_INFORMATION_VALIDATION}/${identification}/${quoteData?.productId}/false`,
      method: 'GET',
    },
    { manual: true },
  );

  const resetBillingToContact = () => {

    const person = { name: '', lastName: '', identification: '',nacionality: '' };
    if (quote?.requestData?.withDifferentHolder) {
      person.name = nameHolder;
      person.lastName = lastNameHolder;
      person.identification = identificationHolder;
      person.nacionality = nacionalityHolder;
    } else {
      person.name = nameMain;
      person.lastName = lastNameMain;
      person.identification = identificationMain;      
      person.nacionality = nacionalityMain;
    }


    setValue([
      { 'billingData.name': person.name },
      { 'billingData.lastName': person.lastName },
      { 'billingData.identification': person.identification },
      { 'billingData.address': watchContact?.address || '' },
      { 'billingData.phone': watchContact?.cellularPhone || '' },
      { 'billingData.email': watchContact?.email || '' },
      { 'billingData.nacionality': person.nacionality },
    ]);
    clearError([
      'billingData.name',
      'billingData.lastName',
      'billingData.identification',
      'billingData.address',
      'billingData.phone',
      'billingData.email',
      'billingData.nacionality',
    ]);
  };

  useEffect(() => {
    if (!anotherPersonWatch) {
      resetBillingToContact();
      setFoundIdentificacion(null);
    } else {
      resetCompleteForm();
    }
  }, [anotherPersonWatch]);

  const setInfo = () => {
    const person = { name: '', lastName: '', identification: '' };
    const samePerson = !getValues('billingData.anotherPerson');
    if (samePerson) {
      if (quote?.requestData?.withDifferentHolder) {
        person.name = nameHolder;
        person.lastName = lastNameHolder;
        person.identification = identificationHolder;
        person.nacionality = nacionalityHolder;
      } else {
        person.name = nameMain;
        person.lastName = lastNameMain;
        person.identification = identificationMain;
        person.nacionality = nacionalityMain;
      }
      setValue([
        { 'billingData.name': person.name },
        { 'billingData.lastName': person.lastName },
        { 'billingData.identification': isIdentification ? person.identification : `${person.identification}001` },
        { 'billingData.nacionality': person.nacionality },
      ]);
    } else {
      if(isIdentification) {

      }
      if (quote?.requestData?.personalData?.billingData) {
        setDataFromQuote();

      }
    }
  };

  const setDataFromQuote = () => {
    const billingDataToSet = quote?.requestData?.personalData?.billingData;
    setValue([
      { 'billingData.name': billingDataToSet.name },
      { 'billingData.lastName': billingDataToSet.lastName },
      { 'billingData.identification': billingDataToSet.identification },
      { 'billingData.address': billingDataToSet.address },
      { 'billingData.phone': billingDataToSet.phone },
      { 'billingData.email': billingDataToSet.email },
      { 'billingData.relationshipBeneficiary': billingDataToSet.relationshipBeneficiary },
      { 'billingData.nacionality': billingDataToSet.nacionality },
    ]);
  }

  const setAddress = () => {
    if (!getValues('billingData.anotherPerson')) {
      if (watchContact.address || watchContact.cellularPhone || watchContact.email ) {
        clearError([
          'billingData.address',
          'billingData.phone',
          'billingData.email',          
        ]);
        setValue([
          { 'billingData.address': watchContact?.address || '' },
          { 'billingData.phone': watchContact?.cellularPhone || '' },
          { 'billingData.email': watchContact?.email || '' },          
        ]);
      }
    }
  };

  useEffect(() => {
    if (getApplication?.response?.InsuranceApplicationBilling) {
      const insuranceApplicationBilling = getApplication?.response?.InsuranceApplicationBilling;
      setValue([
        { 'billingData.anotherPerson': insuranceApplicationBilling?.AnotherPerson},
        { 'billingData.name': insuranceApplicationBilling?.Name?.trim() || '' },
        { 'billingData.lastName': insuranceApplicationBilling?.LastName?.trim() || '' },
        { 'billingData.address': insuranceApplicationBilling?.Address?.trim() || '' },
        { 'billingData.phone': insuranceApplicationBilling?.Phone?.trim() || '' },
        { 'billingData.email': insuranceApplicationBilling?.Email?.trim() || '' },
        { 'billingData.identification': insuranceApplicationBilling?.Identification?.trim() || '' },
        { 'billingData.relationshipBeneficiary': insuranceApplicationBilling?.RelationshipBeneficiary?.trim() || '' },
        { 'billingData.isIdentification': insuranceApplicationBilling?.Identification?.trim().length === 10 ? true : false },
        { 'billingData.nacionality': insuranceApplicationBilling?.nacionality?.trim() || '' },
      ]);

      const identificationLength = insuranceApplicationBilling?.Identification?.trim().length;
      if (identificationLength === 10) {
        setIsIdentification(true);
      } else {
        setIsIdentification(false);
      }
    }
  }, [getApplication.response]);

  const relacionRef = useRef();
  const nombresRef = useRef();
  const apellidosRef = useRef();
  const rucRef = useRef();
  const direccionRef = useRef();
  const telefonoRef = useRef();
  const mailRef = useRef();
  const nacionalityRef= useRef();

  useEffect(() => {
    if (isIdentification !== null) {
      setInfo();
      setAddress();
    }
  }, [isIdentification]);

  const handleSearchIdentification = () => {
    if (identification && isIdentification) {
      if (isValidId(identification)) {
        getIdentificationInfo();
      } else {
        dispatch(addSweetAlert({
          type: 'warning',
          title: Constants.MESSAGE_INVALID_IDENTIFICATION,
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }));
      }
    }
  };

  useEffect(() => {
    if (response && response?.status === 200) {
      if(response?.data?.personDto) {
        setFoundIdentificacion(true);
        clearError([
          'billingData.name',
          'billingData.lastName',
          'billingData.nacionality',
        ]);
        setValue([
          { 'billingData.name': response?.data?.personDto.name || '' },
          { 'billingData.lastName': response?.data?.personDto.lastName || '' },
          { 'billingData.nacionality': response?.data?.personDto.nacionality || '' },
        ]);
      } else {
        dispatch(addSweetAlert({
          type: 'warning',
          title: 'No existe un registro con la identificaci\u00F3n ingresada',
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            setFoundIdentificacion(false);
            resetPartialForm();
            dispatch(closeSweetAlert());
          },
        }));
      }
    } else if (error) {
      dispatch(addSweetAlert({
        type: 'info',
        title: 'Documento no encontrado',
        content: Constants.MESSAGE_REGISTRO_CIVIL,
        showCancel: false,
        onConfirmLabel: 'Continuar',
        onConfirm: () => {
          setFoundIdentificacion(false);
          resetPartialForm();
          dispatch(closeSweetAlert());
        },
      }));
    }
  }, [response]);

  const resetCompleteForm = () => {
    clearError([
      'billingData.name',
      'billingData.lastName',
      'billingData.address',
      'billingData.phone',
      'billingData.email',
      'billingData.identification',
      'billingData.relationshipBeneficiary',
      'billingData.nacionality'
    ]);
    setValue([
      { 'billingData.name': '' },
      { 'billingData.lastName': '' },
      { 'billingData.address': '' },
      { 'billingData.phone': '' },
      { 'billingData.email': '' },
      { 'billingData.identification': '' },
      { 'billingData.relationshipBeneficiary': '' },
      { 'billingData.nacionality': '' },
    ]);
  };

  const resetPartialForm = () => {
    clearError([
      'billingData.name',
      'billingData.lastName',
      'billingData.nacionality',
    ]);
    setValue([
      { 'billingData.name': '' },
      { 'billingData.lastName': '' },
      { 'billingData.nacionality': '' },
    ]);
  };

  const onClickIsIdentification = (value) => {
    setIsIdentification(value);
    setValue([
      { 'billingData.isIdentification': value },
    ]);
  };

  return (
    <CustomPanel title="Datos de Facturación" border={false}>
      <Grid
        container
        direction="row"
        spacing={3}
      >
        <Grid item md={anotherPersonWatch ? 8 : 12} xs={12}>
          <FormControlLabel
            control={(
              <Controller
                as={(
                  <Switch
                    color="primary"
                  />
                )}
                control={control}
                name="billingData.anotherPerson"
                defaultValue={false}
              />
            )}
            label={(
              <Typography variant="h5">
                Factura a nombre de otra persona natural
              </Typography>
            )}
            labelPlacement="start"
          />
        </Grid>
        <Controller
          as={TextField}
          name="billingData.isIdentification"
          control={control}
          defaultValue={false}
          type="hidden"
        />
        {anotherPersonWatch && (
          <Grid item md={4} xs={12}>
            <FormControl
              fullWidth
              error={errors.billingData?.hasOwnProperty('relationshipBeneficiary')}
            >
              <InputLabel id="id-relationship">RELACIÓN CON TITULAR</InputLabel>
              <Controller
                as={(
                  <Select inputRef={relacionRef}>
                    <MenuItem value="">
                      <em>Seleccione..</em>
                    </MenuItem>
                    {
                      relationship?.map((relation) => (
                        <MenuItem
                          key={relation.id}
                          value={relation.id}
                        >
                          {relation.description}
                        </MenuItem>
                      ))
                    }
                  </Select>
                )}
                onFocus={() => relacionRef.current.focus()}
                name="billingData.relationshipBeneficiary"
                control={control}
                defaultValue=""
                inputProps={{ readOnly }}
              />
              <FormHelperText>
                {errors.billingData?.relationshipBeneficiary?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
        <Grid item md={12} xs={12}>
          <ButtonGroup color="primary">
            <Button variant={isIdentification ? 'contained' : 'outlined'} onClick={() => onClickIsIdentification(true)}>CÉDULA</Button>
            <Button variant={isIdentification === null ? 'outlined' : !isIdentification ? 'contained' : 'outlined'} onClick={() => onClickIsIdentification(false)}>RUC</Button>
          </ButtonGroup>
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            as={(
              <TextField inputRef={nombresRef} />
            )}
            onFocus={() => nombresRef.current.focus()}
            autoFocus
            margin="dense"
            label="NOMBRE"
            autoComplete="off"
            name="billingData.name"
            type="text"
            fullWidth
            control={control}
            disabled={(!anotherPersonWatch && foundIdentification === null)
            || (anotherPersonWatch && foundIdentification === null && isIdentification === null )
            || (anotherPersonWatch && foundIdentification === null && isIdentification )
            || (anotherPersonWatch && foundIdentification && isIdentification )}
            error={errors.billingData?.name && true}
            helperText={errors.billingData?.name
            && errors.billingData?.name.message}
            defaultValue=""
            InputProps={{ readOnly }}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            as={(
              <TextField inputRef={apellidosRef} />
            )}
            onFocus={() => apellidosRef.current.focus()}
            autoFocus
            margin="dense"
            label="APELLIDOS"
            autoComplete="off"
            name="billingData.lastName"
            type="text"
            fullWidth
            control={control}
            disabled={(!anotherPersonWatch && foundIdentification === null)
            || (anotherPersonWatch && foundIdentification === null && isIdentification === null )
            || (anotherPersonWatch && foundIdentification === null && isIdentification )
            || (anotherPersonWatch && foundIdentification && isIdentification )}
            error={errors.billingData?.lastName && true}
            helperText={errors.billingData?.lastName
            && errors.billingData?.lastName.message}
            defaultValue=""
            InputProps={{ readOnly }}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            as={(
              <TextField inputRef={rucRef} />
            )}
            onFocus={() => rucRef.current.focus()}
            margin="dense"
            label={isIdentification || isIdentification === null ? 'CI' : 'RUC'}
            autoComplete="off"
            name="billingData.identification"
            type="number"
            fullWidth
            control={control}
            disabled={!anotherPersonWatch}
            error={errors.billingData?.identification && true}
            helperText={errors.billingData?.identification
            && errors.billingData?.identification.message}
            defaultValue=""
            InputProps={{
              endAdornment: (
                anotherPersonWatch && isIdentification && <Tooltip title="Buscar" arrow>
                  <IconButton
                    aria-label="search"
                    size="small"
                    onClick={handleSearchIdentification}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              ),
              readOnly: !anotherPersonWatch,
            }}
          />
        </Grid>
        <Grid item md={4} xs={12}>
        <FormControl
              fullWidth
              error={errors.billingData?.hasOwnProperty('nacionality')}
            >
              <InputLabel id="id-nacionality">NACIONALIDAD</InputLabel>
              <Controller
                as={(
                  <Select inputRef={nacionalityRef}>
                    <MenuItem value="">
                      <em>Seleccione..</em>
                    </MenuItem>
                    {
                      countries?.map((relation) => (
                        <MenuItem
                          key={relation.id}
                          value={relation.description}
                        >
                          {relation.description}
                        </MenuItem>
                      ))
                    }
                  </Select>
                )}
                onFocus={() => nacionalityRef.current.focus()}
                name="billingData.nacionality"
                control={control}
                defaultValue=""
                inputProps={{ readOnly }}
                disabled={(!anotherPersonWatch && foundIdentification === null)
                  || (anotherPersonWatch && foundIdentification === null && isIdentification === null )
                  || (anotherPersonWatch && foundIdentification === null && isIdentification )
                  || (anotherPersonWatch && foundIdentification && isIdentification )}                
              />
              <FormHelperText>
                {errors.billingData?.nacionality?.message}
              </FormHelperText>
            </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <Controller
            as={(
              <TextField inputRef={direccionRef} />
            )}
            onFocus={() => direccionRef.current.focus()}
            margin="dense"
            label="DIRECCIÓN"
            autoComplete="off"
            name="billingData.address"
            type="text"
            fullWidth
            control={control}
            disabled={!anotherPersonWatch}
            error={errors.billingData?.address && true}
            helperText={errors.billingData?.address
            && errors.billingData?.address.message}
            defaultValue=""
            InputProps={{ readOnly }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            as={(
              <TextField inputRef={telefonoRef} />
            )}
            margin="dense"
            label="CELULAR"
            onFocus={() => telefonoRef.current.focus()}
            autoComplete="off"
            name="billingData.phone"
            type="string"
            inputProps={{ maxLength: 10 }}
            fullWidth
            control={control}
            disabled={!anotherPersonWatch}
            error={errors.billingData?.phone && true}
            helperText={errors.billingData?.phone && errors.billingData?.phone.message}
            defaultValue=""
            InputProps={{ readOnly }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            as={(
              <TextField inputRef={mailRef} />
            )}
            onFocus={() => mailRef.current.focus()}
            margin="dense"
            label="EMAIL"
            autoComplete="off"
            name="billingData.email"
            type="text"
            fullWidth
            control={control}
            disabled={!anotherPersonWatch}
            error={errors.billingData?.email && true}
            helperText={errors.billingData?.email && errors.billingData?.email.message}
            defaultValue=""
            InputProps={{ readOnly }}
          />
        </Grid>
      </Grid>
    </CustomPanel>
  );
};

BillData.propTypes = {
  readOnly: PropTypes.bool.isRequired,
};

export default BillData;
