import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Stepper,
  Step,
  Button,
  Typography,
  AppBar,
  Toolbar,
  StepButton,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Application, Payment, Signing } from './components';
import issuanceStyles from './style/issuance-style';
import { isEmpty } from '../../utils/CommonUtils';

function getSteps() {
  return ['Cotización', 'Solicitud', 'Pago', 'Firma'];
}

function getStepContent(stepIndex, handleNext) {
  switch (stepIndex) {
    case 0:
      return (<Redirect to="/quotation" />);
    case 1:
      return (<Application handleNext={handleNext} />);
    case 2:
      return (<Payment handleNext={handleNext} />);
    case 3:
      return (<Signing handleNext={handleNext} />);
    default:
      return 'Unknown stepIndex';
  }
}

const Issuance = (props) => {
  const { children } = props;
  const classes = issuanceStyles();
  const getApplication = useSelector((state) => state.getApplication);

  const [activeStep, setActiveStep] = React.useState(props?.location?.state?.step ? props?.location?.state?.step : 1);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };


  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <AppBar position="sticky" className={classes.header}>
        <Toolbar>
          <div className={classes.flexGrow} />
          <div className={classes.flexGrow} />
          <Typography
            variant="subtitle1"
            style={{ color: '#00315C', fontFamily: 'Roboto Light', fontSize: '18px' }}
          >
            Proceso Emisión
          </Typography>
          <div className={classes.flexGrow} />
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <Stepper activeStep={activeStep} alternativeLabel className={classes.stepperClass}>
          {steps && steps.map((label, index) => (
            !isEmpty(getApplication.response) && index === 0 ?
              <Step disabled key={label}>
                <StepButton onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
              : <Step key={label}>
                <StepButton onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <div>
                {getStepContent(activeStep, handleNext)}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Issuance.propTypes = {
  children: PropTypes.node,
};

export default Issuance;
