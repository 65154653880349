import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import HeaderTitleModal from '../HeaderTitleModal';
import modalStyles from '../style/modal-style';
import { closeModal } from '../../../../../common/modals/actions/simple_modal';

function CashPaymentModal({ classes }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const simpleModal = useSelector((state) => state.simpleModal);

  const close = () => {
    history.push('/emissions');
    dispatch(closeModal());
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={simpleModal.isOpen} onClose={close} aria-labelledby="form-dialog-title">
      <HeaderTitleModal>
        Confirmaci&oacute;n
      </HeaderTitleModal>
      <DialogContent>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          spacing={2}
          style={{ padding: 20 }}
        >
          <Grid item xs={12}>
            <Typography
              className={classes.valueContent}
            >
              Su transacci&oacute;n ha sido exitosa
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.valueContent}
            >
              La transferencia/dep&oacute;sito ser&aacute; validada en BMI
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.valueContent}
            >
              Cualquier valor recibido constituye un dep&oacute;sito que ser&aacute; aplicado una vez aprobado el contrato. El pago  no implica fecha de vigencia del contrato, la vigencia ser&aacute; la fijada conforme las ofertadas.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" className={classes.roundButton}>
          Ver Estado de Emisiones
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CashPaymentModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(modalStyles)(CashPaymentModal);
