import {
  START_REQUEST_PAYMENT_DATA,
  REQUEST_PAYMENT_DATA_SUCCESS,
  REQUEST_PAYMENT_DATA_FAILED,
} from '../actions/signing';

const PAYMENT_DATA_INITIAL_STATE = {
  loading: false,
  response: {},
  error: false,
};

export default (state = PAYMENT_DATA_INITIAL_STATE, action) => {
  switch (action.type) {
    case START_REQUEST_PAYMENT_DATA:
      return {
        ...state,
        loading: true,
        response: {},
        error: false,
      };
    case REQUEST_PAYMENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        error: false,
      };
    case REQUEST_PAYMENT_DATA_FAILED:
      return {
        ...state,
        loading: false,
        response: action.payload,
        error: true,
      };
    default:
      return state;
  }
};
