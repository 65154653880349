export default {
  root: {
    fontSize: 50,
    zIndex: 1,
    display: 'flex',
    color: '#CFCECE',
    '&$active': {
      color: '#709933',
    },
    '&$completed': {
      color: '#709933',
    },
  },
};
