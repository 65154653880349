/* eslint-disable react/no-array-index-key */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid, Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/core/SvgIcon/SvgIcon';
import MaterialTable from 'material-table';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  expansionPanel: {
    borderRadius: '15px',
    borderTopLeftRadius: '15px !important',
    borderTopRightRadius: '15px !important',
    borderBottomLeftRadius: '15px !important',
    borderBottomRightRadius: '15px !important',
  },
  expansionPanelSummary: {
    borderRadius: '15px',
    backgroundColor: '#518CBF',
  },
  expansionPanelDetails: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
  iconColor: {
    color: 'white',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '85%',
    flexShrink: 0,
    color: theme.palette.white,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.white,
  },
}));

function AdditionalBenefits({ benefits = [] }) {
  const classes = useStyles();
  const styleImagen = { maxWidth: '90%' };
  const benefitsOneColumn = [
    {
      title: 'Column1',
      cellStyle: {
        width: '100%',
        textAlign: 'center',
        backgroundColor: '#FFFFFF',
      },
      render: (rowData) => (rowData.valueList.map((img) => (
        <img key={img} src={img} alt="" style={styleImagen} />))),
    },
  ];
  const benefitsOneColumnNoImage = [
    {
      title: 'Column1',
      field: 'description',
      cellStyle: { width: '100%', textAlign: 'justify' },
    },
  ];
  const benefitsTwoColumns = [
    {
      field: 'description',
      cellStyle: { width: '80%' },
    },
    {
      field: 'value',
      cellStyle: { width: '20%' },
    },
  ];

  const benefitOptions = {
    paging: false,
    search: false,
    sorting: false,
    draggable: false,
    showTitle: false,
    toolbar: false,
    header: false,
    rowStyle: (x) => {
      if ((x.order % 2) !== 0) {
        return { backgroundColor: '#c4d9f2', color: '#518CBF' };
      }
      return { color: '#518CBF' };
    },
  };
  const components = {
    Container: (props) => (
      <Paper
        {...props}
        elevation={0}
      />
    ),
  };
  return (
    <Grid item xs={12} style={{ borderRadius: '15px', paddingTop: '1px', paddingBottom: '0px' }}>
      <div className={classes.root}>
        {benefits.map((benefit, index) => (
          <ExpansionPanel className={classes.expansionPanel} key={index}>
            <ExpansionPanelSummary
              className={classes.expansionPanelSummary}
              expandIcon={<ExpandMoreIcon className={classes.iconColor} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                {benefit.title.toUpperCase()}
              </Typography>
              {benefit?.withCoverage && (
                <Typography className={classes.secondaryHeading}>
                  Coberturas
                </Typography>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              <div style={{ flex: 1, maxWidth: '100%' }}>
                {benefit.benefits.map((ben, ind) => {
                  const tmpTable = [ben];
                  if(ben.isImage || (!ben.isImage && !benefit.withCoverage)) {
                    return (
                        <MaterialTable
                            key={ind}
                            // eslint-disable-next-line max-len
                            columns={ben.isImage ? benefitsOneColumn : benefitsOneColumnNoImage}
                            data={tmpTable}
                            options={benefitOptions}
                            components={components}
                        />
                    );
                  } else {
                    return (
                        <table  key={ind} className={"MuiTable-root"}>
                          <tbody className="MuiTableBody-root">
                            <tr index={ind} className={"MuiTableRow-root"} style={(ind % 2) !== 0 ? { backgroundColor: '#c4d9f2', color: '#518CBF' } : { color: '#518CBF' }}>
                              <td  className={"MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft"} style={{ width: '80%'}}>
                                {tmpTable[0].description}
                              </td>
                              <td className={"MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft"} >
                                {tmpTable[0].value}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                    );
                  }
                })}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
    </Grid>
  );
}

AdditionalBenefits.propTypes = {
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      benefits: PropTypes.array.isRequired,
      withCoverage: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default memo(AdditionalBenefits);
