/* eslint-disable no-use-before-define */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { trackPromise } from 'react-promise-tracker';
import api from '../../../utils/Api';

import {
  failedRequestCatalogs,
  START_REQUEST_CATALOGS,
  successRequestCatalogs,
} from '../actions/catalogs';
import { areas } from '../../../utils/Constants';

/* Getting all users saga */
export const fetchCatalogs = () => trackPromise(api
  .get(process.env.REACT_APP_CATALOGS_URL), areas.global);
export const fetchProducts = () => trackPromise(api
  .get(process.env.REACT_APP_PRODUCTS_URL), areas.global);
export const fetchParameters = () => trackPromise(api
  .get(process.env.REACT_APP_PARAMETERS_URL), areas.global);

export function* watchRequestCatalogs() {
  yield takeLatest(START_REQUEST_CATALOGS, requestCatalogsSaga);
}

export function* requestCatalogsSaga() {
  try {
    const [catalogs, products, parameters] = yield all([call(fetchCatalogs),
      call(fetchProducts), call(fetchParameters)]);
    yield put(successRequestCatalogs(catalogs.data, products.data, parameters.data));
  } catch (error) {
    yield put(failedRequestCatalogs(error?.message));
  }
}
