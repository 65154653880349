/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    '& ol': {
      listStyleType: 'none',
      counterReset: 'item',
      margin: 0,
      padding: 0,
      marginBottom: '20px',
      '& li': {
        display: 'table',
        counterIncrement: 'item',
        marginBottom: '0.6em',
        textAlign: 'justify',
        '&::before': {
          content: 'counters(item, ".") ". "',
          display: 'table-cell',
          paddingRight: '0.6em',
        },
        '& p': {
          marginBottom: '10px',
          textAlign: 'justify',
        },
        '& a': {
          fontWeight: 'bold',
        },
      },
    },
  },
  iframe: {
    width: '100%',
    minHeight: 640,
    border: 0,
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <center><h2>POLÍTICA DE PRIVACIDAD</h2></center>
      <br />
      <ol>
        <li><a>ALCANCE</a>
          <p style={{ marginBottom: '20px' }}>Esta Política de Privacidad se aplicará a todas las Bases de Datos y/o Archivos que contengan Datos Personales que sean objeto de tratamiento por BMI IGUALAS MÉDICAS DEL ECUADOR S.A. (en adelante BMI), considerada como responsable del tratamiento de Datos Personales.</p>
        </li>

        <li>
          <a>DEFINICIONES</a>
          <ol>
            <li>Autorización: Es el consentimiento previo, expreso e informado del Titular de los Datos Personales para llevar a cabo el Tratamiento de Datos Personales.</li>
            <li>Aviso de Privacidad: Comunicación escrita generada por BMI, dirigida al TDP para el Tratamiento de sus Datos Personales, mediante la cual se le informa acerca de la existencia de las Políticas de Privacidad de información que le serán aplicables, la forma de acceder a las mismas y las finalidades del Tratamiento que se pretende dar a los Datos Personales.</li>
            <li>Base de Datos: Conjunto organizado de Datos Personales que sea objeto de Tratamiento.</li>
            <li>Clientes: Persona natural o jurídica que tiene relación contractual con BMI y/o ha presentado una solicitud a BMI para celebrar un contrato. Se entenderá también por Clientes a aquellas personas naturales que constan como afiliadas o dependientes de un contrato individual o corporativo.</li>
            <li>Datos Personales: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables, incluye información médica de diagnóstico, tratamientos o condición de salud, así como información personal entre la que se incluye información patrimonial y financiera.</li>
            <li>Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de Datos Personales por cuenta de BMI. </li>
            <li>Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, procese, custodie o administre la Base de Datos y/o el Tratamiento de los datos con los fines establecidos en el contrato.</li>
            <li>Términos y Condiciones: marco general en el cual se establecen las condiciones para los partícipes de actividades comerciales o afines.</li>
            <li>Titular de los Datos Personales (TDP): Persona natural afiliada o dependiente de un contrato individual o corporativo, cuyos Datos Personales sean objeto de Tratamiento, incluyendo aquellas personas que hayan entregado una solicitud de servicios de atención integral de salud prepagada y que no culminaron su contratación.</li>
            <li>Tratamiento: Cualquier operación o conjunto de operaciones sobre Datos Personales, tales como la recolección, almacenamiento, uso o verificación.</li>
            <li>Transferencia: La transferencia de datos tiene lugar cuando el Responsable y/o Encargado del Tratamiento, envía, transmite o comparte los Datos Personales a un tercero o un Encargado de Tratamiento se encuentra dentro o fuera del Ecuador.</li>
          </ol>
        </li>
        <li>
          <a>TRATAMIENTO</a>
          <p style={{ marginBottom: '20px' }}>BMI para el desarrollo de sus actividades comerciales y por la naturaleza del servicio que presta, en calidad de Responsable del Tratamiento de Datos Personales recolecta, almacena, usa y verifica Datos Personales correspondientes a los Clientes y/o TDP.</p>
        </li>
        <li>
          <a>FINALIDAD</a>
          <p style={{ marginBottom: '20px' }}>Los Datos Personales son objeto de Tratamiento por parte de BMI con las siguientes finalidades:</p>
          <ol>
            <li>Participación en distintos tipos de encuestas mediante registros o formularios offline/online.</li>
            <li>Llenado de solicitudes de suscripción, servicios online o bien para facilitar la solicitud de información puntual que nos sea requerida.</li>
            <li>Informarle de noticias importantes concernientes a BMI.</li>
            <li>Informarle sobre nuestros nuevos productos o servicios, actualizaciones, cuestiones de soporte técnico, eventos y ofertas especiales.</li>
            <li>Promocionar productos y servicios en nuestra página web.</li>
            <li>Prevenir y detectar fraude u otras actividades ilícitas o prohibidas.</li>
            <li>Velar por la seguridad e integridad de nuestra Página Web.</li>
            <li>Como parte de un proceso de venta continuo.</li>
            <li>Para formar parte de medios automatizados como protocolos de comunicación.</li>
            <li>Para realizar análisis y evaluaciones sobre nuestro negocio; así como para analizar estrategias para afianzamiento de Clientes.</li>
            <li>Para la gesti&oacute;n y an&aacute;lisis de reembolso de siniestros.</li>
            <li>Para otorgar el beneficio de Cr&eacute;dito Ambulatorio y/u Hospitalario.</li>
            <li>Para la prestaci&oacute;n de servicios de parte de BMI.</li>
          </ol>
        </li>

        <li>
          <a>DERECHOS DE LOS TITULARES DE DATOS PERSONALES</a>
          <p style={{ marginBottom: '20px' }}>Los Clientes y/o TDP cuyos Datos Personales sean objeto de Tratamiento por parte de BMI, tienen los siguientes derechos, los cuales pueden ejercer en cualquier momento:</p>
          <ol>
            <li>Acceder y conocer sus Datos Personales sobre los cuales BMI est&aacute; realizando el Tratamiento de manera gratuita.</li>
            <li>Ser informado por BMI, previa solicitud, respecto del uso que &eacute;sta le ha dado a sus Datos Personales.</li>
            <li>Solicitar a BMI la supresión de sus Datos Personales, excepto su información médica visto que esta tiene relación con su Historia Clínica.  No obstante, la solicitud de supresión de la información y la revocatoria de la autorización no procederán cuando el TDP de la información tenga un deber legal o contractual de permanecer en la Base de Datos y/o Archivos en virtud de la cual fueron recolectados sus datos.</li>
            <li>Solicitar la actualización o rectificación de sus Datos Personales, excepto aquellos datos relacionados con datos de salud. BMI podrá solicitar al TDP información adicional que sustente la necesidad de actualizar o rectificar. </li>
          </ol>
        </li>

        <li>
          <a>AUTORIZACIÓN:</a>
          <p>BMI derivado de la relación contractual que tiene con cada Cliente o por medio de la aceptación de las condiciones establecidas en las Solicitudes de Servicios de Atención Integral de Salud Prepagada tiene autorización para el Tratamiento de sus Datos Personales.</p>
          <p style={{ marginBottom: '20px' }}>La autorización está dada a través de varios instrumentos, tales como:</p>
          <ol>
            <li>La Solicitud de Servicios de Atención Integral de Salud Prepagada.</li>
            <li>El Contrato de Servicios de Atención Integral de Salud Prepagada.</li>
            <li>Mediante conductas inequívocas que permitan concluir que otorgó su autorización, por ejemplo, a través de su aceptación expresa de Términos y Condiciones de una actividad y/o uso de un Sitio Web y/o aplicativo electrónico dentro de los cuales se comunica el Aviso de Privacidad y se requiere la autorización de los participantes para el Tratamiento de sus Datos Personales sea dado directamente a través del Cliente/TDP o de un tercero que lo representa, como por ejemplo un asesor productor de seguros o relacionado a estos últimos.</li>
          </ol>
        </li>

        <li>
          <a>DISPOSICIONES ESPECIALES PARA EL TRATAMIENTO DE DATOS DE SALUD:</a>
          <p>Se consideran datos de salud aquellos que contienen información sobre diagnósticos, tratamientos, situación médica, antecedentes médicos personales y familiares, y cualquier información que sirva para identificar la condición previa o actual de salud del TDP o del Cliente. Los datos de salud pueden incluir datos relacionados con el origen racial o étnico y vida sexual, que de manera general, normalmente no se solicitan pero que por tratarse de servicios relacionados a la salud pueden ser requeridos.</p>
          <p style={{ marginBottom: '20px' }}>En este caso, por cuanto BMI maneja información médica, el Cliente/TDP reconoce que se utilizarán y analizarán los Datos Personales en el desarrollo de la relación contractual entre las partes.</p>
        </li>

        <li>
          <a>DISPOSICIONES ESPECIALES PARA EL TRATAMIENTO DE DATOS PERSONALES DE NIÑOS, NIÑAS Y ADOLESCENTES:</a>
          <p style={{ marginBottom: '20px' }}>El Cliente/TDP reconoce que BMI realizará el Tratamiento de Datos Personales de niños y adolescentes que tienen relación con su condición de salud por el tipo de servicio que presta, esto no afecta el derecho a la privacidad, pues BMI utiliza los Datos Personales en coherencia con el servicio que presta o la evaluación de una solicitud. El Cliente reconoce tener la patria potestad o ser el representante legal del TDP o estar autorizado por el representante legal y que por ende se encuentra facultado para compartir y autorizar el tratamiento de los datos personales del TDP de acuerdo a lo establecido en la presente Política de Privacidad.</p>
        </li>

        <li>
          <a>PROCEDIMIENTO PARA ATENCIÓN Y RESPUESTA A SOLICITUDES Y CONSULTAS:</a>
          <p>Los Titulares de los Datos Personales que están siendo recolectados, almacenados, utilizados por BMI, podrán ejercer en cualquier momento sus derechos a acceder, actualizar y rectificar la información suya o de sus representados. Derivado del cumplimiento de la ley, la historia clínica es única y no puede ser alterada ni modificada, por ello está prohibido modificar de cualquier manera los Datos Personales relacionados con información médica debido a que éstos están relacionados con la historia clínica del TDP.</p>
          <p>En el caso de actualización o rectificación de los Datos Personales, BMI se reserva el derecho de solicitar documentos adicionales que corroboren o confirmen la inexactitud del dato que deba ser actualizado o corregido.</p>
          <p>Para ejercer los derechos establecidos en la presente cláusula, el TDP deberá enviar una comunicación al correo electrónico postventa@bmi.com.ec, desarrollando su solicitud. En la comunicación electrónica, el TDP deberá identificarse con su nombre, apellido, documento de identificación, número de contrato y dirección electrónica a la cual pueda recibir la respuesta. BMI responderá la consulta dentro de los siguientes 2 días laborables desde la recepción del correo electrónico. En caso de ingresar una solicitud escrita, esta se recibirá de Lunes a Viernes de 8h30 a 17h30 en las siguientes direcciones:</p>
          <p>Av. De Los Shyris y Suecia, edificio Renazzo Plaza, piso 12, </p>
          <p>Av. Jaime Roldós Aguilera, Parq. Empresarial Colón Edif. Corporativo 2, Piso 1</p>
          <p>Autopista Cuenca Azogues Edificio Cardeca Business Center</p>
          <p>En caso de tener dudas o consultas respecto al tratamiento, conservación y manejo de sus datos personales, contactarse a la dirección: <a href="postventa@bmi.com.ec">postventa@bmi.com.ec</a>.</p>
          <p>En caso de que BMI cambie su domicilio, este hecho será informado.</p>
        </li>

        <li>
          <a>ANONIMIZACIÓN:</a>
          <p style={{ marginBottom: '20px' }}>BMI declara que para una mayor seguridad podrá adoptar medidas de anonimización de los datos personales de los TDP. A través del proceso de anonimización, no se podrá vincular al dato con una persona natural o TDP, el dato anonimizado no es un Dato Personal. Todo dato personal anonimizado pertenecerá a BMI y podrá usarlo, comercializarlo o transferirlo de acuerdo lo considere necesario, no requerirá de autorización o permiso alguno por parte del TDP.</p>
        </li>

        <li>
          <a>SEGURIDAD DE LOS DATOS PERSONALES:</a>
          <p style={{ marginBottom: '20px' }}>BMI en estricta aplicación del principio de seguridad en el Tratamiento de Datos Personales, implementará las medidas técnicas y organizativas necesarias para garantizar la confidencialidad, integridad y seguridad a los datos de salud y registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. La obligación y responsabilidad de BMI se limita a implementar las medidas de seguridad adecuadas de acuerdo al estado de la técnica disponible de acuerdo a las limitaciones técnicas y económicas.  BMI adoptará todas las medidas de seguridad adecuadas, el Cliente/TDP acepta y reconoce que estas medidas de seguridad no son infalibles y que fallas técnicas o ingresos no autorizados pueden suceder. BMI no será responsable por el acceso no autorizado o alteraciones a la información como consecuencia de fallas técnicas o del ingreso no autorizado por parte de terceros que hayan violado los sistemas de seguridad adoptados por BMI.  BMI exigirá a los proveedores de servicios medidas adecuadas para la protección de los Datos Personales en relación con los cuales dichos proveedores actúen como Encargados.</p>
        </li>

        <li>
          <a>TRANSFERENCIA, TRANSMISIÓN Y REVELACIÓN DE DATOS PERSONALES:</a>
          <p>El TDP acepta y reconoce que BMI puede transferir sus datos personales a terceros relacionados o proveedores, que se encuentren dentro del territorio nacional o en otros países, para realizar el tratamiento de sus datos personales. El TDP autoriza a que BMI transfiera datos relacionados con la naturaleza del servicio asociado con prestaciones médicas, servicios financieros, beneficios adicionales, procesos de auditoría. </p>
          <p>La historia clínica e información de situación de salud es relevante para el diagnóstico y tratamiento de enfermedades, por lo cual podrá ser transferida a los diferentes prestadores de servicios de salud o asesores de BMI, que para efectos de la presente Política, actuarán como Encargados del Tratamiento.</p>
          <p>El TDP acepta y reconoce que BMI podrá transferir sus datos personales a las autoridades administrativas y judiciales que correspondan para la solución de procedimientos administrativos, controversias contractuales o relacionadas con la prestación de salud, o por peticiones motivadas realizadas por las entidades estatales. BMI no será responsable de la seguridad y confidencialidad de los datos e información entregada a las entidades gubernamentales. </p>
          <p>El TDP reconoce que BMI utiliza sistemas informáticos que se encuentran en la nube o “cloud computing”, el TDP reconoce que los servidores que prestan estos servicios se encuentran ubicados en otros países. El TDP acepta que sus datos personales se encuentren alojados en servidores ubicados en otros países, por lo que acepta expresamente la transferencia internacional de los datos, en especial todos los datos sensibles descritos en la presente Política.</p>
        </li>

        <li>
          <a>ANONIMIZACIÓN:</a>
          <p style={{ marginBottom: '20px' }}>Esta Política de Privacidad estará vigente desde el 1 de Agosto de 2020. BMI se reserva el derecho de actualizar la Política de Privacidad cuando lo considere necesario. BMI notificará al TDP en caso de que las futuras actualizaciones modifiquen términos esenciales o derechos de los TDP.</p>
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;
