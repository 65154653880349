import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import api from '../../../utils/Api';
import {
  failedRequestReallocateEmissions,
  START_REQUEST_REALLOCATE_EMISSIONS,
  successRequestReallocateEmissions,
} from '../actions/reallocateEmissions';

/* Getting all users saga */
const fetchReallocateEmissions = (payload) => {
  const {agent, status, creationFrom, creationTo} = payload;
  const url = `${process.env.REACT_APP_ISSUANCE_URL}/findByAgent?agent=${agent}&status=${status}&creationFrom=${creationFrom}&creationTo=${creationTo}`;
  return api.get(url);
};

export function* requestReallocateEmissionsSaga(params) {
  try {
    const response = yield call(fetchReallocateEmissions, params.payload);
    yield put(successRequestReallocateEmissions(response.data));
  } catch (error) {
    yield put(failedRequestReallocateEmissions(error));
  }
}

export function* watchRequestReallocateEmissions() {
  yield takeLatest(START_REQUEST_REALLOCATE_EMISSIONS, requestReallocateEmissionsSaga);
}
