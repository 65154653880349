import { useCallback, useEffect, useState } from 'react';
import { StatusFetch } from '../utils/enums';

const useFetch = ({ api, method = 'get', url, data = null, config = null, immediate = true }) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(undefined);
  const [status, setStatus] = useState(immediate
    ? StatusFetch.Pending : StatusFetch.Idle);

  const execute = useCallback(async () => {
    try {
      if (!immediate) setStatus(StatusFetch.Pending);
      setResponse(null);
      setError(null);
      await api[method](url, data, config)
        .then((res) => {
          setResponse(res.data);
          setStatus(StatusFetch.Success);
        })
        .catch((err) => {
          setError(err);
          setStatus(StatusFetch.Error);
        });
    } catch (err) {
      setError(err);
      setStatus(StatusFetch.Error);
    }
  }, [api, method, url, data, config]);

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return { response, error, status, execute };
};

export default useFetch;
