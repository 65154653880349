import { call, put, select, takeLatest } from 'redux-saga/effects';
import api from '../../../utils/Api';
import { getUser } from '../../Login/reducers/login';
import { getTokenCatalogs, locationUrl } from '../../../utils/CommonUtils';

import {
  failedPostEmployee,
  failedRequestEmployees,
  failedUpdateEmployee,
  START_POST_EMPLOYEE,
  START_REQUEST_EMPLOYEES,
  START_UPDATE_EMPLOYEE,
  successPostEmployee,
  successRequestEmployees,
  successUpdateEmployee,
} from '../actions/administrate_users';

export function* watchRequestEmployees() {
  yield takeLatest(START_REQUEST_EMPLOYEES, requestEmployeesSaga);
  yield takeLatest(START_POST_EMPLOYEE, postEmployeeSaga);
  yield takeLatest(START_UPDATE_EMPLOYEE, updateEmployeeSaga);
}

/* Getting all users saga */
const fetchEmployees = (oid) => api.get(`${process.env.REACT_APP_USERS_URL}/${oid}/employees`);

export function* requestEmployeesSaga() {
  try {
    const { jwtIdToken } = yield select(getUser);
    const { oid } = getTokenCatalogs(jwtIdToken);
    const response = yield call(fetchEmployees, oid);
    yield put(successRequestEmployees(response?.data));
  } catch (error) {
    yield put(failedRequestEmployees(error.response?.data?.Message));
  }
}

/* Post a user */
const postEmployee = (payload, oid) => {
  const data = { ...payload.values, sourceUrl: locationUrl(), broker: oid };
  return api.post(process.env.REACT_APP_USERS_URL, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export function* postEmployeeSaga({ payload }) {
  try {
    const { jwtIdToken } = yield select(getUser);
    const { oid } = getTokenCatalogs(jwtIdToken);
    const response = yield call(postEmployee, payload, oid);
    yield put(successPostEmployee(response));
  } catch (error) {
    yield put(failedPostEmployee(error.response?.data?.Message));
  }
}

/* Update a user */
const updateEmployee = (payload) => {
  const data = payload;
  return api.patch(`${process.env.REACT_APP_USERS_URL}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export function* updateEmployeeSaga({ payload }) {
  try {
    const response = yield call(updateEmployee, payload);
    yield put(successUpdateEmployee(response));
  } catch (error) {
    yield put(failedUpdateEmployee(error.response?.data?.Message));
  }
}
