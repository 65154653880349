import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { useSelector } from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 20,
  },
}));

const returnMonths = (frequency) => ({
  2: 'Semestre',
  3: 'Trimestre',
  5: 'Mes',
})[frequency];

const returnNumberOfMonth = (frequency) => ({
  2: 6,
  3: 3,
  5: 1,
})[frequency];

export default function BankDebitPayment({ effectiveDate, deductibleTotal }) {
  const { control, setValue, watch, errors } = useFormContext();
  const classes = useStyles();
  const { response: { catalogs = [] } } = useSelector((state) => state.catalogs);
  const application = useSelector((state) => state.application);
  const banks = catalogs?.find((c) => c.id === 'InstitucionFinancieraDebito')?.catalogue;
  const frequencyWatch = watch('debitPayment.paymentFrequency');
  const [freqDebitAmount, setFreqDebitAmount] = React.useState(false);

  const onChangeBankDescription = (event) => {
    const bank = banks.find((b) => b.id === event.target.value);
    setValue('debitPayment.bankDescription', bank?.description || '');
    return event.target.value;
  };

  useEffect(() => {
    const { billingData } = application.requestData;
    setValue('debitPayment.accountHolder', `${billingData?.name} ${billingData?.lastName}`);
  }, [application.requestData, setValue]);


  useEffect(() => {
    if (frequencyWatch) {
      const debitAmount = returnNumberOfMonth(frequencyWatch) * deductibleTotal;
      setFreqDebitAmount(Math.round(debitAmount * 100) / 100);
    }
  }, [frequencyWatch]);

  const frecuenciaRef = useRef();
  const numeroRef = useRef();
  const bancoRef = useRef();
  const tipoRef = useRef();

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={errors?.debitPayment?.hasOwnProperty('paymentFrequency')}
            >
              <InputLabel>FRECUENCIA DE PAGO</InputLabel>
              <Controller
                as={(
                  <Select inputRef={frecuenciaRef} labelId="institution-select">
                    <MenuItem value=""><em>Seleccione..</em></MenuItem>
                    <MenuItem value="2">Semestral</MenuItem>
                    <MenuItem value="3">Trimestral</MenuItem>
                    <MenuItem value="5">Mensual</MenuItem>
                  </Select>
                )}
                onFocus={() => frecuenciaRef.current.focus()}
                name="debitPayment.paymentFrequency"
                control={control}
                defaultValue=""
              />
              <FormHelperText>
                {errors.debitPayment?.paymentFrequency?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              as={(
                <TextField />
              )}
              fullWidth
              label="TITULAR DE LA CUENTA"
              name="debitPayment.accountHolder"
              InputProps={{ readOnly: true }}
              control={control}
              defaultValue=""
              error={errors?.debitPayment?.hasOwnProperty('accountHolder')}
              helperText={errors.debitPayment?.accountHolder?.message}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              as={(
                <TextField inputRef={numeroRef} />
              )}
              onFocus={() => numeroRef.current.focus()}
              fullWidth
              label="NUMERO DE CUENTA"
              name="debitPayment.bankAccount"
              inputProps={{ maxLength: 16 }}
              control={control}
              defaultValue=""
              error={errors?.debitPayment?.hasOwnProperty('bankAccount')}
              helperText={errors.debitPayment?.bankAccount?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={errors?.debitPayment?.hasOwnProperty('bankCode')}
            >
              <InputLabel>INSTITUCIÓN FINANCIERA</InputLabel>
              <Controller
                as={(
                  <Select inputRef={bancoRef}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {
                      banks?.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                        >
                          {item.description}
                        </MenuItem>
                      ))
                    }
                  </Select>
                )}
                onFocus={() => bancoRef.current.focus()}
                name="debitPayment.bankCode"
                control={control}
                defaultValue=""
                onChange={([event]) => onChangeBankDescription(event)}
              />
              <FormHelperText>
                {errors.debitPayment?.bankCode?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={errors?.debitPayment?.hasOwnProperty('accountType')}
            >
              <InputLabel>TIPO DE CUENTA</InputLabel>
              <Controller
                as={(
                  <Select inputRef={tipoRef} labelId="institution-select">
                    <MenuItem value=""><em>Seleccione...</em></MenuItem>
                    <MenuItem value="1">Ahorros</MenuItem>
                    <MenuItem value="2">Corriente</MenuItem>
                  </Select>
                )}
                onFocus={() => tipoRef.current.focus()}
                name="debitPayment.accountType"
                control={control}
                defaultValue=""
              />
              <FormHelperText>
                {errors.debitPayment?.accountType?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {frequencyWatch && (
          <Grid container spacing={1}>
            {effectiveDate && (
              <>
                <Grid item><InfoIcon color="error" /></Grid>
                <Grid item>
                  <Typography
                    component="p"
                    color="textSecondary"
                  >
                    {`El dia
                                     ${moment(effectiveDate).format('D')}
                                      de cada ${returnMonths(frequencyWatch) || ''} 
                                      se debitaran $${freqDebitAmount} de su cuenta.`}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Grid>
      <Controller
        as={TextField}
        name="debitPayment.bankDescription"
        control={control}
        type="hidden"
        defaultValue=""
      />
    </Grid>
  );
}

BankDebitPayment.defaultProps = {
  effectiveDate: '',
};

BankDebitPayment.propTypes = {
  effectiveDate: PropTypes.string,
  deductibleTotal: PropTypes.number.isRequired,
};
