export const DEFAULT_VALUE_TYPE_IDENTIFICATION = '1'; // cedula
export const PICKER_FORMAT_DATE = 'dd-MM-yyyy';
export const MESSAGE_INVALID_IDENTIFICATION = 'La cédula ingresada no es válida. Por favor revise los datos y vuelva a ingresarlos.';
export const MESSAGE_REGISTRO_CIVIL = 'Con el número de cédula ingresado no se pudo autocompletar la infomación del cliente. Para continuar con la solicitud, ingrese la información en los campos requeridos.';
export const PROCESSING_PAYMENT = 'Registrando forma de pago, un momento por favor.';
export const REGISTERING_CARD = 'Registrando nueva tarjeta de crédito';
export const INTEGER_EMPTY_VALUE = -32768; // Come from VT when a value is empty
export const MEDICINE_COVERAGE_ENABLED_PARAM = 'MEDICINE_COVERAGE_ENABLED';
// Used for react-promise-tracker, define areas where you should show spinners.
export const DEFAULT_VALUE_NACIONALITY = 'Ecuador';
export const areas = {
  global: 'global-area',
  summary: 'summary-area',
  quotation: 'quotation-area',
};

export const oneEffectiveDateConfiguration = {
  enabled: true,
  Ranges: [
    {
      initialDay: 1,
      endDay: 5,
      proposedDay: 16,
    },
    {
      initialDay: 6,
      endDay: 20,
      proposedDay: 1,
      nextMonth: true,
    },
    {
      initialDay: 21,
      endOfMonth: true,
      proposedDay: 16,
      nextMonth: true,
    },
  ],
};

export const regions = {
  COSTA: [
    {
      name: 'Manabí',
    },
    {
      name: 'Los Ríos',
    },
    {
      name: 'Guayas',
    },
    {
      name: 'Santa Elena',
    },
    {
      name: 'El Oro',
    },
    {
      name: 'Galápagos',
    },
  ],
  SIERRA: [
    {
      name: 'Carchi',
    },
    {
      name: 'Imbabura',
    },
    {
      name: 'Esmeraldas',
    },
    {
      name: 'Pichincha',
    },
    {
      name: 'Sto. Domingo de los Tsachilas',
    },
    {
      name: 'Cotopaxi',
    },
    {
      name: 'Tungurahua',
    },
    {
      name: 'Bolívar',
    },
    {
      name: 'Chimborazo',
    },
    {
      name: 'Orellana',
    },
    {
      name: 'Sucumbíos',
    },
    {
      name: 'Napo',
    },
    {
      name: 'Pastaza',
    },
    {
      name: 'Morona Santiago',
    },
  ],
  AUSTRO: [
    {
      name: 'Cañar',
    },
    {
      name: 'Azuay',
    },
    {
      name: 'Loja',
    },
    {
      name: 'Zamora Chinchipe',
    },
  ],
};
