import { call, put, takeLatest } from 'redux-saga/effects';
import { searchPayments, successPayments, failedPayments } from '../slices/payment_info';
import api from '../../../../../utils/Api';

export function* watchPaymentInfoSaga() {
  yield takeLatest(searchPayments.type, requestPaymentInfoSaga);
}

export function* requestPaymentInfoSaga({ payload: specialDeferred }) {
  try {
    const { data } = yield call(fetchPayments, specialDeferred);
    yield put(successPayments(data));
  } catch (e) {
    yield put(failedPayments(e?.message));
  }
}

const fetchPayments = (specialDeferred) => api
  .get(process.env.REACT_APP_PAYMENT_INFO, { params: { specialDeferred } });
