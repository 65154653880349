import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { usePromiseTracker } from 'react-promise-tracker';
import CircularProgress from '@material-ui/core/CircularProgress';

import { closeModal } from '../../common/modals/actions/simple_modal';
import HeaderTitleModal from '../../Issuance/components/Payment/components/HeaderTitleModal';
import PlaceToPay from '../../../assets/images/payments/placetopay-logo.svg';
import PayPhone from '../../../assets/images/payments/payPhone.png';
import { requestUpdateTokenSendEmail } from '../reducers/updateTokenSendEmailSlice';
import { formatOrEmty, locationUrl, usePrevious } from '../../../utils/CommonUtils';
import { addSweetAlert, closeSweetAlert } from '../../common/SweetAlert/actions/sweet_alert';

const styles = (theme) => ({
  cancelButton: {
    borderRadius: 20,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 30,
      marginRight: 30,
    },
  },
  confirmButton: {
    borderRadius: 20,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 30,
      marginRight: 30,
    },
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#00315C' },
  },
  link: {
    borderBottom: '1.5px solid #518CBF',
    color: '#518CBF',
  },
  noVerticalSpace: {
    display: 'contents',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: 'red',
    position: 'absolute',
  },
});


/**
 * TermsConditionsForm.
 * */
const PaymentResume = withStyles(styles)((props) => {
  const { classes } = props;
  const simpleModal = useSelector((state) => state.simpleModal);
  const iaSelected = simpleModal?.data?.iaSelected;
  const dispatch = useDispatch();
  const updateTokenEmailSliceReducer = useSelector((state) => state.updateTokenEmailSliceReducer);
  const prevUpdateTokenSendEmail = usePrevious(updateTokenEmailSliceReducer);
  const { promiseInProgress } = usePromiseTracker();

  const sendMail = () => {
    dispatch(requestUpdateTokenSendEmail({
      saleProcessId: iaSelected?.saleProcessId,
      appUrl: locationUrl(),
    }));
  };

  const cancel = () => {
    if (!promiseInProgress) {
      dispatch(closeModal({ acceptedTerms: false }));
    }
  };

  const renderPaymentMethod = (payment) => ({
    PLACETOPAY: PlaceToPay,
    PAYPHONE: PayPhone,
  })[payment];

  useEffect(() => {
    if (prevUpdateTokenSendEmail && prevUpdateTokenSendEmail !== updateTokenEmailSliceReducer) {
      if (updateTokenEmailSliceReducer.updateTokenEmailSent) {
        dispatch(addSweetAlert({
          type: 'info',
          title: 'Mail enviado!',
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }));
        dispatch(closeModal());
      }
    }
  }, [updateTokenEmailSliceReducer]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={simpleModal.isOpen}
      onClose={cancel}
      aria-labelledby="form-dialog-title"
    >
      <HeaderTitleModal onClose={() => cancel()}>
        Actualizaci&oacute;n de tarjeta de cr&eacute;dito
      </HeaderTitleModal>
      <form>
        <DialogContent dividers>
          <Grid container direction="column">
            <Grid item xs={12} md={12} className={classes.noVerticalSpace}>
              <Grid container direction="row">
                <Grid item xs={6} md={6}>
                  <Typography className={classes.blueText}>CLIENTE TITULAR</Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.blueText}>CONTRATO</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={classes.noVerticalSpace}>
              <Grid container direction="row">
                <Grid item xs={6} md={6}>
                  <DialogContentText>
                    {iaSelected?.insuranceHolder.name} {iaSelected?.insuranceHolder.lastName}
                  </DialogContentText>
                </Grid>
                <Grid item xs={6} md={6}>
                  <DialogContentText>
                    {iaSelected?.officialPolicyNumber}
                  </DialogContentText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={classes.noVerticalSpace}>
              <Grid container direction="row">
                <Grid item xs={6} md={6}>
                  <Typography className={classes.blueText}>NOMBRE PAGADOR</Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.blueText}>
                    C&Eacute;DULA DE IDENTIDAD PAGADOR
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={classes.noVerticalSpace}>
              <Grid container direction="row">
                <Grid item xs={6} md={6}>
                  <DialogContentText>
                    {iaSelected?.billingData.name} {iaSelected?.billingData.lastName}
                  </DialogContentText>
                </Grid>
                <Grid item xs={6} md={6}>
                  <DialogContentText>
                    {iaSelected?.billingData.identification}
                  </DialogContentText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={classes.noVerticalSpace}>
              <Grid container direction="row">
                <Grid item xs={6} md={6}>
                  <Typography className={classes.blueText}>
                    ESTADO ACTUALIZACIÓN DE TARJETA
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.blueText}>
                    ULTIMA FECHA DE ACTUALIZACIÓN
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={classes.noVerticalSpace}>
              <Grid container direction="row">
                <Grid item xs={6} md={6}>
                  <DialogContentText>
                    {iaSelected?.cardTokenUpdateStatus}
                  </DialogContentText>
                </Grid>
                <Grid item xs={6} md={6}>
                  <DialogContentText>
                    {formatOrEmty(iaSelected?.lastCardTokenUpdateStatus)}
                  </DialogContentText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={classes.noVerticalSpace}>
              <Grid container direction="row">
                <Grid item xs={6} md={6}>
                  <Typography className={classes.blueText}>E-MAIL PAGADOR</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={classes.noVerticalSpace}>
              <Grid container direction="row">
                <Grid item xs={6} md={6}>
                  <DialogContentText>
                    {iaSelected?.billingData.email}
                  </DialogContentText>
                </Grid>
                <Grid item xs={6} md={6}>
                  <DialogContentText>
                    <img
                      width="223"
                      height="62"
                      src={renderPaymentMethod(iaSelected?.paymentButtonProvider)}
                      alt="payment button"
                    />
                  </DialogContentText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 20 }}>
          <Button
            fullWidth
            onClick={cancel}
            color="secondary"
            className={classes.cancelButton}
            variant="outlined"
            disabled={promiseInProgress}
          >
            Cancelar
          </Button>
          <Button
            fullWidth
            type="button"
            color="secondary"
            onClick={sendMail}
            variant="contained"
            className={classes.confirmButton}
            disabled={promiseInProgress}
          >
            Enviar Link {promiseInProgress && (
              <CircularProgress size={24} className={classes.buttonProgress} />
          )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

export default PaymentResume;
