import {
  START_REQUEST_USERS_RE_ASSIGN,
  REQUEST_USERS_RE_ASSIGN_SUCCESS,
  REQUEST_USERS_RE_ASSIGN_FAILED,
} from '../actions/users-re-assign';

const USERS_RE_ASSIGN_INITIAL_STATE = {
  loading: false,
  data: [],
  error: false,
  errorData: '',
  isSuccess: false,
};

export default (state = USERS_RE_ASSIGN_INITIAL_STATE, action) => {
  switch (action.type) {
    case START_REQUEST_USERS_RE_ASSIGN:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        error: false,
      };
    case REQUEST_USERS_RE_ASSIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorData: '',
        isSuccess: true,
        error: false,
      };
    case REQUEST_USERS_RE_ASSIGN_FAILED:
      return {
        ...state,
        loading: false,
        errorData: action.payload,
        isSuccess: false,
        error: true,
      };
    default:
      return state;
  }
};
