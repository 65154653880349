/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  FormLabel, FormHelperText,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

import { closeModal } from '../../../../../common/modals/actions/simple_modal';
import HeaderTitleModal from '../HeaderTitleModal';
import api from '../../../../../../utils/Api';
import CVV from '../../../../../../assets/images/cards/ic_cvv_code.svg';
import TextMaskCustom from '../../../../../common/TextMaskCustom';
import { validateExpirationDate } from '../../../../../../utils/id-validator';
import { renderCreditCardImage } from '../../../../../../utils/CommonUtils';
import {
  addSweetAlert,
  closeSweetAlert,
} from '../../../../../common/SweetAlert/actions/sweet_alert';
import useFetch from '../../../../../../hooks/useFetch';
import { StatusFetch } from '../../../../../../utils/enums';

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  cancelButton: {
    borderRadius: 20,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 30,
      marginRight: 30,
    },
  },
  confirmButton: {
    borderRadius: 20,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 30,
      marginRight: 30,
    },
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#00315C' },
  },
  link: {
    borderBottom: '1.5px solid #518CBF',
    color: '#518CBF',
  },
  buttonProgress: {
    color: 'red',
    position: 'absolute',
  },
});

const schema = yup.object().shape({
  cardNumber: yup.string().required('Este campo es requerido'),
  dateExpiration: yup.string().required('Fecha de Expiracion Requerida')
    .test('expiration-date', 'Fecha de Expiracion Invalida', validateExpirationDate),
  securityCode: yup.string().required('Codigo de Seguridad es Requerido'),
});

const fetchToken = (payload, tokenUpdate) => {
  const url = tokenUpdate
    ? process.env.REACT_APP_SEND_TOKEN_UPDATE_PAYPHONE_URL
    : process.env.REACT_APP_PAYMENT_PAYPHONE;
  return trackPromise(api.post(url, payload));
};

/**
 * CardDataForm.
 * */
const CardDataForm = withStyles(styles)((props) => {
  const { classes } = props;
  const simpleModal = useSelector((state) => state.simpleModal);
  const dispatch = useDispatch();
  const numberCardMask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  const dateExpirationMask = [/\d/, /\d/, '/', /\d/, /\d/];
  const { register, handleSubmit, errors, setError } = useForm({
    validationSchema: schema,
    mode: 'onChange',
  });
  const { promiseInProgress } = usePromiseTracker();
  const [bin, setBin] = React.useState(undefined);
  const { response: deferreds, error: errorDeferred,
    status: statusDeferreds, execute } = useFetch({ api,
    url: `${process.env.REACT_APP_PAYPHONE_DEFERRED}`,
    method: 'post',
    immediate: false,
    data: bin });

  const cancel = () => {
    dispatch(closeModal({ canceled: true, errorMessage: null }));
  };

  const onSubmit = (data) => {
    const currentYearFirstTwoDigits = new Date().getFullYear().toString().slice(0, 2);
    const yearExpirationLastTwoDigits = data.dateExpiration.split('/')[1];
    const payphonePayload = {
      saleProcessId: simpleModal.data.saleProcessId,
      paymentConditionId: simpleModal.data.paymentConditionId,
      paymentMethod: simpleModal.data.paymentMethod,
      cardNumber: data.cardNumber,
      expirationMonth: data.dateExpiration.split('/')[0],
      expirationYear: currentYearFirstTwoDigits.concat(yearExpirationLastTwoDigits),
      securityCode: data.securityCode.toString(),
    };

    const payload = {
      payphonePayload,
      policyPayload: simpleModal.data.policyPayload
    };
    const tokenUpdate = simpleModal.data.tokenUpdate === true;

    fetchToken(payload, tokenUpdate)
      .then((response) => {
        const { paymentResponse, policyResponse } = response.data;
        if(!paymentResponse) {
          dispatch(addSweetAlert({
            type: 'error',
            title: response.data.message,
            showCancel: false,
            onConfirmLabel: 'Continuar',
            onConfirm: () => {
              dispatch(closeSweetAlert());
              dispatch(closeModal());
            },
          }));
        } else {
          const responseData = {
            creditCardName: paymentResponse.creditCardName,
            interests: paymentResponse.interests,
            maskedCard: paymentResponse.maskedCard,
            monthlyAmount: paymentResponse.monthlyAmount,
            payment: paymentResponse.payment,
            paymentReference: paymentResponse.paymentReference,
            result: paymentResponse.result,
            reversed: paymentResponse.reversed,
            canceled: false,
            errorMessage: paymentResponse.message,
            policyResponse
          };
          dispatch(closeModal(responseData));
        }
      }).catch((error) => {
        dispatch(addSweetAlert({
          type: 'error',
          title: 'No se pudo realizar el pago inténtelo mas tarde, ' + error.message,
          showCancel: false,
          onConfirmLabel: 'Continuar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
            dispatch(closeModal());
          },
        }));
      });
  };

  const validateNumberEntry = (e) => {
    const code = (e.which) ? e.which : e.keyCode;
    if (code < 48 || code > 57) {
      e.preventDefault();
    }
  };

  function onBlurCardNumber(cardNumber) {
    const binCardNumber = cardNumber.replace(/ /g, '').trim();
    if (binCardNumber.length >= 6 && simpleModal.data.isDeferred) {
      setBin({ Bin: btoa(binCardNumber.substring(0, 6)) });
    }
  }

  useEffect(() => {
    if (bin) {
      execute();
    }
  }, [bin, execute]);

  useEffect(() => {
    if (statusDeferreds === StatusFetch.Error) {
      setError('cardNumber', 'notMatch', errorDeferred.response.data.Message || 'Error al consultar tarjeta.');
    }
    if (deferreds) {
      const matchDeferred = deferreds.some((deferred) => deferred.name
        .toLowerCase() === simpleModal.data.interests.toLowerCase());
      if (!matchDeferred) {
        setError('cardNumber', 'notMatch', 'La tarjeta proporcionada no soporta el diferido seleccionado previamente.');
      }
    }
  }, [deferreds, statusDeferreds, errorDeferred]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={simpleModal.isOpen}
      onClose={cancel}
      aria-labelledby="form-dialog-title"
    >
      <HeaderTitleModal onClose={() => cancel()}>
        Datos de la tarjeta de cr&eacute;dito
      </HeaderTitleModal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Grid container direction="column">
            <Grid container direction="row">
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.margin}
                  error={errors?.hasOwnProperty('cardNumber')}
                >
                  <FormLabel htmlFor="cardNumber">
                    N&uacute;mero de la tarjeta
                  </FormLabel>
                  <OutlinedInput
                    id="cardNumber"
                    name="cardNumber"
                    endAdornment={(
                      <InputAdornment position="end">
                        <Icon>
                          <img
                            src={renderCreditCardImage(
                              simpleModal.data.creditCardName,
                            ) || renderCreditCardImage('VISA')}
                            alt="visa card"
                            height={25}
                            width={25}
                          />
                        </Icon>
                      </InputAdornment>
                    )}
                    aria-describedby="outlined-weight-helper-text"
                    inputComponent={TextMaskCustom}
                    inputProps={{
                      mask: numberCardMask,
                      placeholder: '1234 5678 9012 3456',
                    }}
                    inputRef={register}
                    autoFocus
                    onBlur={(e) => onBlurCardNumber(e.target.value)}
                    style={{ marginTop: 10 }}
                  />
                  <FormHelperText>
                    {errors.cardNumber?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} style={{ marginTop: '30px' }}>
              {/* Date Expiration */}
              <Grid item lg={6} xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.margin}
                  error={errors?.hasOwnProperty('dateExpiration')}
                >
                  <FormLabel htmlFor="dateExpiration">
                    Fecha de Vencimiento
                  </FormLabel>
                  <OutlinedInput
                    id="dateExpiration"
                    name="dateExpiration"
                    aria-describedby="outlined-weight-helper-text"
                    style={{ marginTop: 10 }}
                    inputComponent={TextMaskCustom}
                    inputProps={{
                      mask: dateExpirationMask,
                      placeholder: 'MM/YY',
                    }}
                    inputRef={register}
                  />
                  <FormHelperText>
                    {errors.dateExpiration?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.margin}
                  error={errors?.hasOwnProperty('securityCode')}
                >
                  <FormLabel htmlFor="securityCode">
                    C&oacute;digo de seguridad (CVV)
                  </FormLabel>
                  <OutlinedInput
                    id="securityCode"
                    name="securityCode"
                    inputRef={register}
                    type="password"
                    endAdornment={(
                      <InputAdornment position="end">
                        <Icon>
                          <img
                            src={CVV}
                            alt="visa card"
                            height={25}
                            width={25}
                          />
                        </Icon>
                      </InputAdornment>
                    )}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{ maxLength: 4 }}
                    onKeyPress={(e) => {
                      validateNumberEntry(e);
                    }}
                    style={{ marginTop: 10 }}
                    placeholder="1234"
                  />
                  <FormHelperText>
                    Código numérico ubicado en el reverso de su tarjeta.
                  </FormHelperText>
                  <FormHelperText>
                    {errors.securityCode?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 20 }}>
          <Button
            fullWidth
            onClick={cancel}
            color="secondary"
            className={classes.cancelButton}
            variant="outlined"
            disabled={promiseInProgress}
          >
            Cancelar
          </Button>
          <Button
            fullWidth
            type="submit"
            color="secondary"
            variant="contained"
            className={classes.confirmButton}
            disabled={promiseInProgress || statusDeferreds === StatusFetch.Pending}
          >
            Aceptar {
            promiseInProgress
            && <CircularProgress size={24} className={classes.buttonProgress} />
          }
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

export default CardDataForm;
