/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

import { openModal } from '../../common/modals/actions/simple_modal';
import api from '../../../utils/Api';
import { generateZip } from '../../../utils/CommonUtils';
import { addSweetAlert, closeSweetAlert } from '../../common/SweetAlert/actions/sweet_alert';

const ActionMenu = React.memo(({ anchorEl, setAnchorEl, issuanceRow, getEmissions }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showPaymentResum = () => {
    //RZ: 1606223 tracker 44097 deshabilitar opción actualizar tarjeta
    handleClose();
    dispatch(addSweetAlert({
      type: 'error',
      title: 'Opción deshabilitada- esta actualización debe realizarse en el Portal de Agentes BMI',
      showCancel: false,
      onConfirmLabel: 'Aceptar',
      onConfirm: () => {
        dispatch(closeSweetAlert());
      },
    }))
 /*  dispatch(openModal({ iaSelected: issuanceRow }));*/
  };

  const handleDownloadSignedDocuments = () => {
    handleClose();
    api.get(`${process.env.REACT_APP_SIGNED_DOWNLOAD_DOCUMENTS}`.concat('/', issuanceRow?.saleProcessId))
      .then(({ data: pdfData }) => {
        generateZip(pdfData);
      })
      .catch((error) => {
        dispatch(addSweetAlert({
          type: 'error',
          title: 'No se pudo generar el documento',
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }));
      });
  };

  const handleDownloadNotSignedDocuments = () => {
    handleClose();
    api.get(`${process.env.REACT_APP_NOT_SIGNED_DOWNLOAD_DOCUMENTS}`.concat('/', issuanceRow?.saleProcessId))
      .then(({ data: pdfData }) => {
        generateZip(pdfData);
      })
      .catch((error) => {
        dispatch(addSweetAlert({
          type: 'error',
          title: 'No se pudo generar el documento',
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }));
      });
  };

  const handleDeleteInsuranceApplication = () => {
    dispatch(addSweetAlert({
      type: 'warning',
      title: `Confirma que desea eliminar la solicitud en curso perteneciente a ${issuanceRow.insuranceHolder.completeName}`,
      showCancel: true,
      onConfirmLabel: 'Confirmar',
      onCancelLabel: 'Cancelar',
      onCancel: () => {
        dispatch(closeSweetAlert());
        setAnchorEl(false);
      },
      onConfirm: () => {
        deleteInsuranceApplication();
      },
    }));
  };

  const deleteInsuranceApplication = () => {
    api.post(`${process.env.REACT_APP_ISSUANCE_URL}/delete/${issuanceRow?.saleProcessId}`).then(
      (response) => {
        getEmissions();
        dispatch(closeSweetAlert());
        setAnchorEl(false);
      },
    ).catch((err) => {
      console.log(err);
      dispatch(addSweetAlert({
        type: 'error',
        title: 'Error al eliminar la solicitud',
        showCancel: false,
        onConfirmLabel: 'Aceptar',
        onConfirm: async () => {
          dispatch(closeSweetAlert());
          setAnchorEl(false);
        },
      }));
    });
  };

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {issuanceRow?.state === 'Firmado' && (
        <MenuItem onClick={handleDownloadSignedDocuments}>Descargar Documentos</MenuItem>
      )}
      {issuanceRow?.state === 'Pendiente Firma' && (
        <MenuItem onClick={handleDownloadNotSignedDocuments}>Descargar Documentos No Firmados</MenuItem>
      )}
      {issuanceRow?.state === 'Pendiente Pago' && (
        <MenuItem onClick={handleDeleteInsuranceApplication}>Eliminar Solicitud</MenuItem>
      )}
      {issuanceRow?.changeP2pTokenAllowed && (
        <MenuItem onClick={showPaymentResum}>Actualizar Tarjeta</MenuItem>
      )}
    </Menu>
  );
});

ActionMenu.propTypes = {
  anchorEl: PropTypes.object.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  getEmissions: PropTypes.func.isRequired,
  issuanceRow: PropTypes.shape({
    saleProcessId: PropTypes.string,
    state: PropTypes.string,
    changeP2pTokenAllowed: PropTypes.bool,
  }).isRequired,
};

export default ActionMenu;
