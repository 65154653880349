import React, { useEffect } from 'react';
import {
  Box,
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ShareIcon from '@material-ui/icons/Share';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  checkEmptyObject,
  generatePdf,
  getRegionName,
  getUserRole,
  isEmpty,
  usePrevious,
} from '../../../../../../utils/CommonUtils';
import { openModal } from '../../../../../common/modals/actions/simple_modal';
import {
  addSweetAlert,
  closeSweetAlert,
} from '../../../../../common/SweetAlert/actions/sweet_alert';
import { goToStep } from '../../../../../Issuance/utils/steps';
import { startSignIn } from '../../../../../Login/actions/login';
import { authProvider } from '../../../../../Login/auth/auth_provider';
import { requestPDF } from '../../../../actions/quotationUtils';
import EmailModal from '../../../Email/EmailModal';
import { SidebarForm } from '../../../Sidebar/components';
import headerStyles from '../../style/header-style';
import { RolEnum } from '../../../../../Issuance/components/Payment/Enums';

export default function HeaderQuote(props) {
  const { onOpen, isDesktop } = props;

  // hooks
  const classes = headerStyles();
  const simpleModal = useSelector((state) => state.simpleModal);
  const { quote: quotation, login } = useSelector(({ quote, login }) => ({ quote, login }));
  const dispatch = useDispatch();
  const history = useHistory();
  const pdfGenerated = useSelector((state) => state.quotationUtils);
  const previousPdfGenerated = usePrevious(pdfGenerated);
  const [loginPending, setLoginPending] = React.useState(false);
  const [expanded, setExpanded] = React.useState();
  const role = getUserRole(login);

  const handleLogin = () => {
    dispatch(startSignIn(authProvider));
  };

  const sendByEmail = () => {
    if (quotation.response && quotation.requestData) {
      if (Object.values(quotation?.deductibleSelected).every((x) => (x !== 0))) {
        quotation.requestData.deductibleId = quotation.deductibleSelected.deductibleId;
        quotation.requestData.DeductibleQuotationData = quotation.response;
        dispatch(requestPDF(
          quotation.requestData,
          quotation.response.quotationHash,
          quotation.deductibleSelected.deductibleId,
        ));
      } else {
        dispatch(addSweetAlert({
          type: 'warning',
          title: 'Debe seleccionar un deducible primero.',
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }));
      }
    }
  };

  const sendEmail = () => {
    if (Object.values(quotation?.deductibleSelected).every((x) => (x !== 0))) {
      if (!checkEmptyObject(quotation.requestData)) {
        dispatch(openModal());
      } else {
        dispatch(addSweetAlert({
          type: 'warning',
          title: 'Debe Generar primero una cotizacion.',
          showCancel: false,
          onConfirmLabel: 'Aceptar',
          onConfirm: () => {
            dispatch(closeSweetAlert());
          },
        }));
      }
    } else {
      dispatch(addSweetAlert({
        type: 'warning',
        title: 'Debe seleccionar un deducible primero.',
        showCancel: false,
        onConfirmLabel: 'Aceptar',
        onConfirm: () => {
          dispatch(closeSweetAlert());
        },
      }));
    }
  };

  const generateRequestDocument = () => {
    if (checkEmptyObject(quotation.response)) {
      dispatch(addSweetAlert({
        type: 'warning',
        title: 'Debe Generar primero una cotizaci\u00f3n.',
        showCancel: false,
        onConfirmLabel: 'Aceptar',
        onConfirm: () => {
          dispatch(closeSweetAlert());
        },
      }));
    } else if (!login.signedIn) {
      setLoginPending(true);
      handleLogin();
    } else if (Object.values(quotation?.deductibleSelected).every((x) => (x !== 0))) {
      goToStep(history, 'Solicitud');
    } else {
      dispatch(addSweetAlert({
        type: 'warning',
        title: 'Por favor seleccione el deducible.',
        showCancel: false,
        onConfirmLabel: 'Aceptar',
        onConfirm: () => {
          dispatch(closeSweetAlert());
        },
      }));
    }
  };

  const expandPanel = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (previousPdfGenerated && (previousPdfGenerated.isSuccess !== pdfGenerated.response)) {
      if (pdfGenerated?.response && !isEmpty(pdfGenerated?.response)) {
        generatePdf(pdfGenerated.response);
      }
    }
  }, [pdfGenerated.response]);

  useEffect(() => {
    if (!login.isSigningInProgress) {
      if (login.signedIn && loginPending) {
        goToStep(history, 'Solicitud');
      }
      setLoginPending(false);
    }
  }, [login]);

  useEffect(() => {
    if (quotation.requestData.planName) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, [quotation]);

  return (
    <div>
      {!isDesktop
      && (
        <div>
          <ExpansionPanel
            defaultExpanded
            expanded={expanded}
          >
            <ExpansionPanelSummary
              onClick={expandPanel}
              expandIcon={(
                <IconButton
                  variant="contained"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                >
                  <ExpandMore style={{ color: '#518CBF' }} />
                </IconButton>
              )}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              {!quotation.requestData.planName
                ? 'DATOS PARA COTIZAR'
                : (quotation.requestData.planName.toUpperCase() + ' * '
                  + quotation.requestData.age + ' AÑOS * '
                  + getRegionName(quotation.requestData.branchOfficeId))}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <SidebarForm
                className={classes.nav}
                isDesktop={false}
                onClose={onOpen}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      )}
      {
        quotation.response.mainGroup
        && (
          <div
            style={{ flex: 1, backgroundColor: 'white', paddingBottom: 65, position: 'relative' }}
          >
            {simpleModal.isOpen && <EmailModal />}
            <Grid container direction="row">
              <Grid item xs={6}>
                <Button
                  className={classes.acceptQuote}
                  variant="contained"
                  color="primary"
                  onClick={generateRequestDocument}
                  disabled={login.isSigningInProgress || role === RolEnum.ReporterUnified}
                >
                  CONTINUAR CON LA SOLICITUD
                </Button>
              </Grid>
              {isDesktop
                ? (
                  <Grid
                    container
                    item
                    xs={6}
                    className={classes.containerShareButton}
                    direction="row"
                  >
                    <Box borderRadius="50%" {...defaultProps(-80)} className={classes.shareButtons}>
                      <IconButton aria-label="share" className={classes.buttonStyle} onClick={sendEmail}>
                        <ShareIcon />
                      </IconButton>
                    </Box>
                    <Box borderRadius="50%" {...defaultProps(-140)} className={classes.pdfButtons}>
                      <IconButton aria-label="document" className={classes.buttonStyle} onClick={sendByEmail}>
                        <InsertDriveFileOutlinedIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                )
                : (
                  <Grid container item xs={6} direction="row">
                    <IconButton
                      aria-label="share"
                      className={classes.shareButtons}
                      onClick={sendEmail}
                    >
                      <ShareIcon style={{ color: '#00315C' }} />
                    </IconButton>
                    <IconButton
                      aria-label="document"
                      className={classes.pdfButtons}
                      onClick={sendByEmail}
                    >
                      <InsertDriveFileOutlinedIcon style={{ color: '#00315C' }} />
                    </IconButton>
                  </Grid>
                )}
            </Grid>
          </div>
        )
      }
    </div>
  );
}

HeaderQuote.propTypes = {
  onOpen: PropTypes.func,
  isDesktop: PropTypes.bool.isRequired,
};

const defaultProps = (marginLeft = 0, marginTop = 40) => ({
  bgcolor: '#143f67',
  borderColor: 'text.primary',
  style: {
    width: '3rem', height: '3rem', position: 'absolute', marginLeft, marginTop,
  },
});
