/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import CustomPanel from '../../../../common/CustomPanel';
import {
  checkEmptyObject, checkEmptyVTValue,
  groupProvinceByRegion,
  isEmpty,
  usePrevious,
} from '../../../../../utils/CommonUtils';
import { selectDivisionPolitica, selectPlace } from '../utils/selectors';
import { ADDRESS_ALERT_MESSAGE } from '../../../../legal.messages';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  select: {
    margin: theme.spacing(1),
    minWidth: '95%',
  },
}));

/**
 * Search  cities from a provinceID.
 * @param states contains all Ecuador provinces.
 * @param provinceID id of the province to be searched.
 * */
function searchCitiesByProvince(states, provinceID) {
  return states.find((c) => c.id === provinceID)?.children;
}

const OwnerContact = (props) => {
  const { readOnly, withDifferentHolder, branchOfficeId } = props;
  const getApplication = useSelector((state) => state.getApplication);
  const prevGetApplication = usePrevious(getApplication);
  const classes = useStyles();
  const { register, control, errors, setValue } = useFormContext();
  const [cities, setCities] = React.useState([]);
  const [disabledCitySelect, setDisabledCitySelect] = React.useState(true);

  const clientInfo = useSelector((state) => state.clientInfo);
  const prevClientInfo = usePrevious(clientInfo);

  const countries = useSelector(selectDivisionPolitica);
  const places = useSelector(selectPlace);
  const ecuadorProvinces = countries?.find((c) => c.id === '55')?.children;
  const states = groupProvinceByRegion(branchOfficeId, ecuadorProvinces);

  const findByIdentificationResult = useSelector((state) => state.findByIdentificationResult);
  const prevFindByIdentificationResult = usePrevious(findByIdentificationResult);

  useEffect(() => {
    if (prevGetApplication && (prevGetApplication.response !== getApplication.response)) {
      if (!isEmpty(getApplication.response)) {
        setContactForm(getApplication?.response?.InsuranceApplicationContact);
      }
    }
  }, [getApplication.response]);

  /**
   * Called when the application is in edition mode. Set the info of owner contact.
   * @param insuranceApplicationContact contain the information from owner contact
   * */
  const setContactForm = (insuranceApplicationContact) => {
    const filteredCities = searchCitiesByProvince(states, insuranceApplicationContact?.Province.trim());
    setCities(filteredCities);
    const city = filteredCities?.find((c) => c.id === insuranceApplicationContact?.City.trim());

    setValue([
      { 'contact.place': insuranceApplicationContact?.Place.trim() || '' },
      { 'contact.province': insuranceApplicationContact?.Province.trim() || '' },
      { 'contact.cityDescription': city?.description || '' },
      { 'contact.address': insuranceApplicationContact?.Address.trim() || '' },
      { 'contact.conventionalPhone': insuranceApplicationContact?.ConventionalPhone.trim() || '' },
      { 'contact.cellularPhone': insuranceApplicationContact?.CellularPhone.trim() || '' },
      { 'contact.email': insuranceApplicationContact?.Email.trim() || '' },
    ]);
  };

  const handleChangeProvince = (event) => {

    if (event.target.value === '') {
      setDisabledCitySelect(true);
    } else {
      setDisabledCitySelect(false);
    }
    setValue('contact.city', '');

    const filteredCities = searchCitiesByProvince(states, event.target.value);
    setCities(filteredCities);
    return event.target.value;
  };

  const handleChangeCity = (event) => {
    const city = cities.find((c) => c.id === event.target.value);

    setValue('contact.cityDescription', city.description);

    return event.target.value;
  };

  /**
   * Set information about MainInsured.
   * */
  useEffect(() => {
    if (prevClientInfo && (clientInfo.response !== prevClientInfo.response)) {
      if (!withDifferentHolder) {
        const client = clientInfo?.response.clientDto;
        setContactValues(client);
      }
    }
  }, [clientInfo.response]);

  /**
   * Set Data of the Holder When Plan is Infinity Kids.
   * */
  useEffect(() => {
    if (prevFindByIdentificationResult && (prevFindByIdentificationResult.response
      !== findByIdentificationResult.response)) {
      if (findByIdentificationResult.found) {
        const client = findByIdentificationResult.response.clientDto;
        if (!checkEmptyObject(client) && withDifferentHolder) {
          setContactValues(client);
        }
      }
    }
  }, [findByIdentificationResult.response]);

  const setContactValues = (client) => {
    if (client?.nProvince) {
      const filteredCities = searchCitiesByProvince(states, client.nProvince.toString());
      setCities(filteredCities);
    }
    setValue('contact.place', checkEmptyVTValue(client?.nTypeOfAddress));
    setValue('contact.province', checkEmptyVTValue(client?.nProvince));
    setValue('contact.cityDescription', client?.sCity || '');
    setValue('contact.address', client?.sStreet || '');
    setValue('contact.conventionalPhone', client?.sPhoneConv || '');
    setValue('contact.cellularPhone', client?.sPhoneCel || '');
    setValue('contact.email', client?.sEmail || '');
  };

  useEffect(() => {
    if (cities?.length > 0 && findByIdentificationResult?.response?.clientDto) {
      const city = cities.find((c) => c.description === findByIdentificationResult?.response?.clientDto?.sCity);
      setValue('contact.city', city?.id || '');
    }
  }, [cities, findByIdentificationResult]);

  /**
   * Sets the city to be displayed on the form. It is
   * necessary that the cities array contains information
   * first before setting the city id.
   * */
  useEffect(() => {
    if (cities?.length > 0 && clientInfo?.response?.clientDto) {
      const city = cities.find((c) => c.description === clientInfo?.response?.clientDto?.sCity);
      setValue('contact.city', city?.id || '');
    }
  }, [cities, clientInfo, setValue]);

  /**
   * Sets the city to be displayed on the form. It is
   * necessary that the cities array contains information and
   * applicationContact too before setting the city id.
   * */
  useEffect(() => {
    const applicationContact = getApplication?.response?.InsuranceApplicationContact;
    if (cities?.length > 0 && applicationContact) {
      setValue('contact.city', applicationContact?.City.trim() || '');
    }
  }, [cities, getApplication, setValue]);


  const lugarRef = useRef();
  const ciudadRef = useRef();
  const direccionRef = useRef();
  const telefonoRef = useRef();
  const celularRef = useRef();
  const emailRef = useRef();

  return (
    <CustomPanel title="Contacto del Titular" border={false} tooltipMessage={ADDRESS_ALERT_MESSAGE}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item md={4} xs={12}>
          <FormControl
            className={classes.select}
            error={errors.contact?.place && true}
          >
            <InputLabel id="region-select">LUGAR</InputLabel>
            <Controller
              as={(
                <Select inputRef={lugarRef} labelId="region-select">
                  <MenuItem value="">
                    <em>Seleccione..</em>
                  </MenuItem>
                  <MenuItem value={places[0]?.id}>
                    {places[0]?.description}
                  </MenuItem>
                </Select>
              )}
              onFocus={() => lugarRef.current.focus()}
              name="contact.place"
              control={control}
              defaultValue=""
              inputProps={{ readOnly }}
            />
            {errors.contact?.place
            && <FormHelperText>{errors.contact?.place.message}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl
            className={classes.select}
            error={errors.contact?.province && true}
          >
            <InputLabel id="province-select">PROVINCIA</InputLabel>
            <Controller
              as={(
                <Select labelId="province-select">
                  <MenuItem value="">
                    <em>Seleccione...</em>
                  </MenuItem>
                  {states?.map((b) => (
                    b.id !== '0' && (
                      <MenuItem key={b.id} value={b.id}>
                        {b.description}
                      </MenuItem>
                    )
                  ))}
                </Select>
              )}
              name="contact.province"
              control={control}
              onChange={([event]) => handleChangeProvince(event)}
              defaultValue=""
              inputProps={{ readOnly }}
            />
            {errors.contact?.province
            && <FormHelperText>{errors.contact?.province.message}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl
            className={classes.select}
            error={errors.contact?.city && true}
            disabled={disabledCitySelect}
          >
            <InputLabel id="city-select">CIUDAD</InputLabel>
            <Controller
              as={(
                <Select inputRef={ciudadRef} labelId="city-select">
                  <MenuItem value="">
                    <em>Seleccione...</em>
                  </MenuItem>
                  {cities?.map((b) => (
                    <MenuItem key={b.id} value={b.id}>
                      {b.description}
                    </MenuItem>
                  ))}
                </Select>
              )}
              onFocus={() => ciudadRef.current.focus()}
              name="contact.city"
              control={control}
              onChange={([event]) => handleChangeCity(event)}
              defaultValue=""
              inputProps={{ readOnly }}
            />
            {errors.contact?.city
            && <FormHelperText>{errors.contact?.city.message}</FormHelperText>}
            <input
              type="hidden"
              name="contact.cityDescription"
              ref={register}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
          <Controller
            as={(
              <TextField inputRef={direccionRef} />
            )}
            onFocus={() => direccionRef.current.focus()}
            fullWidth
            label="DIRECCIÓN"
            name="contact.address"
            autoComplete="off"
            control={control}
            error={errors.contact?.hasOwnProperty('address')}
            helperText={
              errors.contact?.address && errors.contact?.address.message
            }
            defaultValue=""
            InputProps={{ readOnly }}
          />
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
        >
          <Controller
            as={(
              <TextField inputRef={telefonoRef} />
            )}
            onFocus={() => telefonoRef.current.focus()}
            fullWidth
            label="TELF FIJO"
            autoComplete="off"
            name="contact.conventionalPhone"
            inputProps={{ maxLength: 10 }}
            control={control}
            error={errors.contact?.hasOwnProperty('conventionalPhone')}
            helperText={
              errors.contact?.conventionalPhone
              && errors.contact?.conventionalPhone.message
            }
            defaultValue=""
            InputProps={{ readOnly }}
          />
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
        >
          <Controller
            as={(
              <TextField inputRef={celularRef} />
            )}
            onFocus={() => celularRef.current.focus()}
            fullWidth
            label="CELULAR"
            autoComplete="off"
            name="contact.cellularPhone"
            inputProps={{ maxLength: 10 }}
            control={control}
            error={errors.contact?.hasOwnProperty('cellularPhone')}
            helperText={
              errors.contact?.cellularPhone && errors.contact?.cellularPhone.message
            }
            defaultValue=""
            InputProps={{ readOnly }}
          />
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
        >
          <Controller
            as={(
              <TextField inputRef={emailRef} />
            )}
            onFocus={() => emailRef.current.focus()}
            fullWidth
            label="EMAIL"
            autoComplete="off"
            name="contact.email"
            control={control}
            error={errors.contact?.hasOwnProperty('email')}
            helperText={errors.contact?.email && errors.contact?.email.message}
            defaultValue=""
            InputProps={{ readOnly }}
          />
        </Grid>
      </Grid>
    </CustomPanel>
  );
};

OwnerContact.defaultProps = {
  withDifferentHolder: false,
  branchOfficeId: 0,
};

OwnerContact.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  withDifferentHolder: PropTypes.bool,
  branchOfficeId: PropTypes.number,
};

export default OwnerContact;
