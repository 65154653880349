/* eslint-disable prefer-const,import/no-mutable-exports */
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './Reducers';
import rootSaga from './Sagas';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// redux persist
const persistConfig = {
  key: 'applicationState',
  storage: storageSession,
  blacklist: ['simpleModal', 'getApplication'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware],
});

// run saga
sagaMiddleware.run(rootSaga);

let persistor = persistStore(store);

export { store, persistor };
