/* eslint-disable import/prefer-default-export */
import { watchPaymentInfoSaga } from './payment_info';
import { watchCallPaymentButton } from './payment_button';
import { watchPolicySaga } from './policy';

export const payments = [
  watchPaymentInfoSaga,
  watchCallPaymentButton,
  watchPolicySaga,
];
