export default {
  alternativeLabel: {
    top: 24,
  },
  line: {
    height: 10,
    borderRadius: 1,
    backgroundColor: '#E8E8E8',
    color: '#E8E8E8',
    borderColor: 'transparent',
  },
};
