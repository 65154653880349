import { call, put, takeLatest } from 'redux-saga/effects';
import { trackPromise } from 'react-promise-tracker';

import {
  failureFindByIdentification,
  REQUEST_FIND_BY_IDENTIFICATION,
  successFindByIdentification,
} from '../actions/find_by_identification';
import api from '../../../../../utils/Api';
import {
  failedDependentIdentification,
  REQUEST_DEPENDENT_IDENTIFICATION,
  successDependentIdentification,
} from '../actions/dependents_identification';
import { areas } from '../../../../../utils/Constants';

export function* watchFindByIdentification() {
  yield takeLatest(REQUEST_FIND_BY_IDENTIFICATION, requestFinByIdentification);
}

export function* watchDependentsIdentification() {
  yield takeLatest(REQUEST_DEPENDENT_IDENTIFICATION, requestDependentSaga);
}

export function* requestFinByIdentification({ payload }) {
  try {
    const response = yield call(getClientInformation, payload);
    yield put(successFindByIdentification(response.data));
  } catch (e) {
    yield put(failureFindByIdentification(e?.response?.data));
  }
}

export function* requestDependentSaga({ payload: { identification, index } }) {
  try {
    const response = yield call(findByIdentification, identification);
    yield put(successDependentIdentification(response.data, index));
  } catch (e) {
    yield put(failedDependentIdentification(e?.response?.data, index));
  }
}

const findByIdentification = (identification) => trackPromise(api
  .get(process.env.REACT_APP_FIND_BY_IDENTIFICATION_URL.concat('/', identification)), areas.global);

const getClientInformation = (parameters) => trackPromise(api
  .get(process.env.REACT_APP_CLIENT_INFORMATION_VALIDATION
    .concat('/', parameters.identification, '/', parameters.productId, '/', parameters.isHolder)), areas.global);
